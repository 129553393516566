import React from "react";
import DashboardItem, { DashboardItemIconSizeEnum } from "./DashboardItem";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { Util } from "../Utils/Util";
import { FaBook, FaTools, FaUserGraduate, FaWpforms, FaWrench, FaMobileAlt } from "react-icons/fa";
import { TiDocumentText, TiDocument, TiMediaPlay, TiThList } from "react-icons/ti";
import { IoIosWater } from "react-icons/io";
import { AiOutlineEuroCircle } from "react-icons/ai";
import { RiHomeGearFill, RiListSettingsLine } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { GiRibbonMedal } from "react-icons/gi";
import { BsArrowsCollapse } from "react-icons/bs";

import "./Dashboard.scss";
import { IUser } from "../viewmodels/auth";
import { Row, Col, Container } from "reactstrap";
import { UserRepository } from "../repositories/UserRepository";
import withTracker from "../components/analytics/withTracker";

import env from "../env/env.json";
import AuthRepository from "../repositories/AuthRepository";
// import { TiDocumentText, TiThList } from "react-icons/ti";

interface IDashboardProps extends WrappedComponentProps {
    user?: IUser;
}

class Dashboard extends React.Component<IDashboardProps> {

    private onRequestPriceIndicationClick = () => Util.Navigate("Forms/FormA");
    private onRequestsOverviewClick = () => Util.Navigate("PriceIndications");
    private onCommissionFormClick = () => Util.Navigate("Forms/Commissioning");
    private onCommissionOverviewClick = () => Util.Navigate("Commissionings");
    private onComplaintFormClick = () => Util.Navigate("Forms/Reclamatie");
    private onComplaintOverviewClick = () => Util.Navigate("Reclamaties");
    private multiZoneVerdelerConfiguratorClick = () =>
        Util.NavigateAbsolute(`${env.MultiZoneVerdelerConfiguratorWebUrl}/auth?jwt=${(new AuthRepository()).getToken()}`, true,
            this.props.intl.formatMessage({ id: "dashboard.items.multiZoneVerdelerConfigurator", defaultMessage: "MZV configurator" }));
    private onKnowledgeBaseClick = () => Util.Navigate("Kennisbank");
    private onBrlToolClick = () => Util.NavigateAbsolute("https://brl.nathan.nl/login", true);
    private onBeheerSystemenClick = () => Util.Navigate("Solutions");
    private onTestRteClick = () => Util.Navigate("/Test/TestRtePage");
    private onQuoteConfiguratorClick = () => Util.Navigate("QuoteConfigurator/QuoteConfiguratorWizard");
    private onQuoteOverviewClick = () => Util.Navigate(`QuoteConfigurator/QuotationOverview`);

    renderItemColumn = (item: JSX.Element, disabled?: boolean) =>
        !disabled ? <Col sm={6} md={4}>{item}</Col> : <></>;

    render() {
        const availableSoon = this.props.intl.formatMessage({ id: "general.availableSoon", defaultMessage: "Available soon" });
        const availableIn2021 = this.props.intl.formatMessage({ id: "general.availableInYear", defaultMessage: "Available in {year}" }, { year: 2021 });
        const inDevelopment = this.props.intl.formatMessage({ id: "general.inDevelopment", defaultMessage: "In development" });
        const nowLive = this.props.intl.formatMessage({ id: "general.nowLive", defaultMessage: "Now live" });

        const lwdvAppTitle = this.props.intl.formatMessage({ id: "dashboard.items.lwdvApp", defaultMessage: "LWDV app" });
        const nathanAcademyTitle = this.props.intl.formatMessage({ id: "dashboard.items.nathanAcademy", defaultMessage: "Nathan academy" });
        const startUpFormTitle = this.props.intl.formatMessage({ id: "dashboard.items.startUpForm", defaultMessage: "Start-up form" });
        const knowledgeBaseTitle = this.props.intl.formatMessage({ id: "dashboard.items.knowledgeBase", defaultMessage: "Knowledge base" });
        const brlToolTitle = this.props.intl.formatMessage({ id: "dashboard.items.brlTool", defaultMessage: "BRL tool" });
        const beheerSystemenTitle = this.props.intl.formatMessage({ id: "dashboard.items.beheerSystemen", defaultMessage: "BEHEER SYSTEMEN" });
        const quoteConfiguratorTitle = this.props.intl.formatMessage({ id: "dashboard.items.quoteConfigurator", defaultMessage: "OFFERTEGENERATOR" });
        const quoteOverviewTitle = this.props.intl.formatMessage({ id: "dashboard.items.quoteOverview", defaultMessage: "OFFERTEOVERZICHT" });

        const requestPriceIndicationTitle = this.props.intl.formatMessage({ id: "dashboard.items.requestPriceIndication", defaultMessage: "Request price indication" })
        const requestsOverviewTitle = this.props.intl.formatMessage({ id: "dashboard.items.requestsOverview", defaultMessage: "Requests overview" })
        const commissionFormTitle = this.props.intl.formatMessage({ id: "dashboard.items.commissionForm", defaultMessage: "Commission form" })
        const commissionOverviewTitle = this.props.intl.formatMessage({ id: "dashboard.items.commissionOverview", defaultMessage: "Commission overview" })
        const reclamatieFormTitle = this.props.intl.formatMessage({ id: "dashboard.items.complaintForm", defaultMessage: "Reclamatie form" })
        const reclamatieOverviewTitle = this.props.intl.formatMessage({ id: "dashboard.items.complaintOverview", defaultMessage: "Reclamatie form" })

        const quoteConfigurationTitle = this.props.intl.formatMessage({ id: "dashboard.items.quoteConfigurator", defaultMessage: "Offerteconfigurator" });
        const requestSourceQuoteTitle = this.props.intl.formatMessage({ id: "dashboard.items.requestSourceQuote", defaultMessage: "Bronofferte aanvragen" });

        const multiZoneVerdelerConfigurator = this.props.intl.formatMessage({ id: "dashboard.items.multiZoneVerdelerConfigurator", defaultMessage: "MZV configurator" })

        const anonymousClassName = !this.props.user ? "anonymous" : "";

        const userRole = UserRepository.getUserRole();

        return (
            <div className={`page-Dashboard ${anonymousClassName}`}>
                {
                    !this.props.user &&
                    <div className="dashboard-BackgroundImage">&nbsp;</div>
                }

                <Container>
                    {
                        this.props.user &&
                        <div>
                            <Row>
                                {
                                    userRole.requestPriceIndication &&
                                    this.renderItemColumn(<DashboardItem title={requestPriceIndicationTitle} onClick={this.onRequestPriceIndicationClick} iconSize={DashboardItemIconSizeEnum.Large}><AiOutlineEuroCircle /></DashboardItem>)
                                }
                                {
                                    (userRole.showPriceIndicationOverview || userRole.showAllPriceIndications) &&
                                    this.renderItemColumn(<DashboardItem title={requestsOverviewTitle} onClick={this.onRequestsOverviewClick} iconSize={DashboardItemIconSizeEnum.Large}><GoChecklist /></DashboardItem>)
                                }
                                {
                                    userRole.showCommissioning &&
                                    this.renderItemColumn(<DashboardItem title={commissionFormTitle} onClick={this.onCommissionFormClick} iconSize={DashboardItemIconSizeEnum.Large}><RiHomeGearFill /></DashboardItem>)
                                }
                                {
                                    userRole.showCommissioningOverview &&
                                    this.renderItemColumn(<DashboardItem title={commissionOverviewTitle} onClick={this.onCommissionOverviewClick} iconSize={DashboardItemIconSizeEnum.Large}><GoChecklist /></DashboardItem>)
                                }
                                {
                                    userRole.showComplaint &&
                                    this.renderItemColumn(<DashboardItem title={reclamatieFormTitle} onClick={this.onComplaintFormClick} iconSize={DashboardItemIconSizeEnum.Large}><FaTools /></DashboardItem>)
                                }
                                {
                                    userRole.showComplaintOverview &&
                                    this.renderItemColumn(<DashboardItem title={reclamatieOverviewTitle} onClick={this.onComplaintOverviewClick} iconSize={DashboardItemIconSizeEnum.Large}><RiListSettingsLine /></DashboardItem>)
                                }
                                {
                                    userRole.showMultiZoneVerdelerConfigurator &&
                                    this.renderItemColumn(<DashboardItem title={multiZoneVerdelerConfigurator} onClick={this.multiZoneVerdelerConfiguratorClick} iconSize={DashboardItemIconSizeEnum.Large}><BsArrowsCollapse /></DashboardItem>)
                                }
                                {
                                    (userRole.showKnowledgeBaseBasic || userRole.showKnowledgeBaseAdvanced) &&
                                    this.renderItemColumn(<DashboardItem title={knowledgeBaseTitle} onClick={this.onKnowledgeBaseClick}><FaBook /></DashboardItem>)
                                }
                                {
                                    userRole.showBrlTool &&
                                    this.renderItemColumn(<DashboardItem title={brlToolTitle} onClick={this.onBrlToolClick}><GiRibbonMedal /></DashboardItem>)
                                }
                                {
                                    !userRole.showBrlTool &&
                                    this.renderItemColumn(<DashboardItem title={brlToolTitle} subTitle={availableSoon} disabled={true}><GiRibbonMedal /></DashboardItem>, true)
                                }
                                {
                                    userRole.showQuoteSolutions &&
                                    this.renderItemColumn(<DashboardItem title={beheerSystemenTitle} subTitle={availableSoon} onClick={this.onBeheerSystemenClick}><FaWrench /></DashboardItem>)
                                }
                                {
                                    userRole.showQuotes &&
                                    <>
                                        {this.renderItemColumn(<DashboardItem title={quoteConfiguratorTitle} subTitle={availableSoon} onClick={this.onQuoteConfiguratorClick}><FaMobileAlt /></DashboardItem>)}
                                        {this.renderItemColumn(<DashboardItem title={quoteOverviewTitle} subTitle={availableSoon} onClick={this.onQuoteOverviewClick}><TiThList /></DashboardItem>)}
                                    </>
                                }
                                {this.renderItemColumn(<DashboardItem title={nathanAcademyTitle} subTitle={availableIn2021} disabled={true}><FaUserGraduate /></DashboardItem>, true)}
                                {this.renderItemColumn(<DashboardItem title={quoteConfigurationTitle} subTitle={availableIn2021} disabled={true}><FaWpforms /></DashboardItem>, true)}
                                {this.renderItemColumn(<DashboardItem title={requestSourceQuoteTitle} subTitle={availableIn2021} disabled={true}><IoIosWater /></DashboardItem>, true)}
                            </Row>
                        </div>
                    }
                </Container>
            </div>
        );
    }
}

export default withTracker(injectIntl(Dashboard, { forwardRef: true }));