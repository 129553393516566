export default {
  id: "rec2",
  label: "Reclamatie 2",
  type: "container",
  renderer: "form",
  elements: {
    GroothandelContainer: {
      type: "container",
      renderer: "div",
      elements: {
        algemenegegevens: {
          name: "algemenegegevens",
          label: "Algemene gegevens",
          type: "field",
          renderer: "radio",
          options: [
            { title: "Groothandel", value: "A" },
            { title: "Installateur", value: "B" },
            { title: "Klant", value: "C" }
          ]
        },
        groothandelnaam_G: {
          name: "groothandelnaam_G",
          label: "Groothandel",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: ["is", "algemenegegevens", "A"]
        },
        retournummer_G: {
          name: "retournummer_G",
          label: "Retournummer",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "A"]
        },
        inkooporder_G: {
          name: "inkooporder_G",
          label: "Inkooporder",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: ["is", "algemenegegevens", "A"]
        },
        contactpersoon_G: {
          name: "contactpersoon_G",
          label: "Contactpersoon",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "A"]
        },
        "e-mailadres_G": {
          name: "e-mailadres_G",
          label: "E-mailadres",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "A"]
        },
        telefoonnummer_G: {
          name: "telefoonnummer_G",
          label: "Telefoonnummer",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "A"]
        },
        objectAdres_G: {
          type: "container",
          renderer: "fieldset",
          showWhen: ["is", "algemenegegevens", "A"],
          title: "Adres",
          elements: {
            objectStraatEnHuisnummer_G: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                straatCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectstraat_G: {
                      name: "objectStraatenhuisnummer_G",
                      label: "Straat en huisnummer",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            },
            objectAdresGebied_G: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                postcodeCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectpostcode_G: {
                      name: "objectpostcode_G",
                      label: "Postcode",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                },
                plaatsCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectplaats_G: {
                      name: "objectplaats_G",
                      label: "Plaats",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            }
          }
        },
        installateurnaam_I: {
          name: "installateurnaam_I",
          label: "Installateur",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "B"]
        },

        referentie_I: {
          name: "referentie_I",
          label: "Referentie",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "B"]
        },
        contactpersoon_I: {
          name: "contactpersoon_I",
          label: "Contactpersoon",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "B"]
        },
        "e-mailadres_I": {
          name: "e-mailadres_I",
          label: "E-mailadres",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "B"]
        },
        telefoonnummer_I: {
          name: "telefoonnummer_I",
          label: "Telefoonnummer",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "B"]
        },
        objectAdres_I: {
          type: "container",
          renderer: "fieldset",
          showWhen: ["is", "algemenegegevens", "B"],
          title: "Adres",
          elements: {
            objectStraatEnHuisnummer_I: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                straatCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectStraat_I: {
                      name: "objectStraatenhuisnummer_I",
                      label: "Straat en huisnummer",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            },
            objectAdresGebied_I: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                postcodeCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectPostcode_I: {
                      name: "objectPostcode_I",
                      label: "Postcode",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                },
                plaatsColinstallateur: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectPlaats_I: {
                      name: "objectPlaats_I",
                      label: "Plaats",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            }
          }
        },
        contactpersoon_K: {
          name: "contactpersoon_K",
          label: "Contactpersoon",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "C"]
        },
        "e-mailadres_K": {
          name: "e-mailadres_K",
          label: "E-mailadres",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "C"]
        },
        telefoonnummer_K: {
          name: "telefoonnummer_K",
          label: "Telefoonnummer",
          type: "field",
          renderer: "text",
          showWhen: ["is", "algemenegegevens", "C"]
        },
        objectAdres_K: {
          type: "container",
          renderer: "fieldset",
          showWhen: ["is", "algemenegegevens", "C"],
          title: "Adres",
          elements: {
            objectStraatEnHuisnummer_K: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                straatCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectStraat_K: {
                      name: "objectStraatenhuisnummer_K",
                      label: "Straat en huisnummer",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            },
            objectAdresGebied_K: {
              type: "container",
              renderer: "div",
              htmlClass: "row",
              elements: {
                postcodeCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectPostcode_K: {
                      name: "objectPostcode_K",
                      label: "Postcode",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                },
                plaatsCol: {
                  type: "container",
                  renderer: "div",
                  htmlClass: "col",
                  elements: {
                    objectPlaats_K: {
                      name: "objectPlaats_K",
                      label: "Plaats",
                      type: "field",
                      renderer: "text",
                      fieldType: "text"
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
