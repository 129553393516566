import React from "react";
import { WrappedComponentProps, FormattedMessage, injectIntl } from "react-intl";

import "./SolutionTypePage.scss";
import { Util } from "../Utils/Util";
import { Col, Row } from "reactstrap";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

interface ISolutionTypePageProps extends WrappedComponentProps { }

interface ISolutionTypePageState {
    solutionType: number;
    isLoading?: boolean;
    errorMessage?: string;
}

class SolutionTypePage extends React.Component<ISolutionTypePageProps, ISolutionTypePageState> {

    constructor(props: ISolutionTypePageProps) {
        super(props);
        this.state = { solutionType: 1 };
        this.onSolutionTypeChange = this.onSolutionTypeChange.bind(this);
    }

    componentDidMount() {
    }

    onSolutionTypeChange(event) {
        this.setState({
            solutionType: Number(event.target.value)
        });
    }

    render() {
        return (
            <div className="page-CommissioningOverview mt-2 quote">
                <Row className="page-CommissioningOverview-form">
                    <Col md={12}>
                        <h1 className="page-heading"><FormattedMessage id="pages.solutiontype.title" defaultMessage="OPLOSSINGCONFIGURATOR" /></h1>
                        <h2 className="page-subheading"><FormattedMessage id="pages.solutiontype.subtitle" defaultMessage="NIEUWE OPLOSSING" /></h2>
                    </Col>
                    <Col md={12}>
                        <p className="font-weight-bold">Type oplossing</p>
                        <div className="custom-radio-button">
                            <input type="radio" id="warmtepomp" name="SolutionType" value="1"
                                checked={this.state.solutionType === 1}
                                onChange={this.onSolutionTypeChange}
                            />
                            <label htmlFor="warmtepomp">
                                <FormattedMessage id="enum.solutionTypeEnum.HeatPump" defaultMessage="HeatPump" />
                            </label>
                        </div>
                        <div className="custom-radio-button">
                            <input type="radio" id="vloerverwarming" name="SolutionType" value="2"
                                checked={this.state.solutionType === 2}
                                onChange={this.onSolutionTypeChange}
                            />
                            <label htmlFor="vloerverwarming">
                                <FormattedMessage id="enum.solutionTypeEnum.UnderfloorHeating" defaultMessage="UnderfloorHeating" />
                            </label>
                        </div>
                        <div className="custom-radio-button">
                            <input type="radio" id="bron" name="SolutionType" value="3"
                                checked={this.state.solutionType === 3}
                                onChange={this.onSolutionTypeChange}
                            />
                            <label htmlFor="bron">
                                <FormattedMessage id="enum.solutionTypeEnum.Source" defaultMessage="Source" />
                            </label>
                        </div>
                        <div className="custom-radio-button">
                            <input type="radio" id="regelaar" name="SolutionType" value="4"
                                checked={this.state.solutionType === 4}
                                onChange={this.onSolutionTypeChange}
                            />
                            <label htmlFor="regelaar">
                                <FormattedMessage id="enum.solutionTypeEnum.Regulator" defaultMessage="Regulator" />
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row className="page-CommissioningOverview-actions">
                    <Col md={12}>
                        <DefaultButton onClick={() => Util.Navigate("Solutions")}>
                            <FormattedMessage id="pages.solutiontype.cancel" defaultMessage="Annuleer" />
                        </DefaultButton>
                        &nbsp;
                        <PrimaryButton onClick={() => Util.Navigate(`Solutions/New/${this.state.solutionType}`)}>
                            <FormattedMessage id="pages.solutiontype.next" defaultMessage="Volgende" />
                        </PrimaryButton>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(SolutionTypePage, { forwardRef: true });