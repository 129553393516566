import axios, { AxiosResponse } from "axios";
import { ICalculateQuoteSolutionsResponseVM, ICalculateQuoteSolutionsRequestVM } from "../viewmodels/data";
import MijnNathanService from "./MijnNathanService";
import { Service } from "./Service";

export class QuoteConfigurationService {
    public static calculateSolutions = async(form: ICalculateQuoteSolutionsRequestVM): Promise<ICalculateQuoteSolutionsResponseVM> =>{
        const result = await axios.post(`${Service.ApiUrl}/v1/quotes/calculateSolutions`, form, Service.Config());
        return result.data;
    }
}