import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

//@ts-ignore
const Thumb = ({ key, file }) => <div />

const thumbsContainer: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
}

const thumb: React.CSSProperties = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
}

const thumbInner: React.CSSProperties = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
}

const img: React.CSSProperties = {
    display: 'block',
    width: 'auto',
    height: '100%'
}

const baseStyle: React.CSSProperties = {
    width: '100%',
    height: 200,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
    padding: 10
}

const activeStyle: React.CSSProperties = {
    borderColor: '#6c6',
    backgroundColor: '#eee'
}

const acceptStyle: React.CSSProperties = {
    borderColor: '#00e676'
}

const rejectStyle: React.CSSProperties = {
    borderColor: '#ff1744'
}

//@ts-ignore
const prepareFileUploaderOptions = ({ onDrop, onDropAccepted, onDropRejected, ...options }, formik, config) => {
    //@ts-ignore
    options.onDrop = onDrop ? onDrop.bind(this, formik, config) : null;
    //@ts-ignore
    options.onDropAccepted = onDropAccepted ? onDropAccepted.bind(this, formik, config) : null;
    //@ts-ignore
    options.onDropRejected = onDropRejected ? onDropRejected.bind(this, formik, config) : null;

    return options;
}

//@ts-ignore
const FormikFileUploader = ({ config, formik, value, error }) => {

    const {
        name,
        options,
        placeholder,
        disabledText,
        zoneActiveText,
        hasThumbs = false
    } = config;
    const { setFieldValue } = formik;
    const selectedValue = value;
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ ...prepareFileUploaderOptions({ ...options }, formik, config) });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    return (
        <section className="formik-fileUploader" data-formik-field={name}>
            <div { ...getRootProps({ style }) }>
                <input { ...getInputProps() } />
                { isDragActive
                    ? <p>Sleep bestand hierheen ...</p>
                    : <p>Sleep bestand hierheen of klik om deze te selecteren (VOORBEELD_TEXT.CSV)</p>
                 }
            </div>
            <aside style={ thumbsContainer }  className="formik-fileUploader-thumbs">
                {/* { value && (hasThumbs ? value.map((file: any) => (
                    <div style={ thumb } key={ file.id }>
                        <div style={ thumbInner }>
                            <img src={ file.url } alt={ file.label } style={ img } />
                        </div>
                    </div>
                )) : <ul>{ value.map((file: any) => <li key={ file.id }>{ file.url }</li>) }</ul> )} */}
            </aside>
        </section>
    );
}

export default React.memo(FormikFileUploader)