//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IAUniqueVM
{
	id: string;
}
export interface INoVM extends IAUniqueVM
{
}
export interface IBlobVM extends IAUniqueVM
{
	fileName: string;
}
export interface ICommissioningDetailsVM extends ICommissioningVM
{
	data: any;
}
export interface ICommissioningVM extends IAUniqueVM
{
	id: string;
	displayName: string;
	customerId: string;
	customerName: string;
	created: string;
	createdByName: string;
	ibnDate: string;
	documentURL: string;
	csvUrl: string;
	warmtepompType: string;
}
export interface IContactVM extends IAUniqueVM
{
	displayName: string;
	firstname: string;
	insertion: string;
	lastname: string;
	phone: string;
	customer: ICustomerVM;
	role: IRoleVM;
	firstLoginTime?: string;
}
export interface ICustomerVM
{
	id: string;
	displayName: string;
	parentCustomerId: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	phone: string;
	website: string;
}
export interface IItemVM
{
	id: string;
	itemNumber?: string;
	baseUnitOfMeasure?: string;
	description?: string;
	grossPricePerUnit: number;
}
export interface IGetRequestsResponseVM
{
	requests: IRequestVM[];
}
export interface IGetSettingsResponseVM
{
	isDebug: boolean;
	debugValue: string;
}
export interface IGetUserProfileResponseVM extends ICrudGetOneResponseVM<IContactVM>
{
}
export interface ISubmitFormRequestVM<TForm>
{
	form: TForm;
	metadata: { [key:string]: any };
}
export interface IQuotePositionItemVM extends IAUniqueVM
{
	psNumber?: string;
	solutionPositionItemId?: string;
	description?: string;
	quantity: number;
	navisionItemNumber?: string;
	priceEuroPerItem: number;
	baseUnitOfMeasure?: string;
}
export interface IQuotePositionVM extends IAUniqueVM
{
	positionNumber: number;
	title: string;
	isNetto?: boolean;
	description?: string;
	solutionPositionId?: string;
	positionType?: PositionTypeEnum;
	quotePositionItems: IQuotePositionItemVM[];
}
export interface IQuoteSolutionVM extends IAUniqueVM
{
	solutionType: SolutionTypeEnum;
	renovationOrNew?: RenovationNewChoiceEnum;
	solutionTypeText: string;
	relatedSolutionId?: string;
	relatedSolution?: ISolutionVM;
	principleDiagramCode?: string;
	positionNumber: number;
	title: string;
	description?: string;
	remarks?: string;
	importantInformation?: string;
	code?: string;
	erpEnergielabel?: string;
	subsitioncodeIsdeSubsidy?: string;
	heatPumpType: HeatPumpTypeEnum;
	tapWaterMax: number;
	rbe: boolean;
	cvSide: boolean;
	heatingAdjustment: PostAdjustmentTypeEnum;
	underfloorHeatingType: UnderfloorHeatingTypeEnum;
	surfaceAreaMin: number;
	surfaceAreaMax: number;
	floorNumber?: number;
	quotePositions: IQuotePositionVM[];
}
export interface IQuoteVM extends IAUniqueVM
{
	quoteSubject: string;
	quoteRemarks: string;
	created: string;
	createdByContactId: string;
	generatedQuoteDocumentId?: string;
	requestId?: string;
	baseName: string;
	quoteSolutions: IQuoteSolutionVM[];
	formData?: ICalculateQuoteSolutionsRequestVM;
	quoteErrors?: ICalculateQuoteSolutionsErrorVM[];
}
export interface IRelatedSolutionVM extends IAUniqueVM
{
	title: string;
	sortOrder: number;
}
export interface IRequestVM extends IAUniqueVM
{
	id: string;
	displayName: string;
	requestType: RequestTypeEnum;
	requestStatus: RequestStatusEnum;
	customerId: string;
	customerName: string;
	created: string;
	createdByName: string;
	documentURL: string;
	documentUrls1: string[];
	documentUrls2: string[];
	documentUrls3: string[];
	documentUrls4: string[];
	documentUrls5: string[];
	documentUrls6: string[];
	documentUrls7: string[];
	number: string;
	referenceNumber: string;
}
export interface IRoleVM
{
	id: string;
	displayName: string;
	requestPriceIndication: boolean;
	showPriceIndicationOverview: boolean;
	showAllPriceIndications: boolean;
	showCommissioning: boolean;
	showCommissioningOverview: boolean;
	showComplaint: boolean;
	showComplaintOverview: boolean;
	showQuoteSolutions: boolean;
	showQuotes: boolean;
	showMultiZoneVerdelerConfigurator: boolean;
	showKnowledgeBase: boolean;
	showKnowledgeBaseAdvanced: boolean;
	showKnowledgeBaseBasic: boolean;
	isNathanEmployee: boolean;
	showBrlTool: boolean;
}
export interface ISolutionPositionItemVM extends IAUniqueVM
{
	allowedAction: AllowedActionEnum;
	psNumber: string;
	quantity: number;
	navisionKey: string;
	navisionItem?: IItemVM;
}
export interface ISolutionPositionVM extends IAUniqueVM
{
	allowedAction: AllowedActionEnum;
	positionNumber: number;
	title: string;
	isNetto?: boolean;
	positionType?: PositionTypeEnum;
	solutionPositionItems: ISolutionPositionItemVM[];
}
export interface ISolutionVM extends IAUniqueVM
{
	allowedAction: AllowedActionEnum;
	isEnabled: boolean;
	solutionType: SolutionTypeEnum;
	renovationOrNew?: RenovationNewChoiceEnum;
	isSelectableAsRelatedSolution: boolean;
	numberOfPositions: number;
	title: string;
	principleDiagramCode?: string;
	description?: string;
	importantInformation?: string;
	solutionImage: IBlobVM;
	attachments: IBlobVM[];
	code?: string;
	erpEnergielabel?: string;
	subsitioncodeIsdeSubsidy?: string;
	power?: number;
	powerMin: number;
	powerMax: number;
	powerMinus10Deg?: number;
	numberOfFloors?: number;
	heatPumpType: HeatPumpTypeEnum;
	tapWaterMax: number;
	rbe: boolean;
	cvSide: boolean;
	heatingAdjustment: PostAdjustmentTypeEnum;
	underfloorHeatingType: UnderfloorHeatingTypeEnum;
	floorHeatingHOH: number;
	surfaceAreaMin: number;
	surfaceAreaMax: number;
	floorNumber?: number;
	functionHeatpump: HeatPumpFunctionEnum;
	solutionPositions: ISolutionPositionVM[];
	relatedSolutions: IRelatedSolutionVM[];
}
export interface ITemporaryFileInfo
{
	id: string;
	name: string;
	originalName: string;
}
export interface ICrudGetAllResponseVM<T>
{
	value: T[];
}
export interface ICrudGetOneResponseVM<T>
{
	value: T;
}
export interface ICrudCreateResponseVM<T>
{
	value: T;
}
export interface ICrudUpdateResponseVM<T>
{
	value: T;
}
export interface IGetAllCustomersResponseVM
{
	customers: ICustomerVM[];
}
export interface IGetCommissioningResponseVM
{
	value: ICommissioningDetailsVM;
}
export interface IGetCommissioningsResponseVM
{
	commissionings: ICommissioningVM[];
}
export interface IGetComplaintResponseVM
{
	complaint: IRequestVM;
}
export interface IGetComplaintsResponseVM
{
	complaints: IRequestVM[];
}
export interface IGetCustomerResponseVM
{
	customer: ICustomerVM;
}
export interface IGetKnowledgeBaseCredentialsResponseVM
{
	userName: string;
	password: string;
}
export interface IGetListsResponseVM
{
	complaintStatusList: string[];
}
export interface IParseDataFileResponseVM
{
	fileName: string;
	data: { [key:string]: string };
	errors: string[];
	generatedFileId?: string;
}
export interface IQuoteConfigGetRefinersRequestVM
{
	m2leefruimteKelder?: number;
	m2leefruimteBeganeGrond: number;
	m2leefruimte1eVerdieping: number;
	m2leefruimte2eVerdieping?: number;
	m2leefruimte3eVerdieping?: number;
	typeVloerverwarming: string;
	typeNaregeling: string;
}
export interface IQuoteConfigGetRefinersResponseVM
{
	heatPumpType?: HeatPumpTypeEnum;
	heatPumpFunction?: HeatPumpFunctionEnum;
	power?: number;
	powerMinus10Min?: number;
	powerMinus10Max?: number;
	tapWaterMax?: number;
	rbe?: boolean;
	cvSided?: boolean;
	surfaceAreaMin?: number;
	surfaceAreaMax?: number;
	floorHeatingType?: FloorHeatingTypeEnum;
	postAdjustmentType?: PostAdjustmentTypeEnum;
}
export interface ISaveTemporaryFileResponseVM
{
	fileId: string;
	fileName: string;
	originalFileName: string;
}
export interface ISubmitCommissioningsResponseVM
{
	id: string;
	fileId: string;
	pdfUrl: string;
	csvUrl: string;
}
export interface ISubmitFormCommissioningRequestVM extends ISubmitFormRequestVM<any>
{
}
export interface ISubmitFormComplaintRequestVM extends ISubmitFormRequestVM<any>
{
}
export interface ISubmitFormComplaintResponseVM
{
	id: string;
	displayName: string;
	number: string;
}
export interface IUpdateCustomerRequestVM
{
}
export interface IUpdateCustomerResponseVM
{
	customer: ICustomerVM;
}
export interface IUploadedFile
{
	id: string;
	extension: string;
	fileName: string;
	storageFileName: string;
	fieldName: string;
}
export interface IUploadComplaintDocumentResponseVM
{
	files: IUploadedFile[];
}
export interface IAuthor
{
	odataType: string;
	claims: string;
	displayName: string;
	email: string;
	picture: string;
	department: string;
	jobTitle: string;
}
export interface IDocumentContent
{
	contentType: string;
	content: string;
}
export interface IEditor
{
	odataType: string;
	claims: string;
	displayName: string;
	email: string;
	picture: string;
	department: string;
	jobTitle: string;
}
export interface IMetadata
{
	odataEtag: string;
	itemInternalId: string;
	id?: number;
	modified?: string;
	editor: IEditor;
	editorClaims: string;
	sharedWithUsers: any[];
	sharedWithUsersOdataType: string;
	sharedWithUsersClaims: any[];
	sharedWithUsersClaimsOdataType: string;
	created?: string;
	author: IAuthor;
	authorClaims: string;
	oDataDisplayName: string;
	identifier: string;
	isFolder?: boolean;
	thumbnail: IThumbnail;
	link: string;
	name: string;
	filenameWithExtension: string;
	path: string;
	fullPath: string;
	isCheckedOut?: boolean;
	versionNumber: string;
}
export interface IGetPrincipeDiagramResponse
{
	content: IDocumentContent;
	metadata: IMetadata;
}
export interface IThumbnail
{
	large: string;
	medium: string;
	small: string;
}
export interface ICalculateQuoteSolutionsRequestVM
{
	solutionType?: SolutionTypeEnum;
	renovationNewChoice?: RenovationNewChoiceEnum;
	projectPostalCode: string;
	projectHouseNumber: string;
	heatPumpType: HeatPumpTypeEnum;
	heatPumpFunction: HeatPumpFunctionEnum;
	powerNeededInKW?: number;
	numberOfPeopleInHome?: number;
	sanitation: SanitationEnum;
	floorHeatingType: FloorHeatingTypeEnum;
	postAdjustmentType: PostAdjustmentTypeEnum;
	basementLivingArea?: number;
	groundFloorLivingArea?: number;
	firstFloorLivingArea?: number;
	secondFloorLivingArea?: number;
	thirdFloorLivingArea?: number;
	manualHeatingChoice?: boolean;
	heatLossCalculation?: boolean;
	floorArea?: number;
	floorHeatingHOH?: number;
	typeOfHouse: TypeOfHouseEnum;
}
export interface ICalculateQuoteSolutionsResponseVM
{
	errors: ICalculateQuoteSolutionsErrorVM[];
	solutions: ISolutionVM[];
}
export interface ICalculateQuoteSolutionsErrorVM
{
	errorCode: string;
	requestSubject: string;
	requestedQuantity: number;
	errorMessage: string;
	requestSolutionType: string;
	solutionType?: SolutionTypeEnum;
	errorLevel: ErrorLevelEnum;
	errorType: ErrorTypeEnum;
	floorNumber?: number;
	floorName?: string;
}
export interface IFormA extends IAMijnNathanForm
{
	projectName: string;
	projectDescription: string;
	projectPostalCode: string;
	projectHouseNumber: string;
	heatPumpType: HeatPumpTypeEnum;
	heatPumpFunction: HeatPumpFunctionEnum;
	powerNeededInKW: number;
	numberOfPeopleInHome?: number;
	sanitation: SanitationEnum;
	floorHeatingType: FloorHeatingTypeEnum;
	postAdjustmentType: PostAdjustmentTypeEnum;
	basementLivingArea: number;
	groundFloorLivingArea: number;
	firstFloorLivingArea: number;
	secondFloorLivingArea: number;
	thirdFloorLivingArea: number;
	manualHeatingChoice?: boolean;
	heatLossCalculation?: boolean;
	floorArea?: number;
	typeOfHouse: TypeOfHouseEnum;
}
export interface IAMijnNathanForm
{
	companyName: string;
	companyPostalCode: string;
	companyAddress: string;
	companyCity: string;
	companyInstallerCode: string;
	givenName: string;
	initials: string;
	surname: string;
	gender: string;
	emailAddress: string;
	phoneNumber: string;
}
export enum AllowedActionEnum {
	None = 0,
	CanDelete = 1,
	CanModify = 2,
	CanDeleteAndModify = 3
}
export enum BlobTypeEnum {
	AzureBlobStore = 1,
	ContentBlob = 2
}
export enum PostAdjustmentTypeEnum {
	none = 0,
	sMatrixBase = 100,
	wavePlus = 200,
	masterMasterAlphaHome = 300
}
export enum UnderfloorHeatingTypeEnum {
	NotSelected = 0,
	UponorBouwnettenVloerverwarmingssysteem = 1,
	UponorTackerVloerverwarmingssysteem = 2,
	UponorLeidingsysteemVloerverwarming = 100,
	UponorKlettVloerverwarmingssysteem = 103,
	UponorNubosVloerverwarmingssysteem = 104,
	UponorTectoVloerverwarmingssysteem = 105,
	UponorMinitecVloerverwarmingssysteem = 106,
	UponorSiccusVloerverwarmingssysteem = 107,
	UponorKoelplafond = 150,
	MeerdereUponorVloerverwarmingssystemen = 170
}
export enum ErrorLevelEnum {
	Information = 1,
	Warning = 3,
	Error = 5
}
export enum ErrorTypeEnum {
	NotFound = 0,
	NoMatchFound = 1,
	Information = 40,
	Unknown = 99
}
export enum FloorHeatingTypeEnum {
	none = 0,
	constructionNets = 1,
	tacker = 2
}
export enum HeatPumpFunctionEnum {
	None = 0,
	Heating = 1,
	HeatingAndCooling = 2
}
export enum HeatPumpTypeEnum {
	none = 0,
	groundBound = 1,
	airWater = 2
}
export enum PositionTypeEnum {
	Vloerverwarmingssysteem = 1,
	Verdeler = 2,
	MontageVloerverwarming = 3,
	MontageVloerverwarmingSmeervloer = 4
}
export enum RenovationNewChoiceEnum {
	Renovation = 1,
	NewBuild = 2,
	Both = 3
}
export enum RequestStatusEnum {
	Onbekend = 0,
	Nieuw = 10,
	InProgress = 30,
	Completed = 90
}
export enum RequestTypeEnum {
	Commisioning = 1,
	Reclamatie = 2,
	Prijsindicatie = 3,
	Offerte = 4
}
export enum SanitationEnum {
	none = 0,
	shower = 1,
	showerAndBath = 2
}
export enum SolutionTypeEnum {
	HeatPump = 1,
	UnderfloorHeating = 2,
	Source = 3,
	Regulator = 4
}
export enum TypeOfHouseEnum {
	none = 0,
	newBuilding = 1,
	existingTypeX = 2,
	existingTypeY = 3,
	existingTypeZ = 4
}
