import ObjUtils from "./ObjUtils";

export default class NumUtils {

    static roundNumber = (num: number, decimals: number) => {
        const helper = Math.pow(10, decimals);
        const result = Math.round(num * helper) / helper;
        return result;
    }

    public static isNumericString(obj: any): boolean {
        if (obj == undefined) return false;
        if (!ObjUtils.isString(obj)) return false;
        let objString = obj as string;
        if (objString.length == 0) return true;
        const lastChar = objString[objString.length - 1];
        if (lastChar == "." || lastChar == ",") {
            objString = objString.slice(0, objString.length - 1)
        }
        // console.log(obj, objString, +objString);
        return !Number.isNaN(+objString);
    }

    public static ParseNumber(obj: any): number | undefined {
        if (obj == undefined) return undefined;
        if (!ObjUtils.isString(obj)) return undefined;
        let objString = obj as string;
        if (objString.length == 0) return 0;
        const lastChar = objString[objString.length - 1];
        if (lastChar == "." || lastChar == ",") {
            objString = objString.slice(0, objString.length - 1)
        }
        const numResult: number = +objString;
        return Number.isNaN(numResult) ? undefined : numResult;
    }

    public static HandleNumberInput = (textInput: string | undefined | null, oldValue: number | undefined) => {
        if (textInput === undefined || textInput === "" || textInput === null) return undefined;
        const parsedNumber = NumUtils.ParseNumber(textInput);   
        if (parsedNumber === undefined) return oldValue;
        return parsedNumber;
    }

    public static Max(numbers?: number[]) : number | undefined {
        if (!numbers || numbers.length == 0) return undefined;
        return numbers.sort((n1,n2) => n2-n1)[0];
    }

    public static Min(numbers?: number[]) : number | undefined {
        if (!numbers || numbers.length == 0) return undefined;
        return numbers.sort((n1,n2) => n1-n2)[0];
    }
}