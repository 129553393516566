import { IAppSettings } from "../viewmodels/app";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import { IGetSettingsResponseVM } from "../viewmodels/data";

export class AppService {

    public static GetSettings = (): Promise<IAppSettings> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/settings`, Service.Config())
                .then((response: AxiosResponse<IGetSettingsResponseVM>) => resolve({
                    isDebug: response.data.isDebug
                }))
                .catch(reason => reject(reason));
        })
}