import React from "react";
import {
    Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Button
} from 'reactstrap';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import Loading from "../components/Loading";
import "./QuoteConfiguratorWizard.scss";
import {
    ISolutionVM, ISolutionPositionVM, ISolutionPositionItemVM, ICrudCreateResponseVM, ICrudGetAllResponseVM,
    IQuoteVM, IQuoteSolutionVM, IQuotePositionVM, IQuotePositionItemVM, ICalculateQuoteSolutionsErrorVM, ICalculateQuoteSolutionsRequestVM
} from "../viewmodels/data";

import { SolutionService } from "../services/SolutionService";

import { QuoteService } from "../services/QuoteService";
import EditQuote from './tabs/EditQuote';
import DownloadTab from "./tabs/DownloadTab";
import { Util } from "../Utils/Util";

import { IFilterData } from "../viewmodels/quoteConfigurator";

import ConfirmDialog from "../components/ConfirmDialog";
import { QuoteConfiguratorWizardViews } from "./QuoteConfiguratorWizardViews";
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import QuoteWizardForm from "./tabs/QuoteWizardForm";
import { ca } from "date-fns/locale";
import ObjUtils from "../Utils/ObjUtils";

export enum QuoteConfiguratorWizardTabs {
    QuoteWizard = 2,
    EditQuote = 3,
    SaveAndDownload = 4
}

interface IQuoteConfiguratorWizardProps extends WrappedComponentProps { }

interface IQuoteConfiguratorWizardState {
    showSolutionAddedSuccesBar: boolean;
    activeTab: QuoteConfiguratorWizardTabs;
    isLoading?: boolean;
    isSaved: boolean;

    isSolutionLoading?: boolean;
    isQuoteLoading?: boolean;

    errorMessage?: string;
    filterData: IFilterData;
    solutions: ISolutionVM[];
    quoteId?: string;
}

class QuoteConfiguratorWizard extends React.Component<IQuoteConfiguratorWizardProps, IQuoteConfiguratorWizardState> {

    SetState2 = <K extends keyof IQuoteConfiguratorWizardState>(ob: Pick<IQuoteConfiguratorWizardState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: IQuoteConfiguratorWizardProps) {
        super(props);
        this.state = {
            showSolutionAddedSuccesBar: false,
            activeTab: QuoteConfiguratorWizardTabs.QuoteWizard,
            isSaved: false,
            filterData: {
                selectSolutions: []
            },
            solutions: []
        }
    }

    componentDidMount() {

    }

    toggle = async (tab: QuoteConfiguratorWizardTabs) => {
        if (this.state.activeTab !== tab) {
            await this.SetState2({ activeTab: tab })
        }
    }




    solutionsCalculated = async (formData: ICalculateQuoteSolutionsRequestVM, solutions: ISolutionVM[], errors: ICalculateQuoteSolutionsErrorVM[]) => {
        let filterData = this.state.filterData;
        filterData.formData = formData;
        filterData.selectSolutions = [...solutions];
        filterData.errors = errors.slice();

        await this.SetState2({
            filterData: filterData,
            activeTab: QuoteConfiguratorWizardTabs.EditQuote
        })
    }

    onQuoteUpdated = async (filterValue: IFilterData, solutionIsAdded?: boolean) => {
        this.SetState2({
            filterData: { ...filterValue }
        });
    }

    showSolutionAddedMessageBar = () => {
        this.setState({ showSolutionAddedSuccesBar: true });
        window.setTimeout(() => {
            this.setState({ showSolutionAddedSuccesBar: false });
        }, 5000);
    }



    onSaveOrRemove = (isSave: boolean) => {

        const _filterParam = this.state.filterData;
        const { selectSolutions } = _filterParam;

        if (isSave && selectSolutions && selectSolutions.length > 0) {
            this.setState({ isQuoteLoading: true });
            var quoteReq: IQuoteVM = {} as IQuoteVM;

            if (_filterParam.quote) {
                quoteReq.quoteSubject = _filterParam.quote.quoteSubject;
                quoteReq.quoteRemarks = _filterParam.quote.quoteRemarks;
            }

            selectSolutions.map((solution: ISolutionVM, solIndex: number) => {
                var quoteSolutionReq: IQuoteSolutionVM = {
                    solutionType: solution.solutionType,
                    principleDiagramCode: solution.principleDiagramCode,
                    renovationOrNew: solution.renovationOrNew,
                    positionNumber: solution.numberOfPositions,
                    title: solution.title,
                    description: solution.description,
                    remarks: "",
                    importantInformation: solution.importantInformation,
                    code: solution.code,
                    erpEnergielabel: solution.erpEnergielabel,
                    subsitioncodeIsdeSubsidy: solution.subsitioncodeIsdeSubsidy,
                    heatPumpType: solution.heatPumpType,
                    tapWaterMax: solution.tapWaterMax,
                    rbe: solution.rbe,
                    cvSide: solution.cvSide,
                    heatingAdjustment: solution.heatingAdjustment,
                    underfloorHeatingType: solution.underfloorHeatingType,
                    surfaceAreaMax: solution.surfaceAreaMax,
                    surfaceAreaMin: solution.surfaceAreaMin,
                    floorNumber: solution.floorNumber,
                    relatedSolutionId: solution.id
                } as IQuoteSolutionVM;
                if (!quoteReq.quoteSolutions) {
                    quoteReq.quoteSolutions = [];
                }
                quoteReq.quoteSolutions.push(quoteSolutionReq);
                solution.solutionPositions.length > 0 &&
                    solution.solutionPositions.map((solutionPosition: ISolutionPositionVM, posIndex: number) => {
                        var quotePositionReq: IQuotePositionVM = {
                            id: ObjUtils.emptyGuid,
                            isNetto: solutionPosition.isNetto,
                            positionNumber: solutionPosition.positionNumber,
                            positionType: solutionPosition.positionType,
                            title: solutionPosition.title,
                            description: "",
                            solutionPositionId: solutionPosition.id,
                            quotePositionItems: [],
                        };
                        if (!quoteReq.quoteSolutions[solIndex].quotePositions) {
                            quoteReq.quoteSolutions[solIndex].quotePositions = [];
                        }
                        quoteReq.quoteSolutions[solIndex].quotePositions.push(quotePositionReq);

                        solutionPosition.solutionPositionItems.length > 0 &&
                            solutionPosition.solutionPositionItems.map((posItems: ISolutionPositionItemVM, itemIndex: number) => {
                                var quoteItemReq: IQuotePositionItemVM = {
                                    psNumber: posItems.psNumber,
                                    description: posItems.navisionItem && posItems.navisionItem.description,
                                    quantity: posItems.quantity,
                                    navisionItemNumber: posItems.navisionKey,
                                    priceEuroPerItem: posItems.navisionItem ? posItems.navisionItem.grossPricePerUnit : 0,
                                    baseUnitOfMeasure: posItems.navisionItem ? posItems.navisionItem.baseUnitOfMeasure : '',
                                    solutionPositionItemId: posItems.id
                                } as IQuotePositionItemVM;
                                if (!quoteReq.quoteSolutions[solIndex].quotePositions[posIndex].quotePositionItems) {

                                    quoteReq.quoteSolutions[solIndex].quotePositions[posIndex].quotePositionItems = [];
                                }
                                quoteReq.quoteSolutions[solIndex].quotePositions[posIndex].quotePositionItems.push(quoteItemReq);

                            })
                    })
            });

            // add form A2 data
            if (_filterParam.formData) {
                quoteReq.formData = _filterParam.formData;
            }
            if (_filterParam.errors) {
                quoteReq.quoteErrors = _filterParam.errors;
            }

            QuoteService.Create(quoteReq)
                .then((quoteRes: ICrudCreateResponseVM<IQuoteVM>) => {

                    var _filterParam = this.state.filterData;
                    _filterParam.selectSolutions = [];
                    _filterParam.quote = {} as IQuoteVM;
                    this.setState({ filterData: _filterParam, isQuoteLoading: false, quoteId: quoteRes.value.id, isSaved: true }, () => {
                        console.log("QuoteConfiguratorWizard QuoteService.Created", this.state);
                        this.toggle(QuoteConfiguratorWizardTabs.SaveAndDownload);
                    });
                })
                .catch(reason => {

                    this.setState({
                        errorMessage: reason
                    });
                }).finally(() => {
                    this.setState({ isLoading: false })
                });
        } else {

            if (_filterParam.selectSolutions.length > 0 || _filterParam.quote) {
                this.confirmDialog!.showDialog(ok => {
                    if (ok) {
                        this.clearAndGoToStart();
                    }
                })
            } else {
                this.clearAndGoToStart();
            }
        }
    }

    clearAndGoToStart = () => {
        const _filterParam = this.state.filterData;
        _filterParam.selectSolutions = [];
        _filterParam.quote = {} as IQuoteVM;
        this.setState({ filterData: _filterParam }, () => this.toggle(QuoteConfiguratorWizardTabs.QuoteWizard));
    }

    confirmDialog: ConfirmDialog | undefined | null;

    render() {
        const { activeTab, filterData, solutions, isSolutionLoading, isQuoteLoading, quoteId } = this.state;
        const intl = this.props.intl;

        return (
            <div className="page-CommissioningOverview mt-2 quote">
                <ConfirmDialog ref={cf => this.confirmDialog = cf} title={intl.formatMessage({ id: "pages.quoteconfigurator.editquote.removequote.confirm.title" })} question={intl.formatMessage({ id: "pages.quoteconfigurator.editquote.removequote.confirm.question" })} />
                <Row>
                    <Col sm="12" className="text-right">
                        <DefaultButton onClick={() => Util.Navigate(`/`)}>
                            <FormattedMessage id="pages.quoteconfigurator.backtomain" defaultMessage="Terug naar hoofdmenu" />
                        </DefaultButton>
                    </Col>
                    <Col sm="12">
                        <h1 className="page-heading"><FormattedMessage id="pages.quoteconfigurator.header" defaultMessage="OFFERTEGENERATOR" /></h1>
                    </Col>
                    <Col hidden={!this.state.showSolutionAddedSuccesBar} sm="12">
                        <MessageBar messageBarType={MessageBarType.success} ><FormattedMessage id="pages.quoteconfigurator.solutionadded" defaultMessage="Oplossing is toegevoegd aan offerte" /></MessageBar>
                    </Col>
                    {!this.state.isLoading && !this.state.errorMessage &&
                        <Col sm="12">
                            <Nav tabs className="nav-justified">
                                <NavItem hidden={this.state.isSaved}>
                                    <NavLink
                                        className={classnames({ active: activeTab === QuoteConfiguratorWizardTabs.QuoteWizard })}
                                        onClick={() => { this.toggle(QuoteConfiguratorWizardTabs.QuoteWizard); }}>
                                        <FormattedMessage id="pages.quotationconfigurator.maintabs.searchSolution" defaultMessage="Zoek oplossingen" />
                                    </NavLink>
                                </NavItem>
                                <NavItem hidden={this.state.isSaved || !this.state.filterData.selectSolutions}>
                                    <NavLink
                                        className={classnames({ active: activeTab === QuoteConfiguratorWizardTabs.EditQuote })}
                                        onClick={() => { this.toggle(QuoteConfiguratorWizardTabs.EditQuote); }}>
                                        <FormattedMessage id="pages.quotationconfigurator.maintabs.editQuote" defaultMessage="Bewerk offerte" /> ({this.state.filterData.selectSolutions ? this.state.filterData.selectSolutions.length : 0})
                                    </NavLink>
                                </NavItem>
                                <NavItem hidden={!this.state.isSaved || !this.state.filterData.selectSolutions || !this.state.filterData.selectSolutions.length}>
                                    <NavLink
                                        className={classnames({ active: activeTab === QuoteConfiguratorWizardTabs.SaveAndDownload })}
                                        onClick={() => { this.toggle(QuoteConfiguratorWizardTabs.SaveAndDownload); }}>
                                        <FormattedMessage id="pages.quotationconfigurator.maintabs.saveAndDownload" defaultMessage="Opslaan en downloaden" />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={QuoteConfiguratorWizardTabs.QuoteWizard}>
                                    <QuoteWizardForm
                                        solutionsCalculated={this.solutionsCalculated}
                                        onError={this.onCalculateSolutionsError} />
                                </TabPane>
                                <TabPane tabId={QuoteConfiguratorWizardTabs.EditQuote}>
                                    <EditQuote filterData={filterData} onQuoteUpdated={this.onQuoteUpdated}
                                        saveOrRemove={this.onSaveOrRemove} isQuoteLoading={isQuoteLoading} />
                                </TabPane>
                                <TabPane tabId={QuoteConfiguratorWizardTabs.SaveAndDownload}>
                                    <DownloadTab isQuoteLoading={isQuoteLoading} quoteId={quoteId} isSaved={this.state.isSaved} />
                                </TabPane>
                            </TabContent>
                        </Col>
                    }
                    {
                        this.state.isLoading &&
                        <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                    }
                    {
                        !this.state.isLoading && this.state.errorMessage &&
                        <Alert color="danger">
                            <FormattedMessage id="errors.quoteconfigurator.loading" defaultMessage={this.state.errorMessage} />
                        </Alert>
                    }
                </Row>
            </div>
        );
    }



    onCalculateSolutionsError = (errorCode: string) => {
        this.setState({
            errorMessage: errorCode
        });
    }
}

export default injectIntl(QuoteConfiguratorWizard, { forwardRef: true });