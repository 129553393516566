import React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react";




interface IConfirmDialogState{

    dialogHidden: boolean;
    buttonCancelText:string;
    buttonOkText:string;
    callBackAction? : (okClicked:boolean) => void;
}

interface IConfirmDialogProps{
    title: string;
    question: string;
    buttonCancelText?:string;
    buttonOkText?:string;
    buttonCancelAction?: () => void;
    buttonOkAction?: () => void;
}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {

    constructor(props: IConfirmDialogProps) {
        super(props)

        this.state = {
            dialogHidden: true,
            buttonOkText:  (this.props.buttonOkText ? this.props.buttonOkText : "OK"),
            buttonCancelText : (this.props.buttonCancelText ? this.props.buttonCancelText : "Annuleren")
        }

    }

    buttonOkClickInternal = () => {

        if (this.state.callBackAction) {
            this.state.callBackAction(true);
        } else if (this.props.buttonOkAction) {
            this.props.buttonOkAction();
        }

        this.setState({
            dialogHidden: true
        })
    }

    buttonCancelClickInternal = () => {

        if (this.state.callBackAction) {
            this.state.callBackAction(false);
        } else if (this.props.buttonCancelAction) {
            this.props.buttonCancelAction();
        }

        this.setState({
            dialogHidden: true,
            callBackAction: undefined
        })
    }


    public showDialog = (callBack : (okClicked:boolean) => void) => {
        this.setState({
            dialogHidden:false,
            callBackAction: callBack
        });


    }




    render = () =>
            <Dialog hidden={this.state.dialogHidden} title={this.props.title} subText={this.props.question} closeButtonAriaLabel="Sluiten">
                <DialogFooter>
                    <PrimaryButton text={this.state.buttonOkText} onClick={(e) => this.buttonOkClickInternal()} />
                    <DefaultButton text={this.state.buttonCancelText} onClick={(e) => this.buttonCancelClickInternal()} />
                </DialogFooter>
            </Dialog>
	
}