import { IRequestVM, IGetRequestsResponseVM } from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export class RequestService {

    public static GetMyAll = (): Promise<IRequestVM[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/requests`, Service.Config())
                .then((response: AxiosResponse<IGetRequestsResponseVM>) => resolve(response.data.requests))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.requests.getMyAll", reason));
        });

    public static GetAll = (): Promise<IRequestVM[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/requests/all`, Service.Config())
                .then((response: AxiosResponse<IGetRequestsResponseVM>) => resolve(response.data.requests))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.requests.getAll", reason));
        });

}