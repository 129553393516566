import { Util } from "./Utils/Util";
import { ServiceUrls } from "./services/ServiceUrls";
import { jwtDecode } from "jwt-js-decode";
import AuthRepository from "./repositories/AuthRepository";
import moment from "moment";
import AppRepository from "./repositories/AppRepository";

export class NavigateToAuth {
    private static readonly LoginWindowName: string = "B2C login refresh";

    public static EnsureAuth = (): Promise<void> =>
        new Promise((resolve, reject) => {
            const authRepo = new AuthRepository();
            const token = authRepo.getToken();
            if (token) {
                const decodedJwt = jwtDecode(token);
                const exp = decodedJwt.payload["exp"];
                const expTime = new Date(exp * 1000);
                const now = Date.now();
                if (expTime.getTime() < now) {
                    console.log('Auth token expired');
                    const diffTime = moment(now).from(moment(expTime.getTime()), true)
                    console.log(`Expired by ${diffTime}`);
                    console.log('Trying to login again...');

                    authRepo.setToken("");
                    NavigateToAuth.Login(true);

                    NavigateToAuth.WaitForValidToken()
                        .then(() => resolve())
                        .catch(reason => reject(reason));
                } else {
                    resolve();
                }
            } else {
                NavigateToAuth.Login(true);

                NavigateToAuth.WaitForValidToken()
                    .then(() => resolve())
                    .catch(reason => reject(reason));
            }
        });

    private static WaitForValidToken = (): Promise<void> =>
        new Promise((resolve, reject) => {
            const authRepo = new AuthRepository();
            var intervalId = setInterval(() => {
                const newToken = authRepo.getToken();
                if (newToken && newToken.length > 0) {
                    console.log('New token found! Checking token.');
                    clearInterval(intervalId);
                        
                    resolve();
                }
                else {
                    console.log('Waiting for new token...');
                }
            }, 500);
        })

    public static Login = (useNewWindow: boolean = false) => {
        if (useNewWindow)
        {
            var popItUp = (url: string, windowName: string) => {
                var newWindow = window.open(url, windowName);
                newWindow && newWindow.focus && newWindow.focus();
                return false;
            }

            popItUp(ServiceUrls.LoginGet(''), NavigateToAuth.LoginWindowName);
        }
        else {
            document.location.href = ServiceUrls.LoginGet('');
        }
    }

    public static Logout = () => {
        document.location.href = ServiceUrls.LogoutGet;
    }

    public static ResetPassword = () => {
        document.location.href = ServiceUrls.PasswordResetGet;
    }

    public static HandleLoggedIn = () => {
        if (window.name === NavigateToAuth.LoginWindowName) {
            window.close();
        }
    }
}

export class NavigateToPage {
    public static Home = (appRepo?: AppRepository) => {
        if (appRepo) appRepo.setState("/");
        Util.Navigate("/");
    }
    public static ResetPassword = () => Util.Navigate("/me/resetPassword");

    public static InbedrijfstellingForm = (formId?: string) => formId ? Util.Navigate(`Forms/Commissioning?copyFromFormId=${formId}`) : Util.Navigate(`Forms/Commissioning`);
}