import axios, { AxiosResponse } from "axios";
import { now } from "moment";
import { ISubmitFormRequestVM } from "../viewmodels/data";
import { Service, ServiceError } from "./Service";
import MijnNathanService from "./MijnNathanService";
import { FormType } from "../viewmodels/form";

export enum FormQueueItemStatus {
    new = 0,
    processing = 1,
    done = 2,
    error = 3
}

export interface IFormQueueItem {
    id: string;
    title: string;
    url: string;
    form: any;
    formType: FormType;
    metadata: any;
    status: FormQueueItemStatus;
    response?: any;
}

export class FormQueue {
    private static readonly FormQueueKey: string = "MijnNathan.FormQueue";
    private static Initialized: boolean = false;

    private static subscribers: ((item: IFormQueueItem) => void)[] = [];

    public static subscribe(callbackFn: (item: IFormQueueItem) => void) {
        FormQueue.subscribers.push(callbackFn);
    }
    private static notifyUpdate(queueItem: IFormQueueItem) {
        FormQueue.subscribers.forEach(subscriber => subscriber(queueItem));
    }

    public static getQueue = (): IFormQueueItem[] => {
        const queueData = localStorage.getItem(FormQueue.FormQueueKey);
        let queue: IFormQueueItem[] = [];
        if (queueData) {
            queue = JSON.parse(queueData);
        }
        return queue;
    }
    public static addToQueue = (title: string, url: string, form: any, formType: FormType, metadata: any): IFormQueueItem => {
        const queue = FormQueue.getQueue();

        // add to queue
        const item: IFormQueueItem = {
            id: `fqi-${now()}`,
            title,
            url,
            form,
            formType,
            metadata,
            status: FormQueueItemStatus.new
        };
        queue.push(item);

        // write to queue
        localStorage.setItem(FormQueue.FormQueueKey, JSON.stringify(queue));

        FormQueue.notifyUpdate(item);

        return item;
    }

    private static updateStatus = (item: IFormQueueItem, newStatus: FormQueueItemStatus, response: any = undefined): IFormQueueItem | undefined => {
        let result: IFormQueueItem | undefined = undefined;
        // update queue
        const queue = FormQueue.getQueue();
        for (let itemIndex in queue) {
            if (queue[itemIndex].id === item.id) {
                queue[itemIndex].status = newStatus;
                queue[itemIndex].response = response;
                result = queue[itemIndex];
            }
        }
        localStorage.setItem(FormQueue.FormQueueKey, JSON.stringify(queue));

        if (result) {
            FormQueue.notifyUpdate(result);
        }

        return result;
    }

    private static removeItem = (item: IFormQueueItem) => {
        let queue = FormQueue.getQueue();
        let removeAtIndex: number = -1;
        for (let itemIndex in queue) {
            if (queue[itemIndex].id === item.id) {
                removeAtIndex = +itemIndex;
            }
        }

        if (removeAtIndex >= 0) {
            const removedItems = queue.splice(removeAtIndex, 1);
            console.log("Removed items", removedItems);
            localStorage.setItem(FormQueue.FormQueueKey, JSON.stringify(queue));
        }
    }

    public static processQueue = (): Promise<any>[] => {
        console.log('Processing form queue');
        const result: Promise<any>[] = [];

        let queue = FormQueue.getQueue();

        // initialize queue (clean up done and reset processing)
        if (!FormQueue.Initialized) {
            console.log("Initializing form queue");
            for (let itemIndex in queue) {
                const item = queue[itemIndex];
                if (item.status === FormQueueItemStatus.processing || item.status === FormQueueItemStatus.error) {
                    console.log("Resetting item to new", item);
                    FormQueue.updateStatus(item, FormQueueItemStatus.new);
                } else if (item.status === FormQueueItemStatus.done) {
                    console.log("Removing item", item);
                    FormQueue.removeItem(item);
                }
            }

            FormQueue.Initialized = true;
        }

        window.onbeforeunload = FormQueue.beforeBrowseAway;

        // update queue
        queue = FormQueue.getQueue();

        // process new items
        console.log("Processing new form queue items");
        for (let itemIndex in queue) {
            let item: IFormQueueItem | undefined = queue[itemIndex];

            if (item.status === FormQueueItemStatus.new) {
                console.log("Processing queue item", item);
                const itemPromise = FormQueue.processQueueItem(item);

                // update status
                item = FormQueue.updateStatus(item, FormQueueItemStatus.processing);

                result.push(itemPromise);
            }
        }

        return result;
    }

    public static beforeBrowseAway = (event: BeforeUnloadEvent) => {
        const queue = FormQueue.getQueue();
        const processingItems = queue.filter(q => q.status == FormQueueItemStatus.processing);
        if (processingItems.length > 0) {
            if (window.confirm("Niet alle informatie is compleet verwerkt. Wilt u de verwerking annuleren?")) {
                for (let item of processingItems) {
                    FormQueue.removeItem(item);
                }
            } else {
                event.preventDefault();
            }
        }
    }



    private static processQueueItem = (item: IFormQueueItem): Promise<any> =>
        new Promise((resolve, reject) => {
            console.log(`Processing queue item ${item.id}`);
            const body: ISubmitFormRequestVM<any> = {
                form: item.form,
                metadata: item.metadata
            };
            const axiosConfig = Service.Config();
            axios.post(item.url, body, axiosConfig)
                .then((response: AxiosResponse) => {
                    if (Math.floor(response.status / 100) == 2) {
                        // set item status to done
                        FormQueue.updateStatus(item, FormQueueItemStatus.done, response.data);

                        resolve(response.data && response.data.value ? response.data.value : response.data);
                    } else {
                        const message = `Form submit failed with status ${response.status}`;
                        const reason = new ServiceError(message, undefined, undefined, undefined, response);
                        reason.name = "FormSubmitError";
                        MijnNathanService.Catch(reject, reason);
                    }
                })
                .catch(reason => {
                    // set item status to done
                    const errorResponseData = reason && reason.response && reason.response.data ? reason.response.data : undefined;
                    FormQueue.updateStatus(item, FormQueueItemStatus.error, errorResponseData);

                    MijnNathanService.Catch(reject, reason);
                });
        });

}