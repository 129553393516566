import NumUtils from "./NumUtils";
import ObjUtils from "./ObjUtils";
import { DatePicker } from "@fluentui/react";
import ArrayUtils from "./ArrayUtils";
import { DateTime } from "luxon";
import Interval from "./Interval";

export default class DateTimeUtils {
    public static getDateRangeArr(startDate?: DateTime, endDate?: DateTime): DateTime[] {
        if (!startDate || !endDate) return []
        if (startDate > endDate) return [];

        let currentDate = DateTimeUtils.getDate(startDate);
        endDate = DateTimeUtils.getDate(endDate);
        let result: DateTime[] = [];
        while (currentDate <= endDate) {
            result.push(currentDate);
            currentDate = currentDate.plus({ days: 1 })
        }
        return result;
    }

    public static getWeekString = (year: number, week: number) => {
        const weekStr = ObjUtils.PadLeft(week, 2);
        return `${year}-${weekStr}`
    }


    public static getMonday = (date?: DateTime, onlyDate?: boolean) => DateTimeUtils.getDateForWeekDay(WeekDayEnum.Monday, date, onlyDate);
    public static getFriday = (date?: DateTime, onlyDate?: boolean) => DateTimeUtils.getDateForWeekDay(WeekDayEnum.Friday, date, onlyDate);
    public static getSunday = (date?: DateTime, onlyDate?: boolean) => DateTimeUtils.getDateForWeekDay(WeekDayEnum.Sunday, date, onlyDate);
    public static getIsWeekend = (date?: DateTime) => (date || DateTimeUtils.getDate()).weekday >= 6;

    public static getDateForWeekDay(weekDay: WeekDayEnum | number, date?: DateTime, onlyDate?: boolean) {
        if (!date) date = DateTimeUtils.getDate();
        const weekDayNr: number = weekDay;
        if (date.weekday == weekDayNr) return date;
        const newDate = date.plus({ days: (weekDayNr - date.weekday) });
        return onlyDate ? DateTimeUtils.getDate(newDate) : newDate;
    }

    public static getDate(date?: DateTime): DateTime {
        if (!date) date = DateTime.now();
        return DateTime.fromObject({ year: date.year, month: date.month, day: date.day });
    }

    public static getDateForOData(date: DateTime): string {
        return date.toFormat("yyyy-LL-dd");
        // return date.toUTC().toISO();
    }

    public static get = (date: any): DateTime | undefined => {
        if (date == undefined) return undefined;
        if (date instanceof DateTime) return date;
        if (date instanceof Date) return DateTime.fromJSDate(date);
        if ((typeof (date) == "string") || (date instanceof String)) return DateTime.fromISO("" + date);
        return undefined;
    }

    public static ToAppFormat = (dateTime?: DateTime | Date | string, includeTime?: boolean) => {
        if (!dateTime) return "";
        if (ObjUtils.isString(dateTime)) dateTime = DateTime.fromISO(dateTime as any as string);
        if (dateTime instanceof Date) dateTime = DateTime.fromJSDate(dateTime);
        if (dateTime instanceof DateTime) {
            if (includeTime) {
                return dateTime.toFormat("dd-MM-yyyy HH:mm");
            }
            return dateTime.toFormat("dd-MM-yyyy");
        }
        console.error("Unknow value", dateTime);
        console.log(dateTime);
        return "Unknown date value";
    }

    public static ToAppFormatTime = (dateTime?: DateTime | Date | string) => {
        if (!dateTime) return "";
        if (ObjUtils.isString(dateTime)) dateTime = DateTime.fromISO(dateTime as any as string);
        if (dateTime instanceof Date) dateTime = DateTime.fromJSDate(dateTime);
        if (dateTime instanceof DateTime) {
            return dateTime.toFormat("HH:mm");
        }
        console.error("Unknown value", dateTime);
        console.log(dateTime);
        return "Unknown date value";
    }


    public static TryParse = (str?: string): Date | undefined => {
        if (str) {
            const strParts = str.trim().split(/[^\d]+/)

            const day = (strParts.length > 0 && strParts[0]) ? (+strParts[0]) : undefined;
            const month = (strParts.length > 1 && strParts[1]) ? (+strParts[1]) : DateTime.now().month;
            let year = (strParts.length > 2 && strParts[2]) ? (+strParts[2]) : DateTime.now().year;
            if (year && year < 100) year = year + 2000;

            // console.log(strParts, day, month, year);

            if (day && month && year) {
                return new Date(year, month - 1, day);
            }
        } else {
            console.log(str);
        }
    }

    public static IsEqual = (dateTime1?: DateTime, dateTime2?: DateTime, includeTime?: boolean) => {
        if (!dateTime1 && !dateTime2) return true;
        if (!dateTime1 || !dateTime2) return false;
        if (includeTime) {
            return Math.round(dateTime1.toSeconds()) == Math.round(dateTime2.toSeconds());
        }
        return dateTime1.toISODate() == dateTime2.toISODate();
    }

    public static IsValid = (dateTime?: DateTime): boolean => {
        if (!dateTime) return false;
        if (dateTime.year > 2200) return false;
        if (dateTime.year < 1900) return false;
        return true;
    }

    public static GetNumberOfWeekDays = (start: DateTime, end: DateTime) => {
        start = DateTimeUtils.getDate(start);
        end = DateTimeUtils.getDate(end);
        if (start.valueOf > end.valueOf) return 0;
        let currentDate = start;
        let weekDayCount = 0;
        while (currentDate.valueOf <= end.valueOf) {
            if (currentDate.weekday < 6) weekDayCount++;
            currentDate = currentDate.plus({ days: 1 });
        }
        return weekDayCount
    }

    public static GetCenterDate = (date1?: DateTime, date2?: DateTime) => {
        if (!date1) return date2;
        if (!date2) return date1;
        const centerEpoch = (date1.valueOf() + date2.valueOf()) / 2;
        const centerDateTime = DateTime.fromMillis(centerEpoch);
        return DateTimeUtils.getDate(centerDateTime);
    }

    public static GetMaxDateFromArr = (dates: DateTime[]) => {
        if (dates.length == 0) return undefined;
        return dates.slice().sort((dt1, dt2) => dt1.valueOf() > dt2.valueOf() ? -1 : 1)[0]
    }

    public static GetMinDateFromArr = (dates: DateTime[]) => {
        if (dates.length == 0) return undefined;
        return dates.slice().sort((dt1, dt2) => dt1.valueOf() > dt2.valueOf() ? 1 : -1)[0]
    }

    public static GetLastWeekNumber = (year: number) => {
        return DateTime.utc(year, 12, 28).weekNumber;
    }

    /** Return the maximum usable date */
    public static GetMaxDate = () => DateTime.fromObject({ year: 2199, month: 12, day: 31 });
    /**Return the minimum usable date  */
    public static GetMinDate = () => DateTime.fromObject({ year: 1900, month: 1, day: 1 });

    /** Returns the highest date. If only one date is defines, it returns that single one. If no date is defined, it returns undefined */
    public static Max = (dt1?: DateTime, dt2?: DateTime) => {
        if (!dt1 && !dt2) return undefined;
        if (!dt1 || !dt2) return dt1 || dt2;
        return dt1.valueOf() > dt2.valueOf() ? dt1 : dt2;
    }

    /** Returns the lowest date. If only one date is defines, it returns that single one. If no date is defined, it returns undefined */
    public static Min = (dt1?: DateTime, dt2?: DateTime) => {
        if (!dt1 && !dt2) return undefined;
        if (!dt1 || !dt2) return dt1 || dt2;
        return dt1.valueOf() < dt2.valueOf() ? dt1 : dt2;
    }

    public static GetIntervalForYear = (year: number) => {
        const dt1 = DateTime.fromObject({ day: 1, month: 1, year });
        const dt2 = DateTime.fromObject({ day: 31, month: 12, year });
        return Interval.FromDateTimes(dt1, dt2);
    }

    public static GetDistinctDates = (dates: DateTime[]) => {
        const temp1 = dates.map(dt => {
            const dtd = DateTimeUtils.getDate(dt);
            return {
                dt, dtd, valueOf: dtd.valueOf()
            }
        })
        const temp2 = temp1.sort((ob1, ob2) => (ob1.valueOf - ob2.valueOf));
        const temp3 = ArrayUtils.ToLookup(temp1, t => "" + t.valueOf);
        return Object.values(temp3).map(t => t.dtd);
    }


}

export enum WeekDayEnum {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saterday = 6,
    Sunday = 7
};