import React from "react";
import { WrappedComponentProps, FormattedMessage, injectIntl } from "react-intl";
import { Util } from "../Utils/Util";
import { Alert, Button, Card, CardBody, Col, Collapse, CustomInput, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Loading from "../components/Loading";
import {
    ISolutionVM,
    ICrudGetAllResponseVM,
    SolutionTypeEnum,
    HeatPumpTypeEnum,
    HeatPumpFunctionEnum,
    RenovationNewChoiceEnum,
    UnderfloorHeatingTypeEnum,
    PostAdjustmentTypeEnum,
    IItemVM,
    ISolutionPositionItemVM
} from "../viewmodels/data";
import "./SolutionIndex.scss";

import { SolutionService } from "../services/SolutionService";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import ConfirmDialog from "../components/ConfirmDialog";
import { DefaultButton, IColumn, Label, Pivot, PivotItem, PrimaryButton, SelectionMode, Spinner, SpinnerSize, TextField, mergeStyles } from "@fluentui/react";
import { sub } from "date-fns";
import EnumUtils from "../Utils/EnumUtils";
import { merge } from "jquery";
import Debounce from "../Utils/Debounce";
import HxDetailList, { HxDetailListItem } from "../components/HxDetailList";
import { ItemService } from "../services/ItemService";
import Money from "../components/Money";
import { SolutionPositionService } from "../services/SolutionPositionService";
import ArrayUtils from "../Utils/ArrayUtils";


interface ISolutionIndexProps extends WrappedComponentProps { }

interface ISolutionIndexState {
    solutionResponse: ISolutionVM[];
    filteredSolutionResponse: ISolutionVM[];
    searchTextBox?: string;
    searchQuery?: string;
    isLoading?: boolean;
    errorMessage?: string;
    selectedPivotKey?: string;

    modalOpen?: boolean;
    modalView?: "1" | "2" | "3";

    searchText?: string;
    searchTextPositionItems?: string;

    items?: IItemList[]
    listItems?: IDetailListItem[]
    isLoadingArticleItems?: boolean;
    isLoadingPositionItems?: boolean;
    selectedArticleItem?: IDetailListItem;
    selectedPositionItem?: IDetailListSolutionPositionItem;

    solutionPositionItems?: IDetailListSolutionPositionItem[]

}

interface IItemList {
    item: IItemVM;
    isChecked: boolean;
    numberOfItems: number;
}

interface IDetailListItem extends IItemVM, HxDetailListItem {

}

interface IDetailListSolutionPositionItem extends ISolutionPositionItemVM, HxDetailListItem {

}


class SolutionIndex extends React.Component<ISolutionIndexProps, ISolutionIndexState> {

    SetState2 = <K extends keyof ISolutionIndexState>(ob: Pick<ISolutionIndexState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: ISolutionIndexProps) {
        super(props);
        this.state = {
            solutionResponse: [],
            filteredSolutionResponse: [],
            modalOpen: false,
            isLoadingArticleItems: false,
            isLoadingPositionItems: false,
            listItems: [],
            solutionPositionItems: [],
        };
    }

    componentDidMount() {
        const selectedPivotKey = localStorage.getItem("selectedPivotKey");
        const query = localStorage.getItem("searchSolution.searchQuery");

        this.setState({
            selectedPivotKey: selectedPivotKey ?? "",
            searchQuery: query ?? "",
            searchTextBox: query ?? ""
        });
        this.loadRequests();
    }

    private loadRequests = () => {
        this.setState({
            solutionResponse: [],
            isLoading: true,
            errorMessage: undefined
        });

        this.loadSolutionsRequests();
    }

    private loadSolutionsRequests = () =>
        SolutionService.GetAllIncludeChildren()
            .then((requests: ICrudGetAllResponseVM<ISolutionVM>) => {
                this.setState({
                    errorMessage: undefined,
                    solutionResponse: requests.value
                });
            })
            .catch(reason => {
                this.setState({
                    errorMessage: reason
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });

    private editSolutionRedirect = (solutionId) => {
        Util.Navigate(`Solutions/Edit/${solutionId}`)
    }

    private deleteSolutionsRequests = async (solutionId) => {
        this.confirmDialog!.showDialog(ok => {
            if (ok) {
                this.setState({ isLoading: true })
                SolutionService.Delete(solutionId).then((response: ICrudGetAllResponseVM<ISolutionVM>) => {
                    console.log("delete", response);
                }).catch(reason => {
                    // this.setState({
                    //     errorMessage: reason
                    // });
                    console.log("error", reason)
                }).finally(() => {
                    this.loadSolutionsRequests();
                    this.setState({
                        isLoading: false
                    });
                });
            }
        })
    }

    searchSolutionChange = async (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, txt: string | undefined) => {
        await this.SetState2({ searchTextBox: txt });
        Debounce.do("searchSolutionChange", () => {
            this.setState({ searchQuery: txt });
            localStorage.setItem("searchSolution.searchQuery", txt || "");
        }, 400);
    }


    getFilteredSolutions = () => {
        const { searchQuery, solutionResponse } = this.state;
        if (!searchQuery || !searchQuery.trim()) return solutionResponse;

        let filteredSolution = solutionResponse.slice();
        searchQuery.split(" ").forEach((searchPart) => {
            var searchRegEx = new RegExp(`${searchPart}`, 'gi');
            filteredSolution = filteredSolution
                .filter((element) => element.title.match(searchRegEx) !== null || element.description?.match(searchRegEx) !== null);
        });

        return filteredSolution;
    }

    searchItems = async () => {
        await this.SetState2({ isLoadingArticleItems: true })
        const items = await ItemService.Search(this.state.searchText || "");

        if (items == null) {
            await this.SetState2({
                items: undefined,
                isLoadingArticleItems: false,
            });
        } else {
            // let itemList = items.map(item => {
            //     // const listItems: IItemList = { item: item, isChecked: false, numberOfItems: 0 };
            //     const listItems = this.getDetailListItems()
            //     return listItems;
            // });
            const listItems = this.getDetailListItems(items);
            await this.SetState2({
                listItems: listItems,
                isLoadingArticleItems: false,
            })
        }
    }

    searchPositionItems = async () => {
        const { searchTextPositionItems } = this.state;
        await this.SetState2({ isLoadingPositionItems: true })
        const response = await SolutionPositionService.GetAllItems()
        const items = response.value
        if (!items) return undefined

        let listItems = this.getPositionItemsListItems(items)

        if (searchTextPositionItems) {
            const searchValues = searchTextPositionItems.toUpperCase().split(" ");
            console.log("search values", searchValues);
            searchValues.forEach(sv => {
                if (sv) {
                    listItems = listItems.filter(s => s.SearchTextUpper.indexOf(sv!) > -1)
                }
            });

        }

        await this.SetState2({ solutionPositionItems: listItems, isLoadingPositionItems: false })

    }

    getPositionListItems = (items: ISolutionPositionItemVM[]): IDetailListSolutionPositionItem[] => {
        const resultItems = items.map(i => {
            return {
                ...i,
                SearchTextUpper: Object.values(i).join(" ").toUpperCase(),
            }
        });
        return resultItems;
    }

    getDetailListItems = (items: IItemVM[]): IDetailListItem[] => {
        const resultItems = items.map(i => {
            return {
                ...i,
                SearchTextUpper: Object.values(i).join(" ").toUpperCase()
            }
        });
        return resultItems;
    }

    getPositionItemsListItems = (items: ISolutionPositionItemVM[]): IDetailListSolutionPositionItem[] => {
        const resultItems = items.map(i => {
            return {
                ...i,
                SearchTextUpper: i.navisionKey.toUpperCase(),
            }
        });
        return resultItems;
    }

    _onActiveItemChanged = (item: IDetailListItem) => {
        if (item) {
            this.setState({
                selectedArticleItem: item,
            });
        }
    }

    _onActivePositionItemChanged = (item: IDetailListSolutionPositionItem) => {
        if (item) {
            this.setState({
                selectedPositionItem: item
            });
        }
    }

    getRelatedSolutions = () => {
        const { solutionPositionItems, selectedPositionItem, solutionResponse } = this.state;

        const solutionItemsIds = new Set();

        solutionPositionItems?.forEach(s => {
            if (s.navisionKey == selectedPositionItem?.navisionKey) {
                solutionItemsIds.add(s.id);
            }
        })

        const affectedSolutions: ISolutionVM[] = [];

        solutionResponse.forEach(s => {
            for (const sp of s.solutionPositions) {
                for (const spi of sp.solutionPositionItems) {
                    if (solutionItemsIds.has(spi.id)) {
                        affectedSolutions.push(s);
                        break; // Break out of innermost loop
                    }
                }
            }
        });


        return affectedSolutions;
    }

    getColumns = () => {
        let columns: IColumn[] = [
            HxDetailList.getColumn("itemNumber", "Item number", 100, "text", true),
            HxDetailList.getColumn("baseUnitOfMeasure", "Base unit of measure", 100, "text", true),
            HxDetailList.getColumn("description", "Descriprion", 100, "text", true),
            HxDetailList.getColumn("grossPricePerUnit", "Gross price per unit", 100, "text", true)
        ]
        return columns;
    }

    getColumnsSolutionItems = () => {
        let columns: IColumn[] = [
            HxDetailList.getColumn("navisionKey", "Navision key", 100, "text", true),
            HxDetailList.getColumn("psNumber", "Ps number", 100, "text", true),
            HxDetailList.getColumn("quantity", "Quantity", 100, "text", true),
        ];
        return columns;
    }

    replaceArticle = () => {
        const { solutionPositionItems, selectedArticleItem, selectedPositionItem } = this.state;

        if (!solutionPositionItems || solutionPositionItems.length == 0) return;

        const navKey = selectedPositionItem?.navisionKey
        var positionItems = solutionPositionItems?.slice();

        positionItems?.forEach(s => {
            console.log("loop", s.navisionKey, navKey);
            if (s.navisionKey == navKey) {
                console.log("before replace", s.navisionKey, selectedArticleItem?.itemNumber, navKey);
                s.navisionKey = (selectedArticleItem && selectedArticleItem.itemNumber) ? selectedArticleItem.itemNumber : s.navisionKey;
                console.log("after replace", s.navisionKey, selectedArticleItem?.itemNumber, navKey);
            }
        });

        console.log("positionItems", positionItems);

        positionItems.forEach(i => {
            var itemVM: ISolutionPositionItemVM = {
                ...i
            }
            console.log("item VM", itemVM);
            SolutionPositionService.UpdatePositionItems(itemVM, itemVM.id);
            console.log("after update");
        })
    }


    confirmDialog: ConfirmDialog | undefined | null;

    private renderSolutionRow = (solution: ISolutionVM) => {
        var subtitle = "segrweg";
        if (solution.solutionType == SolutionTypeEnum.HeatPump) {
            subtitle = solution.heatPumpType == HeatPumpTypeEnum.airWater ? "Lucht/Water" : "Grond";
            subtitle += " - " + solution.powerMin + " kW";
            subtitle += " - " + solution.powerMax + " kW";
            subtitle += (solution.functionHeatpump == HeatPumpFunctionEnum.Heating ? " - Verwarmen" : " - Verwarmen en koeling");
        }

        if (solution.solutionType == SolutionTypeEnum.Source) {
            subtitle = solution.powerMin + " kW";
            subtitle += " - " + solution.powerMax + " kW";
            if (solution.renovationOrNew == RenovationNewChoiceEnum.NewBuild) subtitle += " - Nieuwbouw";
            if (solution.renovationOrNew == RenovationNewChoiceEnum.Renovation) subtitle += " - Renovatie";
            if (solution.renovationOrNew == RenovationNewChoiceEnum.Both) subtitle += " - Nieuwbouw en renovatie";
        }

        if (solution.solutionType == SolutionTypeEnum.UnderfloorHeating) {
            subtitle = solution.surfaceAreaMin + " m2";
            subtitle += " - " + solution.surfaceAreaMax + " m2";
            subtitle += " - " + EnumUtils.getString(UnderfloorHeatingTypeEnum, solution.underfloorHeatingType);
        }

        if (solution.solutionType == SolutionTypeEnum.Regulator) {
            subtitle = solution.surfaceAreaMin + " m2";
            subtitle += " - " + solution.surfaceAreaMax + " m2";
            subtitle += " - " + EnumUtils.getString(PostAdjustmentTypeEnum, solution.heatingAdjustment);
        }


        return <ListGroupItem key={solution.id} className={solution.isEnabled ? "" : this.listItemStyleDisabled} >
            <Row>
                <Col md={10}>
                    {solution.title}<br />
                    <sub>{subtitle}</sub>
                </Col>
                <Col md={1}><FaPencilAlt className="cp" onClick={() => this.editSolutionRedirect(solution.id)} title={solution.title} /></Col>
                <Col md={1}><FaTrash className="cp" onClick={() => this.deleteSolutionsRequests(solution.id)} title={solution.title} /></Col>
            </Row>
        </ListGroupItem>;
    }

    listItemStyleDisabled = mergeStyles({
        color: "#aaa",
        backgroundColor: "#eee !important"
    });

    renderRelatedSolutions = (solutions: ISolutionVM[]) => {
        return <ul>
            {solutions.map(s => {
                return <>
                    <li>{s.title}</li>
                    {s.solutionPositions && <>
                        <ul>
                            {s.solutionPositions.map(sp => {
                                return <>
                                    <li>{sp.title}</li>
                                </>
                            })}
                        </ul>
                    </>}
                </>
            })}
        </ul>
    }


    renderSolutions = (solutions: ISolutionVM[]) =>
        <ListGroup>
            {
                solutions.length > 0 ?
                    solutions.sort((s1, s2) => s1.title > s2.title ? 1 : -1)
                        .map((solution) => this.renderSolutionRow(solution)) :
                    <ListGroupItem><FormattedMessage id="general.nodata" defaultMessage="No data found" /></ListGroupItem>
            }
        </ListGroup>

    render() {

        const { searchTextBox, selectedPivotKey, modalOpen, searchText, items, listItems, solutionPositionItems, searchTextPositionItems, modalView, selectedArticleItem, selectedPositionItem, solutionResponse } = this.state;
        const intl = this.props.intl;

        const solutions = this.getFilteredSolutions();


        const heatPumpSolutions = solutions.filter(x => x.solutionType == SolutionTypeEnum.HeatPump);
        const underfloorHeatingSolutions = solutions.filter(x => x.solutionType == SolutionTypeEnum.UnderfloorHeating);
        const sourceSolutions = solutions.filter(x => x.solutionType == SolutionTypeEnum.Source);
        const regulatorSolutions = solutions.filter(x => x.solutionType == SolutionTypeEnum.Regulator);

        return <>
            <div className="page-CommissioningOverview mt-2">
                <ConfirmDialog ref={cf => this.confirmDialog = cf} title={intl.formatMessage({ id: "pages.systemconfigurator.confirm.title" })} question={intl.formatMessage({ id: "pages.systemconfigurator.confirm.question" })} />
                <div className="row align-items-center">
                    <div className="col-sm">
                        <h4 className="page-heading"><FormattedMessage id="pages.systemconfigurator.title" defaultMessage="Solutinos" /></h4>

                    </div>
                    <div className="col-sm-auto">
                        <PrimaryButton onClick={() => Util.Navigate("Solutions/New")}>
                            <FormattedMessage id="pages.systemconfigurator.new" defaultMessage="Nieuwe oplossing" />
                        </PrimaryButton>&nbsp;&nbsp;
                        <DefaultButton onClick={() => Util.Navigate(`/`)}>
                            <FormattedMessage id="pages.quotationoverview.btnBackToMainMenu" defaultMessage="Terug naar hoofdmenu" />
                        </DefaultButton>
                    </div>
                </div>
                {!this.state.isLoading && !this.state.errorMessage &&
                    <div className="row">
                        <div className="col-md-auto order-md-12 mt-3">
                            <div className="row align-items-center">
                                <div className="col-sm form-group">
                                    <TextField label="Zoek oplossing" placeholder="Zoekterm ..." onChange={(ev, txt) => this.searchSolutionChange(ev, txt)} value={searchTextBox} />
                                    <br />
                                    <PrimaryButton style={{ width: "100%" }} onClick={() => this.setState({ modalOpen: true, modalView: "1" })} text="Search and replace" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md order-md-1 systeem-accordian mt-3">
                            <Pivot onLinkClick={(ev, item) => this.pivotOnChange(ev, item)} selectedKey={selectedPivotKey}>
                                <PivotItem headerText={"Warmtepomp (" + heatPumpSolutions.length + ")"} itemKey="Warmtepomp">
                                    {this.renderSolutions(heatPumpSolutions)}
                                </PivotItem>
                                <PivotItem headerText={"Vloerverwarming (" + underfloorHeatingSolutions.length + ")"} itemKey="Vloerverwarming">
                                    {this.renderSolutions(underfloorHeatingSolutions)}
                                </PivotItem>
                                <PivotItem headerText={"Naregeling (" + regulatorSolutions.length + ")"} itemKey="Naregeling">
                                    {this.renderSolutions(regulatorSolutions)}
                                </PivotItem>
                                <PivotItem headerText={"VBWW (" + sourceSolutions.length + ")"} itemKey="VBWW">
                                    {this.renderSolutions(sourceSolutions)}
                                </PivotItem>
                            </Pivot>
                        </div>
                    </div>
                }
                {
                    this.state.isLoading &&
                    <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                }
                {
                    !this.state.isLoading && this.state.errorMessage &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.systemconfigurator.loading" defaultMessage={this.state.errorMessage} />
                    </Alert>
                }
            </div>

            <Modal isOpen={modalOpen} >
                <ModalHeader><FormattedMessage id="page.configurationwizard.modal.activeposition.header" defaultMessage="Artikel toevoegen" /></ModalHeader>
                {modalView == "1" && <> <ModalBody>
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-7 col-sm-7">
                            <Label htmlFor="exampleEmail">Zoek artikel {listItems && listItems.length}</Label>

                            <Input type="text" name="email" id="exampleEmail" placeholder="Search..."
                                onChange={(ev) => this.setState({ searchText: ev.target.value })} value={searchText} />
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <PrimaryButton
                                style={{ position: "absolute", bottom: 3 }}
                                onClick={() => this.searchItems()}>
                                <FormattedMessage id="page.configurationwizard.modal.activeposition.searchbtn" defaultMessage="Zoek" />
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 5 }}>
                        <div></div>
                        <div className="col-md-12" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {this.state.isLoadingArticleItems ? <div className="d-flex justify-content-center"><Spinner size={SpinnerSize.small} /></div> :

                                <HxDetailList
                                    uniqueName="ArticleItems"
                                    columns={this.getColumns()}
                                    items={listItems}
                                    selectionMode={SelectionMode.single}
                                    onActiveItemChanged={this._onActiveItemChanged}
                                    isColumnReorderEnabled={true}
                                    isColumnSelectionEnabled={true}
                                />
                            }
                        </div>
                    </div>
                    {/* solutionItem */}
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-7 col-sm-7">
                            <Label htmlFor="exampleEmail">Zoek artikel position items {solutionPositionItems && solutionPositionItems.length}</Label>

                            <Input type="text" name="search" id="exampleEmail2" placeholder="Search..."
                                onChange={(ev) => this.setState({ searchTextPositionItems: ev.target.value })} value={searchTextPositionItems} />
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <PrimaryButton
                                style={{ position: "absolute", bottom: 3 }}
                                onClick={() => this.searchPositionItems()}>
                                <FormattedMessage id="page.configurationwizard.modal.activeposition.searchbtn" defaultMessage="Zoek" />
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 5 }}>
                        <div></div>
                        <div className="col-md-12" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {this.state.isLoadingPositionItems ? <div className="d-flex justify-content-center"><Spinner size={SpinnerSize.small} /></div> :

                                <HxDetailList<IDetailListSolutionPositionItem>
                                    uniqueName="PositionItems"
                                    columns={this.getColumnsSolutionItems()}
                                    items={solutionPositionItems}
                                    selectionMode={SelectionMode.single}
                                    onActiveItemChanged={this._onActivePositionItemChanged}
                                    isColumnReorderEnabled={true}
                                    isColumnSelectionEnabled={true}
                                />
                            }
                        </div>
                    </div>
                </ModalBody>
                    <ModalFooter>
                        <DefaultButton onClick={() => this.setState({ modalOpen: false })}>
                            <FormattedMessage id="general.cancel" defaultMessage="Annuleren" />
                        </DefaultButton>
                        <PrimaryButton onClick={() => {
                            if (selectedArticleItem && selectedPositionItem) {
                                this.setState({ modalView: "2" })
                            } else {
                                alert("Please select articles")
                            }
                        }
                        }>
                            <FormattedMessage id="general.save" defaultMessage="Opslaan" />
                        </PrimaryButton>
                    </ModalFooter></>}


                {modalView == "2" && <> <ModalBody>
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-7 col-sm-7">
                            <Label >Original article {selectedPositionItem ? selectedPositionItem.navisionKey : ""}</Label>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <Label >Replacement article {selectedArticleItem ? selectedArticleItem.itemNumber : ""}</Label>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-12" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            <Label>Affected solutions</Label>
                            {this.renderRelatedSolutions(this.getRelatedSolutions())}
                        </div>
                    </div>
                </ModalBody>
                    <ModalFooter>
                        <DefaultButton onClick={() => this.setState({ modalView: "1" })} text="Back" />
                        <PrimaryButton onClick={() => {
                            this.replaceArticle();
                            this.setState({ modalView: "3" })
                        }} text="Replace article" />
                    </ModalFooter></>}

                {modalView == "3" && <> <ModalBody>
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-12" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            <Label>Affected solutions</Label>
                            {this.renderRelatedSolutions(this.getRelatedSolutions())}
                        </div>
                    </div>
                </ModalBody>
                    <ModalFooter>
                        <DefaultButton onClick={() => this.setState({ modalOpen: false })} text="Close" />
                    </ModalFooter></>}
            </Modal>
        </>



    }

    pivotOnChange(item: PivotItem | undefined, ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void {
        const selectedKey = item?.props.itemKey;
        this.setState({ selectedPivotKey: selectedKey });
        localStorage.setItem("selectedPivotKey", selectedKey ?? "");
    }

}

export default injectIntl(SolutionIndex, { forwardRef: true });