import FormikLink from "../../components/forms/formik/FormikLink";

export default
{
    "id": "ign6",
    "label": "Elektrisch/Regeling",
    "type": "container",
    "renderer": "form",
    "elements": {
        "rechtsDraaiveldGecontroleerd": {
            "name": "rechtsDraaiveldGecontroleerd",
            "label": "Rechts draaiveld gecontroleerd",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" },
                { "title": "Niet van toepassing", "value": "Niet van toepassing" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "werkschakelaarStekkerCompressor": {
            "name": "werkschakelaarStekkerCompressor",
            "label": "Werkschakelaar/stekker compressor",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "werkschakelaarStekkerStuurstroom": {
            "name": "werkschakelaarStekkerStuurstroom",
            "label": "Werkschakelaar/stekker stuurstroom",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "automaatStuurspanning": {
            "name": "automaatStuurspanning",
            "label": "Automaat stuurspanning",
            "type": "field",
            "renderer": "text"
        },
        "automaatCompressorspanning": {
            "name": "automaatCompressorspanning",
            "label": "Automaat compressorspanning",
            "type": "field",
            "renderer": "text"
        },
        "uitbreidingsprint": {
            "name": "uitbreidingsprint",
            "label": "Uitbreidingsprint",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "voorregeling": {
            "name": "voorregeling",
            "label": "Voorregeling",
            "type": "field",
            "renderer": "react-select",
            "fieldType": "text",
            "options": [
                { "label": "RBE", "value": "RBE" },
                { "label": "Alpha-home", "value": "Alpha-home" },
                { "label": "Smatrix Pulse", "value": "Smatrix Pulse" },
                { "label": "ThermoSmart", "value": "ThermoSmart" },
                { "label": "Anders", "value": "Anders" },
                { "label": "Niet van toepassing", "value": "Niet van toepassing" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "naregeling": {
            "name": "naregeling",
            "label": "Naregeling",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "alphaWeb": {
            "name": "alphaWeb",
            "label": "Alpha web",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "alphaWebLink": {
            "type": "field",
            "renderer": FormikLink,
            "urlPreText": "Het Alpha web aanvraagformulier is beschikkbaar op: ",
            "url": "https://www.nathan.nl/service-support/service-beheer-en-onderhoud/aanvraagformulier-alphaweb/",
            "showWhen": [ "is", "alphaWeb", "Ja" ]
        },
        "interneCVPomp": {
            "name": "interneCVPomp",
            "label": "Interne CV-pomp",
            "type": "field",
            "renderer": "react-select",
            "fieldType": "text",
            "options": [
                { "label": "HUP", "value": "HUP" },
                { "label": "ZUP", "value": "ZUP" }
            ]
        },
        "temperatuurregeling": {
            "name": "temperatuurregeling",
            "label": "Temperatuur regeling",
            "type": "field",
            "renderer": "react-select",
            "fieldType": "text",
            "options": [
                { "label": "WA-regeling", "value": "WA-regeling" },
                { "label": "Vrijgave EVU", "value": "Vrijgave EVU" },
                { "label": "TR vast", "value": "TR vast" },
                { "label": "BAC-net", "value": "BAC-net" },
                { "label": "Anders", "value": "Anders" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "aparteStuurstroomVoeding": {
            "name": "aparteStuurstroomVoeding",
            "label": "Aparte stuurstroom voeding",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "tweedeWarmteopwekkerAanwezig": {
            "name": "tweedeWarmteopwekkerAanwezig",
            "label": "Tweede warmteopwekker aanwezig",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "tweedeWarmteopwekkerAangesloten": {
            "name": "tweedeWarmteopwekkerAangesloten",
            "label": "Tweede warmteopwekker aangesloten",
            "type": "field",
            "renderer": "radio",
            "options": [
                { "title": "Ja", "value": "Ja" },
                { "title": "Nee", "value": "Nee" }
            ],            
            "showWhen": [ "is", "tweedeWarmteopwekkerAanwezig", "Ja" ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "buitenvoeler": {
            "name": "buitenvoeler",
            "label": "Buitenvoeler",
            "type": "field",
            "renderer": "react-select",
            "fieldType": "text",
            "options": [
                { "label": "Noord", "value": "Noord" },
                { "label": "Oost", "value": "Oost" },
                { "label": "Zuid", "value": "Zuid" },
                { "label": "West", "value": "West" },
                { "label": "Noord-Oost", "value": "Noord-Oost" },
                { "label": "Noord-West", "value": "Noord-West" },
                { "label": "Zuid-Oost", "value": "Zuid-Oost" },
                { "label": "Zuid-West", "value": "Zuid-West" },
                { "label": "Overkapping", "value": "Overkapping" },
                { "label": "Vaste weerstand", "value": "Vaste weerstand" },
                { "label": "Anders", "value": "Anders" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        },
        "bacNet1": {
            "name": "bacNet1",
            "label": "BACnet 1",
            "type": "field",
            "renderer": "react-select",
            "fieldType": "text",
            "options": [
                { "label": "Vrijgave", "value": "Vrijgave" },
                { "label": "TR gewenst", "value": "TR gewenst" },
                { "label": "TA gemeten", "value": "TA gemeten" },
                { "label": "Anders", "value": "Anders" },
                { "label": "Niet van toepassing", "value": "Niet van toepassing" }
            ],
            "required": true,
            "validation": [["string"], ["required"]]
        }
    }
}