export interface IForm {
    name: string;
    data: any;
}

export enum FormType {
    None = 0,
    Prijsindicatie = 1,
    Inbedrijfstelling = 2,
    Reclamatie = 3,
    OfferteConfig = 4
}