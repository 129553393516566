import { IQuoteVM,   ICrudGetAllResponseVM,ICrudUpdateResponseVM,
    ICrudCreateResponseVM,ICrudGetOneResponseVM} from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export enum QuotesScopeEnum {
    My = "",
    Company = "company"
}

export class QuoteService {
   
    public static GetAll = (scope: QuotesScopeEnum): Promise<ICrudGetAllResponseVM<IQuoteVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/quotes?scope=${scope}`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<IQuoteVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.quote.getAll", reason));
    });
    
    public static Delete = (id: string): Promise<ICrudGetAllResponseVM<IQuoteVM>> =>
    new Promise((resolve, reject) => {
        axios.delete(`${Service.ApiUrl}/v1/quotes/${id}`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<IQuoteVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject,reason, reason));
    });

    public static Create = (quote: IQuoteVM): Promise<ICrudCreateResponseVM<IQuoteVM>> =>
    new Promise((resolve, reject) => {
        axios.post(`${Service.ApiUrl}/v1/quotes`,quote, Service.Config())
            .then((response: AxiosResponse<ICrudCreateResponseVM<IQuoteVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.quote.create", reason));
    });

    public static Update = (quote: IQuoteVM,quoteId:string): Promise<ICrudUpdateResponseVM<IQuoteVM>> =>
    new Promise((resolve, reject) => {
        axios.put(`${Service.ApiUrl}/v1/quotes/${quoteId}`,quote, Service.Config())
            .then((response: AxiosResponse<ICrudUpdateResponseVM<IQuoteVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.quote.update", reason));
    });

    public static GetOne = (quoteId:string): Promise<ICrudGetOneResponseVM<IQuoteVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/quotes/${quoteId}`, Service.Config())
            .then((response: AxiosResponse<ICrudGetOneResponseVM<IQuoteVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.quote.getOne", reason));
    }); 
    
}