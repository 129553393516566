import { IContactVM, IRoleVM } from "../viewmodels/data";

export class UserRepository {
    public static readonly DefaultUserRole: IRoleVM = {
        id: "",
        displayName: "",
        requestPriceIndication: false,
        showPriceIndicationOverview: false,
        showAllPriceIndications: false,
        showCommissioning: false,
        showCommissioningOverview: false,
        showComplaint: false,
        showComplaintOverview: false,
        showMultiZoneVerdelerConfigurator: false,
        showKnowledgeBase: false,
        showKnowledgeBaseAdvanced: false,
        showKnowledgeBaseBasic: false,
        isNathanEmployee: false,
        showQuoteSolutions: false,
        showQuotes: false,
        showBrlTool: false
      };

    private static me?: IContactVM;
    private static userRole: IRoleVM = UserRepository.DefaultUserRole;

    public static setMe = (contact: IContactVM) => {
      UserRepository.me = contact;
      UserRepository.setUserRole(contact.role || UserRepository.DefaultUserRole);
    }
    public static getMe = () => UserRepository.me;

    public static setUserRole = (userRole: IRoleVM) => UserRepository.userRole = userRole;

    public static getUserRole = () => Object.assign({}, UserRepository.userRole);

    public static clear = () => { UserRepository.setUserRole(UserRepository.DefaultUserRole) }
}