import axios, { AxiosResponse } from "axios";
import { IGetKnowledgeBaseCredentialsResponseVM } from "../viewmodels/data";
import MijnNathanService from "./MijnNathanService";
import { Service } from "./Service";

export default class KnowledgeBaseService {
    public static GetGeneralCredentials = (): Promise<IGetKnowledgeBaseCredentialsResponseVM> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/3rdparty/knowledgebase/credentials`, Service.Config())
                .then((response: AxiosResponse<IGetKnowledgeBaseCredentialsResponseVM>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.knowledgeBase.getCredentials", reason));
        });

    
    public static GetCredentials = (installerType: string): Promise<IGetKnowledgeBaseCredentialsResponseVM> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/3rdparty/knowledgebase/credentials/${installerType}`, Service.Config())
                .then((response: AxiosResponse<IGetKnowledgeBaseCredentialsResponseVM>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.knowledgeBase.getCredentials", reason));
        });
}