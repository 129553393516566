import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";
import { FormService } from "./FormService";
import { IRequestVM, IGetComplaintResponseVM, IGetComplaintsResponseVM, IUploadComplaintDocumentResponseVM } from "../viewmodels/data";
import { NavigateToAuth } from "../NavigateTo";
import { FormType } from "../viewmodels/form";
import { IFileUpload } from "../components/forms/formik/FormWrapper";

export class ComplaintService {

    public static GetMyAll = (): Promise<IRequestVM[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/complaints`, Service.Config())
                .then((response: AxiosResponse<IGetComplaintsResponseVM>) => resolve(response.data.complaints))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.complaints.getMyAll", reason));
        });

    public static Get = (complaintId: string): Promise<IRequestVM> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/complaints/${complaintId}`, Service.Config())
                .then((response: AxiosResponse<IGetComplaintResponseVM>) => resolve(response.data.complaint))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.complaints.get", reason));
        });

    public static UploadFiles = (fieldName: string, files: any[]): Promise<IUploadComplaintDocumentResponseVM> =>
        new Promise((resolve, reject) => {
            var formData = new FormData();
            files.forEach(file => formData.append("file", file));
            NavigateToAuth.EnsureAuth()
                .then(() => {
                    axios.post(`${Service.ApiUrl}/v1/complaints/0/fields/${fieldName}/documents`, formData, Service.Config("multipart/form-data"))
                    .then((response: AxiosResponse<IUploadComplaintDocumentResponseVM>) => resolve(response.data))
                    .catch(reason => reject(reason));
                });
        })

    public static submit = (values: any, formName: string, files: IFileUpload[]) => FormService.submit(values, { files: files }, "complaint", formName, FormType.Reclamatie);
}