import { IAppSettings, IAppState } from "../viewmodels/app";
import { AppService } from "../services/AppService";
import { Util } from "../Utils/Util";
import { IGetListsResponseVM, RequestStatusEnum } from "../viewmodels/data";
import { ListService } from "../services/ListServices";
import EnumUtils from "../Utils/EnumUtils";

export default class AppRepository {
    private settings?: IAppSettings;
    private lists?: IGetListsResponseVM;
    private readonly AppStateStorageKey = "app.state";

    public getSettings = () =>
        new Promise<IAppSettings>((resolve, reject) => {
            if (this.settings) {
                resolve(this.settings);
            } else {
                AppService.GetSettings()
                    .then(settings => {
                        this.settings = settings;
                        resolve(settings);
                    })
                    .catch(reason => {
                        Util.Log("Error getting settings");
                        // set default settings
                        Util.Log("Setting default settings");
                        this.settings = {
                            isDebug: false
                        };
                        Util.Log(this.settings);
                        Util.Log(reason);
                        reject(reason);
                    });
            }
        });
    
    public getLists = () => {
        const enumOrds = EnumUtils.getOrdinalValues(RequestStatusEnum);
        return enumOrds as RequestStatusEnum[];



        // new Promise<IGetListsResponseVM>((resolve, reject) => {
        //     if (this.lists) {
        //         resolve(this.lists);
        //     } else {
        //         ListService.GetAll()
        //             .then(lists => {
        //                 this.lists = lists;
        //                 resolve(lists);
        //             })
        //             .catch(reason => {
        //                 Util.Log("Error getting settings");
        //                 // set default settings
        //                 Util.Log("Setting default settings");
        //                 this.lists = {
        //                     complaintStatusList: []
        //                 };
        //                 Util.Log(this.lists);
        //                 Util.Log(reason);
        //                 reject(reason);
        //             });
        //     }
        // });
    }

    public getState = (): IAppState | null => {
        var stateJSON = localStorage.getItem(this.AppStateStorageKey);
        return stateJSON ? JSON.parse(stateJSON) as IAppState : null;
    }

    public setState = (path: string, data?: any) => {
        const state: IAppState = {
            path: path,
            data: data
        };
        const stateJSON = JSON.stringify(state);
        localStorage.setItem(this.AppStateStorageKey, stateJSON);
    }

    public clearState = () => localStorage.removeItem(this.AppStateStorageKey);
}