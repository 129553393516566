import { ISolutionVM,    ICrudGetAllResponseVM,ICrudUpdateResponseVM,
    ICrudCreateResponseVM,ICrudGetOneResponseVM} from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export class SolutionService {
   
    public static GetAll = (): Promise<ICrudGetAllResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/solutions`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.getAll", reason));
    });

    public static GetAllIncludeChildren = (): Promise<ICrudGetAllResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/solutions?includeChildren=true`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.getAll", reason));
    });

    public static GetAll_Related = (): Promise<ICrudGetAllResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/solutions?includeChildren=false&onlyRelated=true`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.getAll", reason));
    });

    public static Delete = (id: string): Promise<ICrudGetAllResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.delete(`${Service.ApiUrl}/v1/solutions/${id}`, Service.Config())
            .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject,reason, reason));
    });

    public static Create = (solution: ISolutionVM): Promise<ICrudCreateResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.post(`${Service.ApiUrl}/v1/solutions`,solution, Service.Config())
            .then((response: AxiosResponse<ICrudCreateResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.create", reason));
    });

    public static Update = (solution: ISolutionVM,solutionId:string): Promise<ICrudUpdateResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.put(`${Service.ApiUrl}/v1/solutions/${solutionId}`,solution, Service.Config())
            .then((response: AxiosResponse<ICrudUpdateResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.update", reason));
    });

    public static GetOne = (solutionId:string): Promise<ICrudGetOneResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}`, Service.Config())
            .then((response: AxiosResponse<ICrudGetOneResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.getOne", reason));
    }); 
    
    public static GetOneIncludeChildren = (solutionId:string): Promise<ICrudGetOneResponseVM<ISolutionVM>> =>
    new Promise((resolve, reject) => {
        axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}?includeChildren=true`, Service.Config())
            .then((response: AxiosResponse<ICrudGetOneResponseVM<ISolutionVM>>) => resolve(response.data))
            .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.getOne", reason));
    }); 
}