import { Selection, CommandBar, ContextualMenu, DetailsList, DetailsListBase, DetailsListLayoutMode, IColumn, ICommandBarItemProps, IContextualMenuItem, IDetailsList, IHoverCardState, SearchBox, Sticky, SelectionMode, ShimmeredDetailsList, mergeStyles, IObjectWithKey, Icon, getAllSelectedOptions, IDragDropEvents, IDragDropContext, getTheme, Link, IColumnReorderOptions, DatePicker, Checkbox, TextField, DayOfWeek, DatePickerBase, ITextField, IDatePicker, ICheckbox, CommandBarButton, IIconProps, Stack, Modal, IconButton, IButtonStyles, IDetailsHeaderProps, IRenderFunction, IDetailsColumnRenderTooltipProps, TooltipHost, ConstrainMode } from "@fluentui/react";
import React, { CSSProperties } from "react";
import LS from "../Utils/LS";
import ObjUtils from "../Utils/ObjUtils";
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import HxDetailListColumn from "./HxDetailListColumn";
import ArrayUtils from "../Utils/ArrayUtils";
import NumUtils from "../Utils/NumUtils";
import EnumUtils from "../Utils/EnumUtils";
import { CopyToClipboardModal } from "./CopyToClipboardModal";
import Debounce from "../Utils/Debounce";
import "./HxDetailList.scss"



interface IHxDetailListProps<T extends HxDetailListItem> {
    onActiveItemChanged?: (item: T, index?: number) => void;
    onSelectionChanged?: (selectedItems: T[]) => void;
    contextMenuItems?: IContextualMenuItem[];
    onContextMenuItemClick?: () => void;
    onContextMenuDismiss?: () => void;
    items?: T[];
    selectedItems?: T[];
    columns: IColumn[];
    uniqueName: string;
    compact?: boolean;
    layoutMode?: DetailsListLayoutMode;
    commandBarItems?: ICommandBarItemProps[];
    getItemIdentifier?: (item: T, index?: number) => string;
    selectionMode?: SelectionMode;
    noItemsFoundMessage?: string;
    isColumnReorderEnabled?: boolean;
    isColumnSelectionEnabled?: boolean;
}


interface IHxDetailListState<T extends HxDetailListItem> {
    visibleColumns: IColumn[];
    showContextualMenu: boolean;
    contextMenuTarget?: Element;
    displayItems?: T[];
    columnSettings: ColumnSetting[];
    searchText?: string;
    selectedItems?: T[];
    showModal?: true | false;
}

class ColumnSetting {
    columnKey: string;
    columnIndex: number;
    isVisible: boolean;
    currentWidth: number;
    constructor(column: IColumn, columnIndex: number) {
        // console.log("ColumnSetting.constructor", column, columnIndex);
        this.columnKey = column.key;
        this.columnIndex = columnIndex;
        this.isVisible = true;
        this.currentWidth = column.currentWidth || column.minWidth;
    }
}


export interface HxDetailListItem {
    SearchTextUpper: string;
    id: string;
    components?: { [columnIndex: number]: ITextField | ICheckbox | IDatePicker }
    componentFocus?: (tabIndex: number) => void;
    readonly?: boolean;
}

export default class HxDetailList<T extends HxDetailListItem> extends React.Component<IHxDetailListProps<T>, IHxDetailListState<T>> {

    constructor(props: IHxDetailListProps<T>) {
        super(props);

        const columns = this.props.columns.slice();
        const baseLSname = "HxDetailList_" + this.props.uniqueName;
        const searchText = LS.GetPageSetting(baseLSname, "searchText");

        this.attachColumnClickHandlers(columns);
        const displayItems = this.calcFilteredAndSortedItems(columns, searchText, this.props.items)

        let columnSettingsFromLS = JSON.parse(LS.GetPageSetting(baseLSname, "columnSettings") ?? "[]") as ColumnSetting[];

        this.state = {
            visibleColumns: [], //columns,
            showContextualMenu: false,
            displayItems: displayItems,
            columnSettings: columnSettingsFromLS,
            searchText: searchText,
            selectedItems: [],
            showModal: false,
        }
    }

    componentDidMount = async () => {
        await this.recalcColumns(undefined, true);
    }

    private _getColumnReorderOptions(): IColumnReorderOptions {
        return {
            handleColumnReorder: this._handleColumnReorder,
        };
    }

    private _handleColumnReorder = async (draggedIndex: number, targetIndex: number) => {
        const { visibleColumns, columnSettings } = this.state;

        const draggedItems = visibleColumns[draggedIndex];
        const newColumns: IColumn[] = [...visibleColumns];
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);


        let otherCounter = newColumns.length;
        let newColumnSettings: ColumnSetting[] = [];
        for (let cs of columnSettings) {
            const foundColumn = newColumns.find(c => c.key == cs.columnKey);
            if (foundColumn) {
                // Index same as the array
                cs.columnIndex = newColumns.indexOf(foundColumn)
            } else {
                // Index after the array
                cs.columnIndex = otherCounter;
                otherCounter++;
            }
            newColumnSettings.push(cs);
        }
        newColumnSettings.sort((cs1, cs2) => cs1.columnIndex - cs2.columnIndex);


        await this.SetState2({
            visibleColumns: newColumns,
            columnSettings: newColumnSettings
        });

        await this.saveStateToLocalStorage();
    };

    saveStateToLocalStorage = () => {
        const { columnSettings } = this.state;
        const baseLSname = "HxDetailList_" + this.props.uniqueName;
        LS.SetPageSetting(baseLSname, "columnSettings", JSON.stringify(columnSettings));
    }


    SetState2 = <K extends keyof IHxDetailListState<T>>(ob: Pick<IHxDetailListState<T>, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });



    componentDidUpdate = async (oldProps: IHxDetailListProps<T>) => {
        const { columnSettings, visibleColumns } = this.state;
        const { columns } = this.props;

        let stateUpdate = {} as IHxDetailListState<T>;
        let needsUpdate = false;
        let useSearchText = this.state.searchText;

        // Column changes
        if (ObjUtils.shallowArrayEqual(oldProps.columns, columns) == false) {

            this.attachColumnClickHandlers(columns);

            const baseLSname = "HxDetailList_" + this.props.uniqueName;
            let columnSettingsFromLS = JSON.parse(LS.GetPageSetting(baseLSname, "columnSettings") ?? "[]") as ColumnSetting[];
            await this.recalcColumns(columnSettingsFromLS, true);

            needsUpdate = true;
        }

        // Data changes
        if (ObjUtils.shallowArrayEqual(oldProps.items, this.props.items) == false) {
            needsUpdate = true
        }

        if (needsUpdate) {
            stateUpdate.displayItems = this.calcFilteredAndSortedItems(this.state.visibleColumns, useSearchText)
            this.setState(stateUpdate);
        }

    }

    recalcColumns = async (justUpdatedColumnSettings?: ColumnSetting[], initialLoad?: boolean) => {
        const { columnSettings } = this.state
        const { columns, isColumnSelectionEnabled } = this.props;

        if (isColumnSelectionEnabled == false) {
            let columnSettings: ColumnSetting[] = [];
            for (let i = 0; i < columns.length; i++) {
                columnSettings.push(new ColumnSetting(columns[i], i));
            }
            await this.SetState2({
                visibleColumns: columns,
                columnSettings: columnSettings
            });

            return;
        }

        if (columns.length == 0) return;

        // Filter out any columns that are not in the props
        let columnSettingsUpdate = (justUpdatedColumnSettings || columnSettings).filter(cs => columns.find(c => c.key == cs.columnKey));

        // First sort on the original index
        let visibleColumnSettings = columnSettingsUpdate.filter(cs => cs.isVisible).sort((cs1, cs2) => cs1.columnIndex - cs2.columnIndex);
        let hiddenColumnSettings = columnSettingsUpdate.filter(cs => !cs.isVisible).sort((cs1, cs2) => cs1.columnIndex - cs2.columnIndex);

        // Determine the new index of the visible columns. 
        let colIndex = 0;
        visibleColumnSettings.forEach(cs => {
            cs.columnIndex = colIndex;
            colIndex++;
        })

        // Determine the new index of the newly added columns.
        const newColumnSettings = columns.filter(c => !columnSettingsUpdate.find(cs => cs.columnKey == c.key)).map(c => {
            const newColumnSetting = new ColumnSetting(c, colIndex);
            colIndex++;
            return newColumnSetting;
        });

        // Determine the new index of the hidden columns and put 
        hiddenColumnSettings.forEach(cs => {
            cs.columnIndex = colIndex;
            colIndex++;
        })

        // First: visible, second: newColumns, third: hiddencolumns
        columnSettingsUpdate = [...visibleColumnSettings, ...newColumnSettings, ...hiddenColumnSettings];

        // Get the columns that are visible and in de order of the columnSettingsUpdate
        const columnUdateArrArr = columnSettingsUpdate.filter(cs => cs.isVisible).map(cs => {
            let columnsWithSameKey = columns.filter(c => c.key == cs.columnKey);
            if (initialLoad) {
                columnsWithSameKey.forEach(c => {
                    c.currentWidth = cs.currentWidth;
                    c.minWidth = cs.currentWidth;
                    c.maxWidth = cs.currentWidth;
                });
            } else {
                columnsWithSameKey.forEach(c => {
                    c.currentWidth = cs.currentWidth;
                    c.minWidth = cs.currentWidth - 10;
                    c.maxWidth = cs.currentWidth;
                });
            }


            return columnsWithSameKey;
        });
        let columnUpdateArr = ArrayUtils.FlattenArray(columnUdateArrArr);
        columnUpdateArr.push({
            key: "END_COLUMN",
            minWidth: 10,
            name: " "
        })

        await this.SetState2({
            columnSettings: columnSettingsUpdate,
            visibleColumns: columnUpdateArr
        });
    }


    static getColumn = (fieldName: string, displayName: string, minWidth: number, columnDataType?: "date" | "bool" | "text", isResizable?: boolean) => new HxDetailListColumn(displayName, fieldName, minWidth, columnDataType, isResizable);



    updateVisibleColumns2 = async (item?: IDropdownOption) => {
        const { columnSettings } = this.state;
        const columnKey = item?.key;
        if (item && columnKey) {
            const columnSettingsUpdate = columnSettings.map(cs => {
                if (cs.columnKey == columnKey) {
                    cs.isVisible = !!item.selected;
                }
                return cs;
            })
            // await this.SetState2({ columnSettings: columnSettingsUpdate });
            await this.recalcColumns(columnSettingsUpdate);
            await this.saveStateToLocalStorage();
        }
    }

    updateColumnSize = async (column?: IColumn, newWidth?: number) => {
        if (column && newWidth) {
            let { columnSettings } = this.state;

            // console.log("HxDetailList.updateColumnSize", newWidth, column);

            const columnSettingsUpdate = columnSettings.map(cs => {
                if (cs.columnKey == column.key) {
                    cs.currentWidth = newWidth;
                }
                return cs;
            })

            // console.log(newWidth);
            // console.log(columnSettingsUpdate[1].currentWidth);

            // await this.SetState2({ columnSettings: columnSettingsUpdate });
            await this.recalcColumns(columnSettingsUpdate);
            await this.saveStateToLocalStorage();
        }
    }

    setColumnSetings() {

        let visibleColumnSettings: { [columnName: string]: boolean } = {};

        this.props.columns.forEach(c => visibleColumnSettings[c.name] = false);

        return visibleColumnSettings
    }


    attachColumnClickHandlers = (columns: IColumn[]) => {
        columns.forEach(c => c.onColumnClick = this._onColumnClick);

        const baseLSname = "HxDetailList_" + this.props.uniqueName;

        const columnSortName = LS.GetPageSetting(baseLSname, "ColumnSortName")
        const columnSortDescending = LS.GetPageSettingBool(baseLSname, "ColumnSortDescending")

        if (columnSortName && columnSortDescending !== undefined) {
            columns.forEach(c => {
                if (c.key == columnSortName) {
                    c.isSorted = true;
                    c.isSortedDescending = columnSortDescending;
                }
            })
        }
    }




    _onActiveItemChanged_lastTabIndex = -1;
    _onActiveItemChanged_lastId: string | undefined;
    _onActiveItemChanged = (item?: HxDetailListItem, index?: number, ev?: React.FocusEvent<HTMLElement>) => {

        if (item) {
            item.componentFocus = (tabIndex) => {
                this._onActiveItemChanged_lastTabIndex = tabIndex;
            }
        }


        const differentRow = (this._onActiveItemChanged_lastId != item?.id);

        if (differentRow && item?.components) {

            let selectKey = this._onActiveItemChanged_lastTabIndex;
            if (selectKey < 0) {
                const allKeys = Object.keys(item.components).map(k => +k).sort();
                if (allKeys.length > 0) {
                    selectKey = allKeys[0];
                }
            }

            if (selectKey >= 0) {
                item.components[selectKey].focus();
                this._onActiveItemChanged_lastTabIndex = selectKey;
            }

        }

        this._onActiveItemChanged_lastId = item?.id;



        if (this.props.onActiveItemChanged) {
            this.props.onActiveItemChanged(item as T, index);
        }
    }

    _onItemContextMenu = (item?: any, index?: number | undefined, ev?: Event | undefined) => {
        if (this.props.contextMenuItems && ev && ev.target) {
            let target = ev.target as Element;
            this.setState({
                showContextualMenu: true,
                contextMenuTarget: target
            });
        }
    }


    _onContextMenuItemClick = () => {
        if (this.props.onContextMenuItemClick) {
            this.props.onContextMenuItemClick();
        }
        this.SetState2({ showContextualMenu: false, contextMenuTarget: undefined });
    }

    _onContextMenuDismiss = () => {
        if (this.props.onContextMenuDismiss) {
            this.props.onContextMenuDismiss();
        }
        this.SetState2({ showContextualMenu: false, contextMenuTarget: undefined });
    }


    calcFilteredAndSortedItems = (columnSettings: IColumn[], searchText?: string, sourceItems?: T[]): T[] | undefined => {
        const { items } = this.props;
        if (!items && !sourceItems) return undefined;

        let results = (sourceItems || items)!.slice();

        // Search text
        if (searchText) {
            const searchValues = searchText.toUpperCase().split(" ");
            console.log(searchValues);
            searchValues.forEach(sv => {
                if (sv) {
                    results = results.filter(t => t.SearchTextUpper.indexOf(sv!) > -1)
                }
            });
        }

        // Sort
        const comparer = (i1: any, i2: any, ascending: boolean): number => {
            if (i1 == undefined && i2 == undefined) return 0;
            if (!i1 && !i2) return 0;
            if (!i1) return ascending ? -1 : 1;
            if (!i2) return ascending ? 1 : -1;
            return (ascending ? i1 > i2 : i1 < i2) ? 1 : -1;
        }
        columnSettings.forEach(cs => {

            if (cs.isSorted) {
                const key = cs.key as keyof T;
                results = results.sort((t1, t2) => comparer(t1[key], t2[key], !cs.isSortedDescending));
            }
        })

        return results;
    }


    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { visibleColumns } = this.state;
        const baseLSname = "HxDetailList_" + this.props.uniqueName;
        const newColumns: IColumn[] = visibleColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                if (currColumn.isSortedDescending === undefined || currColumn.isSorted === false) {
                    currColumn.isSortedDescending = false;
                } else if (currColumn.isSortedDescending == false) {
                    currColumn.isSortedDescending = true;
                } else {
                    currColumn.isSortedDescending = undefined;
                }
                currColumn.isSorted = (currColumn.isSortedDescending !== undefined);

                LS.SetPageSetting(baseLSname, "ColumnSortName", currColumn.isSorted ? currColumn.key : undefined)
                LS.SetPageSettingBool(baseLSname, "ColumnSortDescending", currColumn.isSortedDescending)

            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newDisplayItems = this.calcFilteredAndSortedItems(newColumns, this.state.searchText);

        this.setState({
            visibleColumns: newColumns,
            displayItems: newDisplayItems,
        });
    };



    private _onSearchChangeText = async (ev: React.ChangeEvent<HTMLInputElement> | undefined, text: string | undefined) => {
        const { visibleColumns } = this.state;
        const baseLSname = "HxDetailList_" + this.props.uniqueName;
        await this.SetState2({
            searchText: text
        })
        Debounce.do(baseLSname + "_onSearchChangeText", () => {
            const { searchText } = this.state;
            LS.SetPageSetting(baseLSname, "searchText", searchText)
            const newDisplayItems = this.calcFilteredAndSortedItems(visibleColumns, searchText);
            this.setState({
                displayItems: newDisplayItems
            });
        }, 500);
    };

    _selection = new Selection({
        onSelectionChanged: () => {
            const selection = this._selection.getSelection() as T[];
            this.setState({ selectedItems: selection }, () => {
                if (this.props.onSelectionChanged) {
                    this.props.onSelectionChanged(selection);
                }
            });
        }
    });

    public clearSelection = async (): Promise<void> => {
        this._selection.setAllSelected(false);
        await this.SetState2({ selectedItems: [] });
    }

    // public setSelection = async (selectedItems: T[]) => {
    //     selectedItems.forEach(i => {
    //         this._selection.setKeySelected(i.id, true,false);
    //     })
    //     console.log(selectedItems, this._selection);
    // }

    getDropDownOptions = (): IDropdownOption[] => {
        const { columnSettings } = this.state;
        const { columns } = this.props;

        var columnsPerKey = ArrayUtils.ToLookup(columns, c => c.key);
        // var columnSettingsPerKey = ArrayUtils.ToLookup(columnSettings, cs => cs.columnKey);

        // const columnSettingsSorted = columnSettings.sort((cs1, cs2) => cs1.columnIndex - cs2.columnIndex)
        const options = columnSettings.map(cs => {
            const col = columnsPerKey[cs.columnKey];
            const option: IDropdownOption = {
                key: cs.columnKey,
                text: col?.name || ("unknown" + cs.columnKey),
                selected: cs.isVisible
            }
            return option;
        });
        return options;

    }
    onModalCloseClick = async (): Promise<void> => {
        await this.SetState2({ showModal: false })
    }
    openModal = () => {
        this.SetState2({
            showModal: true,
        });
    }


    render() {

        const { compact, layoutMode, commandBarItems, getItemIdentifier, selectionMode, contextMenuItems, noItemsFoundMessage, isColumnReorderEnabled, isColumnSelectionEnabled } = this.props;
        const { showModal, visibleColumns, displayItems, showContextualMenu, contextMenuTarget, columnSettings } = this.state;


        // a better way of doing this
        // if there is no data stored here this dosen't work
        var keysAsString: string[] = columnSettings.filter(cs => cs.isVisible).map(cs => cs.columnKey);

        const getKey = getItemIdentifier ? getItemIdentifier : (item: any) => item?.id; // Assume that we can have an id property


        return <>
            <Sticky stickyBackgroundColor="#fff">
                <div className="cssSearchBoxDiv">
                    <div className={isColumnSelectionEnabled ? "cssCopyBox_withColumnSelection" : "cssCopyBox_withOutColumnSelection"}>
                        {/* <Icon iconName="Copy" onClick={this.openModal} /> */}
                        <CommandBarButton iconProps={{ iconName: 'Copy' }} onClick={this.openModal} text="Copy table" />
                    </div>
                    {isColumnSelectionEnabled && <div className="cssFieldsBox">
                        <Dropdown
                            placeholder="Select options"
                            //defaultSelectedKeys={this.state.seletedKeys}
                            selectedKeys={keysAsString}
                            onChange={(ev, selectedKey) => this.updateVisibleColumns2(selectedKey)}
                            multiSelect
                            options={this.getDropDownOptions()}
                            styles={{ dropdown: { width: 250 } }}
                        />
                    </div>}

                    <div className="cssSearchBox">
                        <Stack horizontal>
                            <SearchBox placeholder="Search" value={this.state.searchText} onChange={this._onSearchChangeText} />
                        </Stack>
                    </div>

                    {commandBarItems && <CommandBar items={commandBarItems} />}




                </div>
                {/* {columns && columns.map(c => <Checkbox label="column 1" onChange={(ev, chk)=>this.updateVisibleColumns(c, chk)}/>)} */}

            </Sticky>
            <ShimmeredDetailsList
                compact={compact}
                onActiveItemChanged={this._onActiveItemChanged}
                // onRenderDetailsHeader={this.renderDetailsHeader}
                onItemContextMenu={this._onItemContextMenu}
                items={displayItems || []}
                enableShimmer={!visibleColumns || !displayItems}
                constrainMode={ConstrainMode.unconstrained}
                columns={visibleColumns}
                onColumnResize={isColumnReorderEnabled ? this.updateColumnSize : undefined}
                columnReorderOptions={isColumnReorderEnabled ? this._getColumnReorderOptions() : undefined}
                layoutMode={layoutMode || DetailsListLayoutMode.justified}
                selection={this._selection}
                getKey={getKey}
                setKey={'id'}
                selectionMode={selectionMode}
            />

            <CopyToClipboardModal isOpen={showModal || false} columns={visibleColumns} listItems={displayItems!} onModalClose={this.onModalCloseClick} />

            {!!displayItems && displayItems.length == 0 && <i className="cssNoItemsInList">{noItemsFoundMessage ? noItemsFoundMessage : "No items in list"}</i>}

            {contextMenuItems && contextMenuTarget && <ContextualMenu
                items={contextMenuItems}
                hidden={!showContextualMenu}
                target={contextMenuTarget}
                onItemClick={this._onContextMenuItemClick}
                onDismiss={this._onContextMenuDismiss}
            />}
        </>
    }

    renderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: ((props?: IDetailsHeaderProps) => JSX.Element | null) | undefined) => {
        if (defaultRender && props) {

            const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
                <TooltipHost {...tooltipHostProps} />
              );

            return defaultRender({
                ...props,
                onRenderColumnHeaderTooltip,
            });
        }
        return null;
    }

  

}