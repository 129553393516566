import { ca } from "date-fns/locale";
import { PositionTypeEnum, SolutionTypeEnum, TypeOfHouseEnum, UnderfloorHeatingTypeEnum } from "../viewmodels/data";
import EnumUtils from "./EnumUtils";

export class AppUtils {


    static GetUnderfloorHeatingTypeName = (type: UnderfloorHeatingTypeEnum) => {
        type = EnumUtils.getOrdinal(UnderfloorHeatingTypeEnum, type);
        switch (type) {
            case UnderfloorHeatingTypeEnum.UponorBouwnettenVloerverwarmingssysteem: return "Uponor Bouwnetten Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorMinitecVloerverwarmingssysteem: return "Uponor Minitec Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorTackerVloerverwarmingssysteem: return "Uponor Tacker Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorKlettVloerverwarmingssysteem: return "Uponor Klett Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorKoelplafond: return "Uponor Koelplafond Systeem";
            case UnderfloorHeatingTypeEnum.UponorLeidingsysteemVloerverwarming: return "Uponor Leidingsysteem Vloerverwarming";
            case UnderfloorHeatingTypeEnum.UponorNubosVloerverwarmingssysteem: return "Uponor Nubos Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorSiccusVloerverwarmingssysteem: return "Uponor Siccus Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.UponorTectoVloerverwarmingssysteem: return "Uponor Tecto Vloerverwarmingssysteem";
            case UnderfloorHeatingTypeEnum.MeerdereUponorVloerverwarmingssystemen: return "Meerdere Uponor Vloerverwarmingssystemen";
            default: return "";
        }
    }

    static GetPositionTypeName = (positionType: PositionTypeEnum) => {
        const positionTypeOrd: PositionTypeEnum = EnumUtils.getOrdinal(PositionTypeEnum, positionType);
        switch (positionTypeOrd) {
            case PositionTypeEnum.MontageVloerverwarming: return "Montage Vloerverwarming";
            case PositionTypeEnum.MontageVloerverwarmingSmeervloer: return "Montage Vloerverwarming Smeervloer";
            case PositionTypeEnum.Verdeler: return "Verdeler";
            case PositionTypeEnum.Vloerverwarmingssysteem: return "Vloerverwarmingssysteem";
            default: return "";
        }

    }

    static GetSolutionTypeName = (solutionType: SolutionTypeEnum) => {
        solutionType = EnumUtils.getOrdinal(SolutionTypeEnum, solutionType);
        switch (solutionType) {
            case SolutionTypeEnum.HeatPump: return "Warmtepomp";
            case SolutionTypeEnum.Regulator: return "Naregeling";
            case SolutionTypeEnum.Source: return "Bron";
            case SolutionTypeEnum.UnderfloorHeating: return "Vloerverwarming";
        }
    }

    static GetHouseTypeName = (houseType: TypeOfHouseEnum) => {
        houseType = EnumUtils.getOrdinal(TypeOfHouseEnum, houseType);
        switch (houseType) {
            case TypeOfHouseEnum.newBuilding: return "Nieuwbouw";
            case TypeOfHouseEnum.existingTypeX: return "Bestaande bouw (2009-2015)";
            case TypeOfHouseEnum.existingTypeY: return "Bestaande bouw (1999-2009)";
            case TypeOfHouseEnum.existingTypeZ: return "Bestaande bouw (1990-1999)";
            default: return "";
        }
    }

}