import FormikDatePicker from "../../components/forms/formik/FormikDatePicker";
import FormikFileUploader from "../../components/forms/formik/FormikFileUploader";
import FormikUtil from "../../components/forms/formik/FormikUtil";
import FormikLink from "../../components/forms/formik/FormikLink";

export default {
  id: "rec3",
  label: "Reclamatie 3",
  type: "container",
  renderer: "form",
  elements: {
    leveranciercontainer: {
      type: "container",
      renderer: "div",
      elements: {
        leveranciersinformatie: {
          name: "leveranciersinformatie",
          label: "Leveranciersinformatie",
          type: "field",
          renderer: "react-select",
          required: true,
          validation: [["string"], ["required"]],
          options: [
            { label: "Alpha InnoTec", value: "AIT" },
            { label: "Uponor", value: "Uponor" },
            { label: "Delta", value: "Delta" },
            { label: "Komfort", value: "Komfort" },
            { label: "Metro Therm", value: "Metro Therm" },
            { label: "RHOSS", value: "RHOSS" },
            { label: "Biawar", value: "Biawar" }
          ]
        },
        artikelnummer_AI: {
          name: "artikelnummer_AI",
          label: "Begint het artikelnummer met AI (onderdeel warmtepomp)?",
          type: "field",
          renderer: "radio",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: ["is", "leveranciersinformatie", "AIT"],
          options: [
            { title: "Ja", value: "Ja" },
            { title: "Nee", value: "Nee" }
          ]
        },
        typewarmtepomp: {
          name: "typewarmtepomp",
          label: "Type warmtepomp (Bijv. WZS/WZSV of LWD)",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: [
            "and",
            ["is", "leveranciersinformatie", "AIT"],
            ["is", "artikelnummer_AI", "Ja"]
          ]
        },
        serienummer: {
          name: "serienummer",
          label: "Serienummer warmtepomp",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: [
            "and",
            ["is", "leveranciersinformatie", "AIT"],
            ["is", "artikelnummer_AI", "Ja"]
          ]
        },
        inbedrijfsnameop: {
          name: "inbedrijfsnameop",
          label: "Inbedrijfsname op",
          type: "field",
          renderer: FormikDatePicker,
          showWhen: [
            "and",
            ["is", "leveranciersinformatie", "AIT"],
            ["is", "artikelnummer_AI", "Ja"]
          ]
        },
        inbedrijfsnamedoor: {
          name: "inbedrijfsnamedoor",
          label: "Inbedrijfsname door",
          type: "field",
          renderer: "text",
          showWhen: [
            "and",
            ["is", "leveranciersinformatie", "AIT"],
            ["is", "artikelnummer_AI", "Ja"]
          ]
        },
        storingscode: {
          name: "storingscode",
          label: "Storingscode",
          type: "field",
          renderer: "text",
          showWhen: [
            "and",
            ["is", "leveranciersinformatie", "AIT"],
            ["is", "artikelnummer_AI", "Ja"]
          ]
        },
        gereedschap_uponor: {
          type: "field",
          renderer: FormikLink,
          urlPreText:
            "Gereedschap kan niet worden verwerkt in de reclamatietool. Neem contact op met Customer Support via cs@nathan.nl of 026-2051111.",
          showWhen: ["is", "leveranciersinformatie", "Uponor"]
        },
        uponorcontainer: {
          type: "container",
          renderer: "div",
          elements: {
            uponor: {
              name: "uponor",
              label: "",
              type: "field",
              renderer: "radio",
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "leveranciersinformatie", "Uponor"],
              options: [
                { title: "Leiding / buis", value: "Leiding" },
                { title: "Overig", value: "Overig" }
              ]
            },
            leidingbuiscontainer: {
              type: "container",
              renderer: "div",
              elements: {
                leiding_buis: {
                  name: "leiding_buis",
                  label: "",
                  type: "field",
                  renderer: "radio",
                  showWhen: [
                    "and",
                    ["is", "uponor", "Leiding"],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ],
                  options: [
                    { title: "Sanitair", value: "Sanitair" },
                    { title: "Verwarming", value: "Verwarming" }
                  ]
                },
                schadedag: {
                  name: "schadedag",
                  label: "Datum schade",
                  type: "field",
                  renderer: FormikDatePicker,
                  showWhen: [
                    "and",
                    ["is", "uponor", "Leiding"],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                geschatteschade: {
                  name: "geschatteschade",
                  label: "Geschatte schade in €",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "uponor", "Leiding"],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                druktestrapport: {
                  name: "druktestrapport",
                  label: "Druktestrapport beschikbaar?",
                  type: "field",
                  renderer: "radio",
                  options: [
                    { title: "Ja", value: "Ja" },
                    { title: "Nee", value: "Nee" }
                  ],
                  showWhen: [
                    "and",
                    ["is", "uponor", "Leiding"],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                druktestrapport_upload: {
                  name: "druktestrapport_upload",
                  label: "Druktestrapport uploaden",
                  type: "field",
                  required: false,
                  renderer: FormikFileUploader,
                  options: {
                    accept: [".pdf", ".CSV"],
                    multiple: false,
                    onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
                      FormikUtil.addFiles(
                        formik,
                        "druktestrapport_upload",
                        acceptedFiles
                      )
                  },
                  showWhen: [
                    "and",
                    ["is", "druktestrapport", "Ja"],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                monsteropgestuurd: {
                  name: "monsteropgestuurd",
                  label: "Monster wordt toegestuurd naar Nathan?",
                  type: "field",
                  renderer: "radio",
                  options: [
                    { title: "Ja", value: "Ja" },
                    { title: "Nee", value: "Nee" }
                  ],
                  showWhen: [
                    "and",
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]],
                    [
                      "or",
                      ["is", "uponor", "Leiding"],
                      ["is", "uponor", "Overig"]
                    ]
                  ]
                },
                koudwater: {
                  name: "koudwater",
                  label: "Koudwater in °C",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "leiding_buis", "Sanitair"],
                    ["not", ["is", "uponor", "Overig"]],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                warmwater: {
                  name: "warmwater",
                  label: "Warmwater in °C",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "leiding_buis", "Sanitair"],
                    ["not", ["is", "uponor", "Overig"]],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                circulatie: {
                  name: "circulatie",
                  label: "Circulatie in °C",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "leiding_buis", "Sanitair"],
                    ["not", ["is", "uponor", "Overig"]],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                vloerverwarming: {
                  name: "vloerverwarming",
                  label: "Vloerverwarming in °C",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "leiding_buis", "Verwarming"],
                    ["not", ["is", "uponor", "Overig"]],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                radiatoraansluiting: {
                  name: "radiatoraansluiting",
                  label: "Radiator aansluiting in °C",
                  type: "field",
                  renderer: "text",
                  showWhen: [
                    "and",
                    ["is", "leiding_buis", "Verwarming"],
                    ["not", ["is", "uponor", "Overig"]],
                    ["not", ["is", "leveranciersinformatie", "AIT"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "Metro Therm"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]]
                  ]
                },
                verzoek_metrotherm: {
                  type: "field",
                  renderer: FormikLink,
                  urlPreText:
                    "Wij willen je verzoeken om het MetroTherm product waarvoor je deze reclamatie indient, tot nadere orde nog niet op te sturen. We moeten de reclamatie eerst inhoudelijk beoordelen om te weten wat de logische vervolgstap is. Wij nemen contact met je op wanneer het MetroTherm product opgestuurd moet worden.",
                  showWhen: ["is", "leveranciersinformatie", "Metro Therm"]
                },
                metro_delta_warmtepomp: {
                  name: "metro_delta_warmtepomp",
                  label: "Betreft dit een Metro-Delta warmtepomp?",
                  type: "field",
                  renderer: "radio",
                  required: true,
                  validation: [["string"], ["required"]],
                  showWhen: ["is", "leveranciersinformatie", "Metro Therm"],
                  options: [
                    { title: "Ja", value: "Ja" },
                    { title: "Nee", value: "Nee" }
                  ]
                },

                IBN_Upload: {
                  name: "IBN_Upload",
                  label: "Inbedrijfstellingsformulier uploaden",
                  type: "field",
                  showWhen: [
                    "and",
                    ["not", ["is", "leveranciersinformatie", "Uponor"]],
                    ["not", ["is", "leveranciersinformatie", "Delta"]],
                    ["not", ["is", "leveranciersinformatie", "Komfort"]],
                    ["not", ["is", "leveranciersinformatie", "RHOSS"]],
                    ["not", ["is", "leveranciersinformatie", "Biawar"]],
                    [
                      "or",
                      ["is", "artikelnummer_AI", "Ja"],
                      ["is", "metro_delta_warmtepomp", "Ja"]
                    ]
                  ],
                  required: true,
                  validation: [["string"], ["required"]],
                  renderer: FormikFileUploader,
                  options: {
                    accept: [".CSV", ".pdf"],
                    multiple: false,
                    onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
                      FormikUtil.addFiles(
                        formik,
                        "IBN_Upload",
                        acceptedFiles
                      )
                  }
                },
                typeplaatje_Upload: {
                  name: "typeplaatje_upload",
                  label: "Foto typeplaatje van het toestel",
                  type: "field",
                  showWhen: ["is", "leveranciersinformatie", "Metro Therm"],
                  required: true,
                  renderer: FormikFileUploader,
                  options: {
                    accept: [".JPG", ".PDF", ".PNG", ".JPEG"],
                    multiple: true,
                    onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
                      FormikUtil.addFiles(
                        formik,
                        "typeplaatje_upload",
                        acceptedFiles
                      )
                  }
                },
                onderdeel_Upload: {
                  name: "onderdeel_upload",
                  label: "Foto vervangen onderdeel",
                  type: "field",
                  showWhen: ["is", "leveranciersinformatie", "Metro Therm"],
                  required: true,
                  renderer: FormikFileUploader,
                  options: {
                    accept: [".JPG", ".PDF", ".PNG", ".JPEG"],
                    multiple: true,
                    onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
                      FormikUtil.addFiles(
                        formik,
                        "onderdeel_upload",
                        acceptedFiles
                      )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};