import React from "react";
import { FormikProps } from "formik";

import FormikUtil from "../components/forms/formik/FormikUtil";

import { InbedrijfstellingService } from "../services/InbedrijfstellingService";
import AForm from "../forms/AForm";
import { IAppState } from "../viewmodels/app";
import { WrappedComponentProps, FormattedMessage, injectIntl } from "react-intl";

import IbnFormCsv from "../forms/inbedrijfstelling/InbedrijfstellingCsv.json";
import IbnForm1Def from "../forms/inbedrijfstelling/Inbedrijfstelling1";
import IbnForm2Def from "../forms/inbedrijfstelling/Inbedrijfstelling2";
import IbnForm3Def from "../forms/inbedrijfstelling/Inbedrijfstelling3.json";
import IbnForm4Def from "../forms/inbedrijfstelling/Inbedrijfstelling4.json";
import IbnForm5Def from "../forms/inbedrijfstelling/Inbedrijfstelling5.json";
import IbnForm6Def from "../forms/inbedrijfstelling/Inbedrijfstelling6";
import IbnForm7Def from "../forms/inbedrijfstelling/Inbedrijfstelling7";

import $ from "jquery";
import { Spinner, Row, Col, Button } from "reactstrap";

import "./InbedrijfstellingForm.scss";

// Handlebars
import Handlebars from "handlebars";
import { FaCheck } from "react-icons/fa";
import FormWrapper from "../components/forms/formik/FormWrapper";
import { FormType } from "../viewmodels/form";
import { FormQueue, IFormQueueItem } from "../services/FormQueue";
import { NavigateToPage } from "../NavigateTo";
import Analytics from "../components/analytics/Analytics";
import { SpinnerSize, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";

const handlebars = Handlebars.create();
const helpers = require('just-handlebars-helpers');
helpers.registerHelpers(handlebars);

interface ICommissioningFormProps extends WrappedComponentProps { }

interface ICommissioningFormState {
    currentValues: { [key: string]: any };
    values: { [key: string]: any };
    isFormValid?: boolean;
    currentIndex: number;

    isCsvUploaded?: boolean;
    csvWarnings: string[];
    csvFieldNames: string[];
    generatedFileId?: string;

    processingCsv?: boolean;
    isLoadingSourceForm?: boolean;

    queueItem?: IFormQueueItem;

    errorMessage?: string;
    csvErrorMessage?: string;
}

class CommissioningForm extends AForm<ICommissioningFormProps, ICommissioningFormState> {
    private readonly FormName: string = "Inbedrijfstelling";
    private readonly schemas = [
        IbnForm1Def,
        IbnForm2Def,
        IbnForm3Def,
        IbnForm4Def,
        IbnForm5Def,
        IbnForm6Def,
        IbnForm7Def
    ];

    constructor(props: any) {
        super(props, "Commissioning");

        FormikUtil.subscribeToFiles("IBN", (formik: any, fieldName: string, files: any[]) =>
            files.length > 0 && this.processCsvFile(formik, files[0]));

        this.state = {
            currentValues: {},
            values: {},
            csvFieldNames: [],
            csvWarnings: [],
            currentIndex: 0,
            isFormValid: false
        };
    }

    protected onAppState = (appState: IAppState): Promise<void> =>
        new Promise((resolve, reject) => {
            if (appState.data) {
                this.setValues(appState.data);

                resolve();
            } else {
                reject("APPSTATE_INVALID");
            }
        });

    public setValues = (values: any) => this.setState({ values: values });

    private hideUploadElement = (fieldName: string) => {
        const fieldLabel = $(`.form-group > label[for=${fieldName}]`);
        fieldLabel.siblings(`section`).hide();
    }

    private showUploadElement = (fieldName: string) => {
        const fieldLabel = $(`.form-group > label[for=${fieldName}]`);
        fieldLabel.siblings(`section`).show();
    }

    private startCsvProcessing = (fieldName: string) => {
        this.setState({ processingCsv: true, csvWarnings: [], csvErrorMessage: undefined, errorMessage: undefined });

        this.hideUploadElement(fieldName);
    }
    private stopCsvProcessing = (fieldName: string, warnings: string[], hasError: boolean) => {
        this.setState({ processingCsv: false, csvWarnings: [...warnings] });

        if (hasError) {
            // enable upload of new, valid CSV
            this.showUploadElement(fieldName);
        }
    }

    private validateCsvFileUpload = () => {
        const heatPumpSeries = this.state.currentValues.warmtepompserie;
        const heatPumpType = this.state.currentValues[`warmtepomptype_${heatPumpSeries}`];
        if (heatPumpSeries && heatPumpSeries.length > 0 &&
            heatPumpType && heatPumpType.length > 0) {
            return true;
        } else {
            alert("Vul eerst 'warmtepomp type' in");
            return false;
        }
    }

    // private getHeatPump = () => {
    //     const heatPumpSeries = this.state.currentValues.warmtepompserie;
    //     const heatPumpType = this.state.currentValues[`warmtepomptype_${heatPumpSeries}`];
    //     return `${heatPumpSeries} ${heatPumpType}`;
    // }

    private processCsvFile = (formik: any, file: any) => {
        if (this.validateCsvFileUpload()) {
            this.startCsvProcessing("loggingUpload");
            let warnings: string[] = [];
            let hasError: boolean = false;

            const heatPumpSeries = this.state.currentValues.warmtepompserie;
            const heatPumpType = this.state.currentValues[`warmtepomptype_${heatPumpSeries}`];

            InbedrijfstellingService.ParseCsv(file, heatPumpType)
                .then(value => {
                    const data = value.data;
                    warnings = value.errors;
                    const generatedFileId = value.generatedFileId;

                    let values: any = {};
                    const csvFieldNames: string[] = [];
                    let csv: any = IbnFormCsv;
                    for (let csvKey in csv) {
                        var sourceLine = csv[csvKey];
                        var sources = Array.isArray(sourceLine) ? sourceLine : [sourceLine];
                        var fieldValues: any[] = [];

                        for (let source of sources) {
                            csvFieldNames.push(csvKey);
                            var fieldValue = source;
                            // check if is string
                            if (typeof (source) === "string" || source instanceof String) {
                                var template = handlebars.compile(source);
                                fieldValue = template(data);
                            }
                            fieldValues.push(fieldValue);
                        }

                        const validValues = fieldValues.filter(f => f !== undefined && f !== "");
                        let csvFieldValue = validValues.length > 0 ? validValues[0] : undefined;

                        values[csvKey] = csvFieldValue;
                    }

                    const allValues = Object.assign({}, this.state.values, values);
                    this.setState({
                        values: allValues,
                        csvFieldNames: csvFieldNames,
                        isCsvUploaded: true,
                        generatedFileId: generatedFileId
                    });

                    const currentValues = Object.assign({}, this.state.currentValues, values);

                    formik.setValues(allValues);
                })
                .catch(reason => {
                    hasError = true;
                    this.setState({
                        csvErrorMessage: "Fout bij uitlezen CSV bestand"
                    });
                    console.log(reason.response ? reason.response : 'No error response available');
                    console.error(reason);
                })
                .finally(() => {
                    this.stopCsvProcessing("loggingUpload", warnings, hasError);
                })
        }
    }

    private getFormValueContainer = (schema: any) => {
        let result: any = {};

        if (schema["name"]) {
            const name = schema["name"];
            result[name] = null;
        }

        if (schema["elements"]) {
            const elements = schema["elements"];
            const keys = Object.keys(elements);
            keys.forEach(key => {
                const childResult = this.getFormValueContainer(elements[key]);
                result = Object.assign({}, result, childResult);
            })
        }

        return result;
    }

    private onValidateForm = (formikForm: FormikProps<any>, values: any): { [key: string]: string } =>
        !this.state.isCsvUploaded ?
            { loggingUpload: this.props.intl.formatMessage({ id: "errors.commissioning.csvNotUploaded", defaultMessage: "CSV verplicht" }) } :
            {};

    private indexChanged = (index: number) => {
        this.setState({
            currentIndex: index
        });
    }

    submit = async () => {

        const { values: formValues, generatedFileId } = this.state;
        console.log("Submitting form", formValues);

        this.appRepo.setState("/Forms/Commissioning", formValues);

        if (!generatedFileId) throw 'Generated file id is undefined';

        const csvFileHasWarnings = this.state.csvWarnings.length > 0;
        const houseNumberAddition = formValues.objectHuisnummerToevoeging ? `-${formValues.objectHuisnummerToevoeging}` : "";
        const queueItemTitle = this.props.intl.formatMessage({ id: "forms.Commissioning.name", defaultMessage: "Inbedrijfstellingsformulier" }) +
            ` - ${formValues.objectPostcode} ${formValues.objectHuisnummer}${houseNumberAddition}`;
        const queueItem = InbedrijfstellingService.submit(formValues, generatedFileId, csvFileHasWarnings, queueItemTitle);

        await FormQueue.processQueue();

        this.setState({
            queueItem: queueItem
        });

        return queueItem;

    }

    private submitted = () => this.onBackToMain();

    private onBackToMain = () => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.backToOverview' }));
        NavigateToPage.Home(this.appRepo);
    }

    private onNewCsvFile = () => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.logUploaded' }));

        this.showUploadElement("loggingUpload");

        this.setState({
            csvWarnings: [],
            isCsvUploaded: false,
            generatedFileId: undefined,
            isFormValid: false
        });
    }

    private onCurrentValuesChanged = (currentValues: any) => {
        this.setState({
            currentValues: { ...currentValues }
        });
    }
    private onValuesChanged = (values: any) => {
        this.setState({
            values: { ...values }
        });
    }

    public render() {
        return (
            <div className="page-FormCommissioning pageContent">

                <Row id="page-FormCommissioning-actions">
                    <Col className="text-right">
                        <PrimaryButton onClick={this.onBackToMain}>
                            <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                        </PrimaryButton>
                    </Col>
                </Row>

                {
                    this.state.errorMessage &&
                    <div className="alert alert-danger" role="alert">{this.state.errorMessage}</div>
                }
                <h1><FormattedMessage id="forms.Commissioning.title" defaultMessage="Inbedrijfstellingsformulier" /></h1>

                <FormWrapper schemas={this.schemas}
                    formName={this.FormName}
                    formType={FormType.Inbedrijfstelling}
                    formPrefix="Ibn"
                    isFormValid={true}
                    recoveryEnabled={false}
                    copyFromFormEnabled={true}
                    onCurrentValuesChanged={this.onCurrentValuesChanged}
                    onValuesChanged={this.onValuesChanged}
                    onValidateForm={this.onValidateForm}
                    onIndexChanged={this.indexChanged}
                    onSubmit={this.submit}
                    onSubmitted={this.submitted}
                    {...this.props}>
                    <div>
                        {
                            this.state.currentIndex === 0 && this.state.processingCsv &&
                            <div>
                                <Spinner size={SpinnerSize.large} /> <span><FormattedMessage id="forms.Commissioning.processingCsv" defaultMessage="Processing CSV file" />...</span>
                            </div>
                        }
                        {
                            this.state.csvErrorMessage &&
                            <div className="alert alert-danger" role="alert">{this.state.csvErrorMessage}</div>
                        }
                        {
                            this.state.currentIndex === 0 && this.state.isCsvUploaded &&
                            <div>
                                <FaCheck className="icon" /> <FormattedMessage id="forms.Commissioning.doneCsv" defaultMessage="CSV done" />
                                {
                                    this.state.csvWarnings.length > 0 &&
                                    <MessageBar messageBarType={MessageBarType.info}><FormattedMessage id="forms.Commissioning.warningsTitle" defaultMessage="There are warnings" /></MessageBar>
                                }
                                {
                                    this.state.csvWarnings.map(errorMessage =>
                                        <MessageBar messageBarType={MessageBarType.warning}>{errorMessage}</MessageBar>)
                                }
                            </div>
                        }
                        {
                            this.state.currentIndex === 0 && this.state.isCsvUploaded &&
                            <PrimaryButton onClick={this.onNewCsvFile}>
                                <FormattedMessage id="forms.Commissioning.newCsvFile" defaultMessage="New CSV file" />
                            </PrimaryButton>
                        }
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

export default injectIntl(CommissioningForm);