import { IUser } from "../viewmodels/auth";
import { JwtDecode } from "jwt-js-decode";
import moment from "moment";
import { Util } from "../Utils/Util";

export default class AuthRepository {
    private token: string | null = null;
    private readonly IdTokenStorageKey = "auth.id-token";

    public init = () => {
        this.loadToken();
    }

    public setToken = (token: string) => this.storeToken(token);
    public getToken = (): string | null => this.token ? this.token : this.loadToken();
    public clearToken = () => {
        localStorage.removeItem(this.IdTokenStorageKey);
        this.token = null;
    }

    public isValid = () => Util.IsLocal() || this.validateToken(this.getToken() || "");

    private storeToken = (token: string) => {
        if (this.validateToken(token)) {
            localStorage.setItem(this.IdTokenStorageKey, token);
            this.loadToken();
        }
    }

    private loadToken = (): string | null => {
        const token = localStorage.getItem(this.IdTokenStorageKey);
        if (token && this.validateToken(token)) {
            this.token = token;

            return this.token;
        }
        return null;
    }

    private validateToken = (token: string) => this.getTokenUser(token) !== undefined;

    public getTokenUser = (idToken?: string): IUser | undefined => {
        const jwtToken = this.decodeToken(idToken);
        if (jwtToken) {
          const user: IUser = {
            givenName: jwtToken.payload.given_name,
            surname: jwtToken.payload.family_name,
            displayName: jwtToken.payload.name,
            emails: jwtToken.payload.emails,
          }
          return user;
        }
        return undefined;
    }

    public isExpired = () => {
        if (Util.IsLocal()) return false;

        const jwtToken = this.decodeToken(this.getToken());
        if (jwtToken) {
            const expTime = jwtToken.payload.exp;
            const expirationTime = moment.unix(expTime);
            const now = moment();
            return expirationTime < now;
        }
        return true;
    }

    public getRefreshToken = () => {
        const jwtToken = this.decodeToken(this.getToken());
        if (jwtToken) {
            return "";
        }
        return undefined;
    }

    private decodeToken = (idToken?: string | null): JwtDecode | undefined => {
        idToken = idToken || this.token || undefined;
        if (idToken) {
          const jwtToken = new JwtDecode(idToken);
          return jwtToken;
        }
        return undefined;
    }
}