import React from "react";

import "./DashboardItem.scss";

export enum DashboardItemIconSizeEnum {
    Normal, Large
}

interface IDashboardItemProps {
    title: string;
    subTitle?: string;
    iconSize?: DashboardItemIconSizeEnum;
    disabled?: boolean;

    onClick?: () => void;
}

export default class DashboardItem extends React.Component<IDashboardItemProps> {

    private onClick = () => !this.props.disabled && this.props.onClick ? this.props.onClick() : undefined;

    render() {
        const disabledClassName = this.props.disabled ? "DashboardItem-disabled" : "";
        const iconSize = this.props.iconSize || DashboardItemIconSizeEnum.Normal;
        const iconClassName =
            iconSize == DashboardItemIconSizeEnum.Large ? "DashboardItem-icon-large" : "";

        return (
            this.props.disabled ?
            <></> :
            <div className={`DashboardItem icon_box icon_position_top no_border ${disabledClassName}`} onClick={this.onClick}>
                <div className="DashboardItem-icon-wrapper icon_wrapper">
                    <div className={`DashboardItem-icon icon ${iconClassName}`}>
                        {this.props.children}
                    </div>
                </div>
                <div className="DashboardItem-text-wrapper desc_wrapper">
                    <h4 className="DashboardItem-title title">{this.props.title}</h4>
                    {
                        this.props.subTitle &&
                        <div className="DashboardItem-subTitle desc">({this.props.subTitle})</div>
                    }
                </div>
            </div>
        );
    }
}