import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import FormWrapper from "../../components/forms/formik/FormWrapper";
import oc1 from "../../forms/offerteconfigurator/oc1.json";
import { FormType } from "../../viewmodels/form";
import { FloorHeatingTypeEnum, HeatPumpFunctionEnum, HeatPumpTypeEnum, ICalculateQuoteSolutionsErrorVM, IQuoteConfigGetRefinersResponseVM, ICalculateQuoteSolutionsRequestVM, ISolutionVM, PostAdjustmentTypeEnum, RenovationNewChoiceEnum, SanitationEnum, SolutionTypeEnum, TypeOfHouseEnum } from "../../viewmodels/data";
import Form, { IFormTextProps } from "../../components/Form";
import Locales from "../../locales/Locales";
import IFormField, { FormFieldDependentOfType, FormFields } from "../../components/models/FormField";
import { QuoteConfigurationService } from "../../services/QuoteConfigurationService";
import AForm from "../../forms/AForm";
import { Util } from "../../Utils/Util";
import { IAppState } from "../../viewmodels/app";
import { ChoiceGroup, Label, MessageBar, MessageBarType, PrimaryButton, SpinButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { StringUtils } from "../../Utils/StringUtils";
import { he } from "date-fns/locale";
import Debounce from "../../Utils/Debounce";
import EnumUtils from "../../Utils/EnumUtils";
import { AppUtils } from "../../Utils/AppUtils";
import NumUtils from "../../Utils/NumUtils";

interface IFindSolutionConfigFormProps extends WrappedComponentProps {
    solutionsCalculated: (formData: ICalculateQuoteSolutionsRequestVM, solutions: ISolutionVM[], errors: ICalculateQuoteSolutionsErrorVM[]) => Promise<void>;
    onError: (errorCode: string) => void;
}

interface IFindSolutionConfigFormState {

    solutionType?: SolutionTypeEnum;
    projectPostalCode: string;
    projectHouseNumber: string;
    heatPumpType: HeatPumpTypeEnum;
    heatPumpFunction: HeatPumpFunctionEnum;
    powerNeededInKW: number;
    numberOfPeopleInHome: number;
    sanitation: SanitationEnum;
    floorHeatingType: FloorHeatingTypeEnum;
    postAdjustmentType: PostAdjustmentTypeEnum;
    floorHeatingHOH: number;
    basementLivingArea?: number;
    groundFloorLivingArea?: number;
    firstFloorLivingArea?: number;
    secondFloorLivingArea?: number;
    thirdFloorLivingArea?: number;
    manualHeatingChoice?: boolean;
    heatLossCalculation?: boolean;
    floorArea?: number;
    typeOfHouse: TypeOfHouseEnum;
    renovationNewChoice?: RenovationNewChoiceEnum;

    validationWarnings: string[];
    showValidationWarnings: boolean;

    validationErrors: ICalculateQuoteSolutionsErrorVM[];
    showValidationErrors: boolean;

    busySubmitting: boolean;

}

export interface IFindSolutionConfigFormRefiners extends IQuoteConfigGetRefinersResponseVM {

}


class QuoteWizardForm extends React.Component<IFindSolutionConfigFormProps, IFindSolutionConfigFormState> {

    SetState2 = <K extends keyof IFindSolutionConfigFormState>(ob: Pick<IFindSolutionConfigFormState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: IFindSolutionConfigFormProps) {
        super(props);

        const locales = new Locales(this.props);




        this.state = {
            projectHouseNumber: "",
            projectPostalCode: "",
            heatPumpType: HeatPumpTypeEnum.none,
            heatPumpFunction: HeatPumpFunctionEnum.None,
            numberOfPeopleInHome: 2,
            sanitation: SanitationEnum.none,
            floorHeatingType: FloorHeatingTypeEnum.none,
            postAdjustmentType: PostAdjustmentTypeEnum.sMatrixBase,
            typeOfHouse: TypeOfHouseEnum.none,
            showValidationWarnings: false,
            validationWarnings: [],
            showValidationErrors: false,
            validationErrors: [],
            powerNeededInKW: -1,
            floorHeatingHOH: 150,
            busySubmitting: false,
        };
    }

    renderValidationWarnings = () => {
        const { showValidationWarnings, validationWarnings } = this.state;

        return <>
            {showValidationWarnings && validationWarnings.length > 0 && <>
                <MessageBar messageBarType={MessageBarType.warning}>
                    <ul>
                        {validationWarnings.map(validationWarning => <li key={validationWarning}>{validationWarning}</li>)}
                    </ul>
                </MessageBar>
                <br />
            </>}
        </>
    }

    renderValidationErrors = () => {
        const { showValidationErrors, validationErrors } = this.state;
        return <>
            {showValidationErrors && validationErrors.length > 0 && validationErrors.map(validationError =>
                <MessageBar messageBarType={MessageBarType.error}>
                    <b>Fout bij {validationError.requestSolutionType} {validationError.requestSubject}</b><br />
                    {validationError.errorMessage}
                </MessageBar>
            )}
        </>
    }

    getHeatpumpFunctionValue = () => {
        const { heatPumpFunction, heatPumpType } = this.state;
        if (heatPumpType == HeatPumpTypeEnum.groundBound) {
            return HeatPumpFunctionEnum.HeatingAndCooling;
        }
        return heatPumpFunction;
    }

    getHeatpumpFunctionEnabled = () => {
        const { heatPumpType } = this.state;
        if (heatPumpType == HeatPumpTypeEnum.groundBound) {
            return false;
        }
        return true;
    }

    setSelection = async (stateUpdate: Partial<IFindSolutionConfigFormState>) => {

        if (stateUpdate.heatPumpType == HeatPumpTypeEnum.groundBound) {
            stateUpdate.heatPumpFunction = HeatPumpFunctionEnum.HeatingAndCooling;
        }

        const newStateUpdate = { ...this.state, ...stateUpdate };
        await this.SetState2(newStateUpdate);

    }

    // handleFloorKeydown = (ev, p: keyof IFindSolutionConfigFormState) => {
    //     const t = ev.target as HTMLInputElement;
    //     Debounce.do("debounce_" + t.id, () => {
    //         var txt = t.value as string | undefined;
    //         console.log(txt, t);
    //         var updateObj: any = {};
    //         updateObj[p] = txt ? parseFloat(txt) : undefined;;
    //         this.SetState2(updateObj);
    //     }, 100)
    // }

    render = () => {
        const {
            projectHouseNumber, projectPostalCode, heatPumpType, heatPumpFunction, powerNeededInKW, floorArea, typeOfHouse, renovationNewChoice, numberOfPeopleInHome, sanitation,
            floorHeatingType, floorHeatingHOH, postAdjustmentType, basementLivingArea, groundFloorLivingArea, firstFloorLivingArea, secondFloorLivingArea, thirdFloorLivingArea,
            busySubmitting } = this.state;

        const locales = new Locales(this.props);
        const textProps: IFormTextProps = {
            textIsSubmitting: locales.getForFormComponent("isSubmitting", "Submitting form"),
            textFormSentTitle: locales.getForFormComponent("formSent.title", "Form sent"),
            textFormSentMessage: locales.getForFormComponent("formSent.message", "Form has been sent."),
            textFormSendingErrorTitle: locales.getForFormComponent("formSendingError.title", "Error"),
            textFormSendingErrorMessage: locales.getForFormComponent("formSendingError.message", "An error occurred while sending form."),
            textBackToMain: locales.getForFormComponent("backToMain"),
            textBackToForm: locales.getForFormComponent("backToForm"),
            textToOverview: locales.getForFormComponent("textToOverview"),
            textFormLegendIsRequired: locales.getForFormComponent("legendIsRequired")
        };

        return <>
            <h1>OFFERTECONFIGURATIE</h1>
            <h2>PROJECTGEGEVENS</h2>

            {this.renderValidationWarnings()}
            {this.renderValidationErrors()}

            <TextField
                label="Postcode projectadres"
                value={projectPostalCode}
                onChange={(ev, txt) => { this.setSelection({ projectPostalCode: txt || "" }) }}
                required={true}
                maxLength={7}
                description="Indien postcode nog onbekend is, voer postcode van omliggend gebied in" />
            <TextField
                label="Huisnummer van projectadres"
                value={projectHouseNumber}
                onChange={(ev, txt) => { this.setSelection({ projectHouseNumber: txt || "" }) }}
                maxLength={7}
                required={true} />
            <br />
            <h2>INFORMATIE VOOR WARMTEPOMP</h2>

            <ChoiceGroup
                label="Type warmtepomp"
                selectedKey={HeatPumpTypeEnum[heatPumpType]}
                onChange={(ev, option) => { this.setSelection({ heatPumpType: HeatPumpTypeEnum[option!.key] }) }}
                options={[
                    { key: HeatPumpTypeEnum[HeatPumpTypeEnum.groundBound], text: "Grond gebonden" },
                    { key: HeatPumpTypeEnum[HeatPumpTypeEnum.airWater], text: "Lucht water" },
                    { key: HeatPumpTypeEnum[HeatPumpTypeEnum.none], text: "Niet van toepassing" }
                ]}
                required={true} />

            {heatPumpType !== HeatPumpTypeEnum.none && <>

                <Label>Het benodigde vermogen in KW. Vul 0 in wanneer het benodigde vermogen onbekend is.</Label>
                <SpinButton
                    step={-.1} min={0} max={100}
                    value={powerNeededInKW >= 0 ? powerNeededInKW.toString() : ""}
                    onChange={(ev, txt) => { this.setSelection({ powerNeededInKW: txt == "" ? -1 : parseFloat(txt || "0") }) }} />
                {powerNeededInKW == 0 && <>

                    <Label>Vloeroppervlakte in M² *</Label>
                    <SpinButton
                        step={-.1} min={0} max={10000}
                        value={floorArea ? floorArea.toString() : ""}
                        onChange={(ev, txt) => { this.setSelection({ floorArea: parseFloat(txt || "0") }) }}
                    />


                    <ChoiceGroup
                        label="Type woning"
                        selectedKey={"" + typeOfHouse}
                        onChange={(ev, option) => { this.setSelection({ typeOfHouse: option?.key ? +option.key : undefined }) }}
                        options={EnumUtils.getChoiceGroupOptions(TypeOfHouseEnum, AppUtils.GetHouseTypeName).slice(1)}
                        required={true} />
                </>}


                {powerNeededInKW > 0 && <>
                    <ChoiceGroup
                        label="Renovatie of nieuwbouw"
                        selectedKey={renovationNewChoice ? RenovationNewChoiceEnum[renovationNewChoice] : undefined}
                        onChange={(ev, option) => { this.setSelection({ renovationNewChoice: RenovationNewChoiceEnum[option!.key] }) }}
                        options={[
                            { key: RenovationNewChoiceEnum[RenovationNewChoiceEnum.NewBuild], text: "Nieuwbouw" },
                            { key: RenovationNewChoiceEnum[RenovationNewChoiceEnum.Renovation], text: "Renovatie" },
                        ]}
                        required={true} />
                </>}


                <ChoiceGroup
                    label="Functie warmtepomp"
                    selectedKey={HeatPumpFunctionEnum[this.getHeatpumpFunctionValue()]}
                    readOnly={!this.getHeatpumpFunctionEnabled()}
                    disabled={!this.getHeatpumpFunctionEnabled()}
                    hidden={!this.getHeatpumpFunctionEnabled()}
                    onChange={(ev, option) => { this.setState({ heatPumpFunction: HeatPumpFunctionEnum[option!.key] }) }}
                    options={[
                        { key: HeatPumpFunctionEnum[HeatPumpFunctionEnum.Heating], text: "Verwarmen" },
                        { key: HeatPumpFunctionEnum[HeatPumpFunctionEnum.HeatingAndCooling], text: "Verwarmen en koelen" },
                    ]}
                    required={true} />


                <Label>Aantal personen in huishouden *</Label>
                <SpinButton
                    step={1} min={1} max={5}
                    value={numberOfPeopleInHome ? ((numberOfPeopleInHome < 5) ? "" + numberOfPeopleInHome : "" + numberOfPeopleInHome + " of meer") : "1"}
                    onChange={(ev, txt) => { this.setState({ numberOfPeopleInHome: parseFloat((txt || "0").substring(0, 1)) }) }}
                />

                <ChoiceGroup
                    label="Sanitaire voorzieningen"
                    selectedKey={SanitationEnum[sanitation]}
                    onChange={(ev, option) => { this.setState({ sanitation: SanitationEnum[option!.key] }) }}
                    options={[
                        { key: SanitationEnum[SanitationEnum.shower], text: "Douche" },
                        { key: SanitationEnum[SanitationEnum.showerAndBath], text: "Douche en bad" }
                    ]}
                    required={true} />

            </>}


            <h2>INFORMATIE VOOR VLOERVERWARMING</h2>

            <ChoiceGroup
                label="Type vloerverwarming"
                selectedKey={FloorHeatingTypeEnum[floorHeatingType]}
                onChange={(ev, option) => { this.setState({ floorHeatingType: FloorHeatingTypeEnum[option!.key] }) }}
                options={[
                    { key: FloorHeatingTypeEnum[FloorHeatingTypeEnum.constructionNets], text: "Bouwnetten (standaard)" },
                    { key: FloorHeatingTypeEnum[FloorHeatingTypeEnum.tacker], text: "Tacker" },
                    { key: FloorHeatingTypeEnum[FloorHeatingTypeEnum.none], text: "Niet van toepassing" }
                ]}
                required={true} />


            {floorHeatingType != FloorHeatingTypeEnum.none && <>

                <ChoiceGroup
                    label="Type naregeling"
                    selectedKey={PostAdjustmentTypeEnum[postAdjustmentType]}
                    onChange={(ev, option) => { this.setState({ postAdjustmentType: PostAdjustmentTypeEnum[option!.key] }) }}
                    options={[
                        { key: PostAdjustmentTypeEnum[PostAdjustmentTypeEnum.sMatrixBase], text: "Smatrix Base Pulse (bedraad)" },
                        { key: PostAdjustmentTypeEnum[PostAdjustmentTypeEnum.wavePlus], text: "Smatrix Wave Pulse (draadloos)" },
                    ]}
                    required={true} />

                <ChoiceGroup
                    label="Hart-op-hart afstand"
                    selectedKey={floorHeatingHOH.toString()}
                    onChange={(ev, option) => { this.setState({ floorHeatingHOH: +(option?.key || "150") }) }}
                    options={[
                        { key: "150", text: "150mm (standaard)" },
                        { key: "100", text: "100mm" },
                    ]}
                    required={true} />
                <br />

                <Label>Vloeroppervlakte ruimtes</Label>
                <table style={{ width: "350px", tableLayout: "fixed" }}>
                    <colgroup>
                        <col style={{ width: "150px" }} />
                        <col style={{ width: "150px" }} />
                        <col style={{ width: "50px" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>Kelder</td>
                            <td>
                                <TextField
                                    value={basementLivingArea || basementLivingArea === 0 ? "" + basementLivingArea : ""}
                                    onChange={(ev, txt) => this.SetState2({ basementLivingArea: NumUtils.HandleNumberInput(txt, basementLivingArea) })}
                                />
                            </td>
                            <td>&nbsp;M²</td>
                        </tr>
                        <tr>
                            <td>Begane grond</td>
                            <td>
                                <TextField
                                    value={groundFloorLivingArea || groundFloorLivingArea === 0 ? "" + groundFloorLivingArea : ""}
                                    onChange={(ev, txt) => this.SetState2({ groundFloorLivingArea: NumUtils.HandleNumberInput(txt, groundFloorLivingArea) })}
                                />
                            </td>
                            <td>&nbsp;M²</td>
                        </tr>
                        <tr>
                            <td>1e verdieping</td>
                            <td>
                                <TextField
                                    value={firstFloorLivingArea || firstFloorLivingArea === 0 ? "" + firstFloorLivingArea : ""}
                                    onChange={(ev, txt) => this.SetState2({ firstFloorLivingArea: NumUtils.HandleNumberInput(txt, firstFloorLivingArea) })}
                                />
                            </td>
                            <td>&nbsp;M²</td>
                        </tr>
                        <tr>
                            <td>2e verdieping</td>
                            <td>
                                <TextField
                                    value={secondFloorLivingArea || secondFloorLivingArea === 0 ? "" + secondFloorLivingArea : ""}
                                    onChange={(ev, txt) => this.SetState2({ secondFloorLivingArea: NumUtils.HandleNumberInput(txt, secondFloorLivingArea) })}
                                />
                            </td>
                            <td>&nbsp;M²</td>
                        </tr>
                        <tr>
                            <td>3e verdieping</td>
                            <td>
                                <TextField
                                    value={thirdFloorLivingArea || thirdFloorLivingArea === 0 ? "" + thirdFloorLivingArea : ""}
                                    onChange={(ev, txt) => this.SetState2({ thirdFloorLivingArea: NumUtils.HandleNumberInput(txt, thirdFloorLivingArea) })}
                                />
                            </td>
                            <td>&nbsp;M²</td>
                        </tr>
                    </tbody>
                </table>
            </>}
            <br />


            {this.renderValidationWarnings()}
            {this.renderValidationErrors()}

            {busySubmitting && <Spinner size={SpinnerSize.large} label="Bezig met berekenen oplossingen..." />}
            {!busySubmitting && <PrimaryButton text="Verzenden" onClick={() => { this.onSubmit() }} />}
        </>
    }

    getFormData = (): ICalculateQuoteSolutionsRequestVM => {
        const { } = this.props;

        let result: ICalculateQuoteSolutionsRequestVM = {
            ...this.state,
            heatPumpFunction: this.getHeatpumpFunctionValue()
        }

        return result;
    }

    onSubmit = async () => {
        await this.SetState2({ busySubmitting: true });
        await this.validateForm();
        const { validationWarnings } = this.state;
        if (validationWarnings.length > 0) {
            await this.SetState2({ showValidationWarnings: true, busySubmitting: false });
            return;
        } else {
            await this.SetState2({ showValidationErrors: false, validationErrors: [] });
        }

        const formData = this.getFormData();

        const result = await QuoteConfigurationService.calculateSolutions(formData);

        await this.SetState2({ busySubmitting: false });
        this.props.solutionsCalculated(formData, result.solutions, result.errors);
    }

    validateForm = async () => {
        const { projectPostalCode, projectHouseNumber, heatPumpType, typeOfHouse, renovationNewChoice,
            sanitation, postAdjustmentType, powerNeededInKW, floorArea, floorHeatingType } = this.state;
        const validationWarnings: string[] = [];

        const heatPumpFunction = this.getHeatpumpFunctionValue();

        if (!StringUtils.validatePostalCodeFormat(projectPostalCode))
            validationWarnings.push("Postcode is niet correct ingevuld");

        if (!projectHouseNumber || projectHouseNumber.trim().length < 1)
            validationWarnings.push("Huisnummer ontbreekt");

        if (heatPumpType != HeatPumpTypeEnum.none) {

            if (powerNeededInKW <= 0) {
                if (!floorArea || floorArea <= 0)
                    validationWarnings.push("Vloeroppervlakte bij warmtepomp is niet correct ingevuld");

                if (typeOfHouse == TypeOfHouseEnum.none)
                    validationWarnings.push("Selecteer het type woning");
            } else {
                if (!renovationNewChoice)
                    validationWarnings.push("Selecteer Nieuwbouw of Renovatie");
            }


            if (heatPumpFunction == HeatPumpFunctionEnum.None)
                validationWarnings.push("Selecteer de functie van de warmtepomp");

            if (sanitation == SanitationEnum.none)
                validationWarnings.push("Selecteer het type sanitair");
        }

        if (floorHeatingType != FloorHeatingTypeEnum.none) {
            if (postAdjustmentType == PostAdjustmentTypeEnum.none)
                validationWarnings.push("Selecteer het type naregeling");
        }

        await this.SetState2({ validationWarnings });

    }
}


export default injectIntl(QuoteWizardForm, { forwardRef: true });