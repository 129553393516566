import React from "react";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import "./QuotationOverview.scss";
import { Util } from "../Utils/Util";
import {
    Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Card, Collapse, CardBody, CardHeader, Table, Button, FormGroup, InputGroup, InputGroupAddon, Input, CustomInput, Label, Alert, Badge
} from "reactstrap";
import classnames from 'classnames';
import { QuoteService, QuotesScopeEnum } from "../services/QuoteService";
import { ICrudGetAllResponseVM, IQuoteVM } from "../viewmodels/data";
import Loading from "../components/Loading";
import { Service } from "../services/Service";
import moment from "moment";
import { DefaultButton, PrimaryButton, Sticky } from "@fluentui/react";
import { DateTime } from "luxon";
import FilterBox, { IFilterOptions } from "../components/FilterBox";
import DateTimeUtils from "../Utils/DateTimeUtils";


interface IQuotationOverviewProps extends WrappedComponentProps { }

interface IQuotationOverviewState {
    activeTab: string,
    isOpen: number;
    isQuotesLoading?: boolean;
    isCompaniesLoading?: boolean;
    errorMessage?: string;
    companyQuotes: IQuoteVM[];
    myQuotes: IQuoteVM[];

    filterDate?: DateTime
}

class QuotationOverview extends React.Component<IQuotationOverviewProps, IQuotationOverviewState> {

    constructor(props: IQuotationOverviewProps) {
        super(props);
        this.state = {
            activeTab: "1",
            isOpen: 0,
            companyQuotes: [],
            myQuotes: []
        }
    }

    componentDidMount() {
        this.setState({ isQuotesLoading: true, isCompaniesLoading: true });
        this.loadGetCompanyQuotes();
        this.loadGetMyQuotes();
    }

    private loadGetCompanyQuotes = () => {
        QuoteService.GetAll(QuotesScopeEnum.Company)
            .then((response: ICrudGetAllResponseVM<IQuoteVM>) => {
                let allCompanies = response.value.sort((q1, q2) => q1.created > q2.created ? -1 : 1)
                this.setState({
                    companyQuotes: allCompanies,
                    errorMessage: undefined
                });
            }).catch(reason => {
                this.setState({
                    errorMessage: reason
                });
            }).finally(() => {
                this.setState({
                    isCompaniesLoading: false
                });
            });
    }
    private loadGetMyQuotes = () => {
        QuoteService.GetAll(QuotesScopeEnum.My)
            .then((response: ICrudGetAllResponseVM<IQuoteVM>) => {
                let quotes = response.value.sort((q1, q2) => q1.created > q2.created ? -1 : 1)
                this.setState({
                    myQuotes: quotes,
                    errorMessage: undefined
                });
            }).catch(reason => {
                this.setState({
                    errorMessage: reason
                });
            }).finally(() => {
                this.setState({
                    isQuotesLoading: false
                });
            });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    open = (tabNumber) => {
        let newTab = tabNumber;
        if (tabNumber == this.state.isOpen) {
            newTab = 0;
        }
        this.setState({ isOpen: newTab })
    };

    getFilterOptions = () => {
        const { filterDate } = this.state;

        const filterOptions: IFilterOptions = {
            options: [{
                filterTitle: "Created on",
                key: "createdOn",
                filterType: "date",
                currentValue: filterDate,
                onFilterUpdated: (op) => {
                    this.setState({ filterDate: op.currentValue as DateTime })
                }
            }]
        }

        return filterOptions
    }

    getFilteredQuotations = () => {
        const { filterDate, myQuotes, companyQuotes, activeTab } = this.state;

        if (!myQuotes || !companyQuotes) return undefined;

        var filteredQuotes: IQuoteVM[] = [];

        if (activeTab == "1") {
            filteredQuotes = myQuotes.slice();
        } else if (activeTab == "2") {
            filteredQuotes = companyQuotes.slice();
        }

        if (filterDate) filteredQuotes = filteredQuotes.filter(q => DateTimeUtils.getDate(DateTime.fromISO(q.created)).valueOf() == filterDate.valueOf());
        console.log("filtered quotes", filteredQuotes);
        console.log("tab", activeTab);


        return filteredQuotes;
    }

    renderQuotesTable = (quotes: IQuoteVM[]) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th><FormattedMessage id="pages.quotationoverview.tableheader.subject" defaultMessage="Onderwerp" /></th>
                        <th><FormattedMessage id="pages.quotationoverview.tableheader.created" defaultMessage="Gemaakt op" /></th>
                        <th><FormattedMessage id="pages.quotationoverview.tableheader.document" defaultMessage="Document" /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        quotes.length > 0 && quotes.map((quote) => {
                            return <tr key={quote.id}>
                                <td>{quote.quoteSubject}</td>
                                <td>{moment(quote.created).format("DD-MM-YYYY")}</td>
                                <td>
                                    <DefaultButton onClick={() => Util.NavigateAbsolute(`${Service.ApiUrl}/v1/quotes/${quote.id}/Document/content`)}>
                                        <FormattedMessage id="pages.quotationoverview.openquote" defaultMessage="Open" />
                                    </DefaultButton>
                                </td>
                            </tr>
                        })
                    }

                </tbody>
            </Table>
        )
    }

    render() {
        const { activeTab, isOpen, companyQuotes, myQuotes, isCompaniesLoading, isQuotesLoading, errorMessage } = this.state;
        return (
            <div className="page-CommissioningOverview mt-2 quote">
                <Row>
                    <Col sm="12" className="text-right">
                        <PrimaryButton onClick={() => Util.Navigate(`/`)}>
                            <FormattedMessage id="pages.quotationoverview.btnBackToMainMenu" defaultMessage="Terug naar hoofdmenu" />
                        </PrimaryButton>
                    </Col>
                    <Col sm="12">
                        <h1 className="page-heading">
                            <FormattedMessage id="pages.quotationoverview.headerOverviewQuotes" defaultMessage="Overzicht Offertes" />
                        </h1>
                    </Col>
                    <Col sm="12">
                        <Nav tabs>
                            <NavItem style={{ width: 200 }}>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id="pages.quotationoverview.tabs.myquotes" defaultMessage="Mijn offertes" />
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ width: 200 }}>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="pages.quotationoverview.tabs.mycompany" defaultMessage="Van mijn bedrijf" />
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Sticky stickyBackgroundColor="#fff">
                                    <FilterBox filterOptions={this.getFilterOptions()} />
                                </Sticky>
                                <Row className="my-2">
                                    <Col sm="12" className="table-responsive">
                                        {isCompaniesLoading && <FormattedMessage id="pleasewait.quotes" defaultMessage="Een ogenblik geduld aub, de offertes worden geladen...">
                                            {m => <Loading>{m}</Loading>}
                                        </FormattedMessage>}
                                        {
                                            !isQuotesLoading && myQuotes.length == 0 &&
                                            <Alert color="info"><FormattedMessage id="pages.quotationoverview.tabs.noquotes" defaultMessage="Geen offertes gevonden" /></Alert>
                                        }
                                        {
                                            !isQuotesLoading && this.getFilteredQuotations() != undefined &&
                                            this.renderQuotesTable(this.getFilteredQuotations()!)
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Sticky stickyBackgroundColor="#fff">
                                    <FilterBox filterOptions={this.getFilterOptions()} />
                                </Sticky>
                                <Row className="my-2">
                                    <Col sm="12" className="table-responsive">
                                        {isQuotesLoading && <FormattedMessage id="pleasewait.quotes" defaultMessage="Een ogenblik geduld aub, de offertes worden geladen...">
                                            {m => <Loading>{m}</Loading>}
                                        </FormattedMessage>}
                                        {
                                            !isCompaniesLoading && companyQuotes.length == 0 &&
                                            <Alert color="info"><FormattedMessage id="pages.quotationoverview.tabs.noquotes" defaultMessage="Geen offertes gevonden" /></Alert>
                                        }
                                        {
                                            !isCompaniesLoading && this.getFilteredQuotations() != undefined &&
                                            this.renderQuotesTable(this.getFilteredQuotations()!)
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(QuotationOverview, { forwardRef: true });