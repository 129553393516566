import React from "react";


interface IMoneyProps {
    amount?: number;
    decimals?: number;
    symbol?: string;
}

interface IMoneyState {
    amount: number;
    decimals: number;
    symbol: string;
}


export default class Money extends React.Component<IMoneyProps, IMoneyState> {

    constructor(props: IMoneyProps) {

        super(props);

        // console.log(props);
        // console.log(this.props);
        // console.log(this.props.decimals);

        const amount = !this.props.amount ? 0 : this.props.amount;
        const symbol = !this.props.symbol ? "€" : this.props.symbol;
        const decimals = !this.props.decimals ? 2 : this.props.decimals;

        // console.log(!this.props.decimals);
        // console.log(decimals);


        this.state = {
            amount: amount,
            decimals: decimals,
            symbol: symbol
        };
    }

    componentDidUpdate(prevProps: IMoneyProps) {
       
        const _amount = !this.props.amount ? 0 : this.props.amount;
        const _symbol = !this.props.symbol ? "€" : this.props.symbol;
        const _decimals = !this.props.decimals ? 2 : this.props.decimals;

        if (this.state.amount != _amount) {
            this.setState({
                amount: _amount,
                decimals: _decimals,
                symbol: _symbol
            });
        }
    }

    render() {
        return this.state.symbol + " " + this.state.amount.toLocaleString("nl-NL", { maximumFractionDigits: this.state.decimals, minimumFractionDigits: this.state.decimals });
    }


}