import { RouteProps, Route, Redirect } from "react-router";
import React from "react";

export interface IProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
}

export default class ProtectedRoute extends React.Component<IProtectedRouteProps> {
    public render(): any {        
        if (this.props.isAuthenticated == false) {
            console.log("Not authenticated for '"+this.props.path+"'")
        }
        return this.props.isAuthenticated ? <Route {...this.props} /> : <Redirect to='/' />;
    }
}