import React from "react";
import { WrappedComponentProps, FormattedMessage, injectIntl } from "react-intl";
import { Util } from "../Utils/Util";
import { RouteComponentProps } from 'react-router';
import "./SolutionEdit.scss";

import {
    Alert, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Input,
    Col, Row, FormGroup, Table, CustomInput
} from "reactstrap";
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import Loading from "../components/Loading";
import { FaTrash, FaTimes, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Service } from "../services/Service";

import {
    ICrudGetOneResponseVM, ICrudGetAllResponseVM,
    ISolutionVM, ISolutionPositionVM, SolutionTypeEnum, HeatPumpTypeEnum, FloorHeatingTypeEnum,
    ICrudCreateResponseVM, ICrudUpdateResponseVM, IBlobVM, IItemVM, ISolutionPositionItemVM, UnderfloorHeatingTypeEnum, PostAdjustmentTypeEnum, IRelatedSolutionVM, RenovationNewChoiceEnum, AllowedActionEnum, HeatPumpFunctionEnum, IGetPrincipeDiagramResponse,
    PositionTypeEnum
} from "../viewmodels/data";

import $ from "jquery";
import { SolutionService } from "../services/SolutionService";
import { SolutionPrincipleDiagramService } from "../services/SolutionPrincipleDiagramService";
import { SolutionImageService } from "../services/SolutionImageService";
import { SolutionPositionService } from "../services/SolutionPositionService";
import { SolutionAttachmentService } from "../services/SolutionAttachmentService";
import { ItemService } from "../services/ItemService";

import { Editor } from "@tinymce/tinymce-react";
import Money from "../components/Money";

import ConfirmDialog from "../components/ConfirmDialog";
import { Checkbox, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack, TextField, DefaultButton, Dropdown, Label, Position, SpinButton, SpinnerSize, Spinner, ComboBox, IDropdownOption } from "@fluentui/react";
import EnumUtils from "../Utils/EnumUtils";
import NumUtils from "../Utils/NumUtils";
import { AppUtils } from "../Utils/AppUtils";


interface IConfigurationWizardMatchParams {
    solutionType: string;
    solutionId: string;
}

interface IItemList {
    item: IItemVM;
    isChecked: boolean;
    numberOfItems: number;
}

interface IEditedPosition {
    tabIndex: number;
    itemRow: ISolutionPositionItemRow;
    itemIndex?: number;
}
interface ILinkedSolutionList {
    linkedSolution: ISolutionVM;
    isChecked: boolean;
}
interface ISolutionEditProps extends RouteComponentProps<IConfigurationWizardMatchParams>, WrappedComponentProps { }

interface ISolutionEditState {
    solution: ISolutionVM;

    // selectedFilePrincipleScheme?: File;
    // loadedPrincipleScheme?: IBlobVM;

    selectedFileImage?: File;
    imagePreviewUrl: any;
    loadedImage?: IBlobVM;

    selectedFileAttachments?: File[];
    attachmentsPreviewUrl: any[];
    loadedAttachmentUrls?: string[];
    loadedAttachments?: IBlobVM[];

    isLoading?: boolean;
    errorMessage?: string;
    loadingMessage?: string;
    formValidation: IFormValidation;

    activePositionIndexModal: number;
    editPositionModal: boolean;

    searchText?: string;
    items?: IItemList[];
    isLoadingModal?: boolean;

    solutionPositionItemRow?: ISolutionPositionItemRow[];

    positionHeaderAndItemRow: { [positionIndex: number]: ISolutionPositionHeaderAndItemRow };

    editedPosition?: IEditedPosition;

    editItemPsnr?: string;
    editItemQuantity?: number;

    activeTab: string;
    loadedNumberOfPositions?: number;


    linkedSolutionList: ILinkedSolutionList[];
    relatedSolutions: IRelatedSolutionVM[];
    isrelatedSolutionShow: boolean;

    importantInformationInitialEditorText?: string;

    detectedDiagram?: IGetPrincipeDiagramResponse;
    detectedDiagramStatus: "unknown" | "loading" | "found" | "error";
}

interface IFormValidation {
    ErrorOnPrincipleSchemeCode: string;
    ErrorOnTitle: string;
    ErrorOnPositionTitle: string;
    ErrorOnCode: string;
    ErrorOnERPEnergielabel: string;
    ErrorOnSubsitioncodeIsdeSubsidy: string;
    ErrorOnDescription: string;
    ErrorOnSurface: string;
    ErrorOnPower: string;
    ErrorOnHeatPumpType: string;
    ErrorOnTapWaterMax: string;
    ErrorOnUnderfloorHeatingType: string;
    ErrorOnHeatingAdjustment: string;
    ErrorOnAttachments: string;
    ErrorOnNumberOfFloors: string;
    ErrorOnHeatingHOH: string;
    IsValidate: boolean
}

export interface ISolutionPositionItemRow {
    solutionPositionItem: ISolutionPositionItemVM;
    item?: IItemVM;
    isDel?: boolean;
}

export interface ISolutionPositionHeaderAndItemRow {
    positionHeader: ISolutionPositionVM;
    positionItemRow?: ISolutionPositionItemRow[];
}

class SolutionEdit extends React.Component<ISolutionEditProps, ISolutionEditState> {


    SetState2 = <K extends keyof ISolutionEditState>(ob: Pick<ISolutionEditState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: ISolutionEditProps) {
        super(props);

        let _formValidation: IFormValidation = {
            ErrorOnPrincipleSchemeCode: "",
            ErrorOnTitle: "",
            ErrorOnPositionTitle: "",
            ErrorOnCode: "",
            ErrorOnERPEnergielabel: "",
            ErrorOnSubsitioncodeIsdeSubsidy: "",
            ErrorOnDescription: "",
            ErrorOnSurface: "",
            ErrorOnPower: "",
            ErrorOnHeatPumpType: "",
            ErrorOnTapWaterMax: "",
            ErrorOnUnderfloorHeatingType: "",
            ErrorOnHeatingAdjustment: "",
            ErrorOnAttachments: "",
            ErrorOnNumberOfFloors: "",
            ErrorOnHeatingHOH: "",
            IsValidate: false
        };

        let _initialSolution: ISolutionVM = {
            solutionType: Number(this.props.match.params.solutionType ? this.props.match.params.solutionType : "0"),
            isSelectableAsRelatedSolution: false,
            numberOfPositions: 1,
            title: "",
            description: "",
            importantInformation: "",
            code: "",
            erpEnergielabel: "",
            subsitioncodeIsdeSubsidy: "",
            power: 0,
            powerMin: 0,
            powerMax: 0,
            powerMinus10Deg: 0,
            numberOfFloors: 0,
            heatPumpType: 0,
            tapWaterMax: 0,
            rbe: false,
            cvSide: false,
            functionHeatpump: 0,
        } as any;

        let _positionHeaderAndItemRow: ISolutionPositionHeaderAndItemRow[] = [];

        for (var i: number = 0; i < 8; i++) {
            _positionHeaderAndItemRow.push({
                positionHeader: { positionNumber: i, title: '' } as ISolutionPositionVM,
                positionItemRow: []
            } as ISolutionPositionHeaderAndItemRow);
        };

        this.state = {
            solution: _initialSolution,
            formValidation: _formValidation,
            imagePreviewUrl: "",
            attachmentsPreviewUrl: [],
            searchText: "",
            activePositionIndexModal: -1,
            editPositionModal: false,
            positionHeaderAndItemRow: _positionHeaderAndItemRow,
            activeTab: "1",
            loadedNumberOfPositions: 1,
            linkedSolutionList: [],
            relatedSolutions: [],
            isrelatedSolutionShow: false,
            importantInformationInitialEditorText: !_initialSolution ? "" : _initialSolution.importantInformation,
            detectedDiagramStatus: "unknown"
        }
    }

    componentDidMount = async () => {
        await this.SetState2({ isLoading: true });
        await this.loadSolutionIncludeChildren();
        await this.loadLinkSolutions();
        await this.SetState2({ isLoading: false });
    }

    loadSolutionIncludeChildren = async () => {

        let solutionId: string = this.props.match.params.solutionId;
        if (solutionId) {

            let positionHeaderAndItemRow: { [positionNumber: number]: ISolutionPositionHeaderAndItemRow } = {};

            const solutionResponse = await SolutionService.GetOneIncludeChildren(solutionId);
            const _solution = solutionResponse.value;


            if (_solution.solutionPositions && _solution.solutionPositions.length > 0) {
                for (let posHeader of _solution.solutionPositions) {
                    let rows: ISolutionPositionItemRow[] = [];
                    for (let solutionPositionItem of posHeader.solutionPositionItems) {
                        let _IsolutionPositionItemRow: ISolutionPositionItemRow = {
                            item: solutionPositionItem.navisionItem,
                            solutionPositionItem: solutionPositionItem
                        };
                        rows.push(_IsolutionPositionItemRow);
                    }

                    let headerAndRowItem: ISolutionPositionHeaderAndItemRow = {
                        positionHeader: posHeader,
                        positionItemRow: rows
                    };
                    positionHeaderAndItemRow[posHeader.positionNumber] = headerAndRowItem;
                }
            }

            for (let i = 0; i < _solution.numberOfPositions; i++) {
                if (!positionHeaderAndItemRow[i]) {
                    let headerAndRowItem: ISolutionPositionHeaderAndItemRow = {
                        positionHeader: {
                            positionNumber: i,
                            title: '',
                            allowedAction: AllowedActionEnum.None,
                            id: Util.EmptyGuid(),
                            solutionPositionItems: []
                        },
                        positionItemRow: []
                    };
                    positionHeaderAndItemRow[i] = headerAndRowItem;
                }
            }

            await this.SetState2({
                solution: _solution,
                loadedNumberOfPositions: _solution.numberOfPositions,
                importantInformationInitialEditorText: _solution.importantInformation,
                loadedImage: _solution.solutionImage,
                loadedAttachments: _solution.attachments,
                positionHeaderAndItemRow: positionHeaderAndItemRow
            });
        }

    }


    handleChange2 = async (solutionUpdate: Partial<ISolutionVM>) => {

        const { solution, positionHeaderAndItemRow } = this.state;

        if (solutionUpdate.numberOfPositions && solutionUpdate.numberOfPositions != solution.numberOfPositions) {

            for (let i = 0; i < solutionUpdate.numberOfPositions; i++) {
                if (!positionHeaderAndItemRow[i]) {
                    let headerAndRowItem: ISolutionPositionHeaderAndItemRow = {
                        positionHeader: {
                            positionNumber: i,
                            title: '',
                            allowedAction: AllowedActionEnum.None,
                            id: Util.EmptyGuid(),
                            solutionPositionItems: []
                        },
                        positionItemRow: []
                    };
                    positionHeaderAndItemRow[i] = headerAndRowItem;
                }
            }
        }

        if (solutionUpdate.principleDiagramCode && solutionUpdate.principleDiagramCode != solution.principleDiagramCode) {
            await this.setState({
                detectedDiagram: undefined,
                detectedDiagramStatus: "unknown"
            });
        }

        await this.SetState2({
            solution: {
                ...solution,
                ...solutionUpdate
            },
            positionHeaderAndItemRow: positionHeaderAndItemRow
        });




        this.validateForm();
    }

    handleChange = (event) => {
        var _key: string = event.target.name;
        var _value: string = event.target.type == "checkbox" ? event.target.checked : event.target.value;

        this.setState(prevState => ({
            solution: {
                ...prevState.solution,
                [_key]: _value
            }
        }), () => {
            this.validateForm();
        });
    }

    handleEditorChange = (newText: string, editor: any) => {

        let sol = this.state.solution;
        sol.importantInformation = newText;

        this.setState({
            solution: sol
        }, () => this.validateForm());
    }


    handlePositionChange = (event, tabIndex, itemIndex) => {
        var _key: string = event.target.name;

        let _positionHeaderAndItemRow = this.state.positionHeaderAndItemRow;

        if (_key == "title") {
            _positionHeaderAndItemRow[tabIndex].positionHeader.title = event.target.value;
        }
        if (_key == "isNetto") {
            _positionHeaderAndItemRow[tabIndex].positionHeader.isNetto = event.target.checked;
        }

        this.setState(() => ({
            positionHeaderAndItemRow: _positionHeaderAndItemRow
        }));
    }

    deletePosition = (positionIndex: number) => {
        const { positionHeaderAndItemRow } = this.state;
        const allIndex = Object.keys(positionHeaderAndItemRow).map(Number);

        

    }


    handlePositionChange2 = async (positionUpdate: Partial<ISolutionPositionVM>, tabIndex) => {
        const { positionHeaderAndItemRow } = this.state;
        var positionHeaderAndRow = positionHeaderAndItemRow[tabIndex]

        console.log("positionUpdate", positionUpdate);
        console.log("positionHeaderAndRow", positionHeaderAndRow);

        var solutionPositionUpdate: ISolutionPositionVM = { ...positionHeaderAndRow.positionHeader, ...positionUpdate };

        console.log("solutionPositionUpdate", solutionPositionUpdate);

        positionHeaderAndRow.positionHeader = solutionPositionUpdate;

        console.log("positionHeaderAndRow", positionHeaderAndRow);




        await this.SetState2({
            positionHeaderAndItemRow: positionHeaderAndItemRow
        });
    }



    // onPrincipleSchemeChange = event => {
    //     this.setState({
    //         selectedFilePrincipleScheme: event.target.files[0],
    //     }, () => {
    //         this.validateForm();
    //     });
    // };

    // onPrincipleSchemeClick = () => {
    //     $('#filePrincipleScheme').trigger('click');
    // }

    onImageChange = event => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                selectedFileImage: file,
                imagePreviewUrl: reader.result
            }, () => {
                this.validateForm();
            });
        }
        reader.readAsDataURL(file)
    };

    onImageClick = () => {
        $('#fileImage').trigger('click');
    }

    onImagesChange = event => {

        event.preventDefault();
        let files: File[] = Array.from(event.target.files);

        let _selectedFiles: File[] = this.state.selectedFileAttachments && this.state.selectedFileAttachments.length > 0 ? this.state.selectedFileAttachments : [];
        var _allSelectedFile: File[] = _selectedFiles.concat(files);

        this.setState({
            selectedFileAttachments: _allSelectedFile
        });

    };

    onImagesClick = () => {
        $('#fileImages').trigger('click');
    }

    onRemoveAttachment = (index) => {
        if (this.state.selectedFileAttachments && this.state.selectedFileAttachments.length > 0) {
            this.state.selectedFileAttachments.splice(index, 1);
            this.setState({
                isLoading: false
            });
        }
    }

    onDeleteAttachment = (solutionId, attachmentId) => {
        this.confirmDialog!.showDialog(ok => {
            if (ok) {
                SolutionAttachmentService.Delete(solutionId, attachmentId)
                    .then(() => {

                        let _loadedAttachments = this.state.loadedAttachments
                            ? this.state.loadedAttachments.filter(attachment => attachment.id != attachmentId)
                            : [];
                        this.setState({ loadedAttachments: _loadedAttachments });
                    })
                    .catch(reason => {
                        this.setState({
                            errorMessage: reason
                        });
                    })
                    .finally(() => {

                        this.setState({
                            isLoading: false
                        });
                    });
            }
        })
    }

    validateForm = async (isShowError: boolean = false) => {
        const { solution,
            // selectedFilePrincipleScheme, loadedPrincipleScheme, 
            selectedFileAttachments, loadedAttachments, positionHeaderAndItemRow } = this.state;
        let _errorOnPrincipleSchemeCode: string = '',
            _errorOnTitle: string = '',
            _errorOnPositionTitle: string = '',
            _errorOnCode: string = '',
            _errorOnERPEnergielabel: string = '',
            _errorOnSubsitioncodeIsdeSubsidy: string = '',
            _errorOnDescription: string = '',
            _isValidated: boolean = false,
            _errorOnSurface: string = '',
            _errorOnPower: string = '',
            _errorOnHeatPumpType: string = '',
            _errorOnTapWaterMax: string = '',
            _errorOnUnderfloorHeatingType: string = '',
            _errorOnHeatingAdjustment: string = '',
            _errorOnAttachments: string = '',
            _errorOnNumberOfFloors: string = '',
            _errorOnHeatingHOH: string = '';


        const attachmentCount = ((loadedAttachments ? loadedAttachments.length : 0) + (selectedFileAttachments ? selectedFileAttachments.length : 0));

        console.log(loadedAttachments, selectedFileAttachments)

        if ((!solution?.principleDiagramCode || solution.principleDiagramCode.length < 4) && solution.solutionType == SolutionTypeEnum.HeatPump) {
            _errorOnPrincipleSchemeCode = "Principle Diagram Code is vereist";
        }

        if (!solution.title) {
            _errorOnTitle = "Titel is vereist";
        }

        const numberOfPositionArray: number[] = this.range(0, solution.numberOfPositions - 1);
        if (numberOfPositionArray.filter(numb => !positionHeaderAndItemRow[numb].positionHeader.title).length > 0) {
            console.log(positionHeaderAndItemRow);
            _errorOnPositionTitle = "Positie titel is vereist";
        }

        if (!solution.code && solution.solutionType != SolutionTypeEnum.UnderfloorHeating) {
            _errorOnCode = "Code is vereist";
        }
        if (!solution.erpEnergielabel && solution.solutionType == SolutionTypeEnum.HeatPump) {
            _errorOnERPEnergielabel = "Erp Energielabel is vereist";
        }
        if (!solution.subsitioncodeIsdeSubsidy && solution.solutionType == SolutionTypeEnum.HeatPump) {
            _errorOnSubsitioncodeIsdeSubsidy = "Subsidiecode is vereist";
        }
        if (!solution.description) {
            _errorOnDescription = "Beschrijving is vereist";
        }
        if ((!solution.powerMax || !solution.powerMin) && (solution.solutionType == SolutionTypeEnum.HeatPump || solution.solutionType == SolutionTypeEnum.Source)) {
            _errorOnPower = "Er is een min en max vermogen nodig"
        }
        if ((!solution.surfaceAreaMax || !solution.surfaceAreaMin) && (solution.solutionType == SolutionTypeEnum.UnderfloorHeating || solution.solutionType == SolutionTypeEnum.Regulator)) {
            _errorOnSurface = "Er is een min en max oppervlakte nodig"
        }
        if (!solution.heatPumpType && solution.solutionType == SolutionTypeEnum.HeatPump) {
            _errorOnHeatPumpType = "Er is een warmtepomptype nodig";
        }
        if (!solution.tapWaterMax && solution.solutionType == SolutionTypeEnum.HeatPump) {
            _errorOnTapWaterMax = "Er is een tapwaterMax nodig";
        }
        if (!solution.underfloorHeatingType && solution.solutionType == SolutionTypeEnum.UnderfloorHeating) {
            _errorOnUnderfloorHeatingType = "Er is een vloerverwarmingstype nodig";
        }
        if (!solution.heatingAdjustment && solution.solutionType == SolutionTypeEnum.Regulator) {
            _errorOnHeatingAdjustment = "Er is type naregeling nodig";
        }
        if (attachmentCount <= 0 && solution.solutionType == SolutionTypeEnum.Source) {
            _errorOnAttachments = "Er is een bijlage voor een bron nodig";
        }
        if ((!solution.numberOfFloors || solution.numberOfFloors <= 0) && solution.solutionType == SolutionTypeEnum.Regulator) {
            _errorOnNumberOfFloors = "Er is een aantal voor verdiepingen nodig";
        }
        if ((!solution.floorHeatingHOH) && (solution.solutionType == SolutionTypeEnum.UnderfloorHeating || solution.solutionType == SolutionTypeEnum.Regulator)) {
            _errorOnHeatingHOH = "Er is een hart-op-hart afstand nodig"
        }

        if (_errorOnPrincipleSchemeCode == "" && _errorOnTitle == "" && _errorOnPositionTitle == "" && _errorOnCode == ""
            && _errorOnERPEnergielabel == "" && _errorOnSubsitioncodeIsdeSubsidy == "" && _errorOnDescription == "" &&
            _errorOnSurface == "" && _errorOnHeatPumpType == "" && _errorOnTapWaterMax == "" && _errorOnHeatingHOH == "" &&
            _errorOnUnderfloorHeatingType == "" && _errorOnHeatingAdjustment == "" && _errorOnAttachments == "" && _errorOnNumberOfFloors == "") {
            _isValidated = true;
        }

        await this.SetState2({
            formValidation: {
                ErrorOnPrincipleSchemeCode: isShowError ? _errorOnPrincipleSchemeCode : "",
                ErrorOnTitle: isShowError ? _errorOnTitle : "",
                ErrorOnPositionTitle: isShowError ? _errorOnPositionTitle : "",
                ErrorOnCode: isShowError ? _errorOnCode : "",
                ErrorOnERPEnergielabel: isShowError ? _errorOnERPEnergielabel : "",
                ErrorOnSubsitioncodeIsdeSubsidy: isShowError ? _errorOnSubsitioncodeIsdeSubsidy : "",
                ErrorOnDescription: isShowError ? _errorOnDescription : "",
                ErrorOnSurface: isShowError ? _errorOnSurface : "",
                ErrorOnPower: isShowError ? _errorOnPower : "",
                ErrorOnHeatPumpType: isShowError ? _errorOnHeatPumpType : "",
                ErrorOnTapWaterMax: isShowError ? _errorOnTapWaterMax : "",
                ErrorOnUnderfloorHeatingType: isShowError ? _errorOnUnderfloorHeatingType : "",
                ErrorOnHeatingAdjustment: isShowError ? _errorOnHeatingAdjustment : "",
                ErrorOnAttachments: isShowError ? _errorOnAttachments : "",
                ErrorOnNumberOfFloors: isShowError ? _errorOnNumberOfFloors : "",
                ErrorOnHeatingHOH: isShowError ? _errorOnHeatingHOH : "",
                IsValidate: _isValidated
            }
        });
    }

    onSave = async () => {
        const { solutionId } = this.props.match.params;

        await this.validateForm(true);
        const { formValidation } = this.state;

        if (!formValidation.IsValidate) return;

        await this.SetState2({
            isLoading: true,
            errorMessage: undefined
        });

        if (solutionId) {
            await this.updateSolution(solutionId);
        } else {
            await this.saveSolution();
        }
    }




    private createImage = async (solutionId: string) => {
        const { selectedFileImage } = this.state;
        if (selectedFileImage) {
            await SolutionImageService.Create(selectedFileImage, solutionId);
        }
    }

    private updateImage = async (solutionId: string) => {
        const { selectedFileImage } = this.state;
        if (selectedFileImage) {
            await SolutionImageService.UpdateContent(selectedFileImage, solutionId);
        }
    }

    private saveAttachments = (files: File[], parentId: string) => {
        Array.from(files).forEach(file => {
            SolutionAttachmentService.Create(file, parentId)
                .then(() => {
                    this.setState({
                        errorMessage: undefined
                    });
                })
                .catch(reason => {

                    this.setState({
                        errorMessage: reason
                    });
                })
        });
    }

    private deletePositionHeader = async (positionHeader: ISolutionPositionVM, solution: ISolutionVM) => {
        SolutionPositionService.Delete_PosHeader(solution.id, positionHeader.id)
            .then(result => {
                return ""
            })
            .catch(reason => {

                this.setState({
                    errorMessage: reason
                });
                return ""
            })
    }

    private createupdatePositionHeader = async (positionHeader: ISolutionPositionVM, solutionId: string) => {
        if (positionHeader.id && positionHeader.id != Util.EmptyGuid()) {
            var updateResult = await SolutionPositionService.Update_PosHeader(positionHeader, solutionId, positionHeader.id);
            return updateResult.value.id;
        }
        else {
            var createResult = await SolutionPositionService.Create_PosHeader(positionHeader, solutionId);
            return createResult.value.id;
        }
    };

    private createupdatePositionItem = async (solutionPosition: ISolutionPositionItemRow, solutionId: string, positionHeaderId: string, isDel?: boolean) => {
        try {
            if (solutionPosition.solutionPositionItem.id && solutionPosition.solutionPositionItem.id != Util.EmptyGuid()) {
                if (isDel) {
                    await SolutionPositionService.Delete_PosItem(solutionId, positionHeaderId, solutionPosition.solutionPositionItem.id);
                } else {
                    await SolutionPositionService.Update_PosItem(solutionPosition.solutionPositionItem, solutionId, positionHeaderId, solutionPosition.solutionPositionItem.id);
                }
            }
            else {
                await SolutionPositionService.Create_PosItem(solutionPosition.solutionPositionItem, solutionId, positionHeaderId)
            }
        } catch (error) {
            this.setState({
                errorMessage: "" + error
            });
        }
    }

    private saveSolution = async () => {
        const { positionHeaderAndItemRow, selectedFileAttachments, solution, selectedFileImage } = this.state;

        try {
            const response = await SolutionService.Create(solution);
            if (response.value && response.value.id) {

                if (selectedFileImage) {
                    await this.createImage(response.value.id);
                }

                for (let _posHeaderAndItemRow of Object.values(positionHeaderAndItemRow)) {

                    if (_posHeaderAndItemRow.positionItemRow && _posHeaderAndItemRow.positionItemRow.length > 0) {
                        const responsePositionId = await this.createupdatePositionHeader(_posHeaderAndItemRow.positionHeader, response.value.id);
                        for (const row of _posHeaderAndItemRow.positionItemRow) {
                            this.createupdatePositionItem(row, response.value.id, responsePositionId, row.isDel);
                        }
                    }
                }



                if (selectedFileAttachments && selectedFileAttachments.length > 0) {
                    this.saveAttachments(selectedFileAttachments!, response.value.id);
                }
            }

            this.setState({ isLoading: false });
            Util.Navigate(`Solutions/`);

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: "" + error
            });
        }
    }

    private updateSolution = async (solutionId: string) => {
        const { positionHeaderAndItemRow, solution, selectedFileImage, loadedImage } = this.state;

        const responseSolution = await SolutionService.Update(solution, solutionId);

        if (responseSolution.value && responseSolution.value.id) {

            if (selectedFileImage) {
                if (loadedImage) {
                    await this.updateImage(responseSolution.value.id);
                } else {
                    await this.createImage(responseSolution.value.id);
                }
            }

            for (let _posHeaderAndItemRow of Object.values(positionHeaderAndItemRow)) {

                if (responseSolution.value.numberOfPositions < (_posHeaderAndItemRow.positionHeader.positionNumber + 1)) {
                    await this.deletePositionHeader(_posHeaderAndItemRow.positionHeader, responseSolution.value);

                } else {
                    this.createupdatePositionHeader(_posHeaderAndItemRow.positionHeader, responseSolution.value.id).then((responsePositionId: string) => {
                        if (_posHeaderAndItemRow.positionItemRow && _posHeaderAndItemRow.positionItemRow.length > 0) {
                            _posHeaderAndItemRow.positionItemRow!.map((_positionRow) => {

                                this.createupdatePositionItem(_positionRow,
                                    responseSolution.value.id, responsePositionId, _positionRow.isDel);

                            });
                        }
                    });
                }

            }

            if (this.state.selectedFileAttachments && this.state.selectedFileAttachments.length > 0) {

                this.saveAttachments(this.state.selectedFileAttachments!, responseSolution.value.id);
            }
        }
        Util.Navigate(`Solutions/`);
    }




    range = (start: number, end: number) => {
        var arr: number[] = [];
        for (var i = start; i <= end; i++) {
            arr.push(i);
        }
        return arr;
    }

    private submitPositionModal = async () => {
        const { items, activePositionIndexModal, positionHeaderAndItemRow } = this.state;

        if (items && items.length > 0) {

            let itemList = items.filter(item => item.isChecked == true);

            let _existingPositionItems: ISolutionPositionItemRow[] = positionHeaderAndItemRow[activePositionIndexModal].positionItemRow || [];

            for (var _item of itemList) {
                if (_item.item?.itemNumber) {
                    let _IsolutionPositionItemRow: ISolutionPositionItemRow = {
                        item: _item.item,
                        solutionPositionItem: {
                            id: Util.EmptyGuid(),
                            navisionKey: _item.item.itemNumber,
                            psNumber: '001',
                            quantity: Math.max(1, _item.numberOfItems),
                            allowedAction: AllowedActionEnum.None
                        }
                    };
                    _existingPositionItems.push(_IsolutionPositionItemRow);
                }
            }

            positionHeaderAndItemRow[activePositionIndexModal].positionItemRow = _existingPositionItems;

            await this.SetState2({
                positionHeaderAndItemRow: positionHeaderAndItemRow,
                items: [],
                activePositionIndexModal: -1
            });

        }
    };


    cancelPositionModal = async () => {
        await this.SetState2({ items: [], activePositionIndexModal: -1 });
    };

    searchItems = async () => {
        await this.SetState2({ isLoadingModal: true });
        const items = await ItemService.Search(this.state.searchText || "");

        if (items == null) {
            await this.SetState2({
                items: undefined,
                isLoadingModal: false
            });
        } else {
            let _itemList = items.map(item => {
                const itemListItem: IItemList = { item: item, isChecked: false, numberOfItems: 0 };
                return itemListItem;
            });
            await this.SetState2({
                items: _itemList,
                isLoadingModal: false
            });
        }


    }

    deletePositionItemRow = (_itemRow: ISolutionPositionItemRow, solutionId: string, positionId: string, positionIndex: number) => {
        const { positionHeaderAndItemRow } = this.state;
        if (_itemRow.solutionPositionItem.id) {
            _itemRow.isDel = true;
            this.setState({ positionHeaderAndItemRow: positionHeaderAndItemRow });
        }

    }

    removePositionItemRow = (_itemRow: ISolutionPositionItemRow, index: number) => {
        const { positionHeaderAndItemRow } = this.state;

        let _solutionPositionItemRow: ISolutionPositionItemRow[] | undefined = positionHeaderAndItemRow[index].positionItemRow;

        if (_solutionPositionItemRow) {
            _solutionPositionItemRow = _solutionPositionItemRow.filter(function (itemRow) { return itemRow != _itemRow });
            let _positionHeaderAndItemRow = positionHeaderAndItemRow;
            _positionHeaderAndItemRow[index].positionItemRow = _solutionPositionItemRow;
            this.setState({ positionHeaderAndItemRow: _positionHeaderAndItemRow });
        }
    }

    editPositionItemRow = (_itemRow: ISolutionPositionItemRow, _tabIndex: number, _itemIndex: number) => {

        this.setState({
            editedPosition: { itemRow: _itemRow, tabIndex: _tabIndex, itemIndex: _itemIndex },
            editPositionModal: true,
            editItemQuantity: _itemRow.solutionPositionItem.quantity,
            editItemPsnr: _itemRow.solutionPositionItem.psNumber
        });
    }

    private closeEditedPosition = (isCancel: boolean) => {
        const { editedPosition, editItemPsnr, editItemQuantity } = this.state;
        if (!isCancel) {
            let solutionPositionItem = editedPosition!.itemRow.solutionPositionItem;
            solutionPositionItem.psNumber = editItemPsnr!;
            solutionPositionItem.quantity = editItemQuantity!;
        }
        this.setState({ editedPosition: undefined, editPositionModal: false });
    };

    private onBackToMain = () => Util.Navigate("/");

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    loadLinkSolutions = async () => {
        const response = await SolutionService.GetAll_Related()
        let _linkSolutionList = response.value.map((solution) => {
            return {
                linkedSolution: solution,
                isChecked: false
            }
        });

        await this.SetState2({
            errorMessage: undefined,
            linkedSolutionList: _linkSolutionList
        });
    }

    private submitRelatedSolutionModal = () => {
        if (this.state.linkedSolutionList && this.state.linkedSolutionList.length > 0) {
            let _linkedSolutions: ILinkedSolutionList[] = this.state.linkedSolutionList && this.state.linkedSolutionList.filter(item => item.isChecked == true);

            const { solution } = this.state;
            let _relatedSolutions: IRelatedSolutionVM[] = solution.relatedSolutions || [];

            _linkedSolutions.map(ls => {
                let _relatedSolution: IRelatedSolutionVM = { id: ls.linkedSolution.id, title: ls.linkedSolution.title, sortOrder: 0 } as IRelatedSolutionVM;
                _relatedSolutions.push(_relatedSolution);
            })
            solution.relatedSolutions = _relatedSolutions;
            this.setState({ solution }, () => { this.cancelRelatedSolutionModal() });

        }
    };

    private cancelRelatedSolutionModal = () => {
        let _linkSolutionList: ILinkedSolutionList[] = this.state.linkedSolutionList;
        _linkSolutionList.length > 0 && _linkSolutionList.map((solution, index) => {
            _linkSolutionList[index].isChecked = false;
        })

        this.setState({ linkedSolutionList: _linkSolutionList, isrelatedSolutionShow: false });
    };

    private removeRelatedSolution = (rSolution: IRelatedSolutionVM, rIndex: number) => {
        const { solution } = this.state;
        let _relatedSolutions: IRelatedSolutionVM[] = solution.relatedSolutions || [];
        _relatedSolutions.splice(rIndex, 1);
        solution.relatedSolutions = _relatedSolutions;
        this.setState({ solution });
    }

    private solutionPositionItemSorter = (a: ISolutionPositionItemRow, b: ISolutionPositionItemRow): number => {
        if (a && !b) return 1;
        if (!a && b) return -1;
        if (!a && !b) return 0;
        if (a.solutionPositionItem.psNumber > b.solutionPositionItem.psNumber) return 1;
        if (a.solutionPositionItem.psNumber < b.solutionPositionItem.psNumber) return -1;
        if (a.item && !b.item) return 1;
        if (!a.item && b.item) return -1;
        if (!a.item && !b.item) return 0;
        if (a.item!.description == b.item!.description) return 0;
        return ((a.item?.description || "") > (b.item?.description || "")) ? 1 : -1;
    }



    confirmDialog: ConfirmDialog | undefined | null;

    updateSolutionProperty = async (solutionUpdateProp: Partial<ISolutionVM>) => {
        let { solution } = this.state;
        const solutionUpdate = { ...solution, ...solutionUpdateProp };
        await this.SetState2({ solution: solutionUpdate });
    }

    detectPrincipleDiagram = async () => {
        const { solution } = this.state;
        await this.SetState2({ detectedDiagramStatus: "loading" });
        const diagramCode = solution.principleDiagramCode;
        if (diagramCode) {
            try {
                var diagram = await SolutionPrincipleDiagramService.GetDiagram(diagramCode);
                await this.SetState2({ detectedDiagram: diagram, detectedDiagramStatus: diagram ? "found" : "error" })
            } catch (error) {
                await this.SetState2({ detectedDiagram: undefined, detectedDiagramStatus: "error" })
            }
        } else {
            await this.SetState2({ detectedDiagram: undefined, detectedDiagramStatus: "unknown" });
        }
    }

    getValidationMessagesNew = (): JSX.Element[] => {
        const { formValidation } = this.state;

        let messages: JSX.Element[] = [];
        for (var key in formValidation) {
            var errorMessage = formValidation[key];
            if (key.startsWith("ErrorOn") && errorMessage) {
                messages.push(<li>{errorMessage}</li>);
            }
        }

        return messages;
    }

    getValidationMessagesOld = (): JSX.Element[] => {
        const { formValidation } = this.state;

        let messages: JSX.Element[] = [];
        if (formValidation.ErrorOnTitle) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.title" defaultMessage="There are error" /></li>);
        }
        if (formValidation.ErrorOnPrincipleSchemeCode) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.principleScheme" defaultMessage="Pricipe schema code ontbreekt" /></li>);
        }
        if (formValidation.ErrorOnCode) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.Code" defaultMessage="There are error" /></li>);
        }
        if (formValidation.ErrorOnERPEnergielabel) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.erpEnergielabel" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnSubsitioncodeIsdeSubsidy) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.subsitioncodeIsdeSubsidy" defaultMessage="There are error" /></li>);
        }
        if (formValidation.ErrorOnDescription) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.description" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnSurface) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.surface" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnPower) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.power" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnHeatPumpType) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.heatpumptype" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnTapWaterMax) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.tapwatermax" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnUnderfloorHeatingType) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.underfloorheatingtype" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnHeatingAdjustment) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.heatingadjustment" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnAttachments) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.attachments" defaultMessage="There are error" /></li>)
        }
        if (formValidation.ErrorOnNumberOfFloors) {
            messages.push(<li><FormattedMessage id="errors.configurewizard.numberoffloors" defaultMessage="There are error" /></li>)
        }
        return messages;
    }

    getPositionTypeOptions = () => {
        const options = EnumUtils.getDropdownOptions(PositionTypeEnum, AppUtils.GetPositionTypeName)
        return options;
    }

    render() {
        const { solution, formValidation, imagePreviewUrl, searchText, items, positionHeaderAndItemRow,
            loadedImage, selectedFileAttachments, loadedAttachments,
            isLoading, linkedSolutionList, relatedSolutions, isrelatedSolutionShow, detectedDiagram, detectedDiagramStatus } = this.state;
        let numberOfPositionArray: number[] = this.range(0, solution.numberOfPositions - 1);
        const intl = this.props.intl;
        const validationMessages = this.getValidationMessagesNew();

        let _imagePreviewUrl: string = imagePreviewUrl ? imagePreviewUrl :
            (solution.id && (loadedImage && loadedImage.id)) ? `${Service.ApiUrl}/v1/solutions/${solution.id}/image/thumbnail` :
                'https://www.nfrccommunity.org/global_graphics/default-store-350x350.jpg';


        return (
            <div className="page-SolutionEdit mt-2 quote">
                <ConfirmDialog ref={cf => this.confirmDialog = cf} title={intl.formatMessage({ id: "pages.configurationwizard.confirm.title" })} question={intl.formatMessage({ id: "pages.configurationwizard.confirm.question" })} />
                <div className="text-right">
                    <DefaultButton onClick={() => Util.Navigate("/Solutions")}>
                        <FormattedMessage id="general.backToSolution" defaultMessage="Terug naar oplossingen" />
                    </DefaultButton>
                    &nbsp;&nbsp;
                    <DefaultButton onClick={() => Util.Navigate("/")}>
                        <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                    </DefaultButton>


                </div>

                <h1 className="page-heading"><FormattedMessage id="page.configurationwizard.header" defaultMessage="OFFERTEGENERATOR" /></h1>
                <h2><FormattedMessage id="page.configurationwizard.subheader" defaultMessage="OPLOSSING-CONFIGURATOR" /></h2>
                {this.state.solution.id ?
                    <h3><FormattedMessage id="page.configurationwizard.edit" defaultMessage="BEWERK OPLOSSING (1)" /> -  <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" /></h3> :
                    <h3><FormattedMessage id="page.configurationwizard.new" defaultMessage="NIEUWE OPLOSSING" /> -  <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" /></h3>
                }
                {validationMessages.length > 0 && <div className="col-12 validationBar">
                    <MessageBar messageBarType={MessageBarType.error}><ul>{validationMessages}</ul></MessageBar>
                </div>}

                {this.state.loadingMessage && <div className="col-12 mb-4">
                    <Alert color="info"><Spinner size={SpinnerSize.small} /> &nbsp;&nbsp; {this.state.loadingMessage}</Alert>
                </div>
                }
                {!isLoading && !this.state.errorMessage &&
                    <>
                        <Pivot>
                            <PivotItem headerText={intl.formatMessage({ id: "page.configurationwizard.navheader1" })} itemKey="1">

                                <Stack horizontal>
                                    <Stack.Item grow={3} styles={{ root: { padding: 10 } }}>

                                        <Label>Titel van de oplossing (*)</Label>
                                        <TextField value={solution.title} onChange={(ev, newValue) => this.handleChange2({ "title": newValue })} />
                                        {formValidation.ErrorOnTitle != "" && <span className='error'>{formValidation.ErrorOnTitle}</span>}

                                        <Label>Oplossing is actief</Label>
                                        <Checkbox checked={solution.isEnabled} onChange={(ev, newValue) => this.handleChange2({ "isEnabled": newValue })} />

                                        <SpinButton
                                            label="Aantal offerteposities"
                                            labelPosition={Position.top}
                                            value={"" + solution.numberOfPositions} style={{ width: 75 }}
                                            onChange={(ev, newValue) => this.handleChange2({ "numberOfPositions": NumUtils.ParseNumber(newValue) || undefined })} />


                                        {solution.solutionType == SolutionTypeEnum.HeatPump && <>
                                            <Label>Principeschema code (*)</Label>
                                            <TextField
                                                value={solution.principleDiagramCode}
                                                onChange={(ev, newValue) => this.handleChange2({ "principleDiagramCode": newValue })}
                                                style={{ width: "200px" }} />

                                            <div style={{ marginTop: "5px" }}>
                                                {solution.principleDiagramCode && solution.principleDiagramCode.length > 3 && <>
                                                    {detectedDiagram && <>
                                                        Diagram '<b>{detectedDiagram.metadata.filenameWithExtension}</b>' found, test:&nbsp;
                                                        <a href={"data:" + detectedDiagram.content.contentType + ";base64," + detectedDiagram.content.content} target="_blank" download={detectedDiagram.metadata.filenameWithExtension}>Open now</a>&nbsp;|&nbsp;
                                                        <a href={detectedDiagram.metadata.link} target="_blank">Link to SharePoint</a>
                                                        <br />
                                                    </>}
                                                    {detectedDiagramStatus == "error" && <>
                                                        <MessageBar messageBarType={MessageBarType.warning}>Unable to detecting diagram, please check if the code is valid.</MessageBar>
                                                    </>}

                                                    {detectedDiagramStatus == "loading" && <div style={{ width: "200px" }}>
                                                        <Spinner size={SpinnerSize.large} label="Searching SharePoint..." labelPosition="left" />
                                                    </div>}

                                                </>}

                                                {detectedDiagramStatus != "loading" && <>
                                                    <DefaultButton
                                                        disabled={!solution.principleDiagramCode || solution.principleDiagramCode.length <= 3}
                                                        onClick={() => this.detectPrincipleDiagram()}
                                                        text="Detect diagram in SharePoint" />
                                                </>}

                                                {formValidation.ErrorOnPrincipleSchemeCode != "" && <MessageBar messageBarType={MessageBarType.error}>{formValidation.ErrorOnPrincipleSchemeCode}</MessageBar>}
                                            </div>

                                        </>}


                                        {solution.solutionType != SolutionTypeEnum.UnderfloorHeating && <>
                                            <Label>Code (*)</Label>
                                            {formValidation.ErrorOnCode != "" && <span className='error'>{formValidation.ErrorOnCode}</span>}
                                            <TextField onChange={(ev, txt) => this.handleChange2({ "code": txt })} value={solution.code} />
                                        </>}

                                        {solution.solutionType == SolutionTypeEnum.HeatPump && <>
                                            <Label>ErP energielabel (*)</Label>
                                            {formValidation.ErrorOnERPEnergielabel != "" && <span className='error'>{formValidation.ErrorOnERPEnergielabel}</span>}
                                            <TextField onChange={(ev, txt) => this.handleChange2({ "erpEnergielabel": txt })} value={solution.erpEnergielabel} />
                                        </>}

                                        {solution.solutionType == SolutionTypeEnum.HeatPump && <>
                                            <Label>Subsitiecode ISDE subsidie (*)</Label>
                                            {formValidation.ErrorOnSubsitioncodeIsdeSubsidy != "" && <span className='error'>{formValidation.ErrorOnSubsitioncodeIsdeSubsidy}</span>}
                                            <TextField onChange={(ev, txt) => this.handleChange2({ "subsitioncodeIsdeSubsidy": txt })} value={solution.subsitioncodeIsdeSubsidy} />
                                        </>}

                                        <Label>Omschrijving van de oplossing (*)</Label>
                                        {formValidation.ErrorOnDescription != "" && <span className='error'>{formValidation.ErrorOnDescription}</span>}
                                        <TextField multiline rows={5} onChange={(ev, txt) => this.handleChange2({ "description": txt })} value={solution.description} />

                                        <Label>Deze oplossing is alleen selecteerbaar als gerelateerde oplossing</Label>
                                        <Checkbox checked={solution.isSelectableAsRelatedSolution} onChange={(ev, checked) => this.handleChange2({ "isSelectableAsRelatedSolution": checked })} />

                                    </Stack.Item>
                                    <Stack.Item grow={1}>
                                        <Label>Afbeelding</Label>
                                        <img width={128} src={_imagePreviewUrl} /><br />
                                        <a onClick={this.onImageClick}><small className="text-primary text-underline cp">Afbeelding wijzigen...</small></a>
                                        <input type="file" id="fileImage" accept="image/*" onChange={this.onImageChange} style={{ display: "none" }} />
                                    </Stack.Item>
                                </Stack>

                            </PivotItem>
                            {numberOfPositionArray.map(positionIndex => {

                                let _positionHeaderAndItem: ISolutionPositionHeaderAndItemRow = positionHeaderAndItemRow[positionIndex];

                                var grossTotal: number = 0;

                                let positionTitle = _positionHeaderAndItem?.positionHeader.title ? _positionHeaderAndItem.positionHeader.title : `Positie ${positionIndex + 1}`;


                                return <PivotItem headerText={positionTitle} itemKey={`#pos${positionIndex}`} >

                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <label>POS titel</label>
                                            <input type="text" className="form-control" placeholder=""
                                                onChange={(ev) => this.handlePositionChange(ev, positionIndex, undefined)} name="title" value={_positionHeaderAndItem.positionHeader.title}
                                            />
                                        </div>
                                        {solution.solutionType == SolutionTypeEnum.UnderfloorHeating &&
                                            <div className="col-12 form-group">
                                                <label>Positie soort</label>
                                                <ComboBox options={this.getPositionTypeOptions()}
                                                    selectedKey={_positionHeaderAndItem.positionHeader.positionType}
                                                    onChange={(ev, item) => this.handlePositionChange2({ positionType: item?.key as any }, positionIndex)}
                                                />
                                            </div>}
                                        <div className="col-12 form-group">
                                            <label htmlFor={`pos${positionIndex}_isNetto`}>Is Netto</label>
                                            <input type="checkbox" className="form-control" placeholder="" style={{ width: "20px" }}
                                                onChange={(ev) => this.handlePositionChange(ev, positionIndex, undefined)} name="isNetto" checked={_positionHeaderAndItem.positionHeader.isNetto}
                                            />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Artikelen POS</label>
                                            <div className="table-responsive wizard-table">
                                                <table className="table table-borderless table-sm">
                                                    <tr>
                                                        <th>PS-nr.</th>
                                                        <th>Artikelnummer</th>
                                                        <th>Aantal</th>
                                                        <th>Ehd</th>
                                                        <th>Omschrijving</th>
                                                        <th className="right">Per st.</th>
                                                        <th className="right">Totaal</th>
                                                        <th></th>
                                                    </tr>
                                                    {

                                                        _positionHeaderAndItem.positionItemRow && _positionHeaderAndItem.positionItemRow.length > 0 &&
                                                        [..._positionHeaderAndItem.positionItemRow]
                                                            .sort(this.solutionPositionItemSorter)

                                                            .map((itemRow, itemRowIndex) => {
                                                                const grossPricePerUnit = itemRow.item ? itemRow.item.grossPricePerUnit : 0;

                                                                return !itemRow.isDel && (
                                                                    <tr>
                                                                        <td>{itemRow.solutionPositionItem.psNumber}</td>
                                                                        <td>{itemRow.item ? itemRow.item.itemNumber : "NOT FOUND"}</td>
                                                                        <td>{itemRow.solutionPositionItem.quantity}</td>
                                                                        <td>{itemRow.item ? itemRow.item.baseUnitOfMeasure : "NOT FOUND"}</td>
                                                                        <td>{itemRow.item ? itemRow.item.description : "NOT FOUND"}</td>
                                                                        <td className="right"><Money amount={grossPricePerUnit} decimals={2} symbol="€" /></td>
                                                                        <td className="right"><Money amount={itemRow.solutionPositionItem.quantity * grossPricePerUnit} decimals={2} symbol="€" /></td>
                                                                        <td className="buttonColumn">
                                                                            <FaPencilAlt className="text-dark" onClick={() => this.editPositionItemRow(itemRow, positionIndex, itemRowIndex)}></FaPencilAlt>&nbsp;&nbsp;
                                                                            {itemRow.solutionPositionItem.id ?
                                                                                <FaTrash className="text-dark" onClick={() => this.deletePositionItemRow(itemRow, solution.id, _positionHeaderAndItem.positionHeader.id, positionIndex)}></FaTrash>
                                                                                :
                                                                                <FaTrash className="text-dark" onClick={() => this.removePositionItemRow(itemRow, positionIndex)}></FaTrash>

                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                    {
                                                        _positionHeaderAndItem.positionItemRow &&
                                                        _positionHeaderAndItem.positionItemRow.length > 0 &&
                                                        (
                                                            <tr>
                                                                <td colSpan={6}><b className="text-black">Subtotaal exclusief BTW</b></td>
                                                                <td className="right">
                                                                    <Money amount={_positionHeaderAndItem.positionItemRow.reduce((sum, current) => sum + ((current.item ? current.item.grossPricePerUnit : 0) * current.solutionPositionItem.quantity), 0)} decimals={2} symbol="€" />
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            //
                                                        )
                                                    }


                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col form-group">
                                                    <PrimaryButton onClick={() => this.setState({ activePositionIndexModal: positionIndex })}>
                                                        Artikel toevoegen
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                            })
                            }



                            <PivotItem headerText={intl.formatMessage({ id: "page.configurationwizard.navheader3" })} itemKey="3">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <b>
                                            <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" />:
                                            {solution.title}
                                        </b>
                                        <br />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">

                                            <div className="col-12 form-group" hidden={solution.solutionType != SolutionTypeEnum.HeatPump && solution.solutionType != SolutionTypeEnum.Source}>
                                                <Label>Inzetbereik</Label>
                                                <div>
                                                    <div style={{ display: "inline-block" }}>
                                                        <SpinButton label="Tussen" labelPosition={Position.start}
                                                            value={solution.powerMin?.toString()}
                                                            onChange={(ev, value) => this.updateSolutionProperty({ powerMin: parseFloat(value || "0") })}
                                                            style={{ width: "100px" }} />
                                                    </div>
                                                    <div style={{ display: "inline-block", paddingLeft: "5px" }}>
                                                        <SpinButton label="en" labelPosition={Position.start}
                                                            value={solution.powerMax?.toString()}
                                                            onChange={(ev, value) => this.updateSolutionProperty({ powerMax: parseFloat(value || "0") })}
                                                            style={{ width: "100px" }} />
                                                    </div>
                                                    <div style={{ display: "inline-block", paddingLeft: "5px" }}>
                                                        <Label>KW</Label>
                                                    </div>
                                                </div>


                                            </div>

                                            {solution.solutionType == SolutionTypeEnum.Source && <div className="col-12 form-group">
                                                <Dropdown
                                                    label="Geschikt voor renovatie of nieuwbouw"
                                                    style={{ width: "200px" }}
                                                    selectedKey={solution.renovationOrNew ? EnumUtils.getOrdinal(RenovationNewChoiceEnum, solution.renovationOrNew) : undefined}
                                                    onChange={(ev, option) => this.updateSolutionProperty({ renovationOrNew: option?.key as RenovationNewChoiceEnum })}
                                                    options={[{
                                                        key: RenovationNewChoiceEnum.NewBuild,
                                                        text: "Nieuwbouw",
                                                    },
                                                    {
                                                        key: RenovationNewChoiceEnum.Renovation,
                                                        text: "Renovatie"
                                                    },
                                                    {
                                                        key: RenovationNewChoiceEnum.Both,
                                                        text: "Renovatie en Nieuwbouw"
                                                    }]} />
                                            </div>}

                                            {solution.solutionType == SolutionTypeEnum.HeatPump && <div className="col-12 form-group">
                                                <SpinButton label="Vermogen bij -10C (KW)" labelPosition={Position.top}
                                                    value={solution.powerMinus10Deg?.toString()}
                                                    onChange={(ev, value) => this.updateSolutionProperty({ powerMinus10Deg: parseFloat(value || "0") })}
                                                    style={{ width: "100px" }} />
                                            </div>}

                                            {solution.solutionType == SolutionTypeEnum.Regulator && <div className="col-12 form-group">
                                                <SpinButton label="Aantal verdiepingen" labelPosition={Position.top}
                                                    value={solution.numberOfFloors?.toString()}
                                                    onChange={(ev, value) => this.updateSolutionProperty({ numberOfFloors: parseFloat(value || "0") })}
                                                    style={{ width: "100px" }} />
                                            </div>}

                                            {solution.solutionType == SolutionTypeEnum.HeatPump && <div className="col-12 form-group">
                                                <Dropdown label="Type warmtepomp" options={[
                                                    { key: HeatPumpTypeEnum.airWater, text: "Lucht water", selected: solution.heatPumpType == HeatPumpTypeEnum.airWater },
                                                    { key: HeatPumpTypeEnum.groundBound, text: "Grond gebonden", selected: solution.heatPumpType == HeatPumpTypeEnum.groundBound },
                                                ]}
                                                    style={{ width: "200px" }}
                                                    selectedKey={solution.heatPumpType}
                                                    onChange={(ev, opt) => this.updateSolutionProperty({ heatPumpType: opt!.key as HeatPumpTypeEnum })} />
                                            </div>}

                                            {solution.solutionType == SolutionTypeEnum.HeatPump && <div className="col-12 form-group">
                                                <SpinButton label="Tapwater (MAX)" labelPosition={Position.top}
                                                    value={solution.tapWaterMax?.toString()}
                                                    onChange={(ev, value) => this.updateSolutionProperty({ tapWaterMax: parseFloat(value || "0") })}
                                                    style={{ width: "100px" }} />

                                            </div>}

                                            <div className="col-12 form-group" hidden={solution.solutionType != SolutionTypeEnum.UnderfloorHeating && solution.solutionType != SolutionTypeEnum.Regulator}>

                                                <Label>M2 leefruimte</Label>
                                                <div style={{ display: "inline-block" }}>
                                                    <SpinButton label="Tussen" labelPosition={Position.start}
                                                        value={solution.surfaceAreaMin?.toString()}
                                                        onChange={(ev, value) => this.updateSolutionProperty({ surfaceAreaMin: parseFloat(value || "0") })}
                                                        style={{ width: "100px" }} />
                                                </div>
                                                <div style={{ display: "inline-block", paddingLeft: "5px" }}>
                                                    <SpinButton label="en" labelPosition={Position.start}
                                                        value={solution.surfaceAreaMax?.toString()}
                                                        onChange={(ev, value) => this.updateSolutionProperty({ surfaceAreaMax: parseFloat(value || "0") })}
                                                        style={{ width: "100px" }} />
                                                </div>

                                                <Dropdown
                                                    label="Hart-op-hart afstand"
                                                    style={{ width: "200px" }}
                                                    selectedKey={solution.floorHeatingHOH}
                                                    onChange={(ev, option) => this.updateSolutionProperty({ floorHeatingHOH: option?.key as number })}
                                                    options={[
                                                        { key: 100, text: "100 mm" },
                                                        { key: 150, text: "150 mm" }]}
                                                />
                                            </div>

                                            <div className="col-12 form-group" hidden={solution.solutionType != SolutionTypeEnum.UnderfloorHeating}>
                                                <Dropdown
                                                    label="Type vloerverwarming"
                                                    style={{ width: "400px" }}
                                                    selectedKey={solution.underfloorHeatingType ? EnumUtils.getOrdinal(UnderfloorHeatingTypeEnum, solution.underfloorHeatingType) : undefined}
                                                    onChange={(ev, option) => this.updateSolutionProperty({ underfloorHeatingType: option?.key as UnderfloorHeatingTypeEnum })}
                                                    options={EnumUtils.getOrdinalValues(UnderfloorHeatingTypeEnum).map(ufht => {
                                                        return {
                                                            key: ufht,
                                                            text: AppUtils.GetUnderfloorHeatingTypeName(ufht)
                                                        }
                                                    })} />
                                            </div>


                                            <div className="w-100"></div>
                                            <div className="col-auto form-group" hidden={solution.solutionType != SolutionTypeEnum.Regulator}>
                                                <label>Type naregeling</label>
                                                <select className="custom-select"
                                                    onChange={(ev) => this.handleChange(ev)}
                                                    name="heatingAdjustment"
                                                    value={solution.heatingAdjustment} >
                                                    {Object.keys(PostAdjustmentTypeEnum).map(heatingType => !isNaN(Number(heatingType)) &&
                                                        <option key={heatingType} value={heatingType}>
                                                            {intl.formatMessage({ "id": "enum.postAdjustmentType." + PostAdjustmentTypeEnum[heatingType] })}
                                                        </option>)}
                                                </select>
                                            </div>

                                            <div className="w-100"></div>


                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        {solution.solutionType == SolutionTypeEnum.HeatPump && <div>

                                            <Dropdown label="Function warmtepomp" options={[
                                                { key: HeatPumpFunctionEnum.Heating, text: "Verwarmen", selected: solution.functionHeatpump == HeatPumpFunctionEnum.Heating },
                                                { key: HeatPumpFunctionEnum.HeatingAndCooling, text: "Verwarmen en koelen", selected: solution.functionHeatpump == HeatPumpFunctionEnum.HeatingAndCooling },
                                            ]}
                                                style={{ width: "200px" }}
                                                selectedKey={solution.functionHeatpump}
                                                onChange={(ev, opt) => this.updateSolutionProperty({ functionHeatpump: opt!.key as HeatPumpFunctionEnum })} />

                                            <Label>RBE</Label>
                                            <Checkbox
                                                checked={solution.rbe}
                                                onChange={(ev, chk) => this.updateSolutionProperty({ rbe: chk })} />

                                            <Label>CV-Zijdig</Label>
                                            <Checkbox
                                                checked={solution.cvSide}
                                                onChange={(ev, chk) => this.updateSolutionProperty({ cvSide: chk })} />

                                        </div>}

                                        <Dropdown label="Wijzigingen in offerte toestaan" options={[
                                            { key: AllowedActionEnum.None, text: "Geen", selected: solution.allowedAction == AllowedActionEnum.None },
                                            { key: AllowedActionEnum.CanDelete, text: "Verwijderen", selected: solution.allowedAction == AllowedActionEnum.CanDelete },
                                            { key: AllowedActionEnum.CanDeleteAndModify, text: "Aanpassen en verwijderen", selected: solution.allowedAction == AllowedActionEnum.CanDeleteAndModify },
                                            { key: AllowedActionEnum.CanModify, text: "Aanpassen", selected: solution.allowedAction == AllowedActionEnum.CanModify }
                                        ]}
                                            style={{ width: "200px" }}
                                            selectedKey={solution.allowedAction}
                                            onChange={(ev, opt) => this.updateSolutionProperty({ allowedAction: opt!.key as AllowedActionEnum })} />

                                    </div>
                                </div>

                            </PivotItem>
                            <PivotItem headerText={intl.formatMessage({ id: "page.configurationwizard.navheader4" })} itemKey="4">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label>
                                            <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" />
                                        </label>
                                        <p>{solution.title}</p>
                                    </div>
                                    <div className="col-12 form-group">
                                        <label>Belangrijke informatie</label>
                                        <Editor
                                            apiKey="7v2uqiydes7pvc5meclmtbo6zk0wl0nro8at707suho9tvj7"
                                            initialValue={this.state.importantInformationInitialEditorText}
                                            init={{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                                            }}
                                            onEditorChange={this.handleEditorChange}
                                        // value={this.state.importantInformationEditorText}

                                        />
                                    </div>
                                </div>
                            </PivotItem>
                            <PivotItem headerText={intl.formatMessage({ id: "page.configurationwizard.navheader5" })} itemKey="5">
                                <div className="row">
                                    <div className="col-12 form-group mb-5">
                                        <label>
                                            <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" />
                                        </label>
                                        <p>{solution.title}</p>
                                    </div>
                                    <div className="col-12 form-group">
                                        <label className="mb-4">Bijlagen (PDF); bijlagen worden bijgevoegd aan de oplossing in de offerte</label>
                                        <div className="row">
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        {

                                            <ListGroup>
                                                {
                                                    selectedFileAttachments && selectedFileAttachments.length > 0 && selectedFileAttachments.map((attachment, index) => {
                                                        return <ListGroupItem className="justify-content-between">
                                                            {
                                                                <span style={{ paddingRight: 10 }}>{attachment.name}</span>
                                                            }
                                                            <DefaultButton iconProps={{ iconName: "RecycleBin" }} onClick={() => this.onRemoveAttachment(index)}>
                                                                X
                                                            </DefaultButton>
                                                        </ListGroupItem>
                                                    })
                                                }
                                                {
                                                    loadedAttachments && loadedAttachments.length > 0 && loadedAttachments.map((attachment) => {
                                                        return <ListGroupItem className="justify-content-between">
                                                            {
                                                                <a style={{ color: "#007bff" }} href={`${Service.ApiUrl}/v1/solutions/${solution.id}/attachments/${attachment.id}/content`} target="_blank">{attachment.fileName}</a>
                                                            }
                                                            <DefaultButton iconProps={{ iconName: "RecycleBin" }} onClick={() => this.onDeleteAttachment(solution.id, attachment.id)}>
                                                                X
                                                            </DefaultButton>
                                                        </ListGroupItem>
                                                    })
                                                }

                                            </ListGroup>

                                        }


                                    </div>
                                    <div className="row">
                                        <DefaultButton onClick={this.onImagesClick}>Toevoegen...</DefaultButton>
                                        <input type="file" id="fileImages" multiple={true} accept="application/pdf"
                                            onChange={this.onImagesChange} style={{ display: "none" }} />
                                    </div>
                                </div>
                            </PivotItem>
                            <PivotItem headerText={intl.formatMessage({ id: "page.configurationwizard.navheader6" })} itemKey="6">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label>
                                            <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekende oplossing" />
                                        </label>
                                        <p>{solution.title}</p>
                                    </div>
                                    <div className="col-12 form-group">
                                        <Table className="table table-bordered table-sm" style={{ width: "75%" }}>
                                            <tbody>
                                                <tr>
                                                    <th>Gekoppelde oplossingen</th>
                                                    <th>Volgorde</th>
                                                    <th style={{ textAlign: "center" }}><FaPlus className="text-dark cp" onClick={() => this.setState({ isrelatedSolutionShow: true })}></FaPlus></th>
                                                </tr>
                                                {
                                                    solution.relatedSolutions && solution.relatedSolutions.length > 0 &&
                                                    solution.relatedSolutions.map((rSol, rIndex) => {
                                                        return <tr>
                                                            <td>{rSol.title}</td>
                                                            <td style={{ width: 80 }}>
                                                                <Input type="number" style={{ width: 80 }} value={rSol.sortOrder} maxLength={4} max={9999} min={0}
                                                                    onChange={(ev) => {
                                                                        solution.relatedSolutions[rIndex].sortOrder = Number(ev.currentTarget.value);
                                                                        this.setState({ relatedSolutions });
                                                                    }}
                                                                    name="sortOrder"
                                                                />
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <FaTrash className="text-dark cp" onClick={() => this.removeRelatedSolution(rSol, rIndex)}
                                                                ></FaTrash>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                                {
                                                    solution.relatedSolutions && solution.relatedSolutions.length == 0 && <tr><td colSpan={5}>
                                                        <Alert className="small-alert" color="light "><FormattedMessage id="general.nodata" defaultMessage="No data found" /></Alert>
                                                    </td></tr>
                                                }

                                            </tbody>
                                        </Table>


                                    </div>
                                </div>
                            </PivotItem>
                        </Pivot>
                        <hr />
                        <div className="page-SolutionEdit-save">
                            <DefaultButton onClick={() => Util.Navigate(`Solutions/`)}>
                                <FormattedMessage id="general.cancel" defaultMessage="Annuleren" />
                            </DefaultButton>&nbsp;&nbsp;
                            <PrimaryButton onClick={() => this.onSave()}>
                                <FormattedMessage id="general.save" defaultMessage="Opslaan" />
                            </PrimaryButton>

                        </div>
                        <hr />
                        <br />
                    </>}


                {
                    this.state.isLoading &&
                    <Loading text={this.props.intl.formatMessage({ id: "solution.loading", defaultMessage: "Solution is loading..." })} />
                }
                {
                    !this.state.isLoading && this.state.errorMessage &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.configurationwizard.loading" defaultMessage={this.state.errorMessage} />
                    </Alert>
                }


                <Modal isOpen={this.state.activePositionIndexModal > -1 ? true : false} >
                    <ModalHeader><FormattedMessage id="page.configurationwizard.modal.activeposition.header" defaultMessage="Artikel toevoegen" /></ModalHeader>
                    <ModalBody>
                        <div className="row" style={{ padding: 5 }}>
                            <div className="col-md-7 col-sm-7">
                                <Label htmlFor="exampleEmail">Zoek artikel {items && items.length}</Label>

                                <Input type="text" name="email" id="exampleEmail" placeholder="Warmptepomp"
                                    onChange={(ev) => this.setState({ searchText: ev.target.value })} value={searchText} />
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <PrimaryButton
                                    style={{ position: "absolute", bottom: 0 }}
                                    onClick={() => this.searchItems()}>
                                    <FormattedMessage id="page.configurationwizard.modal.activeposition.searchbtn" defaultMessage="Zoek" />
                                </PrimaryButton>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 5 }}>
                            <div></div>
                            <div className="col-md-12" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                {this.state.isLoadingModal ? <div className="d-flex justify-content-center"><Spinner size={SpinnerSize.small} /></div> :
                                    <Table bordered height="200">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Item No</th>
                                                <th>Item omschrijving</th>
                                                <th>Item eenheid</th>
                                                <th>Aantal</th>
                                                <th>Bruto prijs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items && items.sort((i1, i2) => (i1.item.description || "") > (i2.item.description || "") ? 1 : -1).map((item) => {
                                                return <tr>
                                                    <td>
                                                        <CustomInput type="checkbox" id={`chk${item.item.itemNumber}`} name="customRadio"
                                                            onChange={() => {
                                                                item.isChecked = !item.isChecked;
                                                                if (item.isChecked && item.numberOfItems < 1) {
                                                                    item.numberOfItems = 1
                                                                }
                                                                this.setState({ isLoadingModal: false })
                                                            }}
                                                            checked={item.isChecked} />
                                                    </td>
                                                    <td>{item.item.itemNumber}</td>
                                                    <td>{item.item.description}</td>
                                                    <td>{item.item.baseUnitOfMeasure}</td>
                                                    <td><input type="number" value={item.numberOfItems} onChange={(e) => {
                                                        item.numberOfItems = Math.max(1, +e.target.value);
                                                        this.setState({ items: items });
                                                    }} className="aantal-input" hidden={!item.isChecked} /></td>
                                                    <td><Money amount={item.item.grossPricePerUnit} /></td>
                                                </tr>
                                            })
                                            }
                                            {!items && <tr><td colSpan={6}><Alert color="info">Geen artikelen gevonden.</Alert></td></tr>}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <DefaultButton onClick={() => this.cancelPositionModal()}>
                            <FormattedMessage id="general.cancel" defaultMessage="Annuleren" />
                        </DefaultButton>
                        <PrimaryButton onClick={() => this.submitPositionModal()}>
                            <FormattedMessage id="general.save" defaultMessage="Opslaan" />
                        </PrimaryButton>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={isrelatedSolutionShow} >
                    <ModalHeader>
                        <FormattedMessage id="page.configurationwizard.modal.relatedsolution.header" defaultMessage="Link Solutions" /></ModalHeader>
                    <ModalBody>

                        <div className="row" style={{ padding: 5 }}>
                            <div></div>
                            <div className="col-md-12" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                {this.state.isLoadingModal ? <div className="d-flex justify-content-center"><Spinner size={SpinnerSize.large} /></div> :
                                    <Table bordered height="200">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th><FormattedMessage id="general.title" defaultMessage="Title" /></th>
                                                <th><FormattedMessage id="general.description" defaultMessage="Description" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {linkedSolutionList && linkedSolutionList.map((sol) => {
                                                return <tr>
                                                    <td>
                                                        <CustomInput type="checkbox" id={`chk${sol.linkedSolution.id}`} name="customRadio"
                                                            onChange={() => { sol.isChecked = !sol.isChecked; this.setState({ isLoadingModal: false }) }}
                                                            checked={sol.isChecked} />
                                                    </td>
                                                    <td>{sol.linkedSolution.title}</td>
                                                    <td>{sol.linkedSolution.description}</td>
                                                </tr>
                                            })
                                            }
                                            {!linkedSolutionList && <tr><td colSpan={5}><Alert color="info">No item found.</Alert></td></tr>}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <DefaultButton onClick={() => this.cancelRelatedSolutionModal()}>
                            <FormattedMessage id="general.cancel" defaultMessage="Annuleren" />
                        </DefaultButton>
                        <PrimaryButton onClick={() => this.submitRelatedSolutionModal()}>
                            <FormattedMessage id="general.save" defaultMessage="Opslaan" />
                        </PrimaryButton>
                    </ModalFooter>
                </Modal>


                {
                    this.state.editedPosition &&
                    <Modal isOpen={this.state.editPositionModal} >
                        <ModalHeader><FormattedMessage id="page.configurationwizard.modal.editedposition.header" defaultMessage="Artikel bewerken" /></ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="txtArtikel">Artikel</Label>
                                        <Input type="text" disabled={true} id="txtArtikel"
                                            value={this.state.editedPosition!.itemRow.item ? this.state.editedPosition!.itemRow.item.description : "NOT FOUND"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="txtArtikelummer">Artikelummer</Label>
                                        <Input type="number" disabled={true} id="txtArtikelummer"
                                            value={this.state.editedPosition!.itemRow.item ? this.state.editedPosition!.itemRow.item.itemNumber : "NOT FOUND"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="txtEenheid">Eenheid</Label>
                                        <Input type="text" disabled={true} id="txtEenheid"
                                            value={this.state.editedPosition!.itemRow.item ? this.state.editedPosition!.itemRow.item.baseUnitOfMeasure : "NOT FOUND"}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label htmlFor="txtPSnr">PS-nr.</Label>
                                        <Input type="text" id="txtPSnr"
                                            value={this.state.editItemPsnr}
                                            onChange={(ev) => this.setState({ editItemPsnr: ev.target.value })}
                                            name="psNumber"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label htmlFor="txtAantal">Aantal</Label>
                                        <Input type="number" id="txtAantal"
                                            value={this.state.editItemQuantity}
                                            onChange={(ev) => this.setState({ editItemQuantity: Math.max(1, +ev.target.value) })}
                                            name="quantity"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label htmlFor="txtBrutoPrijsPerEenheid">Bruto prijs per eenheid</Label>
                                        <Input type="number" disabled={true} id="txtBrutoPrijsPerEenheid"
                                            value={this.state.editedPosition!.itemRow.item ? this.state.editedPosition!.itemRow.item.grossPricePerUnit : "NOT FOUND"}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label htmlFor="txtNettoPrijsPerEenheid">Netto prijs per eenheid</Label>
                                        <Input type="text" disabled={true} id="txtNettoPrijsPerEenheid" value="-" />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <DefaultButton onClick={() => this.closeEditedPosition(true)}>
                                <FormattedMessage id="general.cancel" defaultMessage="Annuleren" />
                            </DefaultButton>
                            <PrimaryButton onClick={() => this.closeEditedPosition(false)}>
                                <FormattedMessage id="general.save" defaultMessage="Opslaan" />
                            </PrimaryButton>
                        </ModalFooter>
                    </Modal>
                }

            </div >
        );

    }

}

export default injectIntl(SolutionEdit, { forwardRef: true });