import FormikFileUploader from "../../components/forms/formik/FormikFileUploader";
import FormikUtil from "../../components/forms/formik/FormikUtil";
import FormikLink from "../../components/forms/formik/FormikLink";

export default {
  id: "rec4",
  label: "Reclamatie 4",
  type: "container",
  renderer: "form",
  elements: {
    artikelcontainer: {
      type: "container",
      renderer: "div",
      elements: {
        serienummer_buiscode: {
          name: "serienummer_buiscode",
          label: "Serienummer / buiscode (alleen invullen indien aanwezig)",
          type: "field",
          renderer: "text"
        },
        artikelnummer: {
          name: "artikelnummer",
          label: "Artikelnummer",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]]
        },
        artikelomschrijving: {
          name: "artikelomschrijving",
          label: "Artikelomschrijving",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]]
        },
        aantal: {
          name: "aantal",
          label: "Aantal",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]]
        },
        typeklacht: {
          name: "typeklacht",
          label: "Type klacht",
          type: "field",
          renderer: "react-select",
          options: [
            { label: "Beschadiging", value: "Beschadiging" },
            { label: "Defect", value: "Defect" },
            { label: "Dubbel geleverd", value: "Dubbel geleverd" },
            { label: "Garantie", value: "Garantie" },
            { label: "Incompleet", value: "Incompleet" },
            { label: "Klacht", value: "Klacht" },
            { label: "Lekkage", value: "Lekkage" },
            { label: "Overstock", value: "Overstock" },
            { label: "Storing", value: "Storing" },
            { label: "Verkeerd besteld", value: "Verkeerd besteld" },
            { label: "Verkeerd geadviseerd", value: "Verkeerd geadviseerd" },
            { label: "Verkeerd geleverd", value: "Verkeerd geleverd" },
            { label: "Anders", value: "Anders" }
          ],
          required: true,
          validation: [["string"], ["required"]]
               
        },
        overstock: {
          type: "field",
          renderer: FormikLink,
          urlPreText: "Overstock kan niet worden verwerkt in de reclamatietool. Neem contact op met Customer Support via cs@nathan.nl of 026-2051111.",
          showWhen: ["is", "typeklacht", "Overstock"]          
      },
        typeklachtanders: {
          name: "typeklachtanders",
          label: "Voer uw type klacht in",
          type: "field",
          renderer: "text",
          required: true,
          validation: [["string"], ["required"]],
          showWhen: ["is", "typeklacht", "Anders"]
        },
        klachtomschrijving: {
          name: "klachtomschrijving",
          label: "Klachtomschrijving",
          type: "field",
          renderer: "textarea",
          attributes: {
            rows: 5,
            cols: 50
          },
          required: true,
          validation: [["string"], ["required"]],
          showWhen: ["not", ["is", "typeklacht", "Overstock"]]
        },
        extrainformatie_Upload: {
          name: "extrainformatie_Upload",
          label: "Toevoegen bewijsstukken (foto's/ documentatie)",
          type: "field",
          required: false,
          renderer: FormikFileUploader,
          options: {
            accept: [".JPG", ".PDF", ".PNG", ".CSV", ".JPEG"],
            multiple: true,
            onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
              FormikUtil.addFiles(formik, "extrainformatie_Upload", acceptedFiles)
          },
          showWhen: ["not", ["is", "typeklacht", "Overstock"]]
        },
        artikel_2container: {
          type: "container",
          renderer: "div",
          elements: {
            artikel_2: {
              name: "artikel_2",
              label: "Nog een artikel toevoegen?",
              type: "field",
              renderer: "radio",
              options: [
                { title: "Ja", value: "Ja" },
                { title: "Nee", value: "Nee" }
              ],
              showWhen: ["not", ["is", "typeklacht", "Overstock"]]
            },
            serienummer_buiscode_2: {
              name: "serienummer_buiscode_2",
              label: "Serienummer / buiscode (alleen invullen indien aanwezig)",
              type: "field",
              renderer: "text",
              showWhen: ["is", "artikel_2", "Ja"]
            },
            artikelnummer_2: {
              name: "artikelnummer_2",
              label: "Artikelnummer",
              type: "field",
              renderer: "text",
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "artikel_2", "Ja"]
            },
            artikelomschrijving_2: {
              name: "artikelomschrijving_2",
              label: "Artikelomschrijving",
              type: "field",
              renderer: "text",
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "artikel_2", "Ja"]
            },
            aantal_2: {
              name: "aantal_2",
              label: "Aantal",
              type: "field",
              renderer: "text",
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "artikel_2", "Ja"]
            },
            typeklacht_2: {
              name: "typeklacht_2",
              label: "Type klacht",
              type: "field",
              renderer: "react-select",
              options: [
                { label: "Beschadiging", value: "Beschadiging" },
                { label: "Defect", value: "Defect" },
                { label: "Dubbel geleverd", value: "Dubbel geleverd" },
                { label: "Garantie", value: "Garantie" },
                { label: "Incompleet", value: "Incompleet" },
                { label: "Klacht", value: "Klacht" },
                { label: "Lekkage", value: "Lekkage" },
                { label: "Overstock", value: "Overstock" },
                { label: "Storing", value: "Storing" },
                { label: "Verkeerd besteld", value: "Verkeerd besteld" },
                {
                  label: "Verkeerd geadviseerd",
                  value: "Verkeerd geadviseerd"
                },
                { label: "Verkeerd geleverd", value: "Verkeerd geleverd" },
                { label: "Anders", value: "Anders" }
              ],
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "artikel_2", "Ja"]
            },
          overstock_2: {
            type: "field",
            renderer: FormikLink,
            urlPreText: "Overstock kan niet worden verwerkt in de reclamatietool. Neem contact op met Customer Support via cs@nathan.nl of 026-2051111.",
            showWhen: ["is", "typeklacht_2", "Overstock"]
          },
            typeklachtanders_2: {
              name: "typeklachtanders_2",
              label: "Voer uw type klacht in",
              type: "field",
              renderer: "text",
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["is", "typeklacht_2", "Anders"]
            },
            klachtomschrijving_2: {
              name: "klachtomschrijving_2",
              label: "Klachtomschrijving",
              type: "field",
              renderer: "textarea",
              attributes: {
                rows: 5,
                cols: 50
              },
              required: true,
              validation: [["string"], ["required"]],
              showWhen: ["and",
              ["is", "artikel_2", "Ja"],
              ["not", ["is", "typeklacht_2", "Overstock"]]]
            },
            extrainformatie_Upload_2: {
              name: "extrainformatie_2",
              label: "Toevoegen bewijsstukken (foto's/ documentatie)",
              type: "field",
              required: false,
              showWhen: ["and",
              ["is", "artikel_2", "Ja"],
              ["not", ["is", "typeklacht_2", "Overstock"]]],
              renderer: FormikFileUploader,
              options: {
                accept: [".JPG", ".PDF", ".CSV", ".PNG", ".JPEG"],
                multiple: true,
                onDrop: (formik: any, config: any, acceptedFiles: any[]) =>
                  FormikUtil.addFiles(formik, "extrainformatie_2", acceptedFiles)
              }
            },
            notitie: {
              name: "notitie",
              label: "Notitie",
              type: "field",
              renderer: "textarea",              
              showWhen: ["and",
              ["not", ["is", "typeklacht", "Overstock"]],
              ["not", ["is", "typeklacht_2", "Overstock"]]],
              attributes: {
                rows: 5,
                cols: 50
              }
            }
          }
        }
      }
    }
  }
};