import React from 'react';
import { HashRouter } from "react-router-dom";
import { Switch, Route } from "react-router";

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "./App.scss";

import Header from './Header';
import Dashboard from '../dashboard/Dashboard';
import Forms from '../forms/Forms';
import FormA from '../forms/FormA';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { IUser, IAuthError } from '../viewmodels/auth';
import { Util } from '../Utils/Util';
import ProtectedRoute from '../ProtectedRoute';
import AuthRepository from '../repositories/AuthRepository';

import queryString from "query-string";
import { AuthService } from '../services/AuthService';
import PriceIndicationsOverviewPage from '../priceIndications/PriceIndicationsOverviewPage';
import { Service } from '../services/Service';
import ResetPasswordPage from '../me/ResetPasswordPage';
import AppRepository from '../repositories/AppRepository';
import { IRoleVM, IContactVM, ISubmitCommissioningsResponseVM } from '../viewmodels/data';

import env from "../env/env.json";

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';
import 'moment/locale/nl';
import { NavigateToPage, NavigateToAuth } from '../NavigateTo';
import Loading from '../components/Loading';
import { UserRepository } from '../repositories/UserRepository';
import CommissioningForm from '../inbedrijfstelling/InbedrijfstellingForm';
import CommissioningOverviewPage from '../inbedrijfstelling/InbedrijfstellingOverviewPage';
import { FormQueue, IFormQueueItem, FormQueueItemStatus } from '../services/FormQueue';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FaCheck, FaClock, FaCircleNotch, FaExclamationCircle } from 'react-icons/fa';

import PDF from "react-pdf-js"
import ReclamatieForm from '../complaints/ReclamatieForm';
import ComplaintOverviewPage from '../complaints/ComplaintOverviewPage';
import ComplaintDetailPage from '../complaints/ComplaintDetailPage';

import ReactGA from "react-ga";
import { FormType } from '../viewmodels/form';
import Kennisbank from '../kennisbank/Kennisbank';
import withTracker from '../components/analytics/withTracker';
import Analytics from '../components/analytics/Analytics';
import { PrimaryButton, Theme, createTheme, initializeIcons, loadTheme } from '@fluentui/react';

import SolutionEdit from '../solution/SolutionEdit';

import QuoteConfiguratorWizard from '../quoteconfigurator/QuoteConfiguratorWizard';

import QuotationOverview from '../quoteconfigurator/QuotationOverview';
import SolutionTypePage from '../solution/SolutionTypePage';
import SolutionIndex from '../solution/SolutionIndex';

initializeIcons();

const appTheme: Theme = createTheme({
  palette: {
    themePrimary: '#26b4e9',
    themeLighterAlt: '#f6fcfe',
    themeLighter: '#daf2fb',
    themeLight: '#bae7f8',
    themeTertiary: '#78d1f1',
    themeSecondary: '#3dbdeb',
    themeDarkAlt: '#21a2d1',
    themeDark: '#1c89b0',
    themeDarker: '#156582',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }});

loadTheme(appTheme);

interface IAppState {
  user?: IUser;
  
  contact?: IContactVM;
  userRole?: IRoleVM;

  reactGaInitialized?: boolean;
  
  isLoading?: boolean;

  showFormQueue?: boolean;
  formQueue: IFormQueueItem[];
  pdfUrl?: string;
}

export class App extends React.Component<WrappedComponentProps, IAppState> {

  private authRepo = new AuthRepository();

  constructor(props: any) {
    super(props);

    moment.locale('nl');

    const formQueue = FormQueue.getQueue();

    this.state = {
      formQueue: formQueue
    };
  }

  componentDidMount() {
    this.init();
    this.bindResize();
    this.errorHandling();
    const idToken = this.idTokenHandling();

    if (this.authRepo.isValid()) {
      if (!this.authRepo.isExpired()) {
        // set user
        const tokenUser = this.authRepo.getTokenUser();
        this.setState({
            user: tokenUser
        });

        if (idToken) {
          // navigate to root page
          setTimeout(() => {
            NavigateToPage.Home();
          }, 100);
        } else {
          // load user profile and restore app state
          this.loadMe()
            .then(user => {
              if (Util.IsLocal()) {
                // navigate to root page
                setTimeout(() => {
                  Util.Navigate("/");
                }, 100);
              }

              this.restoreAppState();
              this.initFormQueue();
            });
        }
      } else {
        // User needs to login again. Auto-login will happen if B2C credentials are still valid
      }
    }
  }

  private initFormQueue = () => {
    // subscribe to form queue updates
    FormQueue.subscribe(this.handleFormQueueItemUpdate);

    // show form queue if logged in and contains items
    if (this.authRepo.isValid() && this.state.formQueue.length > 0) {

      FormQueue.processQueue();

      const formQueue = FormQueue.getQueue();

      this.setState({
        formQueue: formQueue,
        showFormQueue: formQueue.length > 0
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateBodyPadding.bind(this));
  }  

  private init = () => {
    // init repositories and services
    this.authRepo.init();
    Service.Init(this.props);

    this.initReactGA();
  }

  private initReactGA = () => {
    if (this.state.contact) {
      ReactGA.initialize(env.GaTrackingId, {
        debug: env.Environment === 'local',
        gaOptions: {
          cookieDomain: env.GaCookieDomain,
          userId: this.state.contact.id
        }
      });
      this.setState({ reactGaInitialized: true });
    } else {
      console.warn("Analytics not initialized, because user is not logged in correctly");
    }
  };

  private bindResize = () => {
    // bind resize event
    window.addEventListener("resize", this.updateBodyPadding.bind(this));
    this.updateBodyPadding();
  }

  private errorHandling = () => {
    // get error
    const error = this.getError();
    if (error) {
      // password reset?
      if (error.description.startsWith("AADB2C90118:")) {
        NavigateToAuth.ResetPassword();
      } else {
        NavigateToPage.Home();
      }
    }
  }

  private idTokenHandling = (): string | null => {
    // get id_token
    const idToken = this.getIdToken();
    if (idToken) {
      this.authRepo.setToken(idToken);
      
      NavigateToAuth.HandleLoggedIn();
      Util.NavigateAbsolute(env.WebAppUrl);
    }
    return idToken;
  }

  private loadMe = (): Promise<void> => 
    new Promise((resolve, reject) => {
      this.setState({
        isLoading: true
      });

      AuthService.GetMe()
          .then(contact => {
              const userRole = contact ? contact.role : undefined;

              this.setState({
                  contact: contact,
                  userRole: userRole
              }, () => this.initReactGA());

              UserRepository.setMe(contact);

              // check if first login timestamp is set for password reset
              if (!contact.firstLoginTime) {
                  NavigateToPage.ResetPassword();
              }
          })
          .finally(() => {
              this.setState({
                  isLoading: false
              });
              resolve();
          });
      });

  private updateBodyPadding() {
    setTimeout(() => {
      const topBar = document.getElementById("topBar");
      if (topBar) {
        document.body.style.paddingTop = `${topBar.clientHeight + 10}px`;
      }
    }, 100);
  }

  private restoreAppState = () => {
    const appRepo = new AppRepository();
    const appState = appRepo.getState();
    if (appState) {
      if (!appState.data) {
        appRepo.clearState();
      }
      Util.Navigate(appState.path);
    }
  }

  private getIdToken = (): string | null => {
    const qs = Util.GetQueryParams();

    if (qs.id_token) {
      const idToken = qs.id_token;
      return Array.isArray(idToken) ? idToken[0] : idToken;
    }
    return null;
  }
  
  private getError = (): IAuthError | undefined => {
    const qs = Util.GetQueryParams();

    if (qs.error) {
      const error = Array.isArray(qs.error) ? qs.error[0] : qs.error;
      const description = Array.isArray(qs.error_description) ? qs.error_description[0] : qs.error_description;

      return {
        error: error || "unknown error",
        description: description || "unknown error description"
      };
    }

    return undefined;
  }

  private toggleFormQueue = () => {    
    const newShowFormQueue = !this.state.showFormQueue;
    const textId = 'analytics.actions.formQueue.'  + (newShowFormQueue ? 'open' : 'close');
    Analytics.registerIBNAction(this.props.intl.formatMessage({ id: textId }));
    this.setState({ showFormQueue: newShowFormQueue });
  };

  private handleFormQueueItemUpdate = (item: IFormQueueItem) => {
    this.setState({
      showFormQueue: true,
      formQueue: FormQueue.getQueue()
    });
  }

  private openPdf = (pdfUrl: string) => {
    if (document.body.clientWidth >= 650) {
    this.setState({ pdfUrl: pdfUrl });
    } else {
      window.open(pdfUrl, '_blank');
    }
  }

  private copyInbedrijfstellingForm = (queueItem: IFormQueueItem) => {
    const formResponse: ISubmitCommissioningsResponseVM = queueItem.response;

    Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.copy' }));
    NavigateToPage.InbedrijfstellingForm(formResponse.id);

    // Hide form dialog
    this.setState({
      showFormQueue: false
    });
  }

  render() {
    const isAuthenticated: boolean = this.state.user !== undefined;
    const anonymousClassName = !isAuthenticated ? "anonymous" : "";

    const userRole: IRoleVM = UserRepository.getUserRole();

    return (
      <HashRouter>
        <div className={`App ${anonymousClassName}`}>
          <div id="topBar" className="wrapper fixed-top">
            <Header user={this.state.user} contact={this.state.contact} />
          </div>

          <div className="container">
            <Switch>
              <Route exact path="/" render={(props) => 
                !this.state.isLoading ?
                <Dashboard user={this.state.user} {...props} /> :
                <div className="pageContent"><Loading /></div>
              } />
              <Route exact path="/Me/PasswordReset" component={withTracker(ResetPasswordPage)} />
              <Route exact path="/Me/ResetPassword" component={withTracker(ResetPasswordPage)} />
              <ProtectedRoute exact path="/PriceIndications" component={withTracker(PriceIndicationsOverviewPage)} isAuthenticated={isAuthenticated && userRole.showPriceIndicationOverview} />
              <ProtectedRoute exact path="/Forms" component={withTracker(Forms)} isAuthenticated={isAuthenticated && Util.IsLocal()} />
              <ProtectedRoute exact path="/Forms/FormA" component={withTracker(FormA)} isAuthenticated={isAuthenticated && userRole.requestPriceIndication} />
              <ProtectedRoute exact path="/Forms/Commissioning" component={withTracker(CommissioningForm)} isAuthenticated={isAuthenticated && userRole.showCommissioning} />
              <ProtectedRoute exact path="/Forms/Reclamatie" component={withTracker(ReclamatieForm)} isAuthenticated={isAuthenticated && userRole.showComplaint} />
              <ProtectedRoute exact path="/Commissionings" component={withTracker(CommissioningOverviewPage)} isAuthenticated={isAuthenticated && userRole.showCommissioningOverview} />

              <ProtectedRoute exact path="/Reclamaties" component={withTracker(ComplaintOverviewPage)} isAuthenticated={isAuthenticated && userRole.showComplaintOverview} />
              <ProtectedRoute exact path="/Reclamaties/Detail/:reclamatieId" component={withTracker(ComplaintDetailPage)} isAuthenticated={isAuthenticated && userRole.showComplaintOverview} />
              <ProtectedRoute exact path="/Reclamaties/Formulier" component={withTracker(ReclamatieForm)} isAuthenticated={isAuthenticated && userRole.showComplaint} />
              <ProtectedRoute exact path="/Solutions/New" component={withTracker(SolutionTypePage)} isAuthenticated={isAuthenticated && (userRole.showQuoteSolutions || userRole.showQuotes)} />
              <ProtectedRoute exact path="/Solutions/Edit/:solutionId" component={withTracker(SolutionEdit)} isAuthenticated={isAuthenticated && userRole.showQuoteSolutions} />
              <ProtectedRoute exact path="/Solutions/New/:solutionType" component={withTracker(SolutionEdit)} isAuthenticated={isAuthenticated && userRole.showQuoteSolutions} />
              <ProtectedRoute exact path="/Solutions" component={withTracker(SolutionIndex)} isAuthenticated={isAuthenticated && userRole.showQuoteSolutions} />
              <ProtectedRoute exact path="/QuoteConfigurator/QuoteConfiguratorWizard" component={withTracker(QuoteConfiguratorWizard)} isAuthenticated={isAuthenticated && userRole.showQuotes} />
              <ProtectedRoute exact path="/QuoteConfigurator/QuotationOverview" component={withTracker(QuotationOverview)} isAuthenticated={isAuthenticated && userRole.showQuotes} />

				      <ProtectedRoute exact path="/Kennisbank" component={withTracker(injectIntl(Kennisbank))} isAuthenticated={isAuthenticated && (userRole.showKnowledgeBaseBasic || userRole.showKnowledgeBaseAdvanced)} />
            </Switch>
          </div>

          <Modal isOpen={this.state.showFormQueue} toggle={this.toggleFormQueue}>
            <ModalHeader toggle={this.toggleFormQueue}>Formulieren verzenden</ModalHeader>
            <ModalBody>
              {
                !this.state.pdfUrl && this.state.formQueue.map(item => 
                  <>
                    <div className="row">
                      <div className="col-md-1 center">
                        { item.status === FormQueueItemStatus.new && <FaCircleNotch className="new" /> }                      
                        { item.status === FormQueueItemStatus.processing && <FaClock className="busy" /> }                      
                        { item.status === FormQueueItemStatus.done && <FaCheck className="success" /> }                      
                        { item.status === FormQueueItemStatus.error && <FaExclamationCircle className="error" /> }                      
                      </div>
                      <div className="col-md-3">
                        { item.status === FormQueueItemStatus.new && <span>Nieuw</span> }                      
                        { item.status === FormQueueItemStatus.processing && <span>Bezig</span> }                      
                        { item.status === FormQueueItemStatus.done && <span>Verzonden</span> }                      
                        { item.status === FormQueueItemStatus.error && <span>Fout</span> }                      
                      </div>
                      {
                        item.formType === FormType.Inbedrijfstelling && item.response &&
                        <>
                        <div className="col-md-6">
                          {item.title}
                        </div>
                        <div className="col-md-2">
                          <PrimaryButton onClick={() => this.copyInbedrijfstellingForm(item)}>
                            <FormattedMessage id="general.copy" defaultMessage="Copy" />
                          </PrimaryButton>
                        </div>
                        </>
                      }
                      {
                        (item.formType !== FormType.Inbedrijfstelling || !item.response) &&
                        <div className="col-md-8">
                          {item.title}
                        </div>
                      }
                    </div>
                    {
                      item.status === FormQueueItemStatus.done &&
                      item.response && item.response.value && item.response.value.pdfUrl &&
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                          <a href="#" onClick={() => this.openPdf(item.response.value.pdfUrl)}>PDF tonen</a>
                        </div>
                      </div>
                    }
                    {
                      item.status === FormQueueItemStatus.error &&
                      <>
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                          <a href="#" onClick={() => NavigateToAuth.Login()}>Formulier opnieuw verzenden</a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1 center"></div>
                        <div className="col-md-11 error">{JSON.stringify(item.response)}</div>
                      </div>
                      </>
                    }
                  </>
                )
              }
              {
                this.state.pdfUrl &&                
                <>
                <a href="#" onClick={() => this.setState({ pdfUrl: undefined })}>Terug naar overzicht</a>
                <PDF file={this.state.pdfUrl} page={1} onDocumentComplete={() => { console.log('PDF loading complete') }} withCredentials={false} />
                </>
              }
            </ModalBody>
            <ModalFooter>
              <PrimaryButton color="primary" onClick={this.toggleFormQueue}>Sluiten</PrimaryButton>
            </ModalFooter>
          </Modal>

        </div>
      </HashRouter>
    );
  }
}

export default injectIntl(App, { forwardRef: true });