import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from "react-intl";

import localeData from "./locales/data.json";

// explicitly include polyfill
// import "core-js/es";

import './index.scss';


// define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language: string =
    "nl-NL";
    // (navigator.languages && navigator.languages[0]) ||
    // navigator.language ||
    // (navigator as any).userLanguage;

// split locales with a region code
const languageWithoutRegionCode: string = language.toLowerCase().split(/[_-]+/)[0];

// try full locale, try locale without region code, fallback to 'en'
const messages: any =
    (localeData as any)[languageWithoutRegionCode] ||
    (localeData as any)[language] ||
    (localeData as any).en;

ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
        <App />
    </IntlProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();