import { Checkbox, DatePicker, DayOfWeek, FirstWeekOfYear, IColumn, Icon, mergeStyles, TextField } from "@fluentui/react";
import { DateTime } from "luxon";
import React from "react";
import DateTimeUtils from "../Utils/DateTimeUtils";
import { HxDetailListItem } from "./HxDetailList";
import HxDetailListColumnBase from "./HxDetailListColumnBase";


export default class HxDetailListColumn extends HxDetailListColumnBase {

    columnDataType: "date" | "bool" | "text";
    canEdit: boolean;
    onValueChanged?: (newValue: any, item?: any, index?: number, column?: IColumn) => void;
    tabIndex?: number;

    constructor(
        displayName: string, fieldName: string, minWidth: number, columnDataType?: "date" | "bool" | "text", isResizable?: boolean,
        canEdit?: boolean, onValueChanged?: (newValue: any, item?: any, index?: number, column?: IColumn) => void,
        tabIndex?: number) {

        super(displayName, fieldName, minWidth);
        // console.log("HxDetailListColumn.constructor", fieldName, onValueChanged)

        if (columnDataType == "date" && canEdit) {
            this.minWidth = Math.max(110, this.minWidth);
            this.maxWidth = Math.max(110, this.maxWidth || 0);
        }

        this.columnDataType = columnDataType || "text";
        this.isResizable = (isResizable == undefined) ? true : isResizable;
        this.canEdit = canEdit || false;
        this.onValueChanged = onValueChanged;
        this.tabIndex = tabIndex;
        this.className = HxDetailListColumn.cellCss;

        if (this.canEdit) {
            if (!this.onValueChanged) throw "Need onValueChanged for editable column";
            if (!this.tabIndex) throw "Need tabIndex for editable column";
        }

        this.onRender = this.renderMain;
    }

    renderMain = (item?: HxDetailListItem, index?: number, column?: IColumn) => {

        if (item && !item.components) item.components = {};

        if (this.canEdit && !(item?.readonly)) {
            switch (this.columnDataType) {
                case "bool": return this.renderBoolEdit(item, index, column);
                case "date": return this.renderDateEdit(item, index, column);
                case "text": return this.renderTextEdit(item, index, column);
                default: return <b>Error</b>
            }
        } else {
            switch (this.columnDataType) {
                case "bool": return this.renderBool(item, index, column);
                case "date": return this.renderDate(item, index, column);
                case "text": return this.renderText(item, index, column);
                default: return <b>Error</b>
            }
        }

    }

    renderDate = (item?: any, index?: number, column?: IColumn): string => {
        if (!item || !column || !column.fieldName) return "null";
        const dateValue = item[column.fieldName];
        let dateTime = DateTimeUtils.get(dateValue);
        return dateTime?.toFormat("dd-MM-yyyy") || "";
    }

    renderBool = (item?: any, index?: number, column?: IColumn) => {
        if (!item || !column || !column.fieldName) return "null";
        const boolValue = item[column.fieldName];
        return boolValue ? <Icon iconName="CheckMark" /> : <Icon iconName="Remove" />
    }

    renderText = (item?: any, index?: number, column?: IColumn) => {
        if (!item || !column || !column.fieldName) return "null";
        return item[column.fieldName];
    }


    renderTextEdit = (item?: HxDetailListItem, index?: number, column?: IColumn) => {
        if (!item || !column || !column.fieldName || !this.onValueChanged || !this.tabIndex) return <></>

        const fieldValueString = (item as any)[column.fieldName] as string | null | undefined;

        return <>
            <TextField
                value={fieldValueString || undefined}
                onChange={(ev, txt) => this.onValueChanged!(txt, item, index, column)}
                onFocus={ev => {
                    (ev.target as HTMLInputElement).select()
                    if (item.componentFocus) item.componentFocus(this.tabIndex!);
                }}
                componentRef={c => item.components![this.tabIndex!] = c!}
            />
        </>
    }

    renderBoolEdit = (item?: HxDetailListItem, index?: number, column?: IColumn) => {
        if (!item || !column || !column.fieldName || !this.onValueChanged || !this.tabIndex) return <></>

        const fieldValueBool = (item && column && column.fieldName) ? !!(item as any)[column.fieldName] : undefined;

        return <>
            <Checkbox
                checked={fieldValueBool}
                onChange={(ev, chk) => this.onValueChanged!(chk, item, index, column)}
                componentRef={c => item.components![this.tabIndex!] = c!}
                // onFocus={() => {
                //     //(ev.target as HTMLInputElement).select()
                //     if (item.componentFocus) item.componentFocus(this.tabIndex!);
                // }}
            />
        </>
    }

    renderDateEdit = (item?: HxDetailListItem, index?: number, column?: IColumn) => {
        if (!item || !column || !column.fieldName || !this.onValueChanged || !this.tabIndex) return <></>

        const fieldValueAny = item && column && column.fieldName ? (item as any)[column.fieldName] : undefined;
        const fieldValue = fieldValueAny as DateTime | undefined;

        return <>
            <DatePicker
                firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                value={fieldValue?.toJSDate()}
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers={true}
                formatDate={d => DateTimeUtils.ToAppFormat(d, false)}
                parseDateFromString={s => DateTimeUtils.TryParse(s) || null}
                allowTextInput={true}
                disableAutoFocus={true}
                onSelectDate={dt => this.onValueChanged!(dt, item, index, column)}
                onFocus={ev => {
                    (ev.target as HTMLInputElement).select()
                    if (item.componentFocus) item.componentFocus(this.tabIndex!);
                }}
                componentRef={c => item.components![this.tabIndex!] = c!}
            />
        </>
    }

    static cellCss = mergeStyles({
        "div": {
            marginTop: "-6px",
            marginBottom: "-6px"
        }
    });

}