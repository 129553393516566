import React from "react";

import "./Header.scss";
import AuthRepository from "../repositories/AuthRepository";
import { IUser } from "../viewmodels/auth";
import { IContactVM } from "../viewmodels/data";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { NavigateToPage, NavigateToAuth } from "../NavigateTo";
import { Container, Navbar, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem, NavLink } from "reactstrap";
import { UserRepository } from "../repositories/UserRepository";

interface IHeaderProps extends WrappedComponentProps {
    user?: IUser;
    contact?: IContactVM;
}

export interface IHeaderState {
}

class Header extends React.Component<IHeaderProps, IHeaderState> {

    constructor(props: any) {
        super(props);
        
        this.state = {};
    }

    private login = () => NavigateToAuth.Login();
    private logout = () => {
        const authRepo = new AuthRepository();
        authRepo.clearToken();

        UserRepository.clear();
        
        NavigateToAuth.Logout();
    }

    private resetPassword = () => NavigateToPage.ResetPassword();

    private onLogoClick = () => NavigateToPage.Home();

    render() {
        const displayName = this.props.contact ? this.props.contact.displayName : this.props.user ? this.props.user.displayName : null;
        const customerName = this.props.contact && this.props.contact.customer ? this.props.contact.customer.displayName : null;
        const textLoading = this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" });

        const displayTitle = displayName + (customerName ? ` | ${customerName}` : '');

        return (
            <Container className="Header">
                <Navbar>
                    <NavbarBrand onClick={this.onLogoClick}>
                        <img className="logo" src="./assets/nathan-logo-hires.png" alt="Nathan logo" />
                    </NavbarBrand>

                    {
                        !this.props.user &&
                        <NavItem className="navbar-right">
                            <NavLink onClick={this.login}><FormattedMessage id="components.header.login" defaultMessage="Log in" /></NavLink>
                        </NavItem>
                    }

                    {
                        this.props.user &&
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret title={displayTitle}>
                                {displayName}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={this.resetPassword}>
                                <FormattedMessage id="components.header.resetPassword" defaultMessage="Reset password" />
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={this.logout}>
                                    <FormattedMessage id="components.header.logout" defaultMessage="Log out" />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    }

                    
                </Navbar>
            </Container>
        );
    }
}

export default injectIntl(Header, { forwardRef: true });