import FormikUtil from "../../components/forms/formik/FormikUtil";

export default
{
    "id": "ign7",
    "label": "Temperaturen",
    "type": "container",
    "renderer": "form",
    "elements": {
        "temperaturenInCVBedrijf": {
            "type": "container",
            "renderer": "fieldset",
            "title": "Temperaturen in CV-bedrijf",
            "elements": {
                "temperaturenInCVBedrijfBronIn": {
                    "name": "temperaturenInCVBedrijfBronIn",
                    "label": "Bron in °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                },
                "temperaturenInCVBedrijfBronUit": {
                    "name": "temperaturenInCVBedrijfBronUit",
                    "label": "Bron uit °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                },
                "temperaturenInCVBedrijfCVIn": {
                    "name": "temperaturenInCVBedrijfCVIn",
                    "label": "CV in °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                },
                "temperaturenInCVBedrijfCVUit": {
                    "name": "temperaturenInCVBedrijfCVUit",
                    "label": "CV uit °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                }
            }
        },
        "temperaturenInKoelbedrijf": {
            "type": "container",
            "renderer": "fieldset",
            "title": "Temperaturen in Koel-bedrijf",
            "elements": {
                "temperaturenInKoelbedrijfBronIn": {
                    "name": "temperaturenInKoelbedrijfBronIn",
                    "label": "Bron in °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                },
                "temperaturenInKoelbedrijfBronUit": {
                    "name": "temperaturenInKoelbedrijfBronUit",
                    "label": "Bron uit °C",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number",
                    "validation": [["number"]]
                }
            }
        },
        "flow": {
            "type": "container",
            "renderer": "fieldset",
            "title": "Flow",
            "elements": {
                "flowBron": {
                    "name": "flowBron",
                    "label": "Flow bron (l/h)",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number"
                },
                "flowAfgifte": {
                    "name": "flowAfgifte",
                    "label": "Flow afgifte (l/h)",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "number"
                }
            }
        },
        "opmerkingen": {            
            "name": "opmerkingen",
            "label": "Eventuele opmerkingen:",
            "type": "field",
            "renderer": "textarea",
            "attributes": {
                "rows": 10,
                "cols": 50
            }
        }
    }
};