import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, WrappedComponentProps } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import Loading from "../components/Loading";
import { UserRepository } from "../repositories/UserRepository";
import KnowledgeBaseService from "../services/KnowledgeBaseService";
import { Util } from "../Utils/Util";
import { IRoleVM } from "../viewmodels/data";
import { PrimaryButton } from "@fluentui/react";

interface IKennisbankProps extends WrappedComponentProps {}

interface IKennisbankState {
    isLoading?: boolean;
    errorMessage?: string;
    userName?: string;
    passWord?: string;
}

const Kennisbank: React.FunctionComponent<IKennisbankProps> = (props) => {
    const [ state, setState ] = useState<IKennisbankState>({
        isLoading: false,
        errorMessage: undefined,
        userName: undefined,
        passWord: undefined
    });
    
    const submitRef = useRef<HTMLButtonElement>(null);

    const getInstallerType = (): string | null => {
        const userRole: IRoleVM = UserRepository.getUserRole();

        let installerType: string | null = null;
        if (userRole.showKnowledgeBaseBasic) installerType = "NGI";
        if (userRole.showKnowledgeBaseAdvanced) installerType = "GI";

        return installerType;
    }

    const load = () => {
        setState({
            isLoading: true,
            errorMessage: undefined
        });

        const installerType = getInstallerType();

        if (installerType) {
            KnowledgeBaseService.GetCredentials(installerType)
                .then(credentials => {
                    setState({
                        userName: credentials.userName,
                        passWord: credentials.password
                    });
                })
                .catch(reason => setState({ errorMessage: reason }))
                .finally(() => setState({ isLoading: false }));
        } else {
            setState({
                isLoading: false,
                errorMessage: props.intl.formatMessage({ id: "pages.knowledgeBase.notAuthorized" })
            });
        }
    }

    const triggerSubmit = () =>
        state.userName && state.passWord &&
        submitRef && submitRef.current && submitRef.current.click();

    const onBackToMain = () => Util.Navigate("/");

    useEffect(() => { load(); }, []);
    useEffect(() => { triggerSubmit(); }, [state.userName, state.passWord]);

    const installerType = getInstallerType();

    let destinationDisplay = "/display/MN";
    if (installerType == "NGI") {
        destinationDisplay = "/display/PIM/Productinformatie+Home";
    }
    
    return (
        <>
        <Row id="page-Kennisbank-actions">
            <Col className="text-right">
                <PrimaryButton onClick={onBackToMain}>
                    <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                </PrimaryButton>
            </Col>
        </Row>
        {
            state.isLoading &&
            <Loading text={props.intl.formatMessage({ id: "pages.knowledgeBase.loading" })} />
        }
        {
            !state.isLoading &&
            <>
            <form name="kennisbankForm" method="POST" action="https://kennisbank.nathan.nl/dologin.action" style={{ display: "none" }}>
                <input type="hidden" name="os_username" id="os_username" placeholder="Username" value={state.userName} />
                <input type="hidden" name="os_password" id="os_password" placeholder="Password" value={state.passWord} />
                <input type="hidden" name="login" id="login" placeholder="login" value="Log in" />
                <input type="hidden" name="os_destination" id="os_destination" placeholder="os_destination" value={destinationDisplay} />
                <button id="loginButton" ref={submitRef} name="login" type="submit" value={props.intl.formatMessage({ id: "pages.knowledgeBase.open" })} />
            </form>            
            <Loading text={props.intl.formatMessage({ id: "pages.knowledgeBase.opening" })} />
            </>
        }
        </>
    )
}

export default Kennisbank;