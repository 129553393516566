import { IFormA } from "../viewmodels/data";
import { Service } from "./Service";
import { FormQueue, IFormQueueItem } from "./FormQueue";
import { InbedrijfstellingService } from "./InbedrijfstellingService";
import { FormType } from "../viewmodels/form";
import { Util } from "../Utils/Util";

export class FormService {

    public static submit = <M>(form: M, metadata: any, endpoint: string, formName: string, formType: FormType): IFormQueueItem =>
    {
        const queueItem = FormQueue.addToQueue(formName, `${Service.ApiUrl}/v1/forms/${endpoint}`, form, formType, metadata);
        FormQueue.processQueue();

        return queueItem;
    }

    public static submitA = (form: IFormA, formName: string): IFormQueueItem =>
        FormService.submit<IFormA>(form, undefined, "a", formName, FormType.Prijsindicatie);

    public static LoadFormData = (formType: FormType, id: string): Promise<any> =>
        new Promise((resolve, reject) => {
            switch (formType) {
                case FormType.Inbedrijfstelling:
                    InbedrijfstellingService.Get(id)
                        .then(res => {
                            const data = Util.ClearProperties(res.data,
                                [
                                    "werkbonnummer", "eindgebruikerNaam",
                                    "objectHuisnummer", "objectHuisnummerToevoeging",
                                    "objectTelefoonnummer",
                                    "warmtepompMACadres",
                                    "warmtepompSerienummer", "boilerSerienummer", "buffervatSerienummer", "wandregelaarSerienummer",
                                    "temperaturenInCVBedrijfBronIn", "temperaturenInCVBedrijfBronUit", "temperaturenInCVBedrijfCVIn", "temperaturenInCVBedrijfCVUit",
                                    "temperaturenInKoelbedrijfBronIn", "temperaturenInKoelbedrijfBronUit",
                                    "flowBron", "flowAfgifte",
                                    "opmerkingen"
                                ]);
                            resolve(data);
                        })
                        .catch(reason => reject(reason)); break;
                default: Promise.reject(() => reject(`Form type '${formType}' not implemented`));
            }
        });
}