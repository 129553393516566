import React from "react";
import $ from "jquery";
import { WrappedComponentProps, FormattedMessage, injectIntl } from "react-intl";
import { Util } from "../Utils/Util";
import { UserRepository } from "../repositories/UserRepository";
import { Row, Col, Button, Container, Table, Alert, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { IRequestVM, RequestStatusEnum } from "../viewmodels/data";
import Loading from "../components/Loading";
import moment from "moment";
import { ComplaintService } from "../services/ComplaintService";

import "./ComplaintOverviewPage.scss";
import AppRepository from "../repositories/AppRepository";
import { IColumn, IContextualMenuItem, IContextualMenuProps, IconButton, PrimaryButton, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import Analytics from "../components/analytics/Analytics";
import { DateTime } from "luxon";
import DateTimeUtils from "../Utils/DateTimeUtils";
import HxDetailList, { HxDetailListItem } from "../components/HxDetailList";
import HxDetailListColumn from "../components/HxDetailListColumn";
import FilterBox, { IFilterOption, IFilterOptions } from "../components/FilterBox";
import ArrayUtils from "../Utils/ArrayUtils";
import EnumUtils from "../Utils/EnumUtils";


interface IComplaintOverviewPageProps extends WrappedComponentProps { }

interface IComplaintOverviewPageState {
    myComplaints?: IRequestVM[];

    isLoading?: boolean;
    errorMessage?: string;

    statusFilterOpen?: boolean;

    currentRequestStatusFilter: string;
    currentCustomerFilter: string;
    filterDate?: DateTime;

    buttonTexts: { [num: number]: string }
}

interface ListItem extends IRequestVM, HxDetailListItem {
    createdDateStr: string;
    createdDateObj: DateTime
}

class ComplaintOverviewPage extends React.Component<IComplaintOverviewPageProps, IComplaintOverviewPageState> {
    private appRepo = new AppRepository();

    constructor(props: IComplaintOverviewPageProps) {
        super(props);

        const downloadTitle1 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document1.title", defaultMessage: "Download" });
        const downloadTitle2 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document2.title", defaultMessage: "Download" });
        const downloadTitle3 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document3.title", defaultMessage: "Download" });
        const downloadTitle4 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document4.title", defaultMessage: "Download" });
        const downloadTitle5 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document5.title", defaultMessage: "Download" });
        const downloadTitle6 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document6.title", defaultMessage: "Download" });
        const downloadTitle7 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document7.title", defaultMessage: "Download" });

        this.state = {
            buttonTexts: {
                [1]: downloadTitle1,
                [2]: downloadTitle2,
                [3]: downloadTitle3,
                [4]: downloadTitle4,
                [5]: downloadTitle5,
                [6]: downloadTitle6,
                [7]: downloadTitle7,
            },
            currentRequestStatusFilter: "Any",
            currentCustomerFilter: "Alle",
        };
    }

    componentDidMount() {
        this.loadComplaints();
    }

    private loadComplaints = () => {
        this.setState({
            myComplaints: []
        });

        this.setState({
            isLoading: true,
            errorMessage: undefined
        });

        this.loadMyComplaints();
    }

    private loadMyComplaints = () =>
        ComplaintService.GetMyAll()
            .then(complaints => {
                const sortedComplaints = [...complaints];
                // sort desc
                sortedComplaints.sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0);

                this.setState({
                    myComplaints: [...sortedComplaints],
                    errorMessage: undefined
                });

                // this.autoRefreshMyComplaints();
            })
            .catch(reason => {
                this.setState({
                    errorMessage: reason || ""
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });

    private autoRefreshMyComplaints = () => {
        const { myComplaints } = this.state;
        if (!myComplaints || myComplaints.length === 0) return;

        if ($('.page-ComplaintOverview').is(':visible')) {
            const complaintsWithoutDocument = myComplaints.filter(c => !c.documentUrls1 || c.documentUrls1.length === 0);
            if (complaintsWithoutDocument.length > 0) {
                setTimeout(() => {
                    this.loadMyComplaints();
                }, 1000);
            }
        }
    }

    private onBackToMain = () => {
        Analytics.registerReclamatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.reclamatie.backToMain' }));
        Util.Navigate("/");
    }


    private onItemClick = (id: string) => Util.Navigate(`/Reclamaties/Detail/${id}`);

    private onDownload = (index: number, url?: string) => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.download' + (index === 0 ? 'Document' : 'File') }));
        if (url)
            window.open(url, "_blank");
    }


    getItems = () => {
        const { myComplaints, currentRequestStatusFilter, currentCustomerFilter, filterDate } = this.state;
        if (!myComplaints) return undefined;

        let filteredComplaints = myComplaints;
        if (currentRequestStatusFilter != "Any") {
            filteredComplaints = myComplaints.filter(c => EnumUtils.getString(RequestStatusEnum, c.requestStatus) == currentRequestStatusFilter);
        }

        if (currentCustomerFilter != "Alle") {
            filteredComplaints = filteredComplaints.filter(c => c.customerName == currentCustomerFilter);
        }

        if (filterDate) filteredComplaints = filteredComplaints.filter(c => DateTimeUtils.getDate(DateTime.fromISO(c.created)).valueOf() == filterDate.valueOf())

        const listItems = filteredComplaints.map(c => {
            const createdDateObj = DateTime.fromISO(c.created);
            const createdDateStr = DateTimeUtils.ToAppFormat(createdDateObj);
            const item: ListItem = {
                ...c,
                createdDateObj: createdDateObj,
                createdDateStr: createdDateStr,
                // ibnDateObj: DateTime.fromISO(c.ibnDate),
                SearchTextUpper: Object.values(c).join(" ").toUpperCase() + createdDateStr
            }
            return item;
        })
        return listItems;
    }

    getColumns = () => {
        const { intl } = this.props;
        const columns: IColumn[] = [
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.complaints.columns.complaintNumber' }), "number", 157),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.complaints.columns.customerName' }), "customerName", 157),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.complaints.columns.returnNumber' }), "referenceNumber", 100),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.complaints.columns.created' }), "createdDateObj", 80, "date"),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.complaints.columns.status' }), "status", 120),
            {
                key: "actions",
                minWidth: 250,
                name: "Documenten",
                onRender: (item?: ListItem, index?: number, column?: IColumn) => {
                    return item && this.getDownloadButtons(item);
                }
            }
        ];

        return columns;
    }

    downloadButton = (complaintId: string, url: string, title: string, index: number) => <>
        <PrimaryButton onClick={() => this.onDownload(index, url)} key={`downloadButton_${complaintId}_${index}`}>{title}</PrimaryButton><br />
    </>


    downloadButtons = (complaintId: string, urls: string[], title: string, index: number) =>
        urls.map((url, urlIndex) => this.downloadButton(complaintId, url, urls.length > 1 ? `${title} (${urlIndex + 1})` : title, index));


    getMenuItem = (complaintId: string, urls: string[], title: string, index: number) => {
        var results: IContextualMenuItem[] = [];
        const addCounter = urls.length > 1;
        urls.forEach((url, urlIndex) => {
            results.push({
                key: `downloadButton_${complaintId}_${index}_${urlIndex}`,
                text: addCounter ? `${title} (${urlIndex + 1})` : title,
                onClick: () => this.onDownload(index, url)
            });
        });
        return results;
    }

    getDownloadButtons = (complaint: IRequestVM) => {
        const { buttonTexts } = this.state;

        var allMenuItems: IContextualMenuItem[] = [];
        if (complaint.documentUrls1) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls1, buttonTexts[1], 0));
        if (complaint.documentUrls2) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls2, buttonTexts[2], 0));
        if (complaint.documentUrls3) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls3, buttonTexts[3], 0));
        if (complaint.documentUrls4) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls4, buttonTexts[4], 0));
        if (complaint.documentUrls5) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls5, buttonTexts[5], 0));
        if (complaint.documentUrls6) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls6, buttonTexts[6], 0));
        if (complaint.documentUrls7) allMenuItems = allMenuItems.concat(this.getMenuItem(complaint.id, complaint.documentUrls7, buttonTexts[7], 0));

        const menuProps2: IContextualMenuProps = {
            items: allMenuItems,
            directionalHintFixed: true,
        };

        return <>
            <PrimaryButton iconProps={{ iconName: "TextDocument" }} text={"Download bestand (" + allMenuItems.length + ")"} menuProps={menuProps2} />
        </>
    }

    getFilterOptions = () => {
        const { myComplaints, currentRequestStatusFilter, currentCustomerFilter, filterDate } = this.state;

        let requestStatusOptions = EnumUtils.getStringValues(RequestStatusEnum).map(s => ({ key: s, text: s, id: s }));
        requestStatusOptions.unshift({ key: "All", text: "Alle", id: "All" });


        let allCustomers = ArrayUtils.distinct((myComplaints || []).map(c => c.customerName)).filter(c => !!c).sort();
        allCustomers.unshift("Alle");

        const filterOptions: IFilterOption[] = [];
        filterOptions.push({
            key: "status",
            filterTitle: "Status",
            currentValue: currentRequestStatusFilter,
            filterType: "choice",
            choiceOptions: FilterBox.GetSelectOptions(EnumUtils.getStringValues(RequestStatusEnum), "Any"),
            onFilterUpdated: option => {
                this.setState({ currentRequestStatusFilter: option.currentValue as string });
            }
        });
        filterOptions.push({
            key: "customer",
            filterTitle: "Klant",
            currentValue: currentCustomerFilter,
            filterType: "choice",
            choiceOptions: allCustomers.map(s => ({ key: s, text: s, id: s })),
            onFilterUpdated: option => {
                this.setState({ currentCustomerFilter: option.currentValue as string });
            }
        });

        filterOptions.push({
            key: "date",
            filterTitle: "Date",
            currentValue: filterDate,
            filterType: "date",
            onFilterUpdated: (op) => {
                this.setState({ filterDate: op.currentValue as DateTime })
            }
        })
        const result: IFilterOptions = {
            options: filterOptions
        }
        return result;
    }

    render() {


        const userRole = UserRepository.getUserRole();


        const inProgressButton = (title: string) => (
            <div className="page-ComplaintOverview-downloadButton">
                <Spinner size={SpinnerSize.small} label={title} />
            </div>);

        const downloadTitle1 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document1.title", defaultMessage: "Download" });
        const downloadTitle2 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document2.title", defaultMessage: "Download" });
        const downloadTitle3 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document3.title", defaultMessage: "Download" });
        const downloadTitle4 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document4.title", defaultMessage: "Download" });
        const downloadTitle5 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document5.title", defaultMessage: "Download" });
        const downloadTitle6 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document6.title", defaultMessage: "Download" });
        const downloadTitle7 = this.props.intl.formatMessage({ id: "pages.complaints.rows.document7.title", defaultMessage: "Download" });



        return (
            <div>

                <Row id="page-ComplaintOverview-actions">
                    <Col className="text-right"><br />
                        <PrimaryButton onClick={this.onBackToMain}>
                            <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                        </PrimaryButton>
                    </Col>
                </Row>

                <h1><FormattedMessage id="pages.complaints.title" defaultMessage="Complaints" /></h1>

                {!this.state.isLoading &&
                    <>
                        <FilterBox filterOptions={this.getFilterOptions()} />
                        <HxDetailList
                            uniqueName="complaintOverviewMy"
                            columns={this.getColumns()}
                            items={this.getItems()}
                            isColumnReorderEnabled={true}
                            isColumnSelectionEnabled={true}
                            selectionMode={SelectionMode.none} />
                    </>}
                {
                    // !this.state.isLoading && !this.state.errorMessage && userRole.showComplaintOverview &&
                    // <Table>
                    //     <thead>
                    //         <tr>
                    //             <th><FormattedMessage id="pages.complaints.columns.complaintNumber" defaultMessage="Complaint number" /></th>
                    //             <th><FormattedMessage id="pages.complaints.columns.customerName" defaultMessage="Customer" /></th>
                    //             <th><FormattedMessage id="pages.complaints.columns.returnNumber" defaultMessage="Return number" /></th>
                    //             <th><FormattedMessage id="pages.complaints.columns.created" defaultMessage="Created" /></th>
                    //             <th>
                    //                 <FormattedMessage id="pages.complaints.columns.status" defaultMessage="Status" />
                    //                 <Dropdown
                    //                     isOpen={this.state.statusFilterOpen}
                    //                     toggle={() => this.setState({ statusFilterOpen: !this.state.statusFilterOpen })}>
                    //                     <DropdownToggle caret>
                    //                         {statusFilterText}
                    //                     </DropdownToggle>
                    //                     <DropdownMenu on>
                    //                         <DropdownItem onClick={() => this.onStatusChange(null)}>
                    //                             <FormattedMessage id="pages.complaints.columns.status.all" defaultMessage="All" />
                    //                         </DropdownItem>
                    //                         <DropdownItem divider />
                    //                         {
                    //                             completeStatusList.map(status =>
                    //                                 <DropdownItem key={status} onClick={() => this.onStatusChange(status)}>{status}</DropdownItem>)
                    //                         }
                    //                     </DropdownMenu>
                    //                 </Dropdown>
                    //             </th>
                    //             <th><FormattedMessage id="pages.complaints.columns.documents" defaultMessage="Documents" /></th>
                    //         </tr>
                    //     </thead>
                    //     <tbody>
                    //         {
                    //             myComplaints.map(complaint => (
                    //                 <tr key={complaint.id}>
                    //                     <td scope="row">{complaint.number}</td>
                    //                     <td>{complaint.customerName}</td>
                    //                     <td>{complaint.referenceNumber}</td>
                    //                     <td>{moment(complaint.created).format("DD-MM-YYYY")}</td>
                    //                     <td>{complaint.status}</td>
                    //                     <td>
                    //                         {complaint.documentUrls1 ? this.downloadButtons(complaint.id, complaint.documentUrls1, downloadTitle1, 0) : inProgressButton(downloadTitle1)}
                    //                         {complaint.documentUrls2 && this.downloadButtons(complaint.id, complaint.documentUrls2, downloadTitle2, 1)}
                    //                         {complaint.documentUrls3 && this.downloadButtons(complaint.id, complaint.documentUrls3, downloadTitle3, 2)}
                    //                         {complaint.documentUrls4 && this.downloadButtons(complaint.id, complaint.documentUrls4, downloadTitle4, 3)}
                    //                         {complaint.documentUrls5 && this.downloadButtons(complaint.id, complaint.documentUrls5, downloadTitle5, 4)}
                    //                         {complaint.documentUrls6 && this.downloadButtons(complaint.id, complaint.documentUrls6, downloadTitle6, 5)}
                    //                         {complaint.documentUrls7 && this.downloadButtons(complaint.id, complaint.documentUrls7, downloadTitle7, 6)}
                    //                     </td>
                    //                 </tr>))
                    //         }
                    //     </tbody>
                    // </Table >
                }
                {
                    this.state.isLoading && userRole.showComplaintOverview &&
                    <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                }
                {
                    !this.state.isLoading && this.state.errorMessage && userRole.showComplaintOverview &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.complaints.getAll" defaultMessage={this.state.errorMessage} />
                    </Alert>
                }
                {
                    !userRole.showComplaintOverview &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.unauthorizedAccess" defaultMessage="Unauthorized access" />
                    </Alert>
                }
            </div >
        );
    }
}

export default injectIntl(ComplaintOverviewPage, { forwardRef: true });