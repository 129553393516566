import NumUtils from "./NumUtils";

export default class LS {
    static SetPageSetting = (pageName: string, settingName: string, value: string | undefined) => {
        const keyName = "LS_" + pageName + "_" + settingName;
        if (value) {
            localStorage.setItem(keyName, value)
        } else {
            localStorage.removeItem(keyName);
        }
    }

    static GetPageSetting = (pageName: string, settingName: string): string | undefined => {
        const keyName = "LS_" + pageName + "_" + settingName;
        return localStorage.getItem(keyName) || undefined;
    }

    static GetPageSettingInt = (pageName: string, settingName: string): number | undefined => {
        const settingStr = LS.GetPageSetting(pageName, settingName);
        return NumUtils.ParseNumber(settingStr);
    }


    static SetPageSettingBool = (pageName: string, settingName: string, value: boolean | undefined) => {
        const keyName = "LS_" + pageName + "_" + settingName;
        if (value !== undefined) {
            localStorage.setItem(keyName, value ? "1": "0")
        } else {
            localStorage.removeItem(keyName);
        }
    }

    static GetPageSettingBool = (pageName: string, settingName: string): boolean | undefined => {
        const keyName = "LS_" + pageName + "_" + settingName;
        const foundValue = localStorage.getItem(keyName)
        return  foundValue === null ? undefined : foundValue == "1";
    }
}