import env from "../env/env.json";

import queryString from "query-string";
import Analytics from "../components/analytics/Analytics";

export class Util {
    private static MaxId: number = 999999;

    public static Navigate = (path: string) =>
        document.location.hash = path;
    public static NavigateAbsolute = (url: string, openInNewTab?: boolean, title?: string) =>
    {
        let actionTitle = title || "Navigeren naar externe pagina";
        Analytics.registerNavigateAction(actionTitle, url);
        setTimeout(() => { openInNewTab ? Util.openInNewTab(url) : document.location.href = url });
    }

    public static CreateUrl = (path: string) => `/#${path}`;

    public static EmptyGuid = (): string => "00000000-0000-0000-0000-000000000000";
    public static GenerateId = (max: number = Util.MaxId): number => Math.floor((Math.random() * max) + 1);
    public static GenerateIdString = () => `${Util.GenerateId(Util.MaxId)}`;

    public static IsNullOrWhitespace = (str?: string) => !str || str == null || str.replace(/\s/g, '').length < 1;
    public static Filter = (str: string, filterText: string): boolean => {
        const text = str.toLowerCase();
        const filters = filterText.trim().toLowerCase().split(/\s+/gi);
        for (let filter of filters) {
            if (text.indexOf(filter) < 0) return false;
        }
        return true;
    }

    public static FilterObject = (obj: any, filterText: string, properties: string[]) => {
        let fullText = "";
        for (let propertyName of properties) {
            fullText += `${obj[propertyName]} `;
        }
        return Util.Filter(fullText, filterText);
    }

    public static Unique = (items: any[], uniquePropertyName: string) => {
        const result: any[] = [];
        items.forEach(item => {
            if (result.findIndex(i => i[uniquePropertyName] === item[uniquePropertyName]) < 0) {
                result.push(Object.assign({}, item));
            }
        });
        return result;
    }
    
    public static Sort = <T>(items: T[], columnKey: string, isSortedDescending?: boolean) => {
        const key = columnKey as keyof T;
        return [...items].sort((a: T, b: T) => isSortedDescending ? -1 * Util.Compare(a[key], b[key]) : Util.Compare(a[key], b[key]) );
    }

    public static CompareMulti = (result1: number, result2: number) => result1 !== 0 ? result1 : result2;
    public static CompareString = (a: string, b: string) => a.localeCompare(b);
    public static CompareNumber = (a: number, b: number) => a > b ? 1 : a < b ? -1 : 0;
    public static Compare = (a: any, b: any) => a > b ? 1 : a < b ? -1 : 0;

    public static Classes = (classes: any[]) => classes.join(" ");

    public static IsLocal = () => env.Environment === "local";

    public static MicrosoftTenantId = () => Util.IsLocal() ? env.ADAppTenantId : "common";

    public static GetStringKeysOfEnum = (enumeration: any): string[] => {
        let objKeys = Object.keys(enumeration);
        return objKeys.slice(objKeys.length / 2, objKeys.length);
    }

    public static Log = (message?: any) => console ? console.log(message) : null;

    public static isNothing = (value: any) => value === undefined || value === null || value === "" || value === "none" || (Util.isNumber(value) && value === 0);
    public static isNumber = (value: any) => typeof value === 'number' || !isNaN(value);

    public static openInNewTab = (url: string) => {
        const win = window.open(url, '_blank');
        win && win.focus();
    }

    private static getLocationHash = () => document.location.hash && document.location.hash.startsWith('#/') ? `${document.location.hash}`.substr(2) : document.location.hash;
    private static getLocationSearch = () => document.location.search && document.location.search.startsWith('?') ? `${document.location.search}`.substr(1) : document.location.search;

    public static GetQueryParams = () => {
        const hash = Util.getLocationHash();
        const parts = hash.split('?');
        return (parts.length >= 2) ? queryString.parse(parts[1]) : queryString.parse(parts[0]);
    }

    public static ClearProperties = (data: any, clearFieldNames: string[], defaultValue?: any) => {
        const result = {...data};
        clearFieldNames.forEach(fieldName => { result[fieldName] = defaultValue });
        return result;
    }

    // parse date in format yyyy-MM-dd
    public static ParseDate = (dateString?: string) : Date | undefined => {
        let result: Date | undefined = undefined;
        if (dateString) {
            // split params.value date format yyyy-MM-dd into year, month and day
            const dateParts = dateString.split('-');
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];
            result = new Date(Number(year), Number(month) - 1, Number(day));
        }

        return result;
    }
}