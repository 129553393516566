import { ICrudCreateResponseVM, IBlobVM, ICrudGetOneResponseVM,ICrudUpdateResponseVM } from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export class SolutionImageService {

    public static Create = (file: File, parentId: string): Promise<ICrudCreateResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("file", file);
            axios.post(`${Service.ApiUrl}/v1/solutions/${parentId}/image`, formData, Service.Config("multipart/form-data"))
                .then((response: AxiosResponse<ICrudCreateResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionimage.create", reason));
        });
        
        public static UpdateContent = (file: File, solutionId: string): Promise<ICrudUpdateResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("file", file);
            axios.put(`${Service.ApiUrl}/v1/solutions/${solutionId}/image/content`, formData, Service.Config("multipart/form-data"))
                .then((response: AxiosResponse<ICrudUpdateResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.principlediagram.update", reason));
        });
    
        
        public static GetOne = (solutionId: string): Promise<ICrudGetOneResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/image`, Service.Config())
                .then((response: AxiosResponse<ICrudGetOneResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionimage.getOne", reason));
        });
        
}