import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import { IContactVM, IGetUserProfileResponseVM } from "../viewmodels/data";
import MijnNathanService from "./MijnNathanService";

export class AuthService {

    public static GetMe = (): Promise<IContactVM> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/me`, Service.Config())
                .then((response: AxiosResponse<IGetUserProfileResponseVM>) => resolve(response.data.value))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.auth.getMe", reason));
        })

    public static RegisterResetPassword = (): Promise<void> =>
        new Promise((resolve, reject) => {
            axios.post(`${Service.ApiUrl}/v1/me/resetPassword`, {}, Service.Config())
                .then(() => resolve())
                .catch(reason => MijnNathanService.CatchId(reject, "errors.auth.resetPassword", reason));
        })
}