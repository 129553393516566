import React from "react";
import DashboardItem, { DashboardItemIconSizeEnum } from "../dashboard/DashboardItem";
import { Util } from "../Utils/Util";
import { TiDocumentText } from "react-icons/ti";

export default class Forms extends React.Component {

    private onFormAClick = () => Util.Navigate("Forms/FormA");

    render() {
        return (
            <div className="Forms">
                <h1>Formulieren</h1>

                <DashboardItem title="Formulier A" subTitle="Offerteaanvraag" onClick={this.onFormAClick} iconSize={DashboardItemIconSizeEnum.Large}><TiDocumentText /></DashboardItem>
            </div>
        );
    }
}