import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";
import { IItemVM, ICrudGetAllResponseVM } from "../viewmodels/data";
import { ACrudService } from "./CrudService";

export class ItemService extends ACrudService<IItemVM> {

    constructor() {
        super("1", "items");
    }

    public static Search = (searchText: string): Promise<IItemVM[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/items?searchText=${searchText}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<IItemVM>>) => resolve(response.data.value))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.items.search", reason));
        });

}