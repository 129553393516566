import {
    ICrudGetAllResponseVM, ICrudCreateResponseVM, ICrudUpdateResponseVM,
    ISolutionPositionVM, ISolutionPositionItemVM
} from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";
import { resolve } from "dns";

export class SolutionPositionService {

    public static Create_PosHeader = (positionHeader: ISolutionPositionVM, solutionId: string): Promise<ICrudCreateResponseVM<ISolutionPositionVM>> =>
        new Promise((resolve, reject) => {
            axios.post(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions`, positionHeader, Service.Config())
                .then((response: AxiosResponse<ICrudCreateResponseVM<ISolutionPositionVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.create", reason));
        });

    public static Update_PosHeader = (positionHeader: ISolutionPositionVM, solutionId: string, positionId: string): Promise<ICrudUpdateResponseVM<ISolutionPositionVM>> =>
        new Promise((resolve, reject) => {
            axios.put(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${positionId}`, positionHeader, Service.Config())
                .then((response: AxiosResponse<ICrudUpdateResponseVM<ISolutionPositionVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.update", reason));
        });

    public static Delete_PosHeader = (solutionId: string, positionId: string): Promise<ICrudGetAllResponseVM<ISolutionPositionVM>> =>
        new Promise((resolve, reject) => {
            axios.delete(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${positionId}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionPositionVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.delete", reason));
        });

    public static GetAll_PosHeader = (solutionId: string): Promise<ICrudGetAllResponseVM<ISolutionPositionVM>> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionPositionVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.getAll", reason));
        });

    public static Create_PosItem = (solutionPositionItem: ISolutionPositionItemVM, solutionId: string, positionHeaderId: string): Promise<ICrudCreateResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.post(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${positionHeaderId}/items`, solutionPositionItem, Service.Config())
                .then((response: AxiosResponse<ICrudCreateResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionpositionitem.create", reason));
        });

    public static Update_PosItem = (positionHeader: ISolutionPositionItemVM, solutionId: string, positionId: string, itemId: string): Promise<ICrudUpdateResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.put(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${positionId}/items/${itemId}`, positionHeader, Service.Config())
                .then((response: AxiosResponse<ICrudUpdateResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.update", reason));
        });

    public static Delete_PosItem = (solutionId: string, positionId: string, itemId: string): Promise<ICrudGetAllResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.delete(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${positionId}/items/${itemId}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.delete", reason));
        });

    public static GetAll_PosItem = (solutionId: string, itemId: string): Promise<ICrudGetAllResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/positions/${itemId}/items`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionpositionitem.getAll", reason));
        });

    public static GetAllItems = (): Promise<ICrudGetAllResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/GetAllItems`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionpositionitem.getAll", reason))
        })

    public static UpdatePositionItems = (item: ISolutionPositionItemVM, itemId: string): Promise<ICrudUpdateResponseVM<ISolutionPositionItemVM>> =>
        new Promise((resolve, reject) => {
            axios.put(`${Service.ApiUrl}/v1/UpdatePositionItems/items/${itemId}`, item, Service.Config())
                .then((response: AxiosResponse<ICrudUpdateResponseVM<ISolutionPositionItemVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionposition.update", reason));
        })

}