import { DateTime } from "luxon";
import DateTimeUtils from "./DateTimeUtils";


export default class Interval {

    start: DateTime;
    end: DateTime;
    isValid: boolean;

    public static FromDateTimes(dt1: DateTime, dt2: DateTime) {
        return new Interval(dt1, dt2);
    }

    public static FromYear(year: number) {
        return new Interval(
            DateTime.fromObject({ day: 1, month: 1, year: year }),
            DateTime.fromObject({ day: 31, month: 12, year: year }));
    }

    private constructor(dt1: DateTime, dt2: DateTime) {
        this.start = DateTimeUtils.getDate(dt1);
        this.end = DateTimeUtils.getDate(dt2);
        this.isValid = this.start.valueOf() <= this.end.valueOf();
    }

    /** Return true of there is any overlap */
    public Overlaps = (other?: Interval) => {
        // The default overlaps function of the interval ignores the last date
        if (!other || !this.isValid || !other.isValid) return false;
        const start1 = this.start.valueOf();
        const end1 = this.end.valueOf();
        const start2 = other.start.valueOf();
        const end2 = other.end.valueOf();
        return (end1 >= start2) && (end2 >= start1);
    }

    /** This interval completely engulfs the other */
    public Engulfs = (other: Interval) => {
        const start1 = this.start.valueOf();
        const end1 = this.end.valueOf();
        const start2 = other.start.valueOf();
        const end2 = other.end.valueOf();
        return (start1 <= start2) && (end1 >= end2);
    }

    /** The interval that this and the other shares */
    public Intersection = (other?: Interval) => {
        // The default overlaps function of the interval ignores the last date
        if (!other || !this.isValid || !this.isValid) return undefined;
        const start1 = this.start.valueOf();
        const end1 = this.end.valueOf();
        const start2 = other.start.valueOf();
        const end2 = other.end.valueOf();

        if (start1 > end2 || start2 > end1) return undefined;

        const newStart = start1 > start2 ? this.start : other.start;
        const newEnd = end1 > end2 ? other.end : this.end;

        return Interval.FromDateTimes(newStart, newEnd);
    }

    /** Get the dates of this interval, excluding the dates of the other interval */
    public Difference = (other?: Interval) => {
        if (!other || !this.isValid || !other.isValid) return undefined;
        const startThis = this.start.valueOf();
        const endThis = this.end.valueOf();
        const startOther = other.start.valueOf();
        const endOther = other.end.valueOf();

        if (startThis > endOther || startOther > endThis) return [this]; // No intersection

        let result: Interval[] = [];
        if (startThis < startOther) {
            result.push(Interval.FromDateTimes(this.start, other.start.minus({ day: 1 })));
        }
        if (endOther > endThis) {
            result.push(Interval.FromDateTimes(this.end.plus({ day: 1 }), other.end));
        }
        return result;
    }

    /** Creates an interval with Min of start and Max of end dates */
    public Union = (other?: Interval) => {
        if (!other || !this.isValid || !other.isValid) return undefined;
        const startThis = this.start.valueOf();
        const endThis = this.end.valueOf();
        const startOther = other.start.valueOf();
        const endOther = other.end.valueOf();

        const newStart = DateTimeUtils.Min(this.start, other.start)!;
        const newEnd = DateTimeUtils.Max(this.end, other.end)!;
        return Interval.FromDateTimes(newStart, newEnd);
    }

}