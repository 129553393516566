import { ColumnActionsMode, IColumn, IDetailsColumnFilterIconProps, IDetailsColumnProps, IDetailsColumnStyleProps, IDetailsColumnStyles, IRenderFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { MouseEvent } from "react";


export default class HxDetailListColumnBase implements IColumn {

    key: string;
    name: string;
    fieldName?: string | undefined;
    flexGrow?: number | undefined;
    className?: string | undefined;
    styles?: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles> | undefined;
    minWidth: number;
    targetWidthProportion?: number | undefined;
    ariaLabel?: string | undefined;
    isRowHeader?: boolean | undefined;
    maxWidth?: number | undefined;
    columnActionsMode?: ColumnActionsMode | undefined;
    iconName?: string | undefined;
    isIconOnly?: boolean | undefined;
    iconClassName?: string | undefined;
    isCollapsable?: boolean | undefined;
    isCollapsible?: boolean | undefined;
    isSorted?: boolean | undefined;
    isSortedDescending?: boolean | undefined;
    isResizable: boolean;
    isMultiline?: boolean | undefined;
    onRender?: ((item?: any, index?: number | undefined, column?: IColumn | undefined) => any) | undefined;
    getValueKey?: ((item?: any, index?: number | undefined, column?: IColumn | undefined) => string) | undefined;
    onRenderDivider?: IRenderFunction<IDetailsColumnProps> | undefined;
    onRenderFilterIcon?: IRenderFunction<IDetailsColumnFilterIconProps> | undefined;
    onRenderHeader?: IRenderFunction<IDetailsColumnProps> | undefined;
    isFiltered?: boolean | undefined;
    onColumnClick?: ((ev: MouseEvent, column: IColumn) => void) | undefined;
    onColumnContextMenu?: ((column?: IColumn | undefined, ev?: MouseEvent | undefined) => void) | undefined;
    onColumnResize?: ((width?: number | undefined) => void) | undefined;
    isGrouped?: boolean | undefined;
    data?: any;
    calculatedWidth?: number | undefined;
    currentWidth?: number | undefined;
    headerClassName?: string | undefined;
    isPadded?: boolean | undefined;
    sortAscendingAriaLabel?: string | undefined;
    sortDescendingAriaLabel?: string | undefined;
    groupAriaLabel?: string | undefined;
    filterAriaLabel?: string | undefined;
    isMenuOpen?: boolean | undefined;

    constructor(displayName: string, fieldName: string, minWidth: number) {
        this.key = fieldName;
        this.fieldName = fieldName;
        this.name = displayName;
        
        this.minWidth = minWidth;
        this.maxWidth = minWidth * 2;

        this.isResizable = false;
    }




}