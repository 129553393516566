import { IChoiceGroupOption, IComboBoxOption, IDropdownOption } from "@fluentui/react";
import NumUtils from "./NumUtils";
import ObjUtils from "./ObjUtils"



export default class EnumUtils {


    public static getComboOptions = <T extends { [name: string]: any }>(enumObject: T, getText?: (ordEnum: number) => string): IComboBoxOption[] => {
        const ordinalValues = EnumUtils.getOrdinalValues(enumObject);
        const result: IComboBoxOption[] = [];
        for (let k of ordinalValues) {

            const text = getText ? getText(k) :  enumObject[k] as string;

            result.push({
                key: k,
                id: "" + k,
                text: text
            });
        }
        return result;
    }
    public static getDropdownOptions = <T extends { [name: string]: any }>(enumObject: T, getText?: (ordEnum: number) => string): IDropdownOption[] => {
        const ordinalValues = EnumUtils.getOrdinalValues(enumObject);
        const result: IDropdownOption[] = [];
        for (let k of ordinalValues) {

            const text = getText ? getText(k) :  enumObject[k] as string;

            result.push({
                key: k,
                id: "" + k,
                text: text
            });
        }
        return result;
    }


    public static getChoiceGroupOptions = <T extends { [name: string]: any }>(enumObject: T, getText?: (ordEnum: number) => string): IChoiceGroupOption[] => {
        const ordinalValues = EnumUtils.getOrdinalValues(enumObject);
        const result: IChoiceGroupOption[] = [];
        for (let k of ordinalValues) {

            const text = getText ? getText(k) :  enumObject[k] as string;

            result.push({
                key: "" + k,
                id: "" + k,
                text: text
            });
        }
        return result;
    }


    public static getStringValues = (enumObject: any): string[] => {
        return Object.values(enumObject).filter(e => ObjUtils.isString(e)) as string[];
    }


    public static getOrdinalValues = (enumObject: any): number[] => {
        return Object.values(enumObject).filter(e => ObjUtils.isNumber(e)) as number[];
    }


    public static getOrdinal = <T extends { [name: string]: any }>(enumObject: T, key: string | number): number => {
        if (NumUtils.isNumericString(key)) {
            return +key;
        }
        if (typeof key === 'string') {
            return enumObject[key];
        } else if (typeof key === 'number') {
            return enumObject[enumObject[key]];

        } else {
            throw new Error(`Unable to parse enum from ${typeof (key)}`);
        }
    }

    public static getString = <T extends { [name: string]: any }>(enumObject: T, key: string | number): string => {
        if (NumUtils.isNumericString(key) || ObjUtils.isNumber(key)) {
            return enumObject[+key];
        }

        if (ObjUtils.isString(key)) {
            return enumObject[enumObject[key]];
        } else {
            throw new Error(`Unable to parse enum from ${typeof (key)}`);
        }
    }


}

