import { Util } from "../../Utils/Util";

export enum FormFieldType {
    Header, Text, PostalCode, Checkbox, Email, PositiveInteger, PositiveNumber, Radio
}

export enum FormFieldDependentOfType {
    Or, And
}

export interface IFormOption {
    value: string;
    label: string;
}


export default interface IFormField {
    type: FormFieldType;
    label: string;
    name?: string;
    hint?: string;
    fields?: IFormField[];
    dependentOf?: string | string[] | undefined;
    dependentOfType?: FormFieldDependentOfType;
    dependentOfFn?: (fieldName: string, value: any) => boolean;
    required?: boolean;
    validationFn?: (field: IFormField, value: any) => string[];
    getValue?: (el: HTMLInputElement) => any;
}

export class FormFields {
    public static getFormFieldByName = (fields: IFormField[], fieldName: string): IFormField | undefined => {
        for (const field of fields) {
            if (field.name === fieldName) return field;
            if (field.fields) {
                const result = FormFields.getFormFieldByName(field.fields, fieldName);
                if (result) return result;
            }
        }

        return undefined;
    }
    public static getFieldLabelByName = (fields: IFormField[], fieldName: string, defaultTitle: string = "onbekend") => {
        const field = FormFields.getFormFieldByName(fields, fieldName);
        return field ? field.label : defaultTitle;
    }
}

export class HeaderFormField implements IFormField {
    public readonly type = FormFieldType.Header;
    public label = "";
    public fields?: IFormField[];

    constructor(label: string, fields?: IFormField[]) {
        this.label = label;
        this.fields = fields ? [...fields] : undefined;
    }
}

class AFormField implements IFormField {
    public type = FormFieldType.Text;
    public label = "";
    public name = "";
    public required: boolean | undefined;
    public hint: string | undefined;
    public dependentOf: string | string[] | undefined;
    public dependentOfType: FormFieldDependentOfType;
    public dependentOfFn?: (fieldName: string, value: any) => boolean;
    public validationFn?: (field: IFormField, value: any) => string[];

    public getValue = (el: HTMLInputElement) => el ? el.value : undefined;

    constructor(type: FormFieldType, name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        this.type = type;
        this.name = name;
        this.label = label;
        this.required = required;
        this.hint = hint;
        this.dependentOf = dependentOf;
        this.dependentOfType = dependentOfType || FormFieldDependentOfType.Or;
        this.dependentOfFn = dependentOfFn;
        this.validationFn = validationFn;
    }
}

export class TextFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.Text, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }
}

export class PostalCodeFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.PostalCode, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }
}

export class CheckboxFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.Checkbox, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }

    public getValue = (el: HTMLInputElement): any => el ? el.checked : undefined;
}

export class EmailFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.Email, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }
}

export class PositiveIntegerFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.PositiveInteger, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }

    public getValue = (el: HTMLInputElement): any => el ? (Util.isNumber(el.value) ? +el.value : 0) : undefined;
}

export class PositiveNumberFormField extends AFormField {
    constructor(name: string, label: string, required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.PositiveNumber, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }
}

export class RadioFormField extends AFormField {
    public options: IFormOption[]

    constructor(name: string, label: string, options: IFormOption[], required?: boolean, hint?: string,
                dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
                validationFn?: (field: IFormField, value: any) => string[]) {
        super(FormFieldType.Radio, name, label, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
        this.options = [...options];
    }

    public getValue = (el: HTMLInputElement): any => el ? el.value && el.value !== "" ? el.value : "none" : undefined;
}