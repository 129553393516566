export default class ObjUtils {
    public static shallowEqual(object1: any, object2: any) {
        if ((object1 == undefined) && (object2 == undefined)) return true;
        if ((object1 == undefined) || (object2 == undefined)) return false;

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }

    public static shallowArrayEqual(object1: any[] | undefined, object2: any[] | undefined) {
        if (object1 === undefined && object2 === undefined) return true;
        if (object1 === undefined || object2 === undefined) return false;
        if (object1.length != object2.length) return false;
        for (let i = 0; i < object1.length; i++) {
            if (object1[i] != object2[i]) {
                return false;
            }
        }
        return true;
    }

    public static readonly emptyGuid = "00000000-0000-0000-0000-000000000000"

    public static NewGuid() {
        let newGuid: string | undefined;
        try {
            newGuid = crypto.randomUUID();
        } catch { }

        if (!newGuid) {
            newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        return newGuid;
    }


    public static isGuid(guid: any) {
        return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test('' + guid);
    }

    public static isEmptyGuid(guid: string | undefined | null): boolean {
        if (!guid) return true;
        if (guid == this.emptyGuid) return true;
        return false;
    }

    public static isString(obj: any): boolean {
        return ((obj instanceof String) || (typeof obj == "string"));
    }

    public static isNumber(obj: any): boolean {
        // debugger;
        return ((obj instanceof Number) || (typeof obj == "number"));
    }

    public static isBool(obj: any): boolean {
        return ((obj instanceof Boolean) || (typeof obj == "boolean"));
    }

    public static getKeys = <TK, TV>(map: Map<TK, TV>): TK[] => {
        const keys = map.keys();
        return Array.from(keys);
    }

    public static getValues = <TK, TV>(map: Map<TK, TV>): TV[] => {
        const values = map.values();
        return Array.from(values);
    }

    public static getEntries = <TK, TV>(map: Map<TK, TV>): [TK, TV][] => {
        const entries = map.entries();
        return Array.from(entries);
    }

    public static forEach = <TV>(dic: { [key: string]: TV }, action: (key: string, obj: TV) => void) => {
        const allKeys = Object.keys(dic);
        for (let i = 0; i < allKeys.length; i++) {
            const currentKey = allKeys[i];
            const currentObj = dic[currentKey];
            action(currentKey, currentObj);
        }
    }

    public static PadLeft = (num: number, length: number) => ("" + num).padStart(length, "0")

}


// // This is workaround because Webpack doesn't know this function yet
// declare global {
//     interface Crypto {
//         randomUUID: () => string;
//     }
// }