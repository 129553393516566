import React from "react";
import { RouteComponentProps } from 'react-router';
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import Loading from "../components/Loading";
import { IRequestVM, RequestStatusEnum } from "../viewmodels/data";
import { ComplaintService } from "../services/ComplaintService";
import { Alert, Row, Col, Button } from "reactstrap";
import { UserRepository } from "../repositories/UserRepository";
import { Util } from "../Utils/Util";
import Analytics from "../components/analytics/Analytics";
import { PrimaryButton } from "@fluentui/react";
import EnumUtils from "../Utils/EnumUtils";

interface IComplaintDetailMatchParams {
    reclamatieId: string;
}

interface IComplaintDetailPageProps extends RouteComponentProps<IComplaintDetailMatchParams>, WrappedComponentProps {}
interface IComplaintDetailPageState {
    complaint?: IRequestVM;

    isLoading?: boolean;
    errorMessage?: string;
}

class ComplaintDetailPage extends React.Component<IComplaintDetailPageProps, IComplaintDetailPageState>
{
    constructor(props: IComplaintDetailPageProps) {
        super(props);
        
        this.state = {};
    }

    componentDidMount() {
        this.loadComplaint();
    }

    private loadComplaint = () => {
        this.setState({
            complaint: undefined,
            isLoading: true,
            errorMessage: undefined,
        });

        ComplaintService.Get(this.props.match.params.reclamatieId)
            .then(complaint => this.setState({ complaint: complaint }))
            .catch(reason => this.setState({ errorMessage: reason }))
            .finally(() => this.setState({ isLoading: false }));
    }

    private onBackToOverview = () => {
        Analytics.registerReclamatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.reclamatie.backToOverview' }));
        Util.Navigate("/Reclamaties");
    }

    render() {
        const userRole = UserRepository.getUserRole();
        const { complaint} = this.state;

        const requestStatusStr = complaint ? EnumUtils.getString(RequestStatusEnum, complaint.requestStatus) : "";

        return (
            <div>
                <Row id="page-CommissioningOverview-actions">
                    <Col className="text-right">
                        <PrimaryButton onClick={this.onBackToOverview}>
                            <FormattedMessage id="general.backToOverview" defaultMessage="Back to overview" />
                        </PrimaryButton>
                    </Col>
                </Row>
                
                <h1><FormattedMessage id="pages.complaintDetails.title" defaultMessage="Complaint" /></h1>
                {
                    !this.state.isLoading &&
                    <div className="table">
                        <div className="row">
                            <div className="col col-md-3"><FormattedMessage id="pages.complaintDetails.fields.name" defaultMessage="Name" /></div>
                            <div className="col col-md-3">{complaint?.displayName}</div>
                        </div>
                        <div className="row">
                            <div className="col col-md-3"><FormattedMessage id="pages.complaintDetails.fields.customer" defaultMessage="Customer" /></div>
                            <div className="col col-md-3">{complaint?.customerName}</div>
                        </div>
                        <div className="row">
                            <div className="col col-md-3"><FormattedMessage id="pages.complaintDetails.fields.status" defaultMessage="Status" /></div>
                            <div className="col col-md-3">{requestStatusStr}</div>
                        </div>
                    </div>
                }
                {
                    this.state.isLoading && userRole.showComplaintOverview && 
                    <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                }
                {
                    !this.state.isLoading && this.state.errorMessage && userRole.showComplaintOverview &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.complaints.getAll" defaultMessage={this.state.errorMessage} />
                    </Alert>
                }
                {
                    !userRole.showComplaintOverview &&
                    <Alert color="danger">
                        <FormattedMessage id="errors.unauthorizedAccess" defaultMessage="Unauthorized access" />
                    </Alert>
                }
            </div>
        )
    }
}

export default injectIntl(ComplaintDetailPage, { forwardRef: true });