import React from "react";
import { WrappedComponentProps, FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Util } from "../Utils/Util";
import { UserRepository } from "../repositories/UserRepository";
import { Row, Col, Button, Container, Table, Alert } from "reactstrap";
import { ICommissioningVM } from "../viewmodels/data";
import Loading from "../components/Loading";
import moment from "moment";
import { InbedrijfstellingService } from "../services/InbedrijfstellingService";

import "./InbedrijfstellingOverviewPage.scss";
import { NavigateToAuth, NavigateToPage } from "../NavigateTo";
import Analytics from "../components/analytics/Analytics";
import HxDetailList, { HxDetailListItem } from "../components/HxDetailList";
import HxDetailListColumn from "../components/HxDetailListColumn";
import { DefaultButton, IColumn, PrimaryButton, Sticky } from "@fluentui/react";
import { DateTime } from "luxon";
import DateTimeUtils from "../Utils/DateTimeUtils";
import ArrayUtils from "../Utils/ArrayUtils";
import FilterBox, { IFilterOptions } from "../components/FilterBox";

interface IInbedrijfstellingOverviewPageProps extends WrappedComponentProps { }

interface IInbedrijfstellingOverviewPageState {
    myCommissionings: ICommissioningVM[];

    isLoading?: boolean;
    errorMessage?: string;

    filterHeatPump?: string,
    filterDate?: DateTime,
    filterCustomer?: string,
}

interface ListItem extends ICommissioningVM, HxDetailListItem {
    ibnDateStr: string;
    ibnDateObj: DateTime
}

class CommissioningOverviewPage extends React.Component<IInbedrijfstellingOverviewPageProps, IInbedrijfstellingOverviewPageState> {

    constructor(props: IInbedrijfstellingOverviewPageProps) {
        super(props);

        this.state = {
            myCommissionings: [],
            filterHeatPump: "All",
            filterCustomer: "All",
        };
    }

    componentDidMount() {
        this.loadCommissionings();
    }

    private loadCommissionings = () => {
        this.setState({
            myCommissionings: []
        });

        this.setState({
            isLoading: true,
            errorMessage: undefined
        });

        this.loadMyCommissionings();
    }

    private loadMyCommissionings = () =>
        InbedrijfstellingService.GetMyAll()
            .then(commissionings => {
                const sortedCommissionings = [...commissionings];
                // sort desc
                sortedCommissionings.sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0);

                this.setState({
                    myCommissionings: [...sortedCommissionings],
                    errorMessage: undefined
                });
            })
            .catch(reason => {
                console.log(reason)
                this.setState({
                    errorMessage: ("" + reason)
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });

    private onBackToMain = () => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.backToMain' }));

        NavigateToPage.Home();
    }
    private onDocumentDownload = () => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.downloadDocument' }));
    }
    private onLogDownload = () => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.downloadLog' }));
    }
    // private onDocumentDownload = (commissioning: ICommissioningVM) => Util.openInNewTab(commissioning.documentURL);
    // private onCsvDownload = (commissioning: ICommissioningVM) => Util.openInNewTab(commissioning.csvUrl);

    private copyForm = (formId: string) => {
        Analytics.registerIBNAction(this.props.intl.formatMessage({ id: 'analytics.actions.IBN.copy' }));

        NavigateToPage.InbedrijfstellingForm(formId);
    }

    getItems = () => {
        const { myCommissionings } = this.state;
        const listItems = myCommissionings.map(c => {
            const ibnDate = DateTime.fromISO(c.ibnDate);
            const ibnDateStr = DateTimeUtils.ToAppFormat(ibnDate);
            const item: ListItem = {
                ...c,
                ibnDateObj: ibnDate,
                ibnDateStr: ibnDateStr,
                // ibnDateObj: DateTime.fromISO(c.ibnDate),
                SearchTextUpper: Object.values(c).join(" ").toUpperCase() + ibnDateStr
            }
            return item;
        })
        return listItems;
    }

    getColumns = () => {
        const { intl } = this.props;
        const columns: IColumn[] = [
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.commissionings.columns.name' }), "displayName", 157),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.commissionings.columns.warmtepompType' }), "warmtepompType", 100),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.commissionings.columns.created' }), "ibnDateObj", 80, "date"),
            new HxDetailListColumn(intl.formatMessage({ id: 'pages.commissionings.columns.customer' }), "customerName", 120),
            {
                key: "copy",
                name: intl.formatMessage({ id: 'pages.commissionings.columns.copyForm', defaultMessage: "Copy" }),
                minWidth: 90,
                onRender: (item?: ListItem, index?: number, column?: IColumn) => {
                    return <PrimaryButton onClick={() => this.copyForm(item!.id)}>
                        <FormattedMessage id="pages.commissionings.rows.copyInbedrijfstelling.title" defaultMessage="Copy" />
                    </PrimaryButton>
                }
            },
            {
                key: "doc",
                name: intl.formatMessage({ id: 'pages.commissionings.columns.document' }),
                minWidth: 120,
                onRender: (item?: ListItem, index?: number, column?: IColumn) => {
                    return item?.documentURL &&
                        <a href={item.documentURL} download={item.documentURL} onClick={this.onDocumentDownload}>
                            <PrimaryButton>
                                <FormattedMessage id="pages.commissionings.rows.document.title" defaultMessage="View" />
                            </PrimaryButton>
                        </a>
                }
            },
            {
                key: "csv",
                name: intl.formatMessage({ id: 'pages.commissionings.columns.csv' }),
                minWidth: 120,
                onRender: (item?: ListItem, index?: number, column?: IColumn) => {
                    return item?.csvUrl &&
                        <a href={item.csvUrl} download={item.csvUrl} onClick={this.onLogDownload}>
                            <PrimaryButton>
                                <FormattedMessage id="pages.commissionings.rows.csv.title" defaultMessage="Download" />
                            </PrimaryButton>
                        </a>
                }
            }
        ];

        return columns;
    }

    getFilterOptions = () => {
        const { filterHeatPump, filterDate, filterCustomer, myCommissionings } = this.state;

        var heatPumps = myCommissionings.length > 0 ? ArrayUtils.distinct(myCommissionings.map(c => c.warmtepompType).filter(n => !!n)) as string[] : undefined;
        var customers = myCommissionings.length > 0 ? ArrayUtils.distinct(myCommissionings.map(c => c.customerName).filter(n => !!n)) as string[] : undefined;

        const filterOptions: IFilterOptions = {
            options: [{
                filterTitle: "Heat pump",
                key: "heatPump",
                filterType: "choice",
                choiceOptions: FilterBox.GetSelectOptions(heatPumps ? heatPumps : [], "All"),
                currentValue: filterHeatPump,
                onFilterUpdated: (op) => {
                    this.setState({ filterHeatPump: op.currentValue as string | undefined | "Any" })
                }
            }, {
                filterTitle: "Customers",
                key: "customers",
                filterType: "choice",
                choiceOptions: FilterBox.GetSelectOptions(customers ? customers : [], "All"),
                currentValue: filterCustomer,
                onFilterUpdated: (op) => {
                    this.setState({ filterCustomer: op.currentValue as string | undefined | "All" })
                }
            }, {
                filterTitle: "Date",
                key: "date",
                filterType: "date",
                currentValue: filterDate,
                onFilterUpdated: (op) => {
                    this.setState({ filterDate: op.currentValue as DateTime })
                }
            }]
        }

        return filterOptions;
    }

    getFilteredCommissionings = () => {
        const { filterHeatPump, filterCustomer, filterDate, myCommissionings } = this.state;

        if (!myCommissionings) return undefined

        var filtered = this.getItems().slice();

        // console.log("created", DateTimeUtils.getDate(DateTime.fromISO("2023-05-31T09:25:32.723")).valueOf());
        // console.log("filter", filterDate);
        
        
        if (filterHeatPump && filterHeatPump != "All") filtered = filtered.filter(c => c.warmtepompType == filterHeatPump);
        if (filterCustomer && filterCustomer != "All") filtered = filtered.filter(c => c.customerName == filterCustomer);
        if (filterDate) filtered = filtered.filter(c => DateTimeUtils.getDate(DateTime.fromISO(c.ibnDate)).valueOf() == filterDate.valueOf());

        return filtered
    }

    render() {
        const myCommissionings: ICommissioningVM[] = [...this.state.myCommissionings];

        const userRole = UserRepository.getUserRole();

        const columns = this.getColumns();
        const listItems = this.getItems();

        return (
            <div className="page-CommissioningOverview">

                <Row id="page-CommissioningOverview-actions">
                    <Col className="text-right">
                        <br />
                        <PrimaryButton onClick={this.onBackToMain}>
                            <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                        </PrimaryButton>
                    </Col>
                </Row>

                <h1><FormattedMessage id="pages.commissionings.title" defaultMessage="In gebruiknames" /></h1>

                <Container>
                    <Sticky stickyBackgroundColor="#fff">
                        <FilterBox filterOptions={this.getFilterOptions()} />
                    </Sticky>
                    <HxDetailList uniqueName="inbOverview" columns={columns} items={this.getFilteredCommissionings()} isColumnReorderEnabled={true} isColumnSelectionEnabled={true} />

                    {
                        // !this.state.isLoading && !this.state.errorMessage && userRole.showCommissioningOverview &&
                        // <Table>
                        //     <thead>
                        //         <tr>
                        //             <th><FormattedMessage id="pages.commissionings.columns.name" defaultMessage="Name" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.warmtepompType" defaultMessage="Heat pump" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.created" defaultMessage="Created" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.customer" defaultMessage="Customer" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.copyForm" defaultMessage="Copy form" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.document" defaultMessage="Document" /></th>
                        //             <th><FormattedMessage id="pages.commissionings.columns.csv" defaultMessage="CSV" /></th>
                        //         </tr>
                        //     </thead>
                        //     <tbody>
                        //         {
                        //             myCommissionings.map(commissioning => (
                        //                 <tr>
                        //                     <th scope="row">{commissioning.displayName}</th>
                        //                     <td>{commissioning.warmtepompType}</td>
                        //                     <td>{commissioning.ibnDate && moment(commissioning.ibnDate).format("DD-MM-YYYY")}</td>
                        //                     <td>{commissioning.customerName}</td>
                        //                     <td>
                        //                         <Button onClick={() => this.copyForm(commissioning.id)}>
                        //                             <FormattedMessage id="pages.commissionings.rows.copyInbedrijfstelling.title" defaultMessage="Copy" />
                        //                         </Button>
                        //                     </td>
                        //                     <td>
                        //                         {
                        //                             commissioning.documentURL &&
                        //                             <a href={commissioning.documentURL} download={commissioning.documentURL} onClick={this.onDocumentDownload}>
                        //                                 <Button>
                        //                                     <FormattedMessage id="pages.commissionings.rows.document.title" defaultMessage="View" />
                        //                                 </Button>
                        //                             </a>
                        //                         }
                        //                     </td>
                        //                     <td>
                        //                         {
                        //                             commissioning.csvUrl &&
                        //                             <a href={commissioning.csvUrl} download={commissioning.csvUrl} onClick={this.onLogDownload}>
                        //                                 <Button>
                        //                                     <FormattedMessage id="pages.commissionings.rows.csv.title" defaultMessage="Download" />
                        //                                 </Button>
                        //                             </a>
                        //                         }
                        //                     </td>
                        //                 </tr>))
                        //         }
                        //     </tbody>
                        // </Table>
                    }
                    {
                        this.state.isLoading && userRole.showCommissioningOverview &&
                        <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                    }
                    {
                        !this.state.isLoading && this.state.errorMessage && userRole.showCommissioningOverview &&
                        <Alert color="danger">
                            <FormattedMessage id="errors.commissionings.getAll" defaultMessage={this.state.errorMessage} />
                        </Alert>
                    }
                    {
                        !userRole.showCommissioningOverview &&
                        <Alert color="danger">
                            <FormattedMessage id="errors.unauthorizedAccess" defaultMessage="Unauthorized access" />
                        </Alert>
                    }
                </Container>
            </div>
        );
    }
}

export default injectIntl(CommissioningOverviewPage, { forwardRef: true });