import React from "react";

import "./Loading.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";

interface ILoadingProps {
    text?: string;
}

export default class Loading extends React.Component<ILoadingProps> {
    render() {
        return <>
            <Spinner label={this.props.text} size={SpinnerSize.large} />
        </>
    }
}