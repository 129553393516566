import React from 'react';
import { ICustomFieldParams } from './ComponentParams';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import $ from "jquery";

import nl from 'date-fns/locale/nl';

import "react-datepicker/dist/react-datepicker.css";
import "./FormikDatePicker.scss";
import { Util } from '../../../Utils/Util';

registerLocale('nl', nl);

const FormikDatePicker = (params: ICustomFieldParams) => {
    const {
        name,
        label,
        type,
        attributes,
        fieldType,
        defaultValue,
        icon,
        labelClass = '',
        fieldClass = 'form-control',
        formGroupClass = 'form-group'
    } = params.config;

    const { setFieldValue, handleChange, handleBlur, errors } = params.formik;
    let currentValue = Util.ParseDate(params.value);
    
    const errorMessage = errors[name];

    const onFieldChange = (val: Date, ev: any) => {
        const newDate = `${val.getFullYear()}-${val.getMonth()+1}-${val.getDate()}`;
        setFieldValue(name, val ? newDate : null);
        handleChange(ev);
    }
    
    // disable manual input in the text box
    setTimeout(() => {
        $(`.react-datepicker-wrapper #${name}`).attr('readonly', 'readonly');
    }, 200);

    return (
        <React.Fragment>
            <DatePicker
                id={name}
                selected={currentValue}
                locale="nl"
                dateFormat="dd-MM-yyyy"
                onChange={onFieldChange}
                onBlur={ev => handleBlur(ev)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select" />
            {
                errorMessage &&
                <div className="invalid-feedback">{errorMessage}</div>
            }
        </React.Fragment>
    );
}

export default FormikDatePicker;