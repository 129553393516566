import { WrappedComponentProps } from "react-intl";

export default class Locales {

    componentProps: WrappedComponentProps;

    constructor(props: WrappedComponentProps) {
        this.componentProps = props;
    }

    public get = (id: string, defaultMessage?: string) => {
        const result = this.componentProps.intl.formatMessage({ id: id, defaultMessage: defaultMessage });
        return result;
    }

    public getForComponent = (componentId: string, textId: string, defaultMessage?: string) => this.get(`components.${componentId}.${textId}`, defaultMessage);
    public getForFormComponent = (textId: string, defaultMessage?: string) => this.getForComponent("form", textId, defaultMessage);

    public getForForm = (formId: string, textId: string, defaultMessage?: string) => this.get(`forms.${formId}.${textId}`, defaultMessage);
}