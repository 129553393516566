import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";
import { ICommissioningDetailsVM, ICommissioningVM, IGetCommissioningResponseVM, IGetCommissioningsResponseVM, IParseDataFileResponseVM } from "../viewmodels/data";
import { FormService } from "./FormService";
import { NavigateToAuth } from "../NavigateTo";
import { FormType } from "../viewmodels/form";

export class InbedrijfstellingService {

    public static GetMyAll = (): Promise<ICommissioningVM[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/commissionings`, Service.Config())
                .then((response: AxiosResponse<IGetCommissioningsResponseVM>) => resolve(response.data.commissionings))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.commissionings.getMyAll", reason));
        });

    public static Get = (id: string): Promise<ICommissioningDetailsVM> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/commissionings/${id}`, Service.Config())
                .then((response: AxiosResponse<IGetCommissioningResponseVM>) => resolve(response.data.value))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.commissionings.get", reason));
        });

    public static ParseCsv = (csvFile: any, heatPumpType: string): Promise<IParseDataFileResponseVM> =>
        new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("file", csvFile);
            NavigateToAuth.EnsureAuth()
                .then(() => {
                    axios.post(`${Service.ApiUrl}/v1/parse/csvDataFile?heatPumpType=${heatPumpType}`, formData, Service.Config("multipart/form-data"))
                    .then((response: AxiosResponse<IParseDataFileResponseVM>) => resolve(response.data))
                    .catch(reason => reject(reason));
                });
        })

    public static submit = (values: any, csvFileId: string, csvFileHasWarnings: boolean, formName: string) =>
        FormService.submit(values, { csvFileId: csvFileId, csvFileHasWarnings: csvFileHasWarnings }, "commissioning", formName, FormType.Inbedrijfstelling);
}