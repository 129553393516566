import React from "react";
import { FormikProps } from "formik";
import { Form } from "@flipbyte/formik-json";
import { WrappedComponentProps } from "react-intl";
import $ from "jquery";

import FormikUtil from "./FormikUtil";
import FormikWizard, {
  IFormikWizardForm
} from "./FormikWizard";
import FormRepository from "../../../repositories/FormRepository";
import { FormType, IForm } from "../../../viewmodels/form";
import { Util } from "../../../Utils/Util";
import { FormService } from "../../../services/FormService";
import env from "../../../env/env.json";

interface ISchemaElementData {
  id: string;
  name: string;
  label: string;
  renderer?: string | any;
  type: string;
  required?: boolean;
  showWhen?: string[];
  validation?: string[][];
}
interface ISchemaElement extends ISchemaElementData {
  elements: { [key: string]: ISchemaElement };
}

export interface IFileUpload {
  fieldName: string;
  fileId?: string;
  fileName?: string;
  originalFileName: string;
  errorMessage?: string;
}

interface IFormWrapperProps extends WrappedComponentProps {
  formName: string;
  formType: FormType;
  formPrefix: string;
  schemas: any[];

  initialValues?: any;

  recoveryEnabled?: boolean;
  copyFromFormEnabled?: boolean;

  metadata?: any;
  fileUploads?: IFileUpload[];
  uploadFieldNames?: string[];

  isFormValid: boolean;
  
  onSubmit?: (formValues: any) => Promise<any>;
  onSubmitted?: (data: any) => void;
  onIndexChanged?: (index: number) => void;

  onMetadata?: (metadata: any) => void;

  onCurrentValuesChanged?: (values: any) => void;
  onValuesChanged?: (values: any) => void;

  onValidateForm?: (formikForm: FormikProps<any>, values: any) => { [key: string]: string }
}

interface IFormWrapperState {
  forms: IFormikWizardForm[];
  currentValues: { [key: string]: any };
  values: { [key: string]: any };
  isFormValid?: boolean;
  currentIndex: number;

  isLoadingFormToCopy?: boolean;

  errorMessage?: string;

  formikForm?: FormikProps<any>
}

export default class FormWrapper extends React.Component<IFormWrapperProps, IFormWrapperState> {

  private formikForms: FormikProps<any>[] = [];
  private formRepo: FormRepository = new FormRepository();

  constructor(props: IFormWrapperProps) {
    super(props);

    this.state = {
        forms: [],
        currentValues: {},
        values: {},
        currentIndex: 0,
        isFormValid: false
    };
    
    const formName = this.props.formName;
    this.loadInitialValues(formName)
        .then(initialValues => {
            const forms = this.getForms(formName, initialValues)
            this.setState({ forms: forms });
            setTimeout(() => {
              this.indexChanged(0);
            });
        });
  }

  public componentDidMount() {   
    this.radioButtonFix();
  }

  private radioButtonFix = () => { 
    const component = this;
    setTimeout(() => {
      $('input[type=radio]').on('change', (function () {
            const radioEl = this as HTMLInputElement;
            const name = radioEl.name;
            const formValue = component.state.currentValues[name];
            if (component.state.formikForm && radioEl.value !== formValue) {
                component.state.formikForm.setFieldValue(name, radioEl.value);
            }
        }));
    }, 1000);
  }

  public componentDidUpdate(prevProps: IFormWrapperProps, prevState: IFormWrapperState) {
    const prevFileUploadLength = prevProps.fileUploads ? prevProps.fileUploads.length : 0;
    const currentFileUploadLength = this.props.fileUploads ? this.props.fileUploads.length : 0;

    if (this.state.formikForm && prevFileUploadLength !== currentFileUploadLength) {
      this.updateValues(this.state.formikForm);
    }
  }

  private loadInitialValues = (formName: string): Promise<IForm | null> =>
    new Promise((resolve, reject) => {
        const initialValues = this.props.initialValues || {};
        // load from source form
      if (this.props.copyFromFormEnabled) {
            const queryParams = Util.GetQueryParams();
            if (queryParams.copyFromFormId && typeof queryParams.copyFromFormId === "string") {
              this.setState({ isLoadingFormToCopy: true });
              FormService.LoadFormData(this.props.formType, queryParams.copyFromFormId)
                    .then(values => {
                      // merge initial values and copied values
              const mergedValues = { ...initialValues, ...values };
                      resolve(mergedValues);
                      this.triggerMetadata(mergedValues);
                    })
                    .catch(reason => reject(reason))
                    .finally(() => this.setState({ isLoadingFormToCopy: false }));
              return;
            }
        }
        // recover from repo
        if (this.props.recoveryEnabled) {
            const storedFormData = this.formRepo.get(formName);
        const mergedValues = { ...initialValues, ...storedFormData };
            resolve(mergedValues);
            this.triggerMetadata(mergedValues);

            return;
        }

        resolve(initialValues)
    })

  private triggerMetadata = (initialValues: any) => {
    const metadata = initialValues && initialValues._metadata;

    // publish new metadata
    if (metadata && JSON.stringify(metadata) !== JSON.stringify(this.props.metadata || "{}"))
      this.props.onMetadata && this.props.onMetadata(metadata);
  }

  private getForms = (formName: string, initialValues: IForm | null) => {
    const forms: any[] = [];

    this.triggerMetadata(initialValues);

    for (let i = 0; i < this.props.schemas.length; i++) {
      const formId = `${this.props.formPrefix}${i + 1}`;
      forms.push({
        id: formId,
        component: (
          <Form
            schema={this.props.schemas[i]}
            initialValues={initialValues}
            onUpdate={this.updateValues}
            key={formId}
          />
        ),
        schema: this.props.schemas[i]
      });
    }
    return forms;
  };

  private updateValues = (formikForm: FormikProps<any>) => {
    if (this.formikForms.length < this.props.schemas.length) {
        this.formikForms.push(formikForm);
    }

    let areValuesChanged = false;

    let values = this.state.values;
    Object.keys(formikForm.values).forEach(key => {
        values[key] = formikForm.values[key];
    });
    let currentValues = this.state.currentValues;
    Object.keys(formikForm.values).forEach(key => {
        areValuesChanged = areValuesChanged || currentValues[key] !== formikForm.values[key];

        currentValues[key] = formikForm.values[key];
    });

    this.setState({
        currentValues: currentValues,
        values: values
    });
    this.props.onCurrentValuesChanged && this.props.onCurrentValuesChanged(currentValues);
    this.props.onValuesChanged && this.props.onValuesChanged(values);

    const schema = this.props.schemas[this.state.currentIndex];
    const formContainer = FormikUtil.getSchemaFieldNullValues(schema);

    // add formik values
    const allCurrentValues = Object.assign({}, formContainer, formikForm.values);
    // add file uploads
    if (this.props.fileUploads) {
      this.props.fileUploads.forEach(fu => allCurrentValues[fu.fieldName] = `${allCurrentValues[fu.fieldName]}||${fu.originalFileName}`);
    }

    this.validateForm(formikForm, allCurrentValues);

    if (areValuesChanged) {
      // save to form repo
      const formValues = currentValues;
      formValues["_metadata"] = this.props.metadata || {};
      this.props.formName && this.formRepo.save(this.props.formName, formValues);
    }
  }

  private validateForm = (formikForm: FormikProps<any>, values: any): Promise<void> => 
        new Promise((resolve, reject) => {
            this.setState({
              formikForm: formikForm
            });

            // validate form
            formikForm.validateForm(values)
            .then(errors => {
                const customErrors = this.props.onValidateForm ? this.props.onValidateForm(formikForm, values) : {};
                const fileUploadErrors = this.getFileUploadErrors();
                const combinedErrors = { ...errors, ...customErrors, ...fileUploadErrors };
                this.processFormErrors(values, combinedErrors, formikForm.touched);
                resolve();
            })
            .catch(reason => {
                console.log(reason);
                reject(reason);
            });           
        });

  private getCurrentForm = () => this.state.forms[this.state.currentIndex];

  private getCurrentFormElements = (): ISchemaElementData[] => {
    const form = this.getCurrentForm();
    const formSchema: ISchemaElement = form.schema;

    const elements: ISchemaElementData[] = this.getAllElements(formSchema);

    return elements;
  }
  private getAllElements = (parent: ISchemaElement): ISchemaElementData[] => {
    const elements: ISchemaElementData[] = [parent as ISchemaElementData];
    if (parent.elements) {
      const keys = Object.keys(parent.elements);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const element = parent.elements[key];
        const children = this.getAllElements(element);
        elements.push(...children);
      }
    }
    return elements;
  }

  private getFileUploadErrors = (): { [key: string]: string } => {
    let errors: { [key: string]: string } = {};

    const elements = this.getCurrentFormElements();
    const fileUploadFields = elements.filter(el => this.props.uploadFieldNames && this.props.uploadFieldNames.indexOf(el.name) >= 0); // elements.filter(el => el.renderer && el.renderer.type && el.renderer.type.name === "FormikFileUploader");

    for (let i = 0; i < fileUploadFields.length; i++) {
      if (fileUploadFields[i].required) {
        if (this.props.fileUploads) {
          const uploadedFieldFiles = this.props.fileUploads.filter(fu => fu.fieldName === fileUploadFields[i].name);
          if (uploadedFieldFiles.length === 0)
            errors[fileUploadFields[i].name] = "Geen bestand";
        } else {
          errors[fileUploadFields[i].name] = "Geen bestand";
        }
      }
    }

    return errors;
  }

  private processFormErrors = (values: any, errors: any, touched: any) => {
    // only handle errors from visible fields
    const errorsVisible: any = {};
    for (let errorKey of Object.keys(errors)) {
        if ($(`#${errorKey}`).length > 0 || $(`label[for=${errorKey}]`).length > 0) {
            errorsVisible[errorKey] = errors[errorKey];
        }
    }

    const isFormValid =
        Object.keys(errorsVisible).length === 0;

    this.setState({
        isFormValid: isFormValid
    });

    if (Object.keys(errorsVisible).length > 0) {
        console.warn(errorsVisible);
        const form = this.getCurrentForm();
        for (let fieldName in errorsVisible) {
            const fieldValidationElementId = `${fieldName}_validationMessage`;
            const fieldValidationElement = document.getElementById(fieldValidationElementId);
            if (fieldValidationElement) {
                const field = FormikUtil.getSchemaField(form.schema, fieldName);
                fieldValidationElement.innerText = FormikUtil.translateError(errorsVisible[fieldName] as string, field);
            } else {
                console.warn(`validation element not found for '${fieldName}'`);
            }
        }
    } else {
        console.log('No errors');
    }
  }

  private indexChanged = (index: number) => {
    let isFormValid = this.state.currentIndex > index;

    this.setState({
        currentIndex: index,
        isFormValid: isFormValid
    });

    var formikForm = this.formikForms[this.state.currentIndex];

    // update form values
    const fields = FormikUtil.getSchemaFields(this.props.schemas[this.state.currentIndex]);
    const formValues: any = {};
    for (let fieldName in fields) {
        const fieldValue = this.state.values[fieldName];
        if (fieldValue) {
            formValues[fieldName] = fieldValue;
        }
    }

    // update form values
    formikForm.setValues(formValues);

    // update form radio values
    for (let fieldName in fields) {
        const field = fields[fieldName];
        const fieldValue = this.state.values[fieldName];
        if (fieldValue) {
            if (field.renderer === "radio") {
                $(`input[name=${fieldName}][value='${fieldValue}']`).prop('checked', true);
            }
        }
    }

    this.props.onIndexChanged && this.props.onIndexChanged(index);
  }

  private onSubmit = () => new Promise((resolve, reject) => {
    this.props.onSubmit && this.props.onSubmit(this.state.values);
    resolve(this.state.values)
  });

  private onSubmitted = (data: any) => {
    if (this.props.formName) { setTimeout(() => { this.formRepo.remove(this.props.formName); }, 500); }
    if (this.props.onSubmitted) this.props.onSubmitted(data); 
  }

  render() {
    return (
      <FormikWizard
        name={this.props.formName}
        forms={this.state.forms}
        isValid={this.state.isFormValid}
        isLoading={this.state.isLoadingFormToCopy}
        onSubmit={this.onSubmit}
        onSubmitted={this.onSubmitted}
        onIndexChanged={this.indexChanged}
      >
        {this.props.children}
      </FormikWizard>
    );
  }
}
