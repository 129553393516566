import React from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import "./DownloadTab.scss";
import {
    Col, Row, Button, FormGroup, Alert, ButtonToolbar, ButtonGroup
} from 'reactstrap';

import PDF from "react-pdf-js"
import { Util } from "../../Utils/Util";
import { Service } from "../../services/Service";
import { DefaultButton, Spinner } from "@fluentui/react";

interface IDownloadTabProps extends WrappedComponentProps {
    isQuoteLoading?: boolean;
    quoteId?: string;
    isSaved: boolean;
}

interface IDownloadTabState {
    isPDFloaded: boolean;
    quoteId?: string;
    page: number;
    pages: number;
    isSaved: boolean;
}

class DownloadTab extends React.Component<IDownloadTabProps, IDownloadTabState> {

    constructor(props: IDownloadTabProps) {
        super(props);
        this.state = {
            isPDFloaded: false,
            isSaved: props.isSaved,
            quoteId: props.quoteId,
            page: 1,
            pages: 1
        }
        console.log("DownloadTab.constructor", props);
    }

    componentWillReceiveProps(nextProps: IDownloadTabProps) {
        let newState: IDownloadTabState = { ...this.state };
        let needsUpdate = false;
        if (nextProps.isSaved != this.state.isSaved) {
            newState.isSaved = nextProps.isSaved;
            needsUpdate = true;
        }
        if (nextProps.quoteId != this.state.quoteId) {
            newState.quoteId = nextProps.quoteId;
            needsUpdate = true;
        }
        if (needsUpdate) {
            this.setState(newState);
        }
    }


    handlePrevious() {
        this.setState({ page: this.state.page - 1 });
    }

    handleNext() {
        this.setState({ page: this.state.page + 1 });
    }

    render() {
        const { page, pages, quoteId, isPDFloaded } = this.state;

        console.log("DownloadTab.render", this.state);

        return (
            <div>
                {/* {
                    !this.state.isPDFloaded && <Loading text="Please wait, pdf is loading..." />
                } */}

                <Row className="my-2">
                    <Col sm="6">
                        <FormGroup><h4><FormattedMessage id="pages.quoteconfigurator.download.header" defaultMessage="UW OFFERTE" /></h4></FormGroup>
                    </Col>
                    <Col sm="6"  className="text-right">
                        <DefaultButton onClick={() => Util.Navigate(`QuoteConfigurator/QuotationOverview`)}>Naar offerte overzicht</DefaultButton>&nbsp;
                        {isPDFloaded && quoteId && <DefaultButton onClick={() => Util.NavigateAbsolute(`${Service.ApiUrl}/v1/quotes/${quoteId}/Document/content`)}>
                            Download offerte
                        </DefaultButton>}
                    </Col>
                    <Col sm="12" className="text-center" hidden={this.state.isSaved && this.state.isPDFloaded}>
                        <div>
                            <Spinner label={this.props.intl.formatMessage({ id: 'pages.quoteconfigurator.download.loading' })} ariaLive="assertive" labelPosition="right" />
                        </div>
                    </Col>
                    <Col sm="2" hidden={!this.state.isPDFloaded}>&nbsp;</Col>
                    <Col sm="4" className="text-center" hidden={!this.state.isPDFloaded}>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <Button onClick={() => this.handlePrevious()} disabled={!this.state.page || this.state.page <= 1}>&lt;</Button>
                                <Button onClick={() => this.handleNext()} disabled={!this.state.page || this.state.page >= this.state.pages}>&gt;</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    <Col sm="4" hidden={!this.state.isPDFloaded}>{this.state.page} / {this.state.pages}</Col>
                    <Col sm="2" hidden={!this.state.isPDFloaded}>&nbsp;</Col>
                    <Col sm="12" className={this.state.isPDFloaded ? "text-center pdfPreviewContainer" : "text-center"}>
                        {this.state.isSaved && this.state.quoteId &&
                            <PDF file={`${Service.ApiUrl}/v1/quotes/${this.state.quoteId}/Document/content`} page={page}
                                onDocumentComplete={(_pages) => {
                                    console.log("PDF.onDocumentComplete", _pages)
                                    this.setState({
                                        isPDFloaded: !!_pages && (_pages > 0),
                                        pages: _pages
                                    });
                                }}
                                withCredentials={false} />
                        }
                    </Col>
                    <Col sm="2" hidden={!this.state.isPDFloaded}>&nbsp;</Col>
                    <Col sm="4" className="text-center" hidden={!this.state.isPDFloaded}>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <Button onClick={() => this.handlePrevious()} disabled={!this.state.page || this.state.page <= 1}>&lt;</Button>
                                <Button onClick={() => this.handleNext()} disabled={!this.state.page || this.state.page >= this.state.pages}>&gt;</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    <Col sm="4" hidden={!this.state.isPDFloaded}>{this.state.page} / {this.state.pages}</Col>
                    <Col sm="2" hidden={!this.state.isPDFloaded}>&nbsp;</Col>
                    <Col sm="12" className="text-right mt-3">
                        <DefaultButton onClick={() => Util.Navigate(`QuoteConfigurator/QuotationOverview`)}>Naar offerte overzicht</DefaultButton>&nbsp;
                        {isPDFloaded && quoteId && <DefaultButton onClick={() => Util.NavigateAbsolute(`${Service.ApiUrl}/v1/quotes/${quoteId}/Document/content`)}>
                            Download offerte
                        </DefaultButton>}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(DownloadTab, { forwardRef: true });