import React from "react";
import IFormField, { FormFieldType, RadioFormField, FormFieldDependentOfType, FormFields } from "./models/FormField";

import "./Form.scss";
import { Util } from "../Utils/Util";
import { Service } from "../services/Service";
import AppRepository from "../repositories/AppRepository";

import { Form as BSForm, FormText, FormGroup, Button, Label, Input, Col, Container, Row, Spinner, Alert } from "reactstrap";
import { NavigateToPage } from "../NavigateTo";
import Analytics from "./analytics/Analytics";
import { PrimaryButton } from "@fluentui/react";

export interface IFormTextProps {
    textIsSubmitting: string;
    textFormSentTitle: string;
    textFormSentMessage: string;
    textFormSendingErrorTitle: string;
    textFormSendingErrorMessage: string;
    textBackToMain: string;
    textBackToForm: string;
    textToOverview: string;
    textFormLegendIsRequired: string;
}

export interface IFormProps<M extends IFormValues> extends IFormTextProps {
    name: string;
    fields: IFormField[];
    submitText?: string;

    defaultValues?: M;

    showErrorInAlert?: boolean;

    onSubmit?: (values: M) => Promise<void>;
    onValidate?: (fields: IFormField[], values: M) => string[];

    getCodeMessage?: (code: string, fieldName: string) => string;
    getErrorMessage?: (errorCode: string) => string | null | undefined;
}

export interface IFormValues {
    [key: string]: any;
}

export interface IFormState<M> {
    values: M;
    fieldsVisibility: { [key: string]: boolean };

    isFormValid: boolean;
    formValidationCodes: string[];

    isSubmitting?: boolean;
    formSubmitted?: boolean;
    error?: boolean;
    errorMessage?: string;

    isDebug?: boolean;
}

export default class Form<M extends IFormValues> extends React.Component<IFormProps<M>, IFormState<M>> {

    private fieldsVisibility: { [key: string]: boolean } = {}
    private appRepo = new AppRepository();

    constructor(props: IFormProps<M>) {
        super(props);

        const values = this.props.defaultValues || {} as M;
        this.updateDependentFields(this.props.fields, values);

        const formValidationCodes = this.formValidation(this.props.fields, values);

        this.state = {
            values: values,
            fieldsVisibility: this.fieldsVisibility,
            isFormValid: formValidationCodes.length === 0,
            formValidationCodes: formValidationCodes,
            formSubmitted: false,
            error: false
        };
    }

    componentDidMount() {
        this.appRepo.getSettings()
            .then(settings => this.setState({ isDebug: settings.isDebug }));
    }

    public setValues = (values: M) => {
        const newValues: IFormValues = Object.assign({}, this.state.values, values);

        // fix enum 'none' values
        const allFields = this.getAllFields(this.props.fields);
        for (let field of allFields) {
            if (field.name) {
                const fieldName = field.name;
                if (field.type === FormFieldType.Radio && newValues[fieldName] === 'none') {
                    newValues[fieldName] = '';
                }
            }
        }

        this.updateDependentFields(this.props.fields, newValues);
        this.validateForm(this.props.fields, newValues);

        this.setState({
            values: newValues as M,
            fieldsVisibility: this.fieldsVisibility
        });
    }

    private onFormSubmit = (event: React.MouseEvent<HTMLElement>) => {
        const { onSubmit } = this.props;

        event.preventDefault();

        Analytics.registerFormAction(this.props.name, 'Formulier verzonden');

        this.setState({
            isSubmitting: true,
            formSubmitted: false,
            error: false
        });

        console.log("Form.onSubmit onSubmit", onSubmit);

        if (onSubmit) {
            const values: IFormValues = Object.assign({}, this.state.values);

            console.log("Form.onSubmit values", values);

            // fix invalid values
            for (let fieldName of Object.keys(this.state.values)) {
                const field = FormFields.getFormFieldByName(this.props.fields, fieldName);
                if (field) {
                    if (field.type === FormFieldType.PositiveInteger || field.type === FormFieldType.PositiveNumber) {
                        values[fieldName] = Math.max(+values[fieldName], 0);
                    }
                }
            }



            onSubmit(values as M)
                .then(() => {
                    this.appRepo.clearState();
                })
                .catch(reason => {
                    this.setState({
                        error: true
                    })

                    // log details
                    Service.GetErrorResponse(reason)
                        .then(error => {
                            if (error) {
                                const codeMessage = this.props.getErrorMessage && error.errorMessage ? this.props.getErrorMessage(error.errorMessage) : error.errorMessage;
                                const errorMessage = codeMessage ? codeMessage : `status=${error.status}, statusText=${error.statusText}, message=${error.errorMessage}`;
                                this.setState({
                                    errorMessage: errorMessage
                                });
                            }
                        })
                })
                .finally(() => {
                    this.setState({
                        isSubmitting: false,
                        formSubmitted: true
                    });
                });
        }
    }

    private onFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = ev.target.name;
        const field = FormFields.getFormFieldByName(this.props.fields, fieldName);

        const values: IFormValues = this.state.values;

        let fieldValue = field && field.getValue ? field.getValue(ev.target) : undefined;
        if (field && fieldValue) {
            if (field.type === FormFieldType.PostalCode) {
                let postalCode: string = fieldValue;
                postalCode = postalCode.trimStart();
                // upper case
                postalCode = (postalCode as string).toUpperCase();
                // remove other characters
                postalCode = postalCode.replace(/[^A-Z0-9]/g, '');
                // limit length
                postalCode = postalCode.substr(0, 6);
                // limit decimals
                postalCode = postalCode.length > 4 ?
                    postalCode.substr(0, 4) + postalCode.substr(4).replace(/[0-9]/g, '') :
                    postalCode;
                // limit alpha
                postalCode = postalCode.substr(0, 4).replace(/[A-Z]/g, '') + postalCode.substr(4);

                fieldValue = postalCode;
            }
        }
        values[fieldName] = fieldValue;

        this.updateDependentFields(this.props.fields, this.state.values);
        this.validateForm(this.props.fields, values);

        this.setState({
            values: (values as M),
            fieldsVisibility: this.fieldsVisibility
        });
    }

    private updateDependentFields = (fields: IFormField[], values: IFormValues) => {
        const defaultDependentOfFn = (fieldName: string, value: any) => !Util.isNothing(value);

        for (let field of fields) {
            if (field.name) {
                // check visibility for field by checking dependent fields
                let isVisible = true;
                if (field.dependentOf) {
                    // set dependentOfFn
                    const dependentOfFn = field.dependentOfFn ? field.dependentOfFn : defaultDependentOfFn;
                    if (Array.isArray(field.dependentOf)) {
                        // initialize visibility depending on OR or AND
                        isVisible = field.dependentOfType === FormFieldDependentOfType.Or ? false : true;
                        for (let item of field.dependentOf) {
                            const fieldVisibleByDependency = dependentOfFn(item, values[item]);

                            // are fields OR or AND?
                            if (field.dependentOfType === FormFieldDependentOfType.Or) {
                                isVisible = isVisible || fieldVisibleByDependency;
                            } else {
                                isVisible = isVisible && fieldVisibleByDependency;
                            }
                        }
                    } else {
                        const fieldVisibleByDependency = dependentOfFn(field.dependentOf, values[field.dependentOf]);
                        isVisible = fieldVisibleByDependency;
                    }
                }
                this.fieldsVisibility[field.name] = isVisible;
            }

            // update child fields
            if (field.fields) {
                this.updateDependentFields(field.fields, values);
            }
        }
    }

    private validateForm = (fields: IFormField[], values: IFormValues) => {
        const formValidationCodes = this.formValidation(fields, values);
        const isFormValid = formValidationCodes.length === 0;
        this.setState({
            isFormValid: isFormValid,
            formValidationCodes: formValidationCodes
        })
    }

    private formValidation = (fields: IFormField[], values: IFormValues) => {
        const { onValidate } = this.props;

        console.log("Form.formValidation fields, values, onValidate", fields, values, onValidate);

        const defaultFormValidation = this.defaultFormValidation(fields, values);
        const customValidation = onValidate ? onValidate(fields, values as M) : [];

        return [...defaultFormValidation, ...customValidation];
    }

    private defaultFormValidation = (fields: IFormField[], values: IFormValues): string[] => {
        const validationCodes: string[] = [];
        const allFields = this.getAllFields(fields);
        for (let field of allFields) {
            const fieldValidationCodes = this.validateField(field, values);
            validationCodes.push(...fieldValidationCodes);
        }
        return validationCodes;
    }

    private defaultFieldValidation = (field: IFormField, fieldValue: any): string[] => {
        const validationCodes: string[] = [];
        if (field.type === FormFieldType.Radio) {
            if (fieldValue === undefined)
                validationCodes.push(`VALIDATION_RADIO_ISNOTHING|${field.name}`);
            if (fieldValue === 0)
                validationCodes.push(`VALIDATION_RADIO_ISZERO|${field.name}`)
        }
        else if (Util.isNothing(fieldValue))
            validationCodes.push(`VALIDATION_ISNOTHING|${field.name}`);
        return validationCodes;
    }

    private validateField = (field: IFormField, values: IFormValues): string[] => {
        if (field.name) {
            const isFieldVisible = this.fieldsVisibility[field.name];
            if (isFieldVisible) {
                const fieldValue = values[field.name];
                if (field.required) {
                    const fieldValueValidationCodes =
                        field.validationFn ? field.validationFn(field, fieldValue) : this.defaultFieldValidation(field, fieldValue);
                    return fieldValueValidationCodes;
                }
            }
        }
        return [];
    }

    private getAllFields = (fields: IFormField[]): IFormField[] => {
        const result: IFormField[] = [];
        for (let field of fields) {
            result.push(field);
            if (field.fields) {
                result.push(...this.getAllFields(field.fields));
            }
        }
        return result;
    }

    private getFormFieldType = (fieldName: string): FormFieldType | undefined => {
        const formField = FormFields.getFormFieldByName(this.props.fields, fieldName);
        return formField ? formField.type : undefined;
    }

    private navigateBackToForm = () => {
        // reset error and submission state
        this.setState({
            error: false,
            errorMessage: undefined,
            isSubmitting: false,
            formSubmitted: false
        });
    }

    private navigateBackToMain = () => {
        // clear app state
        this.appRepo.clearState();

        Analytics.registerFormAction(this.props.name, 'Terug naar dashboard');

        // navigate
        NavigateToPage.Home();
    }

    private navigateToOverview = () => {
        // clear app state
        this.appRepo.clearState();

        Analytics.registerFormAction(this.props.name, 'Terug naar overzicht');

        // navigate
        Util.Navigate("/PriceIndications");
    }



    render = () => {
        const { getCodeMessage, showErrorInAlert, fields, textFormLegendIsRequired, textIsSubmitting, submitText, 
            textFormSentTitle, textFormSentMessage, textFormSendingErrorTitle, textFormSendingErrorMessage, 
            textBackToForm, textToOverview, textBackToMain } = this.props;
        const { isSubmitting, formSubmitted, errorMessage, isDebug, formValidationCodes, isFormValid, error } = this.state;


        const formHiddenClassName = this.state.formSubmitted ? "hidden" : "";
        const validationMessages: string[] = [];

        for (let validationCode of this.state.formValidationCodes) {
            const code = validationCode.split('|')[0];
            const fieldName = validationCode.split('|')[1];
            const message = getCodeMessage ? getCodeMessage(code, fieldName) : code;
            validationMessages.push(message);
        }

        return (
            <div>
                {
                    ((!isSubmitting && !formSubmitted) || (formSubmitted && errorMessage && showErrorInAlert)) &&
                    <>
                        {
                            errorMessage && showErrorInAlert &&
                            <Alert color="danger">{errorMessage}</Alert>
                        }
                        <BSForm className={formHiddenClassName}>
                            <FormGroup>
                                {
                                    fields.map(field => this.renderField(field))
                                }
                            </FormGroup>

                            <legend><span className="form-required-asterisk">*</span> {textFormLegendIsRequired}</legend>

                            {
                                isDebug &&
                                <FormText>
                                    {formValidationCodes.map((validationCode: string, index) => <span key={`formValidationCodes_${index}`}>{validationCode}, </span>)}
                                </FormText>
                            }

                            <div>
                                {
                                    validationMessages.map((validationMessage, index) =>
                                        // <div key={`validationMessage_${index}`} className="form-validation-message">{validationMessage}</div>
                                        <Alert color="warning" key={index}>{validationMessage}</Alert>
                                    )
                                }
                            </div>

                            <FormGroup>
                                <PrimaryButton disabled={!isFormValid} onClick={this.onFormSubmit}>
                                    {submitText || "Verzenden"}
                                </PrimaryButton>
                            </FormGroup>
                        </BSForm>
                    </>
                }
                {
                    isSubmitting &&
                    <div><Spinner color="secondary" /> {textIsSubmitting}...</div>
                }
                {
                    !isSubmitting && formSubmitted &&
                    <div>
                        {
                            !error &&
                            <div>
                                <h3>{textFormSentTitle}</h3>
                                <p>{textFormSentMessage}</p>
                            </div>
                        }
                        {
                            error && !showErrorInAlert &&
                            <div>
                                <h3 className="error">{textFormSendingErrorTitle}</h3>
                                <p className="error">{textFormSendingErrorMessage}</p>
                                {
                                    errorMessage &&
                                    <p className="error error-details">{errorMessage}</p>
                                }
                            </div>
                        }
                        <Container>
                            <Row>
                                {
                                    error &&
                                    <Col><PrimaryButton onClick={this.navigateBackToForm}>{textBackToForm}</PrimaryButton></Col>
                                }
                                {
                                    !error &&
                                    <Col><PrimaryButton onClick={this.navigateToOverview}>{textToOverview}</PrimaryButton></Col>
                                }
                                <Col><PrimaryButton onClick={this.navigateBackToMain}>{textBackToMain}</PrimaryButton></Col>
                            </Row>
                        </Container>
                    </div>
                }
            </div>
        );
    }

    renderField = (field: IFormField) => {
        const isHeader = field.type === FormFieldType.Header;
        let isVisible = true;
        if (field.name) {
            isVisible = this.state.fieldsVisibility[field.name];
        }
        const labelClassName = isHeader ? "form-field-header" : "";
        const hasFieldsClassName = field.fields && field.fields.length > 0 ? "form-field-hasFields" : "";
        const isVisibleClassName = !isVisible ? "hidden" : "";
        const value = !isHeader && field.name ? (this.state.values as IFormValues)[field.name] : null;
        const fieldLabelRequired = field.required ? " *" : "";

        const fieldKey = field.name || field.label;

        switch (field.type) {
            case FormFieldType.Header: {
                return (
                    <div key={fieldKey} className={`form-field ${hasFieldsClassName} ${isVisibleClassName}`}>
                        <h2>{field.label}</h2>
                        {
                            field.fields &&
                            field.fields.map(f => this.renderField(f))
                        }
                    </div>
                );
            } break;
            case FormFieldType.Radio: {
                return (
                    <div key={fieldKey} className={`form-field ${hasFieldsClassName} ${isVisibleClassName}`}>
                        <FormGroup tag="fieldset">
                            <Label>{field.label} <span className="form-required-asterisk">{fieldLabelRequired}</span><span className="form-hint">{field.hint}</span></Label>
                            {
                                this.renderFieldValue(field, value, isVisible)
                            }
                        </FormGroup>
                    </div>
                );
            } break;
            default: {
                return (
                    <div key={fieldKey} className={`form-field ${hasFieldsClassName} ${isVisibleClassName}`}>
                        <Label className={labelClassName}>{field.label} <span className="form-required-asterisk">{fieldLabelRequired}</span><span className="form-hint">{field.hint}</span></Label>
                        {
                            this.renderFieldValue(field, value, isVisible)
                        }
                        {
                            field.fields &&
                            field.fields.map(f => this.renderField(f))
                        }
                    </div>
                );
            }
        }
    }

    renderFieldValue = (field: IFormField, value: any, isVisible: boolean) => {
        const isRequired = isVisible && field.required;
        switch (field.type) {
            case FormFieldType.Header:
                // return <span></span>
                return <h2>{value}</h2>
            case FormFieldType.Text:
                return <Input type="text" name={field.name} value={value} onChange={this.onFieldChange} required={isRequired} />
            case FormFieldType.PostalCode:
                return <Input type="text" name={field.name} value={value} onChange={this.onFieldChange} required={isRequired} />
            case FormFieldType.Email:
                return <Input type="email" name={field.name} value={value} onChange={this.onFieldChange} required={isRequired} />
            case FormFieldType.PositiveInteger:
                return <Input type="number" min={0} step={1} name={field.name} value={Util.isNumber(value) && value >= 0 ? value : ''} onChange={this.onFieldChange} required={isRequired} />
            case FormFieldType.PositiveNumber:
                return <Input type="number" min={0} step={0.1} name={field.name} value={Util.isNumber(value) && value >= 0 ? value : ''} onChange={this.onFieldChange} required={isRequired} />
            case FormFieldType.Checkbox:
                return <Input type="checkbox" name={field.name} value={value} onChange={this.onFieldChange} />
            case FormFieldType.Radio: {
                const radioField = field as RadioFormField;
                return (
                    <>
                        {
                            radioField.options.map(option => (
                                <FormGroup key={`${radioField.name}_${option.label}`} check>
                                    <Label check>
                                        <Input type="radio" name={radioField.name} value={option.value} id={`${radioField.name}_${option.value.replace(' ', '')}`} onChange={this.onFieldChange} required={isRequired} checked={option.value == value} />{' '}
                                        {option.label}
                                    </Label>
                                </FormGroup>
                            ))
                        }
                    </>
                );

            }
            default:
                return <div>Unsupported field type: {field.type}</div>
        }
    }
}