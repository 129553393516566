export default class FormikUtil {
    private static filesSubscribers: { [key: string]: ((formik: any, fieldName: string, files: any[]) => void)} = {};

    public static subscribeToFiles = (id: string, onNewFiles: (formik: any, fieldName: string, files: any[]) => void) => FormikUtil.filesSubscribers[id] = onNewFiles;
    public static addFiles = (formik: any, fieldName: string, files: any[]) => 
        Object.keys(FormikUtil.filesSubscribers).forEach(key => FormikUtil.filesSubscribers[key](formik, fieldName, files));

    public static getSchemaFields = (schema: any) => {
        let result: any = {};

        if (schema["name"]) {
            const name = schema["name"];
            result[name] = schema;
        }

        if (schema["elements"]) {
            const elements = schema["elements"];
            const keys = Object.keys(elements);
            keys.forEach(key => {
                const childResult = FormikUtil.getSchemaFields(elements[key]);
                result = Object.assign({}, result, childResult);
            })
        }

        return result;
    }

    public static getSchemaField = (schema: any, fieldName: string) => {
        const allFields = FormikUtil.getSchemaFields(schema);
        return allFields[fieldName];
    }

    public static getSchemaFieldNullValues = (schema: any) => {
        const result = FormikUtil.getSchemaFields(schema);
        const keys = Object.keys(result);
        keys.forEach(key => result[key] = '');

        return result;
    }

    public static translateError = (error: string, field: any) => {
        // required
        if (error.endsWith("is a required field")) return `${field.label} is een verplicht veld`;

        // minimum
        var minMatches = new RegExp("must be at least ([\\d]*) characters$", "gi").exec(error);
        if (minMatches && minMatches.length > 0) return `${field.label} moet minimaal ${minMatches[1]} lang zijn`;

        return field.validationError || error;
    }

}