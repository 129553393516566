import { ICrudCreateResponseVM, IBlobVM, ICrudGetOneResponseVM, ICrudUpdateResponseVM, IGetPrincipeDiagramResponse } from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export class SolutionPrincipleDiagramService {

    // public static Create = (file: File, solutionId: string): Promise<ICrudCreateResponseVM<IBlobVM>> =>
    //     new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append("file", file);
    //         axios.post(`${Service.ApiUrl}/v1/solutions/${solutionId}/principleDiagram`, formData, Service.Config("multipart/form-data"))
    //             .then((response: AxiosResponse<ICrudCreateResponseVM<IBlobVM>>) => resolve(response.data))
    //             .catch(reason => MijnNathanService.CatchId(reject, "errors.principlediagram.create", reason));
    //     });

    // public static UpdateContent = (file: File, solutionId: string): Promise<ICrudUpdateResponseVM<IBlobVM>> =>
    //     new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append("file", file);
    //         axios.put(`${Service.ApiUrl}/v1/solutions/${solutionId}/principleDiagram/content`, formData, Service.Config("multipart/form-data"))
    //             .then((response: AxiosResponse<ICrudUpdateResponseVM<IBlobVM>>) => resolve(response.data))
    //             .catch(reason => MijnNathanService.CatchId(reject, "errors.principlediagram.update", reason));
    //     });

    // public static GetOne = (solutionId: string): Promise<ICrudGetOneResponseVM<IBlobVM>> =>
    //     new Promise((resolve, reject) => {
    //         axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/principleDiagram`, Service.Config())
    //             .then((response: AxiosResponse<ICrudGetOneResponseVM<IBlobVM>>) => resolve(response.data))
    //             .catch(reason => MijnNathanService.CatchId(reject, "errors.principlediagram.getOne", reason));
    //     });

    // public static GetOneThumbnail = (solutionId: string): Promise<ICrudGetOneResponseVM<string>> =>
    //     new Promise((resolve, reject) => {

    //         axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/principleDiagram/thumbnail`, Service.Config())
    //             .then((response: AxiosResponse<ICrudGetOneResponseVM<string>>) => resolve(response.data))
    //             .catch(reason => MijnNathanService.CatchId(reject, "errors.principlediagram.getOneThumbnail", reason));
    //     });

    public static GetDiagram = async (code: string) => {
        const response = await axios.get<IGetPrincipeDiagramResponse>(`${Service.ApiUrl}/v1/solutions/principleDiagram/` + code, Service.Config());
        var responseData = response.data;
        if (responseData) {
            responseData.content.content = responseData.content["$content"];
            responseData.content.contentType = responseData.content["$content-type"];
            responseData.metadata.filenameWithExtension = responseData.metadata["{FilenameWithExtension}"];
            responseData.metadata.link = responseData.metadata["{Link}"];
        }

        return responseData;
    }
}