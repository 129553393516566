

export default class Debounce {


    static timeouts: { [key: string]: NodeJS.Timeout } = {};

    static do = (key: string, action: () => void, ms?: number) => {

        if (Debounce.timeouts[key]) {
            clearTimeout(Debounce.timeouts[key]);
            delete Debounce.timeouts[key];
        }

        Debounce.timeouts[key] = setTimeout(action, ms || 200);
    }
}