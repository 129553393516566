import { ServiceError, Service } from "./Service";
import { NavigateToAuth } from "../NavigateTo";

export default class MijnNathanService {

    // token validation
    private static readonly TokenExpiredErrorCode = "IDX10223";

    private static CatchTokenValidation = (reason?: ServiceError) => {
        Service.GetErrorResponse(reason, [ MijnNathanService.TokenExpiredErrorCode ])
            .then(errorMessage => {
                if (errorMessage && errorMessage.errorMessage) {
                    if (errorMessage.errorMessage.startsWith(`${MijnNathanService.TokenExpiredErrorCode}:`)) {                        
                        // Try login to refresh token
                        NavigateToAuth.Login();
                    }
                }
            })
    }

    // generic error handling

    public static Catch = (reject: (reason?: any) => void, reason?: ServiceError) => {
        MijnNathanService.CatchTokenValidation(reason);
        Service.Catch(reject, reason);
    }

    public static CatchId = (reject: (reason?: any) => void, textIntlId: string, reason?: ServiceError) => {
        MijnNathanService.CatchTokenValidation(reason);
        Service.CatchId(reject, textIntlId, reason);
    }

    public static CatchText = (reject: (reason?: any) => void, text: string, reason?: ServiceError) => {
        MijnNathanService.CatchTokenValidation(reason);
        Service.CatchId(reject, text, reason);
    }
}