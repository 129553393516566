import React from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import "./EditQuote.scss";

import {
    Col, Row, Card, Collapse, CardBody, CardHeader, Table, Button,
    FormGroup, Input, CustomInput, Label, Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';

import { FaTrash, FaCaretDown, FaPen, FaPlusCircle } from "react-icons/fa";

import {
    SolutionTypeEnum, ISolutionVM, ISolutionPositionVM, ISolutionPositionItemVM,
    IItemVM, IQuoteVM, AllowedActionEnum, ErrorTypeEnum, ErrorLevelEnum
} from "../../viewmodels/data";

import { Service } from "../../services/Service";

import Money from "../../components/Money";
import { ItemService } from "../../services/ItemService";
import Loading from "../../components/Loading";
import { IFilterData } from "../../viewmodels/quoteConfigurator";
import ConfirmDialog from "../../components/ConfirmDialog";
import { ISolutionPositionItemRow } from "../../solution/SolutionEdit";
import { QuoteConfiguratorWizardViews } from "../QuoteConfiguratorWizardViews";
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import ArrayUtils from "../../Utils/ArrayUtils";
import { StringUtils } from "../../Utils/StringUtils";


interface IEditQuoteProps extends WrappedComponentProps {
    filterData?: IFilterData;
    isQuoteLoading?: boolean;
    onQuoteUpdated: (event: IFilterData) => void
    saveOrRemove: (isSave: boolean) => void
}

interface IEditedItem {
    item: ISolutionPositionItemVM;
    itemIndex: number;
    posIndex: number;
    solIndex: number;
    newQuantity?: number;
    newPsNumber?: string;
    isChanged: boolean;
}

interface IItemList {
    item: IItemVM;
    isChecked: boolean;
}

interface IEditQuoteState {
    isOpen?: string;

    editedSolution: ISolutionVM;
    editedSolutionIndex: number;

    editedItem: IEditedItem;

    searchText?: string;
    items?: IItemList[];
    isLoadingModal?: boolean;
    activePositionIndex: number;
    activeSolutionIndex: number;

    isShowSaveAndDownloadModal: boolean;
    saveAndDownloadQuote?: IQuoteVM;
}

class EditQuote extends React.Component<IEditQuoteProps, IEditQuoteState> {


    SetState2 = <K extends keyof IEditQuoteState>(ob: Pick<IEditQuoteState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: IEditQuoteProps) {
        super(props);

        this.state = {
            isOpen: undefined,
            editedSolutionIndex: -1,
            editedSolution: {} as ISolutionVM,

            editedItem: {
                itemIndex: -1,
                posIndex: -1,
                solIndex: -1,
                isChanged: false,
                item: {} as ISolutionPositionItemVM
            },

            searchText: "Uponor",
            activePositionIndex: -1,
            activeSolutionIndex: -1,

            isShowSaveAndDownloadModal: false
        }
    }

    componentDidMount() {
    }

    open = (solution: ISolutionVM) => {
        let newTab : string | undefined = solution.id + solution.floorNumber ;
        if (newTab == this.state.isOpen) {
            newTab = undefined;
        }
        this.setState({ isOpen: newTab })
    };

    removeSolutionFromQuote = (solution: ISolutionVM) => {
        this.confirmDialog!.showDialog(ok => {
            let _filterData = this.props.filterData;
            if (ok && _filterData) {
                const remainingSolutions = _filterData.selectSolutions.filter((sol) => sol.id !== solution.id);
                _filterData.selectSolutions = remainingSolutions;
                this.props.onQuoteUpdated(_filterData);
            }
        })
    }

    openEditPositionItem = (solutionPositionItem: ISolutionPositionItemVM, itemIndex: number, posHeaderIndex, solutionIndex: number) => {
        this.setState({
            editedItem: {
                newPsNumber: solutionPositionItem.psNumber,
                newQuantity: solutionPositionItem.quantity,
                itemIndex: itemIndex,
                posIndex: posHeaderIndex,
                solIndex: solutionIndex,
                item: solutionPositionItem,
                isChanged: false
            }
        });
    }

    closeEditPositionItem = (isSave: boolean) => {

        if (isSave) {

            let _filterData = this.props.filterData;

            let _selectSolutions: ISolutionVM[] = this.props.filterData && this.props.filterData.selectSolutions || [];

            let item = _selectSolutions[this.state.editedItem.solIndex].solutionPositions[this.state.editedItem.posIndex].solutionPositionItems[this.state.editedItem.itemIndex];

            let isChanged = false;
            if (this.state.editedItem.newQuantity) {
                item.quantity = this.state.editedItem.newQuantity
                isChanged = true
            }
            if (this.state.editedItem.newPsNumber) {
                item.psNumber = this.state.editedItem.newPsNumber
                isChanged = true
            }

            if (isChanged && _filterData) {
                _filterData.selectSolutions = _selectSolutions;
                this.props.onQuoteUpdated(_filterData);
            }

            this.closeEditPositionItem(false)

        } else {
            this.setState({
                editedItem: {
                    itemIndex: -1,
                    posIndex: -1,
                    solIndex: -1,
                    isChanged: false,
                    newQuantity: undefined,
                    newPsNumber: undefined,
                    item: {} as ISolutionPositionItemVM
                }
            });
        }

    }

    editPositionItemChange = (event, itemIndex: number, posIndex: number, solIndex: number) => {


        var _key: string = event.target.name;
        var _value: string = event.target.type == "checkbox" ? event.target.checked : event.target.value;

        var editItem = this.state.editedItem;
        editItem.itemIndex = itemIndex;
        editItem.solIndex = solIndex;



        // let _selectSolutions: ISolutionVM[] = this.props.filterData && this.props.filterData.selectSolutions || [];

        if (_key == "psNumber") {
            editItem.newPsNumber = _value;
            editItem.isChanged = true;
            //_selectSolutions[solIndex].solutionPositions[posIndex].solutionPositionItems[itemIndex].psNumber = _value
        }
        else if (_key == "quantity") {
            editItem.newQuantity = Math.max(1, +_value);
            editItem.isChanged = true;
            // _selectSolutions[solIndex].solutionPositions[posIndex].solutionPositionItems[itemIndex].quantity = Number(_value)
        }

        this.setState({ editedItem: editItem });

    }

    editSaveAndDownloadModalChange = (event) => {
        var _key: string = event.target.name;
        var _value: string = event.target.type == "checkbox" ? event.target.checked : event.target.value;

        let _quote = this.state.saveAndDownloadQuote;

        if (!_quote) {
            _quote = {} as IQuoteVM;
        }

        if (_key == "subjectQuotation") {
            _quote.quoteSubject = _value;
        }
        if (_key == "remarks") {
            _quote.quoteRemarks = _value;
        }
        this.setState({ saveAndDownloadQuote: _quote }, () => { });
    }

    removeEditPositionItem = (solutionPositionItem: ISolutionPositionItemVM, itemIndex: number, posIndex: number, solIndex: number) => {
        let _selectSolutions: ISolutionVM[] = this.props.filterData && this.props.filterData.selectSolutions || [];
        let _items: ISolutionPositionItemVM[] = _selectSolutions[solIndex].solutionPositions[posIndex].solutionPositionItems.filter(function (itemRow) { return itemRow != solutionPositionItem });
        _selectSolutions[solIndex].solutionPositions[posIndex].solutionPositionItems = _items;

        let _filterData = this.props.filterData;

        if (_filterData) {
            _filterData.selectSolutions = _selectSolutions;
            this.props.onQuoteUpdated(_filterData);
        }
    }

    openEditSolution = (solutionVM: ISolutionVM, solutionIndex: number) => {
        this.setState({
            editedSolution: solutionVM,
            editedSolutionIndex: solutionIndex
        });
    }

    closeEditSolution = () => {
        this.setState({
            editedSolution: {} as ISolutionVM,
            editedSolutionIndex: -1
        });
    }

    editSolutionChange = (event, index) => {
        var _key: string = event.target.name;
        var _value: string = event.target.type == "checkbox" ? event.target.checked : event.target.value;

        let _selectSolutions: ISolutionVM[] = this.props.filterData && this.props.filterData.selectSolutions || [];

        if (_key == "title") {
            _selectSolutions[index].title = _value;
        }
        if (_key == "subsitioncodeIsdeSubsidy") {
            _selectSolutions[index].subsitioncodeIsdeSubsidy = _value;
        }

        let _filterData = this.props.filterData;

        if (_filterData) {
            _filterData.selectSolutions = _selectSolutions;
            this.props.onQuoteUpdated(_filterData);
        }
    }

    private searchItems = () => {
        this.setState({ isLoadingModal: true });
        ItemService.Search(this.state.searchText || "")
            .then((items: IItemVM[]) => {

                let _itemList: IItemList[] = [];
                items.map((item) => {
                    _itemList.push({ item: item, isChecked: false });
                });
                this.setState({ items: _itemList });
            })
            .catch(() => {
                this.setState({
                });
            })
            .finally(() => {
                this.setState({
                    isLoadingModal: false
                });
            });
    }

    private cancelPositionModal = () => {

        this.setState({ items: [], activePositionIndex: -1, activeSolutionIndex: -1 });
    };

    private submitPositionModal = (activePositionIndex: number, activeSolutionIndex: number) => {

        if (this.state.items && this.state.items.length > 0) {

            let _selectSolutions: ISolutionVM[] = this.props.filterData && this.props.filterData.selectSolutions || [];

            let itemList: IItemList[] = this.state.items && this.state.items.filter(item => item.isChecked == true);
            let _solutionPositions = _selectSolutions[activeSolutionIndex].solutionPositions[activePositionIndex];


            itemList.map(_item => {
                let newSolutionPositionItem: ISolutionPositionItemVM = {
                    id: "",
                    psNumber: '001',
                    quantity: 1,
                    navisionKey: _item.item.itemNumber || "",
                    navisionItem: _item.item,
                    allowedAction: AllowedActionEnum.CanDeleteAndModify
                };

                if (_solutionPositions.solutionPositionItems.length == 0) {
                    _solutionPositions.solutionPositionItems = [];
                }
                _solutionPositions.solutionPositionItems.push(newSolutionPositionItem);
            })

            let _filterData = this.props.filterData;

            if (_filterData) {
                _filterData.selectSolutions = _selectSolutions;
                this.props.onQuoteUpdated(_filterData);
            }

            this.setState({ items: [], activePositionIndex: -1, activeSolutionIndex: -1 });
        }

    };

    solutionPositionSorter = (a: ISolutionPositionItemVM, b: ISolutionPositionItemVM) => {

        if (a && !b) return 1;
        if (!a && b) return -1;
        if (!a && !b) return 0;
        if (a.psNumber > b.psNumber) return 1;
        if (a.psNumber < b.psNumber) return -1;

        const ad = a.navisionItem?.description ? a.navisionItem.description : "";
        const bd = b.navisionItem?.description ? b.navisionItem.description : "";

        if (ad == bd) return 0;
        return ad > bd ? 1 : -1;
    }

    onSaveOrRemove = (isSave) => {
        if (isSave) {
            let _filterData = this.props.filterData;
            if (_filterData) {
                let _quote = this.state.saveAndDownloadQuote;
                if (!_quote) {
                    _quote = {} as IQuoteVM;
                }
                _filterData.quote = _quote;
                this.props.onQuoteUpdated(_filterData);
                this.hideSaveAndDownloadModal();
            }
        }
        this.props.saveOrRemove(isSave);
    }
    hideSaveAndDownloadModal = () => {
        this.setState({ isShowSaveAndDownloadModal: false, saveAndDownloadQuote: {} as IQuoteVM })
    }

    confirmDialog: ConfirmDialog | undefined | null;

    render() {
        const { isOpen,
            editedSolution, editedSolutionIndex,
            editedItem,
            searchText, items, activePositionIndex, activeSolutionIndex,
            isShowSaveAndDownloadModal, saveAndDownloadQuote
        } = this.state;

        let selectedSolutions = this.props.filterData && this.props.filterData.selectSolutions || [];
        let errors = this.props.filterData && this.props.filterData.errors || [];
        const isQuoteLoading = this.props.isQuoteLoading;
        const intl = this.props.intl;


        selectedSolutions.forEach(s => { if (s.floorNumber === null) s.floorNumber = undefined })
        selectedSolutions.sort((s1, s2) => {
            if (s1.floorNumber === s2.floorNumber) return s1.solutionType > s2.solutionType ? 1 : -1;
            return (s1.floorNumber || -100) > (s2.floorNumber || -100) ? 1 : -1;
        });

        const toVerdiepingSortName = (floorNumber: number | undefined) => {
            if (floorNumber === undefined || floorNumber === null) return "A";
            if (floorNumber == -1) return "K";
            if (floorNumber == 0) return "P";
            return "V" + floorNumber;
        }

        const toVerdiepingDisplayName = (floorSortName: string) => {
            if (floorSortName == "A") return "Algemeen";
            if (floorSortName == "K") return "Kelder";
            if (floorSortName == "P") return "Begane grond";
            return "Verdieping " + floorSortName[1];
        }


        let groupedSolutions = ArrayUtils.GroupBy(selectedSolutions, s => toVerdiepingSortName(s.floorNumber));
        let groupedErrors = ArrayUtils.GroupBy(errors, e => toVerdiepingSortName(e.floorNumber));

        let allFloorKeys = ArrayUtils.distinct(Object.keys(groupedSolutions).concat(Object.keys(groupedErrors)));

        allFloorKeys.sort((a, b) => a > b ? 1 : -1);


        return (
            <div>
                <ConfirmDialog ref={cf => this.confirmDialog = cf} title={intl.formatMessage({ id: "pages.quoteconfigurator.editquote.confirm.title" })} question={intl.formatMessage({ id: "pages.quoteconfigurator.editquote.confirm.question" })} />
                {!isQuoteLoading && <Row className="my-2">
                    <Col sm="12">
                        <FormGroup>
                            <h4><FormattedMessage id="pages.quoteconfigurator.editquote.header" defaultMessage="BEWERK OFFERTE" /></h4>
                        </FormGroup>
                    </Col>

                    <Col sm="12">
                        {
                            allFloorKeys.length === 0 &&
                            <Alert color="danger">
                                <FormattedMessage id="pages.quoteconfigurator.editquote.noSolutions" defaultMessage="Er zijn geen oplossingen gevonden" />
                            </Alert>
                        }
                        {allFloorKeys.length > 0
                            && allFloorKeys.map(floorKey => {

                                const solutionsInGroup = groupedSolutions[floorKey] || [];
                                const errorsInGroup = groupedErrors[floorKey] || [];

                                return <>
                                    {<h5>{toVerdiepingDisplayName(floorKey)}</h5>}

                                    {errorsInGroup.map(err => {
                                        return <>
                                            <MessageBar messageBarType={err.errorLevel > ErrorLevelEnum.Information ? MessageBarType.error : MessageBarType.warning}>
                                                {err.errorLevel >= ErrorLevelEnum.Warning && <b>Fout bij {err.requestSolutionType} {err.requestSubject}</b>}
                                                {err.errorLevel == ErrorLevelEnum.Information && <b>Opmerking betreft {err.requestSolutionType} {err.requestSubject}</b>}
                                                <br />
                                                {err.errorMessage}
                                            </MessageBar>
                                        </>
                                    })}
                                    {errorsInGroup.length > 0 && <br />}

                                    {solutionsInGroup.map((solution, solutionIndex) => {

                                        // sort solution positions
                                        let solutionPositions = solution.solutionPositions || [];
                                        solutionPositions.sort((a, b) => a.positionNumber > b.positionNumber ? 1 : a.positionNumber < b.positionNumber ? -1 : 0);

                                        return <Card className="collapsible-panel">
                                            <CardHeader>
                                                <span className="cp" onClick={() => this.open(solution)} >
                                                    {solutionIndex + 1}.&nbsp;
                                                    <FormattedMessage id={"enum.solutionTypeEnum." + SolutionTypeEnum[solution.solutionType]} defaultMessage="Onbekend type oplossing" />&nbsp;
                                                    : <b>{solution.title}</b></span>

                                                {(solution.allowedAction == AllowedActionEnum.CanDeleteAndModify || solution.allowedAction == AllowedActionEnum.CanDelete) && <>
                                                    <a onClick={() => this.removeSolutionFromQuote(solution)} href="javascript:;" className="dlt-item cp"><FaTrash></FaTrash></a>
                                                </>}
                                                <FaCaretDown onClick={() => this.open(solution)} className="caret-down cp"></FaCaretDown>
                                            </CardHeader>
                                            <Collapse isOpen={isOpen == (solution.id + solution.floorNumber) ? true : false}>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12">
                                                            <FormGroup>
                                                                <Row hidden={editedSolutionIndex != -1 && editedSolutionIndex == solutionIndex ? true : false} >
                                                                    <Col className="pr-0">
                                                                        <FormGroup>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3">Titel van de oplossing:</Col>
                                                                                <Col sm="7" md="8" lg="9">{solution.title}</Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                        <FormGroup hidden={solution.floorNumber === undefined || solution.floorNumber === null}>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3">Verdieping:</Col>
                                                                                <Col sm="7" md="8" lg="9">{solution.floorNumber}</Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                        <FormGroup hidden={solution.solutionType != SolutionTypeEnum.HeatPump}>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3">ErP energielabel:</Col>
                                                                                <Col sm="7" md="8" lg="9">{solution.erpEnergielabel}</Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                        <FormGroup hidden={solution.solutionType != SolutionTypeEnum.HeatPump}>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3">Subsidiecode ISDE subsidie:</Col>
                                                                                <Col sm="7" md="8" lg="9">{solution.subsitioncodeIsdeSubsidy}</Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        {(solution.allowedAction == AllowedActionEnum.CanDeleteAndModify || solution.allowedAction == AllowedActionEnum.CanModify) && <>
                                                                            <DefaultButton onClick={() => this.openEditSolution(solution, solutionIndex)}><FaPen></FaPen></DefaultButton>
                                                                        </>}
                                                                    </Col>
                                                                </Row>
                                                                <Row hidden={editedSolutionIndex == solutionIndex ? false : true}>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3" className="align-self-center">
                                                                                    <Label>Titel systeem</Label>
                                                                                </Col>
                                                                                <Col sm="7" md="8" lg="9">
                                                                                    <Input type="text"
                                                                                        value={editedSolution.title}
                                                                                        onChange={(ev) => this.editSolutionChange(ev, editedSolutionIndex)}
                                                                                        name="title"
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Row>
                                                                                <Col sm="5" md="4" lg="3" className="align-self-center">
                                                                                    <Label>Subsidiecode ISDE subsidie</Label>
                                                                                </Col>
                                                                                <Col sm="7" md="8" lg="9">
                                                                                    <Input type="text"
                                                                                        value={editedSolution.subsitioncodeIsdeSubsidy}
                                                                                        readOnly disabled
                                                                                        name="subsitioncodeIsdeSubsidy"
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12" className="text-right">
                                                                        <DefaultButton onClick={() => this.closeEditSolution()} >Annuleer</DefaultButton>
                                                                        <PrimaryButton onClick={() => this.closeEditSolution()}>Opslaan</PrimaryButton>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        {
                                                            solutionPositions.length &&
                                                            solutionPositions.map((solutionPositions: ISolutionPositionVM, posIndex) => {

                                                                let grossTotal: number = 0;
                                                                let netTotal: number = 0;
                                                                return <Row className="col-sm-12">
                                                                    <Col sm="12">
                                                                        <p className="m-0"><b><u>{solutionPositions.title}</u></b></p>
                                                                        <FaPlusCircle className="cp" style={{ float: "right" }}
                                                                            onClick={() => this.setState({ activePositionIndex: posIndex, activeSolutionIndex: solutionIndex })}
                                                                        ></FaPlusCircle>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Row>
                                                                                <Col className="table-responsive wizard-table pr-0">
                                                                                    <Table className="table table-borderless table-sm">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th>PS-nr</th>
                                                                                                <th>Artikelnummer</th>
                                                                                                <th>Aantal</th>
                                                                                                <th>Omschrijving</th>
                                                                                                <th className="right">{solutionPositions.isNetto ? "Netto/st." : "Bruto/st."}</th>
                                                                                                <th className="right">{solutionPositions.isNetto ? "Netto/totaal" : "Bruto/totaal"}</th>
                                                                                            </tr>
                                                                                            {
                                                                                                solutionPositions.solutionPositionItems.length > 0 &&
                                                                                                solutionPositions.solutionPositionItems.sort(this.solutionPositionSorter).map((posItem: ISolutionPositionItemVM, posItemIndex) => {

                                                                                                    const grossPricePerUnit = posItem.navisionItem ? posItem.navisionItem.grossPricePerUnit : 0;
                                                                                                    const itemNumber = posItem.navisionItem ? posItem.navisionItem.itemNumber : -1;
                                                                                                    const description = posItem.navisionItem ? posItem.navisionItem.description : "";

                                                                                                    grossTotal += grossPricePerUnit;
                                                                                                    netTotal += posItem.navisionItem ? (posItem.quantity * posItem.navisionItem.grossPricePerUnit) : 0;

                                                                                                    return <tr>
                                                                                                        <td>{posItem.psNumber}</td>
                                                                                                        <td>{itemNumber}</td>
                                                                                                        <td>{posItem.quantity}</td>
                                                                                                        <td>{description}</td>
                                                                                                        <td className="right"><Money amount={grossPricePerUnit} decimals={2} symbol="€" /></td>
                                                                                                        <td className="right"><Money amount={grossPricePerUnit * posItem.quantity} decimals={2} symbol="€" /></td>
                                                                                                        <td>
                                                                                                            {(solution.allowedAction == AllowedActionEnum.CanDeleteAndModify || solution.allowedAction == AllowedActionEnum.CanModify) && <>
                                                                                                                <FaPen className="text-dark" onClick={() => this.openEditPositionItem(posItem, posItemIndex, posIndex, solutionIndex)}></FaPen>
                                                                                                            </>}
                                                                                                            &nbsp;&nbsp;
                                                                                                            {(solution.allowedAction == AllowedActionEnum.CanDeleteAndModify || solution.allowedAction == AllowedActionEnum.CanDelete) && <>
                                                                                                                <FaTrash className="text-dark" onClick={() => this.removeEditPositionItem(posItem, posItemIndex, posIndex, solutionIndex)}></FaTrash>
                                                                                                            </>}
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan={4}><b>Subtotaal</b></td>
                                                                                                <td className="right"><Money amount={grossTotal} decimals={2} symbol="€" /></td>
                                                                                                <td style={{ border: "solid 1px #000" }} className="right"><Money amount={netTotal} decimals={2} symbol="€" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Col>

                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>
                                                            })

                                                        }
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    })}
                                    <br />
                                </>

                            }
                            )}

                    </Col>

                    <Col sm="12" className="text-right mt30">
                        <DefaultButton onClick={() => this.onSaveOrRemove(false)} >
                            <FormattedMessage id="pages.quoteconfigurator.editquote.removequote" defaultMessage="Verwijder Offerte" />
                        </DefaultButton>
                        &nbsp;
                        <PrimaryButton disabled={selectedSolutions.length === 0} className="ml-3" onClick={() => this.setState({ isShowSaveAndDownloadModal: true })}>
                            <FormattedMessage id="pages.quoteconfigurator.editquote.saveanddownload" defaultMessage="Opslaan en downloaden" />
                        </PrimaryButton>
                    </Col>

                    {
                        <Modal isOpen={activePositionIndex > -1 ? true : false} >
                            <ModalHeader><FormattedMessage id="pages.quoteconfigurator.editquote.modal.activepos.header" defaultMessage="Artikel toevoegen" /></ModalHeader>
                            <ModalBody>
                                <div className="row" style={{ padding: 5 }}>
                                    <div className="col-md-7 col-sm-7">
                                        <Label for="exampleEmail">Zoek artikel</Label>
                                        <Input type="text" name="email" id="exampleEmail" placeholder="Artikelnaam"
                                            onChange={(ev) => this.setState({ searchText: ev.target.value })} value={searchText} />
                                    </div>
                                    <div className="col-md-3 col-sm-3">
                                        <PrimaryButton style={{ position: "absolute", bottom: 0 }} onClick={() => this.searchItems()}>
                                            Zoek
                                        </PrimaryButton>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 5 }}>
                                    <div className="col-md-12" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {this.state.isLoadingModal ? <div className="d-flex justify-content-center"><Spinner color="secondary" /></div> :
                                            <Table bordered height="200">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Item No</th>
                                                        <th>Item omschrijving</th>
                                                        <th>Item eenheid</th>
                                                        <th>Bruto prijs</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items && items.map((item) => {
                                                        return <tr>
                                                            <td>
                                                                <CustomInput type="checkbox" id={`chk${item.item.itemNumber}`} name="customRadio"
                                                                    onChange={() => { item.isChecked = !item.isChecked; this.setState({ isLoadingModal: false }) }}
                                                                    checked={item.isChecked} />
                                                            </td>
                                                            <td>{item.item.itemNumber}</td>
                                                            <td>{item.item.description}</td>
                                                            <td>{item.item.baseUnitOfMeasure}</td>
                                                            <td><Money amount={item.item.grossPricePerUnit} /></td>
                                                        </tr>
                                                    })
                                                    }
                                                </tbody>
                                            </Table>
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <DefaultButton onClick={() => this.cancelPositionModal()}>
                                    <FormattedMessage id="pages.quoteconfigurator.editquote.modal.activepos.cancel" defaultMessage="Annuleer" />
                                </DefaultButton>
                                <PrimaryButton onClick={() => this.submitPositionModal(activePositionIndex, activeSolutionIndex)}>
                                    <FormattedMessage id="pages.quoteconfigurator.editquote.modal.activepos.submit" defaultMessage="Voeg toe" />
                                </PrimaryButton>
                            </ModalFooter>
                        </Modal>
                    }

                    {editedItem.itemIndex != -1 &&
                        <Modal isOpen={editedItem.itemIndex > -1 ? true : false} >
                            <ModalHeader><FormattedMessage id="pages.quoteconfigurator.editquote.modal.editeditem.header" defaultMessage="Artikel bewerken" /></ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="txtArtikel">Artikel</Label>
                                            <Input type="text" disabled={true} id="txtArtikel"
                                                value={editedItem.item.navisionItem ? editedItem.item.navisionItem.itemNumber : "NOT FOUND"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="txtArtikelummer">Artikelummer</Label>
                                            <Input type="number" disabled={true} id="txtArtikelummer"
                                                value={editedItem.item.navisionItem ? editedItem.item.navisionItem.itemNumber : "NOT FOUND"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="txtEenheid">Eenheid</Label>
                                            <Input type="text" disabled={true} id="txtEenheid"
                                                value={editedItem.item.navisionItem ? editedItem.item.navisionItem.baseUnitOfMeasure : "NOT FOUND"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="txtPSnr">PS-nr.</Label>
                                            <Input type="text" id="txtPSnr"
                                                value={editedItem.newPsNumber}
                                                readOnly disabled
                                                name="psNumber"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="txtAantal">Aantal</Label>
                                            <Input type="number" id="txtAantal"
                                                value={editedItem.newQuantity}
                                                onChange={(ev) => this.editPositionItemChange(ev, editedItem.itemIndex, editedItem.posIndex, editedItem.solIndex)}
                                                name="quantity"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="txtBrutoPrijsPerEenheid">Bruto prijs per eenheid</Label>
                                            <Input type="number" disabled={true} id="txtBrutoPrijsPerEenheid"
                                                value={editedItem.item.navisionItem ? editedItem.item.navisionItem.grossPricePerUnit : "NOT FOUND"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="txtNettoPrijsPerEenheid">Netto prijs per eenheid</Label>
                                            <Input type="text" disabled={true} id="txtNettoPrijsPerEenheid" value="-" />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <DefaultButton onClick={() => this.closeEditPositionItem(false)}>
                                    <FormattedMessage id="pages.quoteconfigurator.editquote.modal.editeditem.cancel" defaultMessage="Annuleer" />
                                </DefaultButton>
                                <PrimaryButton hidden={!this.state.editedItem.isChanged} onClick={() => this.closeEditPositionItem(true)}>
                                    <FormattedMessage id="general.OK" defaultMessage="OK" />
                                </PrimaryButton>
                            </ModalFooter>
                        </Modal>
                    }

                    {isShowSaveAndDownloadModal &&
                        <Modal isOpen={isShowSaveAndDownloadModal} >
                            <ModalHeader><FormattedMessage id="pages.quoteconfigurator.editquote.modal.download.header" defaultMessage="Offerte opslaan en downloaden?" /></ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={12}>

                                        <FormGroup>
                                            <Label for="txtSubjectQuotation">Onderwerp offerte</Label>
                                            <Input type="text" id="txtSubjectQuotation"
                                                value={saveAndDownloadQuote && saveAndDownloadQuote.quoteSubject}
                                                onChange={(ev) => this.editSaveAndDownloadModalChange(ev)}
                                                // invalid={saveAndDownloadQuote && saveAndDownloadQuote.quoteSubject ? false : true}
                                                name="subjectQuotation" />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="txtRemarks">Opmerkingen</Label>
                                            <Input type="textarea" id="txtRemarks"
                                                value={saveAndDownloadQuote && saveAndDownloadQuote.quoteRemarks}
                                                onChange={(ev) => this.editSaveAndDownloadModalChange(ev)}
                                                // invalid={saveAndDownloadQuote && saveAndDownloadQuote.quoteRemarks ? false : true}
                                                name="remarks"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </ModalBody>
                            <ModalFooter>
                                <DefaultButton onClick={() => this.hideSaveAndDownloadModal()}>
                                    <FormattedMessage id="pages.quoteconfigurator.editquote.modal.download.cancel" defaultMessage="Annuleer" />
                                </DefaultButton>
                                &nbsp;
                                <PrimaryButton onClick={() => this.onSaveOrRemove(true)} disabled={saveAndDownloadQuote && saveAndDownloadQuote.quoteSubject && saveAndDownloadQuote.quoteRemarks ? false : true}>
                                    <FormattedMessage id="pages.quoteconfigurator.editquote.modal.download.submit" defaultMessage="Opslaan en downloaden" />
                                </PrimaryButton>
                            </ModalFooter>
                        </Modal>
                    }

                </Row>
                }
                {
                    isQuoteLoading && <FormattedMessage id="pleasewait.quotesave" defaultMessage="Een ogenblik geduld aub, de offerte wordt opgeslagen...">
                        {m => <Loading>{m}</Loading>}
                    </FormattedMessage>
                }
            </div >

        );
    }
}

export default injectIntl(EditQuote, { forwardRef: true });