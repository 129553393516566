import { injectIntl, FormattedMessage, WrappedComponentProps } from "react-intl";
import React from "react";
import { Table, Alert, Button, Nav, NavLink, TabContent, TabPane, Container, Col, Row } from "reactstrap";
import { IRequestVM } from "../viewmodels/data";
import { RequestService } from "../services/RequestService";
import { Util } from "../Utils/Util";

import "./PriceIndicationsOverviewPage.scss";
import moment from "moment";
import classnames from "classnames";
import Loading from "../components/Loading";
import { UserRepository } from "../repositories/UserRepository";

import { NavigateToPage } from "../NavigateTo";
import Analytics from "../components/analytics/Analytics";

import Locales from "../locales/Locales";
import HxDetailList, { HxDetailListItem } from "../components/HxDetailList";
import { DateTime } from "luxon";
import { IColumn, PrimaryButton, SelectionMode, Sticky } from "@fluentui/react";
import HxDetailListColumn from "../components/HxDetailListColumn";
import DateTimeUtils from "../Utils/DateTimeUtils";
import ArrayUtils from "../Utils/ArrayUtils";
import FilterBox, { IFilterOptions } from "../components/FilterBox";
import EnumUtils from "../Utils/EnumUtils";

enum PriceIndicationsOverviewPageTabEnum {
    my, all
}

interface ListItem extends IRequestVM, HxDetailListItem {
    createdDateStr: string;
    createdDateObj: DateTime
}

interface IPriceIndicationsOverviewPageProps extends WrappedComponentProps { }

interface IPriceIndicationsOverviewPageState {
    activeTab: PriceIndicationsOverviewPageTabEnum;

    myRequests: IRequestVM[];
    allRequests: IRequestVM[];

    isLoading?: boolean;
    errorMessage?: string;

    filterProjectName?: string;
    filterCustomerName?: string;
    filterSubmittedBy?: string;
    filterRequestedOn?: DateTime;
}

class PriceIndicationsOverviewPage extends React.Component<IPriceIndicationsOverviewPageProps, IPriceIndicationsOverviewPageState> {

    private locales: Locales;

    constructor(props: IPriceIndicationsOverviewPageProps) {
        super(props);

        this.locales = new Locales(this.props);

        this.state = {
            activeTab: PriceIndicationsOverviewPageTabEnum.all,
            myRequests: [],
            allRequests: [],
            filterProjectName: "All",
            filterCustomerName: "All",
            filterSubmittedBy: "All",
        };
    }

    componentDidMount() {
        this.loadRequests();
    }

    private loadRequests = (tabId?: PriceIndicationsOverviewPageTabEnum) => {
        this.setState({
            myRequests: [],
            allRequests: []
        });

        if (tabId === undefined) {
            tabId = this.state.activeTab;
        }

        this.setState({
            isLoading: true,
            errorMessage: undefined
        });

        switch (tabId) {
            case PriceIndicationsOverviewPageTabEnum.my: this.loadMyRequests(); break;
            case PriceIndicationsOverviewPageTabEnum.all: this.loadAllRequests(); break;
            default: this.setState({ isLoading: false }); break;
        }
    }

    private loadMyRequests = () =>
        RequestService.GetMyAll()
            .then(requests => {
                const sortedRequests = [...requests];
                // sort desc
                sortedRequests.sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0);

                this.setState({
                    myRequests: [...sortedRequests],
                    errorMessage: undefined
                });
            })
            .catch(reason => {
                console.error(reason);
                this.setState({
                    errorMessage: this.locales.get("errors.priceIndications.getAll")
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });

    private loadAllRequests = () =>
        RequestService.GetAll()
            .then(requests => {
                const sortedRequests = [...requests];
                // sort desc
                sortedRequests.sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0);

                this.setState({
                    allRequests: [...sortedRequests],
                    errorMessage: undefined
                });
            })
            .catch(reason => {
                console.error(reason);
                this.setState({
                    errorMessage: this.locales.get("errors.priceIndications.getAll")
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });

    private setTab = (tabId: PriceIndicationsOverviewPageTabEnum) => {
        if (tabId !== this.state.activeTab) {
            this.loadRequests(tabId);

            this.setState({
                activeTab: tabId
            });
        }
    }

    private onBackToMain = () => {
        Analytics.registerPrijsindicatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.prijsindicatie.backToMain' }));

        NavigateToPage.Home();
    }

    private onDocumentDownload = (request: IRequestVM) => {
        Analytics.registerPrijsindicatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.prijsindicatie.download' }));

        Util.openInNewTab(request.documentURL);
    }



    getItems = (onlyMy: boolean) => {
        const { myRequests, allRequests } = this.state;
        const request = onlyMy ? myRequests : allRequests;
        const listItems = request.map(c => {
            const createdDateObj = DateTime.fromISO(c.created);
            const createdDateStr = DateTimeUtils.ToAppFormat(createdDateObj);
            const item: ListItem = {
                ...c,
                createdDateObj: createdDateObj,
                createdDateStr: createdDateStr,
                // ibnDateObj: DateTime.fromISO(c.ibnDate),
                SearchTextUpper: Object.values(c).join(" ").toUpperCase() + createdDateStr
            }
            return item;
        })
        return listItems;
    }

    getColumns = (onlyMy: boolean) => {
        const { intl } = this.props;
        const columns: IColumn[] = [];

        columns.push(new HxDetailListColumn(intl.formatMessage({ id: 'pages.priceIndications.columns.name' }), "displayName", 220));
        if (!onlyMy) columns.push(new HxDetailListColumn(intl.formatMessage({ id: 'pages.priceIndications.columns.customerName' }), "customerName", 200));
        if (!onlyMy) columns.push(new HxDetailListColumn(intl.formatMessage({ id: 'pages.priceIndications.columns.createdBy' }), "createdByName", 180));
        columns.push(new HxDetailListColumn(intl.formatMessage({ id: 'pages.priceIndications.columns.created' }), "createdDateObj", 110, "date"));
        columns.push({
            key: "csv",
            name: intl.formatMessage({ id: 'pages.priceIndications.columns.document' }),
            minWidth: 120,
            onRender: (item?: ListItem, index?: number, column?: IColumn) => {
                return item?.documentURL &&
                    <PrimaryButton onClick={() => this.onDocumentDownload(item)}>
                        <FormattedMessage id="pages.priceIndications.rows.document.title" defaultMessage="View" />
                    </PrimaryButton>
            }
        });

        return columns;
    }

    getFilterOptions = async () => {
        const { filterProjectName, filterCustomerName, filterSubmittedBy, filterRequestedOn, myRequests, allRequests, activeTab } = this.state;

        var requests: IRequestVM[] = [];

        if (activeTab === PriceIndicationsOverviewPageTabEnum.all) {
            requests = allRequests;
        } else {
            requests = myRequests;
        }

        var customers = requests.length > 0 ? ArrayUtils.distinct(requests.map(r => r.customerName).filter(n => !!n)) as string[] : undefined;
        var submittedby = requests.length > 0 ? ArrayUtils.distinct(requests.map(r => r.createdByName).filter(n => !!n)) as string[] : undefined;

        const filterOptions: IFilterOptions = {
            options: [{
                filterTitle: "Customer",
                key: "customer",
                filterType: "choice",
                choiceOptions: FilterBox.GetSelectOptions(customers ? customers : [], "All"),
                currentValue: filterCustomerName,
                onFilterUpdated: (filterOption) => {
                    this.setState({ filterCustomerName: filterOption.currentValue as string | undefined | "Any" })
                }
            }, {
                filterTitle: "Submitted by",
                key: "submittedBy",
                filterType: "choice",
                choiceOptions: FilterBox.GetSelectOptions(submittedby ? submittedby : [], "All"),
                currentValue: filterSubmittedBy,
                onFilterUpdated: (filterOption) => {
                    this.setState({ filterSubmittedBy: filterOption.currentValue as string | undefined | "Any" })
                }
            }, {
                filterTitle: "Requested on",
                key: "requestedOn",
                filterType: "date",
                currentValue: filterRequestedOn,
                onFilterUpdated: (filterOption) => {
                    this.setState({ filterRequestedOn: filterOption.currentValue as DateTime })
                }
            }]
        }

        return filterOptions;
    }

    getFilteredRequests = () => {
        const { filterCustomerName, filterSubmittedBy, filterRequestedOn, myRequests, allRequests, activeTab } = this.state;

        if (!myRequests || !allRequests) return undefined;

        var requestItems: ListItem[] = [];

        if (activeTab === PriceIndicationsOverviewPageTabEnum.all) {
            requestItems = this.getItems(false).slice();
        } else {
            requestItems = this.getItems(true).slice();
        }
        
        if (filterCustomerName && filterCustomerName != "All") requestItems = requestItems.filter(r => r.customerName == filterCustomerName);
        if (filterSubmittedBy && filterSubmittedBy != "All") requestItems = requestItems.filter(r => r.createdByName == filterSubmittedBy);
        if (filterRequestedOn) requestItems = requestItems.filter(r => DateTimeUtils.getDate(DateTime.fromISO(r.created)).valueOf()  == filterRequestedOn.valueOf());

        return requestItems;

    }



    render() {
        const myRequests: IRequestVM[] = [...this.state.myRequests];
        const allRequests: IRequestVM[] = [...this.state.allRequests];

        if (this.state.activeTab === PriceIndicationsOverviewPageTabEnum.all) {
            // sort all requests by created (desc)
            allRequests.sort((r1, r2) => r1.created < r2.created ? -1 : 1);
        }

        const userRole = UserRepository.getUserRole();

        return (
            <div className="page-PriceIndicationsOverview">

                <Row id="page-PriceIndicationsOverview-actions">
                    <Col className="text-right">
                        <PrimaryButton onClick={this.onBackToMain}>
                            <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                        </PrimaryButton>
                    </Col>
                </Row>

                <h1><FormattedMessage id="pages.priceIndications.title" defaultMessage="Price indications" /></h1>

                <Nav tabs>
                    {
                        userRole.showPriceIndicationOverview &&
                        <NavLink
                            className={this.state.activeTab === PriceIndicationsOverviewPageTabEnum.my ? "active" : ""}
                            onClick={() => this.setTab(PriceIndicationsOverviewPageTabEnum.my)}>
                            <FormattedMessage id="pages.priceIndications.my.title" defaultMessage="My requests" />
                        </NavLink>
                    }
                    {
                        userRole.showAllPriceIndications &&
                        <NavLink
                            className={this.state.activeTab === PriceIndicationsOverviewPageTabEnum.all ? "active" : ""}
                            onClick={() => this.setTab(PriceIndicationsOverviewPageTabEnum.all)}>
                            <FormattedMessage id="pages.priceIndications.all.title" defaultMessage="Requests from all customers" />
                        </NavLink>
                    }
                </Nav>

                <Container>
                    {
                        !this.state.isLoading && !this.state.errorMessage &&
                        <TabContent activeTab={this.state.activeTab}>
                            {
                                userRole.showPriceIndicationOverview &&
                                <TabPane tabId={PriceIndicationsOverviewPageTabEnum.my}>

                                    <Sticky stickyBackgroundColor="#fff">
                                        <FilterBox filterOptions={this.getFilterOptions()} />
                                    </Sticky>
                                    <HxDetailList
                                        uniqueName="requestOverviewMy"
                                        columns={this.getColumns(true)}
                                        items={this.getFilteredRequests()}
                                        isColumnReorderEnabled={true}
                                        isColumnSelectionEnabled={true}
                                        selectionMode={SelectionMode.none} />

                                    {/* <Table>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="pages.priceIndications.columns.name" defaultMessage="Name" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.created" defaultMessage="Created" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.document" defaultMessage="Document" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                myRequests.map(request => (
                                                    <tr>
                                                        <th scope="row">{request.displayName}</th>
                                                        <td>{moment(request.created).format("D MMMM YYYY")}</td>
                                                        <td>
                                                            {
                                                                request.documentURL &&
                                                                <PrimaryButton onClick={() => this.onDocumentDownload(request)}>
                                                                    <FormattedMessage id="pages.priceIndications.rows.document.title" defaultMessage="View" />
                                                                </PrimaryButton>
                                                            }
                                                        </td>
                                                    </tr>))
                                            }
                                        </tbody>
                                    </Table> */}
                                </TabPane>
                            }

                            {
                                userRole.showAllPriceIndications &&
                                <TabPane tabId={PriceIndicationsOverviewPageTabEnum.all}>

                                    <Sticky stickyBackgroundColor="#fff">
                                        <FilterBox filterOptions={this.getFilterOptions()} />
                                    </Sticky>
                                    <HxDetailList
                                        uniqueName="requestOverviewAll"
                                        columns={this.getColumns(false)}
                                        items={this.getFilteredRequests()}
                                        isColumnReorderEnabled={true}
                                        isColumnSelectionEnabled={true}
                                        selectionMode={SelectionMode.none} />

                                    {/* <Table>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="pages.priceIndications.columns.name" defaultMessage="Project name" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.customerName" defaultMessage="Customer name" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.createdBy" defaultMessage="Created by" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.created" defaultMessage="Created" /></th>
                                                <th><FormattedMessage id="pages.priceIndications.columns.document" defaultMessage="Document" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allRequests.map(request => (
                                                    <tr>
                                                        <th scope="row">{request.displayName}</th>
                                                        <td>{request.customerName}</td>
                                                        <td>{request.createdByName}</td>
                                                        <td>{moment(request.created).format("D MMMM YYYY")}</td>
                                                        <td>
                                                            {
                                                                request.documentURL &&
                                                                <PrimaryButton onClick={() => this.onDocumentDownload(request)}>
                                                                    <FormattedMessage id="pages.priceIndications.rows.document.title" defaultMessage="View" />
                                                                </PrimaryButton>
                                                            }
                                                        </td>
                                                    </tr>))
                                            }
                                        </tbody>
                                    </Table> */}
                                </TabPane>
                            }
                        </TabContent>
                    }
                    {
                        this.state.isLoading &&
                        <Loading text={this.props.intl.formatMessage({ id: "general.loading", defaultMessage: "Loading" })} />
                    }
                    {
                        !this.state.isLoading && this.state.errorMessage &&
                        <Alert color="danger">
                            <FormattedMessage id="errors.priceIndications.getAll" defaultMessage={this.state.errorMessage} />
                        </Alert>
                    }
                </Container>
            </div>
        );
    }
}

export default injectIntl(PriceIndicationsOverviewPage, { forwardRef: true });