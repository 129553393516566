import ReactGA from "react-ga";

export default class Analytics
{
    private static CategoryNavigate: string = "Navigatie";
    private static CategoryGeneral: string = "Algemeen";
    private static CategoryPrijsindicatie: string = "Prijsindicatie";
    private static CategoryIBN: string = "Inbedrijfstelling";
    private static CategoryReclamatie: string = "Reclamatie";

    private static registerCategoryAction = (category: string, action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        ReactGA.event({ category: category, action: action, value: value, label: label, nonInteraction: nonInteraction });

    public static registerNavigateAction = (action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        Analytics.registerCategoryAction(Analytics.CategoryNavigate, action, value, label, nonInteraction);

    public static registerAction = (action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        Analytics.registerCategoryAction(Analytics.CategoryGeneral, action, value, label, nonInteraction);

    public static registerPrijsindicatieAction = (action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        Analytics.registerCategoryAction(Analytics.CategoryPrijsindicatie, action, value, label, nonInteraction);
        
    public static registerIBNAction = (action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        Analytics.registerCategoryAction(Analytics.CategoryIBN, action, value, label, nonInteraction);
    
    public static registerReclamatieAction = (action: string, value?: any, label?: string, nonInteraction?: boolean) =>
        Analytics.registerCategoryAction(Analytics.CategoryReclamatie, action, value, label, nonInteraction);
    
    public static registerFormAction = (formName: string, action: string, value?: any, label?: string, nonInteraction?: boolean) =>
    {
        const category =
            formName === "Inbedrijfstelling" ? Analytics.CategoryIBN :
            formName === "complaint" ? Analytics.CategoryReclamatie :
            "Onbekend";
        Analytics.registerCategoryAction(category, action, value, label, nonInteraction);
    }
}