import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";
import { ICrudGetAllResponseVM, ICrudGetOneResponseVM } from "../viewmodels/data";

export abstract class ACrudService<T> {

    constructor(private apiVersion: string, private apiEndpoint: string) {
    }

    public getAll = (): Promise<T[]> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v${this.apiVersion}/${this.apiEndpoint}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<T>>) => resolve(response.data.value))
                .catch(reason => MijnNathanService.CatchId(reject, `errors.${this.apiEndpoint}.getAll`, reason));
        });
    
    public get = (id: string): Promise<T> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v${this.apiVersion}/${this.apiEndpoint}/${id}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetOneResponseVM<T>>) => resolve(response.data.value))
                .catch(reason => MijnNathanService.CatchId(reject, `errors.${this.apiEndpoint}.get`, reason));
        });
}