import { ICrudCreateResponseVM, ICrudGetAllResponseVM, IBlobVM } from "../viewmodels/data";
import { Service } from "./Service";
import axios, { AxiosResponse } from "axios";
import MijnNathanService from "./MijnNathanService";

export class SolutionAttachmentService {

    public static Create = (file: File, parentId: string): Promise<ICrudCreateResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("file", file);
            axios.post(`${Service.ApiUrl}/v1/solutions/${parentId}/attachments`, formData, Service.Config("multipart/form-data"))
                .then((response: AxiosResponse<ICrudCreateResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionattachment.create", reason));
        });

    public static GetAll = (solutionId:string): Promise<ICrudGetAllResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            axios.get(`${Service.ApiUrl}/v1/solutions/${solutionId}/attachments`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solutionattachment.getAll", reason));
        });

        public static Delete = (solutionId: string,attachmentId:string): Promise<ICrudGetAllResponseVM<IBlobVM>> =>
        new Promise((resolve, reject) => {
            axios.delete(`${Service.ApiUrl}/v1/solutions/${solutionId}/attachments/${attachmentId}`, Service.Config())
                .then((response: AxiosResponse<ICrudGetAllResponseVM<IBlobVM>>) => resolve(response.data))
                .catch(reason => MijnNathanService.CatchId(reject, "errors.solution.delete", reason));
        });
}