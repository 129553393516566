import React from "react";
import Form, { IFormTextProps, IFormValues } from "../components/Form";
import IFormField from "../components/models/FormField";

export interface IFormPageProps<FM> extends IFormTextProps {
    className: string;
    formName: string;
    title: string;
    fields: IFormField[];
    defaultValues?: FM;
    showErrorInAlert?: boolean;

    onSubmit?: (values: FM) => Promise<void>;
    onValidate?: (fields: IFormField[], values: FM) => string[];

    getCodeMessage?: (code: string, fieldName: string) => string;
    getErrorMessage?: (errorCode: string) => string | null | undefined;
}

export default class FormPage<FM extends IFormValues> extends React.Component<IFormPageProps<FM>> {
    private form: Form<FM> | null = null;

    onRef = (instance: Form<FM> | null) => {
        this.form = instance;
    }

    public setValues = (values: FM) => this.form ? this.form.setValues(values) : null;

    render = () => {

        const { title, className, formName, onSubmit, onValidate, getCodeMessage, getErrorMessage, defaultValues} = this.props;


        return (
            <div className={className}>
                <h1>{title}</h1>

                <Form<FM> // fields={this.props.fields} <== Gebeurt al via {...this.props}
                    name={formName}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    getCodeMessage={getCodeMessage}
                    getErrorMessage={getErrorMessage}
                    defaultValues={defaultValues}
                    ref={this.onRef}
                    {...this.props} />
            </div>
        );
    }
}