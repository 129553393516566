import { CommandBarButton, DefaultButton, getTheme, IButtonStyles, IColumn, IconButton, IIconProps, IStackTokens, mergeStyles, Modal, ScrollablePane, Stack, Sticky, StickyPositionType } from "@fluentui/react";
import React from "react";
import { HxDetailListItem } from "./HxDetailList";
import HxDetailListColumn from "./HxDetailListColumn";
import DateTimeUtils from "../Utils/DateTimeUtils";

interface ICopyToCLipboardModalState {
    copiedMessage: string;
}

interface ICopyToCLipboardModalProps<T extends HxDetailListItem> {
    columns: IColumn[],
    listItems: T[],
    onModalClose: () => void;
    isOpen: boolean;
}
const copyIcon: IIconProps = { iconName: 'Copy' };

const buttonStyle = mergeStyles([
    {
        padding: "5px",
        marginLeft: "20px"
    }
])
const tableStyle = mergeStyles([
    {
        minWidth: "200px",
        minHeight: "200px",
        maxHeight: "800px",
        maxWidth: "800px",
        border: "1px solid black",
        textAlign: "left",
        marginLeft: "50px",
        marginRight: "50px",
    }
])
const headerSize = mergeStyles([
    {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        minWidth: "150px",
        minHeight: "80px",

    }
]);
const rowBorderBottom = mergeStyles([
    {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
    }
]);
const paneStyle = mergeStyles([
    {
        width: "500px",
    }
])
const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        alignContent: 'right',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const horizontalGapStackTokens: IStackTokens = {
    childrenGap: "100%",
    padding: 10,
};

export class CopyToClipboardModal<T extends HxDetailListItem> extends React.Component<ICopyToCLipboardModalProps<T>, ICopyToCLipboardModalState> {

    SetState2 = <K extends keyof ICopyToCLipboardModalState>(ob: Pick<ICopyToCLipboardModalState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });

    constructor(props: ICopyToCLipboardModalProps<T>) {
        super(props)

        this.state = {
            copiedMessage: ""
        }
    }

    componentDidUpdate = async () => {
        const { columns, isOpen, listItems } = this.props;
        const { copiedMessage } = this.state;
        const succesMessage = "Table is copied to clipboard, click the button to copy again";

        if (columns && isOpen && listItems) {
            setTimeout(() => {
                try {
                    this.copyTable();
                    if (succesMessage != copiedMessage) {
                        this.SetState2({ copiedMessage: succesMessage });
                    }
                } catch { }
            }, 500);
        }
    }

    copyTable = () => {
        const elTable = document.querySelector('table#generatedTable');

        let range;
        let sel: any

        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {

            range = document.createRange();
            sel = window.getSelection();
            // unselect any element in the page
            sel.removeAllRanges();

            try {
                range.selectNodeContents(elTable!);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(elTable!);
                sel.addRange(range);
            }

            document.execCommand('copy');
        }

        sel.removeAllRanges();

        console.log('Element Copied! Paste it in a file')
    }

    render() {
        const { isOpen, onModalClose, columns, listItems } = this.props;
        const { copiedMessage } = this.state;

        {/* <DefaultButton text="Copy to clipboard" /> */ }

        return <> {columns && listItems && isOpen &&
            <Modal
                isOpen={true}
                onDismiss={() => onModalClose()}
                closeButtonAriaLabel="Close"
                isBlocking={false}
                containerClassName={this.modalStyle}


            >

                <ScrollablePane>
                    <Sticky stickyPosition={StickyPositionType.Header}>
                        <br />
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm3">
                                    <CommandBarButton className={buttonStyle} iconProps={copyIcon} text="Copy to clipboard" onClick={(ev) => this.copyTable()} />
                                </div>
                                <div className="ms-Grid-col ms-sm6" style={{ textAlign: "center" }}>
                                    <i>{copiedMessage}</i>
                                </div>
                                <div className="ms-Grid-col ms-sm3" style={{ textAlign: "right" }}>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={(ev) => { onModalClose() }} />
                                </div>
                            </div>
                        </div>
                        <br />
                    </Sticky>


                    {/* {this.generateTable()} */}
                    <table className={tableStyle} id="generatedTable">
                        <thead>
                            <tr>
                                {columns.map(c => {
                                    return <th key={"th_" + c.name} className={headerSize}>{c.name}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {listItems.map(li => {
                                return <>
                                    <tr>
                                        {columns.map(c => {
                                            const dataType = (c instanceof HxDetailListColumn) ? c.columnDataType : "text";
                                            let fieldValue = "";
                                            if (dataType == "text") fieldValue = (li as any)[c.fieldName || "dummy"];
                                            if (dataType == "date") fieldValue = DateTimeUtils.ToAppFormat((li as any)[c.fieldName || "dummy"]);
                                            if (dataType == "bool") fieldValue = (li as any)[c.fieldName || "dummy"] ? "Yes" : "No";

                                            return <td key={"td_" + c.fieldName} className={rowBorderBottom}>{fieldValue}</td>
                                        })}
                                    </tr>
                                </>
                            })}
                        </tbody>
                    </table>
                </ScrollablePane>
                <br />
            </Modal>
        }
        </>

    }





    modalStyle = mergeStyles({
        width: "90%",
        height: "90%",
    })

}