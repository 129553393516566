import React from "react";
import FormWrapper, { IFileUpload } from "../components/forms/formik/FormWrapper";

import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";

import { ComplaintService } from "../services/ComplaintService";
import AForm from "../forms/AForm";
import { IAppState } from "../viewmodels/app";

import rec2 from "../forms/reclamatie/rec2";
import rec3 from "../forms/reclamatie/rec3";
import rec4 from "../forms/reclamatie/rec4";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Util } from "../Utils/Util";
import FormikUtil from "../components/forms/formik/FormikUtil";
import { FormType } from "../viewmodels/form";
import { UserRepository } from "../repositories/UserRepository";
import AuthRepository from "../repositories/AuthRepository";

import $ from "jquery";

import "./ReclamatieForm.scss";
import { MessageBar, MessageBarType, PrimaryButton, SpinnerSize } from "@fluentui/react";
import Analytics from "../components/analytics/Analytics";

interface IReclamatieFormProps extends WrappedComponentProps {}
interface IReclamatieFormState {
  fileUploads: IFileUpload[];

  initialValues: any;
  currentValues?: any;
}

class ReclamatieForm extends AForm<IReclamatieFormProps, IReclamatieFormState> {
  private readonly FormName: string = "complaint";
  private readonly schemas = [ rec2, rec3, rec4 ];

  constructor(props: IReclamatieFormProps) {
    super(props, "Reclamatie");
    
    const me = UserRepository.getMe();
    const authRepo = new AuthRepository();
    const tokenUser = authRepo.getTokenUser();

    const customer = me ? me.customer : undefined;

    // // load file uploads from storage
    let fileUploads: IFileUpload[] = [];
    // const storedFormData = this.formRepo.get(this.FormName);
    // if (storedFormData && storedFormData.data && storedFormData.data._metadata && storedFormData.data._metadata.files) {
    //   fileUploads = [...storedFormData.data._metadata.files];
    // }

    // set initial state
    this.state = {
      initialValues: {
        groothandelnaam_G: customer && customer.displayName,
        contactpersoon_G: me && me.displayName,
        mailadres_G: tokenUser && tokenUser.emails.length > 0 ? tokenUser.emails[0] : undefined,
        telefoonnummer_G: customer && customer.phone,
        objectStraatenhuisnummer_G: customer && customer.address,
        objectpostcode_G: customer && customer.postalCode,
        objectplaats_G: customer && customer.city
      },
      fileUploads: fileUploads
    };

    FormikUtil.subscribeToFiles("Reclamatie", (formik: any, fieldName: string, files: any[]) => files.length > 0 && this.processFile(formik, fieldName, files));
  }

  protected onAppState = (appState: IAppState): Promise<void> => Promise.resolve();

  public componentDidMount() {
    setTimeout(() => this.ensureFileUploadContent(), 200);
  }
  public componentDidUpdate() {
    setTimeout(() => this.ensureFileUploadContent(), 200);
  }

  private processFile = (formik: any, fieldName: string, files: any[]) => {
    if (files.length > 0)
    { 
      // add new file to fileUploads
      const newFileUploads = [...this.state.fileUploads];
      const fileToUpload = files[0];
      newFileUploads.push({
        fieldName: fieldName,
        originalFileName: fileToUpload.name
      });
      this.setState({
        fileUploads: newFileUploads
      }, () => this.ensureFileUploadContent());

      // Upload to backend
      ComplaintService.UploadFiles(fieldName, files)
        .then(result => {
          const fileUploads = [...this.state.fileUploads];
          const file = result.files[0];
          // set file id and file name
          fileUploads.forEach(fu => {
            if (fu.fieldName === fieldName && !fu.fileId) {
              fu.fileId = file.id;
              fu.fileName = file.storageFileName
            }
          });
          
          this.setState({
            fileUploads: fileUploads
          }, () => this.ensureFileUploadContent());
        })
        .catch(reason => {
          const fieldFileUploads = this.state.fileUploads.filter(fu => fu.fieldName === fieldName);
          if (fieldFileUploads.length > 0) {
            const fieldFileUpload = fieldFileUploads[0];
            const fileUploads = this.state.fileUploads.filter(fu => fu.fieldName !== fieldName);
            
            fileUploads.push({
              fieldName: fieldFileUpload.fieldName,
              originalFileName: fieldFileUpload.originalFileName,
              errorMessage: "Fout bij uploaden"
            });
            this.setState({
              fileUploads: fileUploads
            }, () => this.ensureFileUploadContent());
          }
        });
    }
  }

  private onMetadata = (metadata: any) => {
    if (metadata) {
      this.setState({
        fileUploads: metadata.files
      });
    }
  }

  private onSubmit = (formValues: any): Promise<void> =>
    new Promise((resolve, reject) => {        
      this.appRepo.setState("/Reclamaties/Formulier", formValues);

      const formName = this.props.intl.formatMessage({ id: "forms.Complaint.name", defaultMessage: "Reclamatieformulier" });
      const queueItem = ComplaintService.submit(formValues, formName, this.state.fileUploads || []);
      console.log(queueItem);
      
      this.appRepo.setState("/");
      
      resolve();
    });

  private onValuesChanged = (values: any) => {
    this.setState({ currentValues: values });
  }

  private onBackToMain = () => {
    Analytics.registerReclamatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.reclamatie.backToMain' }));
    Util.Navigate("/");
  }

  // -- START file uploads --

  private ensureFileUploadContent = () => {
    this.state.fileUploads.forEach(fu => {
      const fieldName = fu.fieldName;
      const sourceNode = $(`div.formik-fileUpload[data-field-name=${fieldName}]`);
      const targetNode = $(`.formik-fileUploader[data-formik-field=${fieldName}] .formik-fileUploader-thumbs`);
      if (sourceNode.length > 0 && targetNode.length > 0) {
        const html = sourceNode.html();
        targetNode.html(html);
      }
    });
  }

  // -- END file uploads --

  render() {
    const metadata = {
      files: this.state.fileUploads || []
    };

    // Only active in local development
    const isCopyFromFormEnabled = Util.IsLocal();

    let uploadFieldNames: string[] = [];
    if (this.state.fileUploads) {
      uploadFieldNames = this.state.fileUploads.map(fu => fu.fieldName);
      uploadFieldNames = Array.from(new Set(uploadFieldNames));
      uploadFieldNames = uploadFieldNames.sort();
    }

    return (
      <div className="page-FormComplaint pageContent">

        <Row id="page-FormCommissioning-actions">
            <Col className="text-right">
                <PrimaryButton onClick={this.onBackToMain}>
                    <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                </PrimaryButton>
            </Col>
        </Row>

        <h1><FormattedMessage id="forms.Complaint.title" defaultMessage="Complaint" /></h1>

        <FormWrapper schemas={this.schemas}
                      formName={this.FormName}
                      formType={FormType.Reclamatie}
                      formPrefix="rec"
                      initialValues={this.state.initialValues}
                      isFormValid={true}
                      metadata={metadata}
                      fileUploads={this.state.fileUploads}
                      uploadFieldNames={[ 'typeplaatje_upload', 'onderdeel_upload' ]}
                      copyFromFormEnabled={isCopyFromFormEnabled}
                      recoveryEnabled={true}
                      onMetadata={this.onMetadata}
                      onSubmit={this.onSubmit}
                      onValuesChanged={this.onValuesChanged}
                      {...this.props} />
          {
            uploadFieldNames.map(fieldName => {

              return (
                <div className="formik-fileUpload" data-field-name={fieldName} key={fieldName}>
                {
                  this.state.fileUploads.filter(fu => fu.fieldName === fieldName).map(fileUpload => {
                    return (
                      <div key={fileUpload.fileId || Math.random()} style={{ marginRight: "8px" }}>
                        { fileUpload.fileId ? <MessageBar messageBarType={MessageBarType.info}>{fileUpload.originalFileName}</MessageBar> : <div><Spinner size="large"></Spinner> <span><FormattedMessage id="general.uploadingFile" defaultMessage="Uploading"/>...</span></div> }
                        { fileUpload.errorMessage ? <MessageBar messageBarType={MessageBarType.error}>{fileUpload.errorMessage}</MessageBar> : <></> }
                      </div>);
                  })
                }
                </div>
              );
            })
          }
      </div>
    );
  }
}

export default injectIntl(ReclamatieForm);