import FormikDatePicker from "../../components/forms/formik/FormikDatePicker";

export default {
    "id": "ign2",
    "label": "Opstellingen",
    "type": "container",
    "renderer": "form",
    "elements": {
        "inbedrijfstellingDatum": {
            "name": "inbedrijfstellingDatum",
            "label": "Datum inbedrijfstelling",
            "type": "field",
            "renderer": FormikDatePicker,
            "fieldType": "text",
            "required": true,
            "validation": [["string"], ["required", "verplicht"]]
        },
        "warmtepompOpstelling": {
            "type": "container",
            "renderer": "fieldset",
            "elements": {
                "warmtepompPlaats": {
                    "name": "warmtepompPlaats",
                    "label": "Plaats warmtepomp",
                    "type": "field",
                    "renderer": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                },
                "warmtepompSerienummer": {
                    "name": "warmtepompSerienummer",
                    "label": "Serienummer + index",
                    "type": "field",
                    "renderer": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                },
                "warmtepompArtikelnummer": {
                    "name": "warmtepompArtikelnummer",
                    "label": "Artikelnummer",
                    "type": "field",
                    "renderer": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                },
                "warmtepompMACadres": {
                    "name": "warmtepompMACadres",
                    "label": "MAC-adres",
                    "type": "field",
                    "renderer": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                },
                "warmtepompSoftwareversie": {
                    "name": "warmtepompSoftwareversie",
                    "label": "Softwareversie",
                    "type": "field",
                    "renderer": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                },
                "boilerContainer": {
                    "type": "container",
                    "renderer": "div",
                    "elements": {
                        "boilerAanwezig": {
                            "name": "boilerAanwezig",
                            "label": "Boiler aanwezig?",
                            "type": "field",
                            "renderer": "radio",
                            "options": [
                                { "title": "Ja", "value": "Ja" },
                                { "title": "Nee", "value": "Nee" }
                            ]
                        },
                        "boiler": {
                            "name": "boiler",
                            "label": "Boiler type",
                            "type": "field",
                            "renderer": "text",
                            "required": true,
                            "validation": [["string"], ["required"]],
                            "showWhen": [ "is", "boilerAanwezig", "Ja" ]
                        },
                        "boilerSerienummer": {
                            "name": "boilerSerienummer",
                            "label": "Serienummer + index",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "boilerAanwezig", "Ja" ]
                        },
                        "boilerArtikelnummer": {
                            "name": "boilerArtikelnummer",
                            "label": "Artikelnummer",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "boilerAanwezig", "Ja" ]
                        }
                    }
                },
                "buffervatContainer": {
                    "type": "container",
                    "renderer": "div",
                    "elements": {
                        "buffervatAanwezig": {
                            "name": "buffervatAanwezig",
                            "label": "Buffervat aanwezig?",
                            "type": "field",
                            "renderer": "radio",
                            "options": [
                                { "title": "Ja", "value": "Ja" },
                                { "title": "Nee", "value": "Nee" }
                            ]
                        },
                        "buffervat": {
                            "name": "buffervat",
                            "label": "Buffervat type",
                            "type": "field",
                            "renderer": "text",
                            "required": true,
                            "validation": [["string"], ["required"]],
                            "showWhen": [ "is", "buffervatAanwezig", "Ja" ]
                        },
                        "buffervatSerienummer": {
                            "name": "buffervatSerienummer",
                            "label": "Serienummer + index",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "buffervatAanwezig", "Ja" ]
                        },
                        "buffervatArtikelnummer": {
                            "name": "buffervatArtikelnummer",
                            "label": "Artikelnummer",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "buffervatAanwezig", "Ja" ]
                        }
                    }
                },
                "wandregelaarContainer": {
                    "type": "container",
                    "renderer": "div",
                    "elements": {
                        "WandregelaarAanwezig": {
                            "name": "wandregelaarAanwezig",
                            "label": "Wandregelaar aanwezig?",
                            "type": "field",
                            "renderer": "radio",
                            "options": [
                                { "title": "Ja", "value": "Ja" },
                                { "title": "Nee", "value": "Nee" }
                            ]
                        },
                        "Wandregelaar": {
                            "name": "wandregelaar",
                            "label": "Wandregelaar type",
                            "type": "field",
                            "renderer": "text",
                            "required": true,
                            "validation": [["string"], ["required"]],
                            "showWhen": [ "is", "wandregelaarAanwezig", "Ja" ]
                        },
                        "wandregelaarSerienummer": {
                            "name": "wandregelaarSerienummer",
                            "label": "Serienummer + index",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "wandregelaarAanwezig", "Ja" ]
                        },
                        "WandregelaarArtikelnummer": {
                            "name": "wandregelaarArtikelnummer",
                            "label": "Artikelnummer",
                            "type": "field",
                            "renderer": "text",
                            "showWhen": [ "is", "wandregelaarAanwezig", "Ja" ]
                        }
                    }
                }
            }
        }
    }
}