import { values } from "@fluentui/react";

export class StringUtils {

    

    public static validatePostalCodeFormat = (postalCode: string): boolean => {
        const postalCodeRegex = new RegExp(/^[0-9]{4}\s*[a-z]{2}$/gi);
        const val2 = postalCode && postalCode.trim() || undefined;
        const result = val2 ? postalCodeRegex.test(val2) : false;
        return result;
    }

}