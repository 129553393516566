import { AxiosRequestConfig, AxiosError } from "axios";
import { Util } from "../Utils/Util";
import env from "../env/env.json";
import AuthRepository from "../repositories/AuthRepository";
import AppRepository from "../repositories/AppRepository";
import Locales from "../locales/Locales";
import { WrappedComponentProps } from "react-intl";
import { IErrorResponse } from "../viewmodels/app";

export class ServiceError<T = string, D = any> extends AxiosError<T, D> {

}

export class Service {

    private static authRepo = new AuthRepository();
    private static locales?: Locales;

    public static Init = (componentProps: WrappedComponentProps) => {
        Service.locales = new Locales(componentProps);
    }

    public static Config = (contentType?: string): AxiosRequestConfig => {
        const headers: any = {};
        headers["X-MS-TOKEN-AAD-ID-TOKEN"] = Service.authRepo.getToken();
        if (contentType) headers["Content-Type"] = "multipart/form-data";
        
        return {
            withCredentials: !Util.IsLocal(),
            headers: headers
        }
    }

    public static ApiUrl = `${env.ApiBaseUrl}/${env.ApiResource}`;

    public static Catch = (reject: (reason?: any) => void, reason?: ServiceError) => Service.CatchId(reject, "errors.unknown", reason);

    public static CatchText = (reject: (reason?: any) => void, text: string, reason?: ServiceError) => {
        Util.Log(text);

        const appRepo = new AppRepository();

        appRepo.getSettings()
            .then(settings => {
                if (settings.isDebug) {
                    const error = Service.MapReasonToErrorResponse(reason);
                    if (error) {
                        Util.Log(error);
                    }
                }
            })
            .finally(() => reject(reason));
    }

    public static CatchId = (reject: (reason?: any) => void, textIntlId: string, reason?: ServiceError) => {
        const text = Service.locales ? Service.locales.get(textIntlId, "Unknown error") : "";
        Service.CatchText(reject, text, reason);
    }

    public static GetErrorResponse = (reason?: ServiceError, includes?: string[]): Promise<IErrorResponse | undefined> =>
        new Promise((resolve, reject) => {
            const appRepo = new AppRepository();
            appRepo.getSettings()
            .then(settings => {
                let responseReason: any = settings.isDebug ? reason : undefined;
                if (includes && reason && reason.response && reason.response.data) {
                    const reasonResponse = reason.response;
                    includes.forEach(include => {
                        if (reasonResponse.data.indexOf(include) >= 0) {
                            responseReason = {
                                response: {
                                    data: reasonResponse.data
                                }
                            }
                        }
                    });
                }

                resolve(Service.MapReasonToErrorResponse(responseReason));
            })
            .catch(reason => reject(reason));
        })

    private static MapReasonToErrorResponse = (reason?: ServiceError): IErrorResponse | undefined => {
        if (reason) {
            return {
                errorMessage: reason.response ? reason.response.data : "",
                status: reason.response ? reason.response.status : 0,
                statusText: reason.response ? reason.response.statusText : "",
            }
        } else {
            return undefined;
        }
    }
}