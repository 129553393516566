import React from "react";
import FormikUtil from "./FormikUtil";

import "./FormikWizard.scss";
import { Alert, Spinner } from "reactstrap";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

import $ from "jquery";
import Analytics from "../../analytics/Analytics";
import { PrimaryButton } from "@fluentui/react";
import { ca } from "date-fns/locale";

export interface IFormikWizardForm {
    id: string;
    component: JSX.Element
    schema: any;
}

interface IFormikWizardProps extends WrappedComponentProps {
    name: string;
    forms: IFormikWizardForm[];
    isValid?: boolean;
    isLoading?: boolean;

    onSubmit?: () => Promise<any>;
    onSubmitted?: (data: any) => void;
    onIndexChanged?: (index: number) => void;
}

interface IFormikWizardState {
    stepIndex: number;

    isSubmitting?: boolean;
    isSubmitted?: boolean;
    errorMessage?: string;
}

class FormikWizard extends React.Component<IFormikWizardProps, IFormikWizardState> {

    constructor(props: IFormikWizardProps) {
        super(props);

        this.state = {
            stepIndex: 0
        };
    }

    componentDidMount() {
        this.props.forms.forEach(form => {
            const formAllFields = FormikUtil.getSchemaFields(form.schema);
            for (let fieldName in formAllFields) {
                this.ensureFieldValidationElement(fieldName);
            }
        })
    }

    private ensureFieldValidationElement = (fieldName: string) => {
        const field = document.getElementById(fieldName);
        const fieldValidationId = `${fieldName}_validationMessage`;
        const fieldValidation = document.getElementById(fieldValidationId);
        if (field && field.parentElement && !fieldValidation) {
            const validationMessage = document.createElement('div');
            validationMessage.id = fieldValidationId;
            validationMessage.className = "invalid-feedback";
            validationMessage.setAttribute('i18n', 'nl');
            validationMessage.innerText = "";
            field.parentElement.append(validationMessage);
        }
    }

    private getCurrentForm = (): IFormikWizardForm | null => {
        if (this.state.stepIndex < this.props.forms.length) {
            return this.props.forms[this.state.stepIndex];
        }

        return null;
    }

    private previous = (ev: React.MouseEvent<any>) => {
        Analytics.registerFormAction(this.props.name, this.props.intl.formatMessage({ id: "analytics.actions.form.previous" }));

        this.navigateEvent(ev, Math.max(0, this.state.stepIndex - 1));
    }

    private next = (ev: React.MouseEvent<any>) => {
        Analytics.registerFormAction(this.props.name, this.props.intl.formatMessage({ id: "analytics.actions.form.next" }));

        this.navigateEvent(ev, Math.min(this.props.forms.length - 1, this.state.stepIndex + 1));
    }

    private onFormSubmit = (event: React.MouseEvent<HTMLElement>) => {
        try {
            Analytics.registerFormAction(this.props.name, this.props.intl.formatMessage({ id: "analytics.actions.form.submit" }));
        } catch (e) {
            console.log(e);
        }

        event.preventDefault();

        if (this.props.onSubmit) {
            this.setState({
                isSubmitting: true,
                isSubmitted: false,
                errorMessage: undefined
            });

            this.props.onSubmit()
                .then(data => {
                    this.setState({ isSubmitted: true });

                    this.props.onSubmitted && this.props.onSubmitted(data);
                })
                .catch(reason => {
                    const errorMessage =
                        reason && reason.response && reason.response.data ? reason.response.data : "Fout bij verzenden van formulier";

                    this.setState({ errorMessage: errorMessage });
                })
                .finally(() => this.setState({ isSubmitting: false }));
        }
    }

    private navigateEvent = (ev: React.MouseEvent, newIndex: number) => {
        ev.preventDefault();

        const indexChanged = this.state.stepIndex !== newIndex;

        this.setState({ stepIndex: newIndex });

        if (indexChanged) {
            const me = this;
            setTimeout(() => {
                me.props.onIndexChanged && me.props.onIndexChanged(newIndex);
            }, 200);

            // scroll to top
            document.body.scrollIntoView(true);
        }
    }


    private addRequiredClasses = (form: IFormikWizardForm) => {
        setTimeout(() => {
            const fields = FormikUtil.getSchemaFields(form.schema);
            for (let fieldName in fields) {
                const field = fields[fieldName];
                if (field.required) {
                    $(`label[for=${fieldName}]`).addClass("field-required");
                }
            }
        }, 200);
    }

    private fixFileUploadText = () => {
        setTimeout(() => {
            const fileUploadText = this.props.intl.formatMessage({ id: "components.formikWizard.fileUpload", defaultMessage: "Drag 'n' drop some files here, or click to select files" });
            $(`.component-FormikWizard input[type=file]`).siblings("p").first().text(fileUploadText);
        }, 200);
    }

    private resetErrorMessage = (ev: React.MouseEvent<any, MouseEvent>) => {
        ev.preventDefault();

        Analytics.registerFormAction(this.props.name, this.props.intl.formatMessage({ id: "analytics.actions.form.resetErrorMessage" }));

        this.setState({ errorMessage: undefined });
    }

    public render() {
        const currentForm = this.getCurrentForm();

        const showFormFields = !this.state.isSubmitting && !this.state.isSubmitted && !this.state.errorMessage;

        const formStyle: React.CSSProperties = {
            display: showFormFields ? "block" : "none"
        }

        if (currentForm) {
            this.addRequiredClasses(currentForm);
            this.fixFileUploadText();
        }

        const previousText = this.props.intl.formatMessage({ id: "general.previous", defaultMessage: "Previous" });
        const nextText = this.props.intl.formatMessage({ id: "general.next", defaultMessage: "Next" });
        const sendText = this.props.intl.formatMessage({ id: "general.send", defaultMessage: "Send" });

        return (
            <div className="component-FormikWizard">
                {this.props.isLoading && <div><Spinner color="secondary" /> Bezig met laden...</div>}
                {this.state.isSubmitting && <div><Spinner color="secondary" /> Bezig met verzenden...</div>}
                {this.state.isSubmitted && <Alert color="success">Formulier verzonden</Alert>}
                {
                    this.state.errorMessage &&
                    <div>
                        <Alert color="danger">Fout bij verzenden van formulier: <text>{this.state.errorMessage}</text></Alert>
                        <PrimaryButton onClick={(ev) => this.resetErrorMessage(ev)}>Terug naar formulier</PrimaryButton>
                    </div>
                }
                <div style={formStyle}>
                    <div>
                        {
                            this.props.forms.map(form => {
                                const formStepStyle: React.CSSProperties = {
                                    display: currentForm === form ? "block" : "none"
                                }
                                return <div style={formStepStyle} id={form.id} key={form.id}>{form.component}</div>;
                            })
                        }
                    </div>
                    <div className="component-FormikWizard-content">{this.props.children}</div>
                    <div>
                        <span id="legend-field-required">*</span> <FormattedMessage id="formikWizard.legend.requiredField" defaultMessage="Required field" />
                    </div>
                    <div>
                        {
                            this.state.stepIndex > 0 &&
                            <>
                                <PrimaryButton onClick={this.previous}>{previousText}</PrimaryButton>
                                &nbsp;
                            </>
                        }
                        {
                            this.state.stepIndex < this.props.forms.length - 1 &&
                            <>
                                <PrimaryButton onClick={this.next} disabled={!this.props.isValid}>{nextText}</PrimaryButton>
                                &nbsp;
                            </>
                        }
                        {
                            this.state.stepIndex === this.props.forms.length - 1 &&
                            <>
                                <PrimaryButton onClick={this.onFormSubmit} disabled={!this.props.isValid}>{sendText}</PrimaryButton>
                                &nbsp;
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(FormikWizard);