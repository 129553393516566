import React from 'react';
import { ICustomFieldParams } from './ComponentParams';

import "./FormikLink.scss";

const FormikLink = (params: ICustomFieldParams) => {
    const {
        urlPreText = '',
        url
    } = params.config;

    return (
        <React.Fragment>
            <div className="FormikLink">
                <span>{urlPreText}</span><a href={url} target="_blank">{url}</a>
            </div>
        </React.Fragment>
    );
}

export default FormikLink;