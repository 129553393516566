import { DateTime } from "luxon";
import { Checkbox, ComboBox, DatePicker, DayOfWeek, DefaultButton, FirstWeekOfYear, IComboBoxOption, Icon, ISelectableOption, Label, Panel, PanelType, Stack } from "@fluentui/react";
import React, { Fragment } from "react";
import DateTimeUtils from "../Utils/DateTimeUtils";
import ObjUtils from "../Utils/ObjUtils";
import "./FilterBox.scss"

interface IFilterBoxState {
    filterOptions?: IFilterOptions
    showPanel: boolean;
}

interface IFilterBoxProps {
    filterOptions: IFilterOptions | Promise<IFilterOptions>
}

export interface IFilterOptions {
    options: IFilterOption[];
    filterUpdated?: (filterOption: IFilterOption) => void;
}

export interface IFilterOption {
    key: string;
    filterTitle: string;
    // startValue: string | DateTime | boolean | undefined;
    currentValue: string | DateTime | boolean | undefined;
    filterType: "choice" | "date" | "checkbox";
    choiceOptions?: ISelectableOption[];
    onFilterUpdated?: (filterOption: IFilterOption) => void;
    hiddenInDisplay?: (filterOption: IFilterOption) => boolean;
    hiddenInEdit?: (filterOption: IFilterOption) => boolean;
}

export default class FilterBox extends React.Component<IFilterBoxProps, IFilterBoxState> {

    SetState2 = <K extends keyof IFilterBoxState>(ob: Pick<IFilterBoxState, K>) => new Promise<void>((resolve, reject) => {
        this.setState(ob, resolve);
    });


    constructor(props: IFilterBoxProps) {
        super(props)

        this.state = {
            showPanel: false
        }
    }
    componentDidMount = async () => {
        const fo = await Promise.resolve(this.props.filterOptions);
        await this.SetState2({ filterOptions: fo });
    }

    componentDidUpdate = async (prevProps: IFilterBoxProps, prevState: IFilterBoxState) => {
        if (this.props.filterOptions != prevProps.filterOptions) {
            const fo = await Promise.resolve(this.props.filterOptions);
            await this.SetState2({ filterOptions: fo });
        }
    }

    static GetSelectOptions = (options: string[], nofilterOption?: string): ISelectableOption[] => {
        let result = options.sort((o1, o2) => o1 > o2 ? 1 : -1).map(otxt => {
            const option: ISelectableOption = {
                key: otxt,
                text: otxt,
                id: otxt
            };
            return option;
        });

        if (nofilterOption) {
            result.unshift({
                key: nofilterOption,
                text: nofilterOption,
                id: nofilterOption
            })
        }

        return result;
    }

    renderFilterOptionDisplay = (filterOption: IFilterOption) => {
        let isHidden = filterOption.currentValue == undefined;
        if (filterOption.hiddenInDisplay) {
            isHidden = filterOption.hiddenInDisplay(filterOption);
        }
        if (!isHidden) {
            let displayValue = "";
            if (filterOption.filterType == "checkbox") displayValue = filterOption.currentValue ? "Yes" : "No"
            if (filterOption.filterType == "date") displayValue = DateTimeUtils.ToAppFormat(filterOption.currentValue as DateTime);
            if (filterOption.filterType == "choice") displayValue = filterOption.choiceOptions?.find(fo => fo.key == filterOption.currentValue)?.text || "filter value not found";
            return <div className="filterItem" key={"ftl_" + filterOption.key}>
                {filterOption.filterTitle}: {displayValue}
            </div>

        }
    }

    updateFilterValue = async (key: string, newValue: any) => {
        // console.log("updateFilterValue1",key, newValue)
        let { filterOptions } = this.state;
        if (!filterOptions) return;

        const toBeUpdated = filterOptions.options.find(o => o.key == key);
        if (toBeUpdated) {
            if (newValue instanceof Date) {
                newValue = DateTime.fromJSDate(newValue);
            }
            const updatedOption = {
                ...toBeUpdated,
                "currentValue": newValue
            }

            // console.log("updateFilterValue2",updatedOption)

            const newOptionArr = filterOptions.options.map(o => o.key == key ? updatedOption : o);
            filterOptions.options = newOptionArr;
            await this.SetState2({ filterOptions: filterOptions });
            if (updatedOption.onFilterUpdated) {
                updatedOption.onFilterUpdated(updatedOption);
            }
            if (filterOptions.filterUpdated) {
                filterOptions.filterUpdated(updatedOption);
            }
        }
    }


    renderFilterOptionEdit = (filterOption: IFilterOption) => {
        let isHidden = false;
        if (filterOption.hiddenInEdit) {
            isHidden = filterOption.hiddenInEdit(filterOption);
        }
        if (isHidden) return <></>

        if (filterOption.filterType == "checkbox") {
            const currentValue = filterOption.currentValue == undefined ? undefined : !!filterOption.currentValue;
            return <Fragment key={filterOption.key}>
                <Label key={filterOption.key + "_lbl"}>{filterOption.filterTitle}</Label>
                <Checkbox key={filterOption.key + "_cb"} checked={currentValue} onChange={(e, c) => this.updateFilterValue(filterOption.key, c)} />
            </Fragment>
        }

        if (filterOption.filterType == "choice") {
            const currentValue = ObjUtils.isString(filterOption.currentValue) ? filterOption.currentValue as string : undefined;
            return <ComboBox
                key={filterOption.key}
                options={filterOption.choiceOptions || []}
                label={filterOption.filterTitle}
                selectedKey={currentValue}
                onChange={(e, option, index) => this.updateFilterValue(filterOption.key, option?.key)} />
        }

        if (filterOption.filterType == "date") {
            const currentValue = (filterOption.currentValue instanceof DateTime) ? filterOption.currentValue.toJSDate() : undefined;
            return <div key={filterOption.key}>
                <div style={{ width: "80%", display: "inline-block" }}>
                    <DatePicker
                        label={filterOption.filterTitle} firstDayOfWeek={DayOfWeek.Monday} firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                        value={currentValue} onSelectDate={(d) => this.updateFilterValue(filterOption.key, d)}
                        showCloseButton={true}
                        unselectable="on" />
                </div>
                <div style={{ width: "15%", display: "inline-block", paddingLeft: "5px" }}>
                    <Icon
                        iconName="Clear"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.updateFilterValue(filterOption.key, undefined)} />
                </div>
            </div>
        }

    }

    render = () => {
        let { filterOptions, showPanel } = this.state;
        if (!filterOptions) return <></>;

        const filtersDisplay = filterOptions.options.map(o => this.renderFilterOptionDisplay(o))

        return <>
            <div onClick={() => this.setState({ showPanel: true })} className="filterBox">
                <b>Filters: </b>
                {filtersDisplay && filtersDisplay.length > 0 && filtersDisplay.map(f => f)}
            </div>
            <Panel type={PanelType.smallFixedFar}
                isLightDismiss
                headerText="Filter"
                isOpen={showPanel}
                onDismiss={() => this.setState({ showPanel: false })}
                closeButtonAriaLabel="Close">

                {filterOptions.options.map(o => this.renderFilterOptionEdit(o))}

            </Panel>
        </>
    }

}