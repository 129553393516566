import FormikUtil from "../../components/forms/formik/FormikUtil";

export default
  {
    "id": "ign1",
    "label": "",
    "type": "container",
    "renderer": "form",
    "elements": {
      "land": {
        "name": "land",
        "label": "Land",
        "type": "field",
        "renderer": "react-select",
        "fieldType": "text",
        "defaultValue": "Nederland",
        "options": [
          { "label": "Nederland", "value": "Nederland" },
          { "label": "België", "value": "België" },
          { "label": "Luxemburg", "value": "Luxemburg" }
        ],
        "required": true,
        "validation": [["string"], ["required"]]
      },
      "werkbonnummer": {
        "name": "werkbonnummer",
        "label": "Werkbonnummer",
        "type": "field",
        "renderer": "text",
        "fieldType": "text"
      },
      warmtepompserie: {
        name: "warmtepompserie",
        label: "Warmtepomp serie",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "LW", value: "LW" },
          { label: "LWAV", value: "LWAV" },
          { label: "LWCV", value: "LWCV" },
          { label: "LWD", value: "LWD" },
          { label: "LWDV", value: "LWDV" },
          { label: "SW", value: "SW" },
          { label: "SWC", value: "SWC" },
          { label: "SWCV", value: "SWCV" },
          { label: "SWP", value: "SWP" },
          { label: "WZS", value: "WZS" },
          { label: "WZSV", value: "WZSV" }
        ],
        required: true,
        validation: [["string"], ["required"]]
      },
      warmtepomptype_LW: {
        name: "warmtepomptype_LW",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "LW 101", value: "LW 101" },
          { label: "LW 121", value: "LW 121" },
          { label: "LW 140A/RX", value: "LW 140A/RX" },
          { label: "LW 180A", value: "LW 180A" },
          { label: "LW 251A", value: "LW 251A" },
          { label: "LW 310A", value: "LW 310A" },
          { label: "LW 90A/RX", value: "LW 90A/RX" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "LW"]
      },
      warmtepomptype_LWAV: {
        name: "warmtepomptype_LWAV",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "LWAV 122R3", value: "LWAV 122R3" },
          { label: "LWAV 82R1/3", value: "LWAV 82R1/3" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "LWAV"]
      },
      warmtepomptype_LWCV: {
        name: "warmtepomptype_LWCV",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "LWCV 122R3", value: "LWCV 122R3" },
          { label: "LWCV 82R1/3", value: "LWCV 82R1/3" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "LWCV"]
      },
      warmtepomptype_LWD: {
        name: "warmtepomptype_LWD",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "LWD 50A", value: "LWD 50A" },
          { label: "LWD 50A/RSX", value: "LWD 50A/RSX" },
          { label: "LWD 50A/RX", value: "LWD 50A/RX" },
          { label: "LWD 50A/SX", value: "LWD 50A/SX" },
          { label: "LWD 70A", value: "LWD 70A" },
          { label: "LWD 70A/RSX", value: "LWD 70A/RSX" },
          { label: "LWD 70A/RX", value: "LWD 70A/RX" },
          { label: "LWD 70A/SX", value: "LWD 70A/SX" },
          { label: "LWD 90A", value: "LWD 90A" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "LWD"]
      },
      warmtepomptype_LWDV: {
        name: "warmtepomptype_LWDV",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [{ label: "LWDV 91-1/3", value: "LWDV 91-1/3" }],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "LWDV"]
      },
      warmtepomptype_SW: {
        name: "warmtepomptype_SW",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "SW 102H3", value: "SW 102H3" },
          { label: "SW 122H3", value: "SW 122H3" },
          { label: "SW 142H3", value: "SW 142H3" },
          { label: "SW 172H3", value: "SW 172H3" },
          { label: "SW 192H3", value: "SW 192H3" },
          { label: "SW 232H3", value: "SW 232H3" },
          { label: "SW 262H3", value: "SW 262H3" },
          { label: "SW 302H3", value: "SW 302H3" },
          { label: "SW 42H3", value: "SW 42H3" },
          { label: "SW 42K3", value: "SW 42K3" },
          { label: "SW 82H3", value: "SW 82H3" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "SW"]
      },
      warmtepomptype_SWC: {
        name: "warmtepomptype_SWC",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "SWC 102H3", value: "SWC 102H3" },
          { label: "SWC 102K3", value: "SWC 102K3" },
          { label: "SWC 122H3", value: "SWC 122H3" },
          { label: "SWC 122K3", value: "SWC 122K3" },
          { label: "SWC 142H3", value: "SWC 142H3" },
          { label: "SWC 142K3", value: "SWC 142K3" },
          { label: "SWC 172H3", value: "SWC 172H3" },
          { label: "SWC 172K3", value: "SWC 172K3" },
          { label: "SWC 192H3", value: "SWC 192H3" },
          { label: "SWC 192K3", value: "SWC 192K3" },
          { label: "SWC 42H3", value: "SWC 42H3" },
          { label: "SWC 42K3", value: "SWC 42K3" },
          { label: "SWC 62K3", value: "SWC 62K3" },
          { label: "SWC 82H3", value: "SWC 82H3" },
          { label: "SWC 82K3", value: "SWC 82K3" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "SWC"]
      },
      warmtepomptype_SWCV: {
        name: "warmtepomptype_SWCV",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "SWCV 122H3", value: "SWCV 122H3" },
          { label: "SWCV 122K3", value: "SWCV 122K3" },
          { label: "SWCV 162H3", value: "SWCV 162H3" },
          { label: "SWCV 162K3", value: "SWCV 162K3" },
          { label: "SWCV 62H3", value: "SWCV 62H3" },
          { label: "SWCV 62K3", value: "SWCV 62K3" },
          { label: "SWCV 92H3", value: "SWCV 92H3" },
          { label: "SWCV 92K3", value: "SWCV 92K3" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "SWCV"]
      },
      warmtepomptype_SWP: {
        name: "warmtepomptype_SWP",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "SWP 1000H", value: "SWP 1000H" },
          { label: "SWP 1100", value: "SWP 1100" },
          { label: "SWP 291H", value: "SWP 291H" },
          { label: "SWP 371", value: "SWP 371" },
          { label: "SWP 451", value: "SWP 451" },
          { label: "SWP 561H", value: "SWP 561H" },
          { label: "SWP 581", value: "SWP 581" },
          { label: "SWP 691", value: "SWP 691" },
          { label: "SWP 700H", value: "SWP 700H" },
          { label: "SWP 850H", value: "SWP 850H" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "SWP"]
      },
      warmtepomptype_WZS: {
        name: "warmtepomptype_WZS",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "WZS 102H3M", value: "WZS 102H3M" },
          { label: "WZS 102K3M", value: "WZS 102K3M" },
          { label: "WZS 122K3M", value: "WZS 122K3M" },
          { label: "WZS 42H3M", value: "WZS 42H3M" },
          { label: "WZS 42K3M", value: "WZS 42K3M" },
          { label: "WZS 42K3M C", value: "WZS 42K3M C" },
          { label: "WZS 62K1M C", value: "WZS 62K1M C" },
          { label: "WZS 62K3M", value: "WZS 62K3M" },
          { label: "WZS 82H3M", value: "WZS 82H3M" },
          { label: "WZS 82K3M", value: "WZS 82K3M" },
          { label: "WZS 82K3M C", value: "WZS 82K3M C" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "WZS"]
      },
      warmtepomptype_WZSV: {
        name: "warmtepomptype_WZSV",
        label: "Warmtepomp type",
        type: "field",
        renderer: "react-select",
        fieldType: "text",
        options: [
          { label: "WZSV 122H3M", value: "WZSV 122H3M" },
          { label: "WZSV 122K3M", value: "WZSV 122K3M" },
          { label: "WZSV 162K3M", value: "WZSV 162K3M" },
          { label: "WZSV 42K3M", value: "WZSV 42K3M" },
          { label: "WZSV 62H3M", value: "WZSV 62H3M" },
          { label: "WZSV 62K3M", value: "WZSV 62K3M" },
          { label: "WZSV 92H3M", value: "WZSV 92H3M" },
          { label: "WZSV 92K3M", value: "WZSV 92K3M" }
        ],
        required: true,
        validation: [["string"], ["required"]],
        showWhen: ["is", "warmtepompserie", "WZSV"]
      },
      // "warmtepomp": {
      //     "name": "warmtepomp",
      //     "label": "Warmtepomp type",
      //     "type": "field",
      //     "renderer": "text",
      //     "fieldType": "text",
      //     "required": true,
      //     "validation": [["string"], ["required"]]
      // },
      // "warmtepomp": {
      //     "name": "warmtepomp",
      //     "label": "Warmtepomp type",
      //     "type": "field",
      //     "renderer": "react-select",
      //     "fieldType": "text",
      //     "options": [
      //         { "label": "WZS", "value": "WZS" },
      //         { "label": "WZSV", "value": "WZSV" },
      //         { "label": "SWC", "value": "SWC" },
      //         { "label": "SWCV", "value": "SWCV" },
      //         { "label": "SW", "value": "SW" },
      //         { "label": "LWD", "value": "LWD" },
      //         { "label": "LWDV", "value": "LWDV" },
      //         { "label": "LWAV", "value": "LWAV" }
      //     ],
      // },
      // "wamtepompAanvulling": {
      //     "name": "wamtepompAanvulling",
      //     "label": "Wamtepomp aanvulling",
      //     "type": "field",
      //     "renderer": "text",
      //     "fieldType": "text"
      // }
      // "inbedrijfstellerNaam": {
      //     "name": "inbedrijfstellerNaam",
      //     "label": "Naam inbedrijfsteller",
      //     "type": "field",
      //     "renderer": "text",
      //     "fieldType": "text",
      //     "required": true,
      //     "validation": [["string"], ["required"]]
      // },
      "eindgebruikerNaam": {
        "name": "eindgebruikerNaam",
        "label": "Naam eindgebruiker",
        "type": "field",
        "renderer": "text",
        "fieldType": "text"
      },
      "objectAdres": {
        "type": "container",
        "renderer": "fieldset",
        "title": "Object adres",
        "elements": {
          "objectStraatRow": {
            "type": "container",
            "renderer": "div",
            "htmlClass": "row",
            "elements": {
              "postcodeCol": {
                "type": "container",
                "renderer": "div",
                "htmlClass": "col",
                "elements": {
                  "objectStraat": {
                    "name": "objectStraat",
                    "label": "Straat",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "required": true,
                    "validation": [["string"], ["required"]],
                  }
                }
              }
            }
          },
          "objectHuisnummer": {
            "type": "container",
            "renderer": "div",
            "htmlClass": "row",
            "elements": {
              "huisnummerCol": {
                "type": "container",
                "renderer": "div",
                "htmlClass": "col col-md-8",
                "elements": {
                  "objectHuisnummer": {
                    "name": "objectHuisnummer",
                    "label": "Huisnummer",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "required": true,
                    "validation": [["string"], ["required"]],
                  }
                }
              },
              "toevoegingCol": {
                "type": "container",
                "renderer": "div",
                "htmlClass": "col col-md-4",
                "elements": {
                  "objectHuisnummerToevoeging": {
                    "name": "objectHuisnummerToevoeging",
                    "label": "Toevoeging",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text"
                  }
                }
              }
            }
          },
          "objectAdresGebied": {
            "type": "container",
            "renderer": "div",
            "htmlClass": "row",
            "elements": {
              "postcodeCol": {
                "type": "container",
                "renderer": "div",
                "htmlClass": "col",
                "elements": {
                  "objectPostcode": {
                    "name": "objectPostcode",
                    "label": "Postcode",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "required": true,
                    "validation": [["string"], ["required"], ["min", 6]]
                  }
                }
              },
              "plaatsCol": {
                "type": "container",
                "renderer": "div",
                "htmlClass": "col",
                "elements": {
                  "objectPlaats": {
                    "name": "objectPlaats",
                    "label": "Plaats",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "required": true,
                    "validation": [["string"], ["required"]]
                  }
                }
              }
            }
          }
        }
      },
      "objectTelefoonnummer": {
        "name": "objectTelefoonnummer",
        "label": "Telefoonnummer",
        "type": "field",
        "renderer": "text",
        "fieldType": "text"
      },
      "objectsoort": {
        "name": "objectsoort",
        "label": "Objectsoort",
        "type": "field",
        "renderer": "react-select",
        "fieldType": "text",
        "options": [
          { "label": "Woonhuis", "value": "Woonhuis" },
          { "label": "Bedrijf", "value": "Bedrijf" },
          { "label": "School", "value": "School" },
          { "label": "Winkel", "value": "Winkel" },
          { "label": "Ziekenhuis", "value": "Ziekenhuis" },
          { "label": "Woonboot", "value": "Woonboot" },
          { "label": "Anders", "value": "Anders" }
        ],
        "required": true,
        "validation": [["string"], ["required"]]
      },
      "loggingUpload": {
        "name": "loggingUpload",
        "label": "Logging uploaden",
        "type": "field",
        "renderer": "file-uploader",
        "options": {
          "accept": [".csv"],
          "multiple": false,
          "onDrop": (formik: any, config: any, acceptedFiles: any[]) => FormikUtil.addFiles(formik, "loggingUpload", acceptedFiles)
        },
        "required": true
      }
    }
  }