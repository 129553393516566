import React from "react";
import { AuthService } from "../services/AuthService";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { NavigateToAuth } from "../NavigateTo";
import { Button } from "reactstrap";
import Analytics from "../components/analytics/Analytics";
import { UserRepository } from "../repositories/UserRepository";
import { PrimaryButton } from "@fluentui/react";

class ResetPasswordPage extends React.Component<WrappedComponentProps> {
    private onResetPasswordClick = () => {
        Analytics.registerAction(this.props.intl.formatMessage({ id: "analytics.actions.general.resetPassword" }));

        if (UserRepository.getMe()) {
            AuthService.RegisterResetPassword()
                .then(() => NavigateToAuth.ResetPassword());
        } else {
            NavigateToAuth.ResetPassword();
        }
    }

    render() {
        return (
            <div className="ResetPasswordPage pageContent">
                <h1><FormattedMessage id="pages.resetPassword.title" defaultMessage="Reset password" /></h1>
                <p><FormattedMessage id="pages.resetPassword.description" defaultMessage="Reset password" /></p>
                <PrimaryButton onClick={this.onResetPasswordClick}>
                    <FormattedMessage id="pages.resetPassword.submit.title" defaultMessage="Reset password" />
                </PrimaryButton>
            </div>
        );
    }
}

export default injectIntl(ResetPasswordPage, { forwardRef: true });