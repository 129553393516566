import React from "react";
import IFormField, { FormFieldDependentOfType, FormFields } from "../components/models/FormField";
import { IFormA, HeatPumpTypeEnum, HeatPumpFunctionEnum, SanitationEnum, FloorHeatingTypeEnum, PostAdjustmentTypeEnum, TypeOfHouseEnum } from "../viewmodels/data";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import AForm from "./AForm";
import { FormService } from "../services/FormService";
import FormPage from "./FormPage";
import Locales from "../locales/Locales";
import { IFormTextProps } from "../components/Form";
import { Util } from "../Utils/Util";
import { IAppState } from "../viewmodels/app";
import { Row, Col, Button } from "reactstrap";
import { NavigateToPage } from "../NavigateTo";
import Analytics from "../components/analytics/Analytics";
import { on, PrimaryButton } from "@fluentui/react";
import { de } from "date-fns/locale";

interface IFormAProps extends WrappedComponentProps {

    onSubmitForm?: (formProps: IFormA) => Promise<void>;

}

export interface IFormAState {
    defaultValues: IFormA;
}

class FormA extends AForm<IFormAProps, IFormAState> {

    private fields: IFormField[] = [];
    private formPage: FormPage<IFormA> | null = null;

    private defaultValues: IFormA = {
        projectName: "",
        projectDescription: "",
        projectPostalCode: "",
        projectHouseNumber: "",

        companyName: "",
        companyPostalCode: "",
        companyAddress: "",
        companyCity: "",
        companyInstallerCode: "",
        givenName: "",
        initials: "",
        surname: "",
        gender: "",
        emailAddress: "",
        phoneNumber: "",

        heatPumpType: HeatPumpTypeEnum.none,
        heatPumpFunction: HeatPumpFunctionEnum.None,
        powerNeededInKW: 0,
        numberOfPeopleInHome: undefined,
        sanitation: SanitationEnum.none,

        floorHeatingType: FloorHeatingTypeEnum.none,
        postAdjustmentType: PostAdjustmentTypeEnum.none,
        basementLivingArea: -1,
        groundFloorLivingArea: -1,
        firstFloorLivingArea: -1,
        secondFloorLivingArea: -1,
        thirdFloorLivingArea: -1,

        floorArea: -1,
        heatLossCalculation: false,
        manualHeatingChoice: false,
        typeOfHouse: TypeOfHouseEnum.none
    }

    constructor(props: WrappedComponentProps) {
        super(props, "FormA");

        const text = this.text;
        const postalCode = this.postalCode;
        const posNumber = this.posNumber;
        const posInteger = this.posInteger;
        const header = this.header;
        const radio = this.radio;
        const radioEnum = this.radioEnum;

        const projectPostalCodeValidationFn = (field: IFormField, value: any): string[] =>
            new RegExp(/^[0-9]{4}\s*[a-z]{2}$/gi).test(value && value.trim() || undefined) === false ? [`VALIDATION_PROJECTPOSTALCODE_INVALID|${field.name}`] : [];

        const heatPumpDependentOfFn = (fieldName: string, value: any): boolean =>
            fieldName === "heatPumpType" ? !Util.isNothing(value) && value !== "groundBound" : true;

        const dependentOfPowerNeededInKWFn = (fieldName: string, value: any) =>
            fieldName === "heatPumpType" ? !Util.isNothing(value) :
                fieldName === "powerNeededInKW" ? value == 0 :
                    true;

        const powerNeededInKWValidationFn = (field: IFormField, value: any): string[] =>
            !Util.isNumber(value) || value < 0 ? [`VALIDATION_POWERNEEDEDINKW_ISNOTHING|${field.name}`] : [];

        const floorAreaValidationFn = (field: IFormField, value: any): string[] =>
            value <= 0 ? [`VALIDATION_FLOORAREA_NOTZERO|${field.name}`] : [];

        const projectNameHint = 'Bijvoorbeeld: "Woning De Vries Paterswolde 9961GD"';
        const projectPostalCodeHint = 'Indien postcode nog onbekend is, voer postcode van omliggend gebied in';

        this.fields = [
            header("projectData", [
                text("projectName", true, projectNameHint),
                postalCode("projectPostalCode", true, projectPostalCodeHint, undefined, undefined, undefined, projectPostalCodeValidationFn),
                text("projectHouseNumber", true)
            ]),
            header("requestForOneHome", [
                radioEnum("heatPumpType", HeatPumpTypeEnum, false, true, true),
                radioEnum("heatPumpFunction", HeatPumpFunctionEnum, true, false, true, undefined, "heatPumpType", FormFieldDependentOfType.And, heatPumpDependentOfFn),
                posNumber("powerNeededInKW", true, undefined, "heatPumpType", undefined, undefined, powerNeededInKWValidationFn),
                posInteger("floorArea", true, undefined, ["heatPumpType", "powerNeededInKW"], FormFieldDependentOfType.And, dependentOfPowerNeededInKWFn, floorAreaValidationFn),
                radioEnum("typeOfHouse", TypeOfHouseEnum, true, undefined, true, undefined, ["heatPumpType", "powerNeededInKW"], FormFieldDependentOfType.And, dependentOfPowerNeededInKWFn),
                radio("numberOfPeopleInHome", ["1", "2", "3", "4", "5+"], false, false, true, undefined, "heatPumpType"),
                radioEnum("sanitation", SanitationEnum, true, false, true, undefined, "heatPumpType"),

                radioEnum("floorHeatingType", FloorHeatingTypeEnum, false, true, true),
                radioEnum("postAdjustmentType", PostAdjustmentTypeEnum, false, true, true),
                posInteger("basementLivingArea", false, undefined, ["floorHeatingType", "postAdjustmentType"]),
                posInteger("groundFloorLivingArea", false, undefined, ["floorHeatingType", "postAdjustmentType"]),
                posInteger("firstFloorLivingArea", false, undefined, ["floorHeatingType", "postAdjustmentType"]),
                posInteger("secondFloorLivingArea", false, undefined, ["floorHeatingType", "postAdjustmentType"]),
                posInteger("thirdFloorLivingArea", false, undefined, ["floorHeatingType", "postAdjustmentType"])
            ])
        ];

        this.state = {
            defaultValues: this.defaultValues
        };
    }

    onRef = (instance: FormPage<IFormA> | null) => {
        this.formPage = instance;
    }

    public setValues = (values: IFormA) => this.formPage ? this.formPage.setValues(values) : null;

    private onSubmit = async (values: IFormA) => {
        const { onSubmitForm } = this.props;
        console.log("FormA.onSubmit values, onSubmitForm", values, onSubmitForm);
        if (onSubmitForm) {
            await onSubmitForm(values);
        } else {
            this.appRepo.setState("/Forms/FormA", values);
            const queueItem = FormService.submitA(values, this.props.intl.formatMessage({ id: "forms.FormA.name", defaultMessage: "Prijsindicatie" }));
            console.log(queueItem);
        }
    }

    private onValidate = (fields: IFormField[], values: IFormA): string[] =>
        Util.isNothing(values["heatPumpType"]) &&
            Util.isNothing(values["floorHeatingType"]) &&
            Util.isNothing(values["postAdjustmentType"]) ?
            [`VALIDATION_INVALIDSELECTION|heatPumpType;floorHeatingType;postAdjustmentType`] :
            [];

    private getCodeMessage = (code: string, fieldName: string) => {
        
        const record = {
            fieldLabel:
                fieldName.indexOf(';') >= 0 ?
                    fieldName.split(';').map(fieldName => FormFields.getFieldLabelByName(this.fields, fieldName)).join("', '") :
                    FormFields.getFieldLabelByName(this.fields, fieldName)
        };
        
        const defaultMessage = fieldName.indexOf(';') >= 0 ? `Ingevulde waardes voor '{fieldLabel}' zijn ongeldig.` : `Ingevulde waarde voor '{fieldLabel}' is ongeldig.`;
        let message = this.props.intl.formatMessage({
            id: `validation.${code}`,
            defaultMessage: defaultMessage
        });

        for (var key in record) {
            message =message.replace(`{${key}}`, record[key]);
        }

        return message;
    }

    private getCodeMessages = (code: string, fieldNames: string[]) => {
        const fieldLabels = fieldNames.map(fieldName => FormFields.getFieldLabelByName(this.fields, fieldName));

    }

    protected onAppState = (appState: IAppState): Promise<void> =>
        new Promise((resolve, reject) => {
            if (appState.data) {
                this.setValues(appState.data);

                resolve();
            } else {
                reject("APPSTATE_INVALID");
            }
        });

    private onBackToMain = () => {
        Analytics.registerPrijsindicatieAction(this.props.intl.formatMessage({ id: 'analytics.actions.prijsindicatie.backToOverview' }));
        NavigateToPage.Home();
    }

    render() {
        const locales = new Locales(this.props);
        const textProps: IFormTextProps = {
            textIsSubmitting: locales.getForFormComponent("isSubmitting", "Submitting form"),
            textFormSentTitle: locales.getForFormComponent("formSent.title", "Form sent"),
            textFormSentMessage: locales.getForFormComponent("formSent.message", "Form has been sent."),
            textFormSendingErrorTitle: locales.getForFormComponent("formSendingError.title", "Error"),
            textFormSendingErrorMessage: locales.getForFormComponent("formSendingError.message", "An error occurred while sending form."),
            textBackToMain: locales.getForFormComponent("backToMain"),
            textBackToForm: locales.getForFormComponent("backToForm"),
            textToOverview: locales.getForFormComponent("textToOverview"),
            textFormLegendIsRequired: locales.getForFormComponent("legendIsRequired")
        };
        const formTitle = locales.getForForm("FormA", "title", "Form A");

        return (
            <div className="page-FormA pageContent">

                <Row id="page-PriceIndicationsOverview-actions">
                    <Col className="text-right">
                        <PrimaryButton onClick={this.onBackToMain}>
                            <FormattedMessage id="general.backToMain" defaultMessage="Back to main" />
                        </PrimaryButton>
                    </Col>
                </Row>

                <FormPage<IFormA>
                    className="FormA"
                    formName={this.formName}
                    title={formTitle}
                    fields={this.fields}
                    defaultValues={this.state.defaultValues}
                    onSubmit={this.onSubmit}
                    onValidate={this.onValidate}
                    getCodeMessage={this.getCodeMessage}
                    ref={this.onRef}
                    {...textProps} />
            </div>)
    }
}

export default injectIntl(FormA, { forwardRef: true });