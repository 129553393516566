import { WrappedComponentProps } from "react-intl";
import IFormField, { FormFieldType, TextFormField, CheckboxFormField, PositiveNumberFormField, PositiveIntegerFormField, HeaderFormField, RadioFormField, IFormOption, FormFieldDependentOfType, PostalCodeFormField } from "../components/models/FormField";
import React from "react";
import { Util } from "../Utils/Util";
import { IAppState } from "../viewmodels/app";
import AppRepository from "../repositories/AppRepository";
import MijnNathanService from "../services/MijnNathanService";
import FormRepository from "../repositories/FormRepository";

export default abstract class AForm<P extends WrappedComponentProps, S> extends React.Component<P, S> {

    protected formName: string = "";
    protected appRepo = new AppRepository();
    protected formRepo = new FormRepository();

    constructor(props: any, formName: string) {
        super(props);

        this.formName = formName;
    }

    componentDidMount() {
        // restore app state
        const appState = this.appRepo.getState();
        if (appState) {
            this.onAppState(appState)
                .then(() => this.appRepo.clearState())
                .catch(reason => MijnNathanService.CatchId(() => { }, 'errors.appState.invalid', reason));
        }
    }

    protected abstract onAppState : (appState: IAppState) => Promise<void>;

    public translate = (fieldName: string) => {
        let defaultValue = fieldName;
        if (defaultValue.indexOf('.') > 0) {
            const values = defaultValue.split('.');
            defaultValue = values[values.length - 1];
        }
        return this.props.intl.formatMessage({ id: `forms.${this.formName}.${fieldName}`, defaultMessage: defaultValue });
    }

    public field = (fieldName: string, fieldType: FormFieldType, fields?: IFormField[], required?: boolean): IFormField => {
        return {
            name: fieldName,
            label: this.translate(fieldName),
            type: fieldType,
            fields: fields,
            required: required
        }
    }

    public text = (fieldName: string, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        new TextFormField(fieldName, this.translate(fieldName), required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public postalCode = (fieldName: string, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        new PostalCodeFormField(fieldName, this.translate(fieldName), required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public checkbox = (fieldName: string, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        new CheckboxFormField(fieldName, this.translate(fieldName), required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public posNumber = (fieldName: string, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        new PositiveNumberFormField(fieldName, this.translate(fieldName), required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public posInteger = (fieldName: string, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        new PositiveIntegerFormField(fieldName, this.translate(fieldName), required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public radio = (fieldName: string, options: string[], excludeNoneValue?: boolean, putNoneValueLast?: boolean, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField => {
        let radioOptions = options.map(option => {
            const optionValue = option; // option != "none" ? option : "";
            return { value: optionValue, label: this.translate(`${fieldName}.${optionValue}`) } as IFormOption
        }
        );
        if (excludeNoneValue) {
            radioOptions.shift();
        } else if (putNoneValueLast) {
            const noneValue = radioOptions.shift();
            if (noneValue) {
                radioOptions.push(noneValue);
            }
        }

        return new RadioFormField(fieldName, this.translate(fieldName), radioOptions, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);
    }

    public radioEnum = (fieldName: string, options: any, excludeNoneValue?: boolean, putNoneValueLast?: boolean, required?: boolean, hint?: string,
        dependentOf?: string | string[], dependentOfType?: FormFieldDependentOfType, dependentOfFn?: (fieldName: string, value: any) => boolean,
        validationFn?: (field: IFormField, value: any) => string[]): IFormField =>
        this.radio(fieldName, Util.GetStringKeysOfEnum(options), excludeNoneValue, putNoneValueLast, required, hint, dependentOf, dependentOfType, dependentOfFn, validationFn);

    public header = (fieldName: string, fields: IFormField[]): IFormField => new HeaderFormField(this.translate(fieldName), fields);
}