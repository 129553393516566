import { IForm } from "../viewmodels/form";

export default class FormRepository {
    private readonly FormStateStorageKeyPrefix = "form.state";

    private getKey = (formName: string) => `${this.FormStateStorageKeyPrefix}.${formName}`;

    public get = (formName: string): IForm | null => {
        const dataString = localStorage.getItem(this.getKey(formName));
        return dataString ? JSON.parse(dataString) : null;
    }

    public save = (formName: string, data: any) => localStorage.setItem(this.getKey(formName), JSON.stringify(data));

    public remove = (formName: string) => localStorage.removeItem(this.getKey(formName));
}