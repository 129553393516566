export default class KeyValue {

    public Key: string;
    public Value: string;


    constructor(key: string, value: string) {
        this.Key = key;
        this.Value = value;
    }

    public Equals = (other:KeyValue) : boolean => this.Key == other.Key && this.Value == other.Value;
    public EqualsKey = (other:KeyValue) : boolean => this.Key == other.Key;


}