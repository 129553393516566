import KeyValue from "../components/KeyValue";

export default class ArrayUtils {

    static distinct<T>(arr: T[], keySelector?: (obj: T) => string | number): T[] {

        if (!arr || arr.length < 2) return arr;

        if (!keySelector && arr[0] instanceof KeyValue) {
            keySelector = (t1: any) => (t1 as KeyValue).Key;
            // compareIsEquals = (t1: any, t2: any) => (t1 as KeyValue).Key == (t2 as KeyValue).Key;
        }

        if (!keySelector) {
            const onlyUnique = (value: T, index: number, self: T[]) => {
                return self.indexOf(value) === index;
            }
            const unique = arr.filter(onlyUnique);
            return unique;
        }

        const nm = new Map();
        arr.forEach(i => {
            const k = keySelector!(i);
            nm.set(k, i);
        })

        const resultArr = Array.from(nm.values()) as T[];
        return resultArr;
    }

    static contains<T, TKey>(arr: T[], selector: (item: T) => TKey, refValue: TKey): boolean {
        return arr.map(selector).indexOf(refValue) >= 0;
    }

    static indexOf<T>(arr: T[], element: T, compareIsEquals?: (t1: T, t2: T) => boolean): number {
        if (compareIsEquals) {
            for (let i = 0; i < (arr.length - 1); i++) {
                if (compareIsEquals(element, arr[i])) return i;
            }
            return -1;
        }

        for (let i = 0; i < (arr.length - 1); i++) {
            if (element == arr[i]) return i;
        }
        return -1;
    }

    static NewArr = (lenght: number): number[] => {
        let result: number[] = [];
        for (let i = 0; i < lenght; i++) {
            result.push(i);
        }
        return result;
    }

    static FlattenArray = <T>(twoDimensionArray: T[][]): T[] => {
        const flattArray = ([] as T[]).concat(...twoDimensionArray);
        return flattArray;
    }

    static GroupBy = <T>(flatArr: T[], selector: (item: T) => string): { [key: string]: T[] } => {
        const result: { [key: string]: T[] } = {}
        flatArr.forEach(el => {
            const key = selector(el);
            if (key in result) {
                result[key].push(el);
            } else {
                result[key] = [el]
            }
        });
        return result;
    }

    static ToLookup = <T>(flatArr: T[], selectorKey: (item: T) => string): { [key: string]: T } => {
        const result: { [key: string]: T } = {}
        flatArr.forEach(el => {
            const key = selectorKey(el);
            result[key] = el;
        });
        return result;
    }

    static Range = (start: number, end: number) => {
        let result: number[] = [];
        for (let i = start; i <= end; i++) result.push(i);
        return result;
    }

    static Sum = (arr: number[] | (number | undefined)[] | undefined): number | undefined => {
        if (!arr) return undefined;
        const filteredArr = arr.filter(n => n != undefined) as number[];
        if (filteredArr.length == 0) return undefined;
        const result = filteredArr.reduce((total, value) => total + value, 0);
        return result;
    }

    static SumSelect = <T>(arr: T[] | undefined, selector: (item: T) => number | undefined): number | undefined => {
        if (!arr) return undefined;
        const arrNumbers = arr.map(item => selector(item));
        const result = ArrayUtils.Sum(arrNumbers);
        return result;
    }

}